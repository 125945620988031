<template>
    <div v-if="hasInformation">
        <div class="text-black has-text-weight-semibold fs-22 px-5 py-3 position-relative has-background-white">
            Important info for you
        </div>
        <BCollapse
            v-for="(information, index) in informations"
            class="show"
            animation="slide"
            :key="index"
            :open="isOpen === index"
            @open="isOpen = index"
            :aria-id="'service' + index">
            <template #trigger="props">
                <div
                    class="card-header is-shadowless is-radiusless border-0 mx-5 px-0"
                    :class="{'border-bottom app-grey--light-border': informations.length - 1 !== index}"
                    role="button"
                    :aria-controls="'information-' + index"
                    :aria-expanded="props.open"
                >
                    <div v-if="information.imageUrl" class="is-flex is-align-items-center" style="width: 35px">
                        <img :src="information.imageUrl"/>
                    </div>
                    <p class="card-header-title mb-0 px-0 py-2 has-text-black has-text-weight-semibold">{{ information.title }}</p>
                    <a class="card-header-icon p-0">
                        <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    {{ information.content }}
                </div>
            </div>
        </BCollapse>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "Info",
    mounted() {
        this.loadData();
    },
    computed: {
        informations() {
            return this.ids ? this.$store.getters['information/collection'](this.ids) : [];
        },
        hasInformation() {
            return this.informations.length > 0;
        }
    },
    data() {
        return {
            isOpen: null,
            loading: false,
            ids: []
        }
    },
    methods: {
        ...mapActions({
            informationIndex: 'information/index',
        }),
        loadData() {
            this.loading = true
            this.informationIndex({
                params: {
                    status: 'published'
                }
            }).then((response) => {
                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
