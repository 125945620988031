const Router =  {
    goToLastRoute: (router) => {
        const routeHistory = JSON.parse(localStorage.getItem('routeHistory'));
        if (routeHistory && routeHistory.length) {
            router.push(routeHistory.at(-1));
        } else {
            router.back();
        }
    }
}

export default Router;
