import { ResourceService } from '@/internal/modules';

export default class CartServiceResource extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'cart';
    }

    add(payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'post',
            url: `${this.baseUrl}/add`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest)
        });

    }

}
