<template>
  <Dialog v-model="open" :title="isEdit ? 'Edit Rule' : 'Add Rule'" @submit="handleSave" @close="handleClose">
    <b-loading :is-full-page="true" v-model="loading"></b-loading>

    <div class="flex:between|center gap:12 mb:12">
      <div class="flex:1">
        <label class="--label mb:4">Rule</label>
        <SelectInput placeholder="Select rule" v-model="form.fields.type" :options="typeOptions" />
        <FormError field="type" :form="form" />
      </div>

      <div class="flex:1">
        <label class="--label mb:4">Value</label>
        <input class="--input" type="number" placeholder="Input number" v-model="form.fields.value" />
        <FormError field="value" :form="form" />
      </div>
    </div>

    <div v-if="showTimePicker" class="flex:between|center gap:12 mb:12">
      <div class="flex:1">
        <label class="--label mb:4">From</label>
        <b-timepicker
          placeholder="From"
          icon="clock"
          :incrementMinutes="15"
          append-to-body
          v-model="form.fields.startTime"
        />
        <FormError field="start_time" :form="form" />
      </div>
      <div class="flex:1">
        <label class="--label mb:4">To</label>
        <b-timepicker
          placeholder="To"
          icon="clock"
          :incrementMinutes="15"
          append-to-body
          v-model="form.fields.endTime"
        />
        <FormError field="end_time" :form="form" />
      </div>
    </div>
    <div class="mb:12">
      <label class="--label mb:4">Apply</label>
      <SelectInput
        placeholder="Select when rule is applied"
        v-model="form.fields.applyType"
        :options="applyTypeOptions"
      />
      <FormError field="type" :form="form" />
    </div>
    <div v-if="form.fields.applyType === 'days-of-week'">
      <label class="--label mb:4">Week days</label>
      <div class="flex:|center gap:8 flex-wrap">
        <b-checkbox
          v-for="weekDay in weekDaysOptions"
          v-model="form.fields.applyTypeConfig.weekDays"
          :native-value="weekDay.value"
          :key="weekDay.value"
        >
          {{ weekDay.label }}
        </b-checkbox>
      </div>
    </div>
    <div v-if="form.fields.applyType === 'specific-dates'">
      <label class="--label mb:4">Specific dates</label>
      <CalendarInput placeholder="Click to select..." v-model="form.fields.applyTypeConfig.dates" multiple />
    </div>
    <div v-if="form.fields.applyType === 'date-interval'" class="field">
      <label class="--label mb:4">Date range</label>
      <CalendarInput placeholder="Click to select..." v-model="form.fields.applyTypeConfig.dateRange" range />
    </div>
  </Dialog>
</template>

<script>
import { Form } from '@/internal/modules'

import { ServiceRule } from '@/internal'
import Dialog from '@/components/admin/Dialog.vue'
import CalendarInput from '@/components/admin/CalendarInput.vue'
import { mapActions } from 'vuex'
import mixin from '@/mixin'
import moment from 'moment'

export default {
  name: 'RulesDialog',
  mixins: [mixin],
  components: {
    Dialog,
    CalendarInput,
  },
  props: {
    rule: {
      type: Object || null,
      required: false,
    },
  },
  data() {
    return {
      open: false,
      loading: false,
      form: new Form({
        serviceId: this.$route.query.show,
        type: null,
        value: 0,
        startTime: moment('00:00', 'HH:mm').toDate(),
        endTime: moment('00:00', 'HH:mm').toDate(),
        applyType: 'everyday',
        applyTypeConfig: {
          weekDays: [],
          dates: [],
          dateRange: [],
        },
      }),
      datePickerLocale: undefined,
      typeOptions: ServiceRule.typeOptions,
      applyTypeOptions: ServiceRule.applyTypeOptions,
      weekDaysOptions: ServiceRule.weekDaysOptions,
    }
  },
  computed: {
    showTimePicker() {
      return (
        this.form.fields.type &&
        ['max-persons-per-interval', 'max-bookings-per-interval'].includes(this.form.fields.type)
      )
    },
    service() {
      return this.form.fields.serviceId ? this.$store.getters['service/show'](this.form.fields.serviceId) : null
    },
    isEdit() {
      return !!this.form.fields.id
    },
  },
  methods: {
    ...mapActions({
      serviceRuleStore: 'serviceRule/store',
      serviceRuleUpdate: 'serviceRule/update',
    }),
    fillForm(fields) {
      this.form.fields = {
        ...this.form.fields,
        ...fields,
        startTime: moment(fields.startTime, 'HH:mm:ss').toDate(),
        endTime: moment(fields.endTime, 'HH:mm:ss').toDate(),
        applyTypeConfig: {
          ...fields.applyTypeConfig,
          dates: fields.applyTypeConfig.dates ? this.parseDates(fields.applyTypeConfig.dates) : [],
          dateRange: fields.applyTypeConfig.dateRange ? this.parseDates(fields.applyTypeConfig.dateRange) : [],
        },
      }
    },
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        startTime: this.form.fields.startTime ? moment(this.form.fields.startTime).format('HH:mm') : null,
        endTime: this.form.fields.endTime ? moment(this.form.fields.endTime).format('HH:mm') : null,
        applyTypeConfig: {
          ...this.form.fields.applyTypeConfig,
          dates: this.form.fields.applyTypeConfig.dates ? this.formatDates(this.form.fields.applyTypeConfig.dates) : [],
          dateRange: this.form.fields.applyTypeConfig.dateRange
            ? this.formatDates(this.form.fields.applyTypeConfig.dateRange)
            : [],
        },
      }

      let action = null
      if (this.isEdit) {
        action = this.serviceRuleUpdate
      } else {
        action = this.serviceRuleStore
      }

      action(fields)
        .then((response) => {
          this.clearForm()
          this.open = false
          this.$emit('onSave', response)
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDates(dates) {
      if (!dates || dates.length < 1) {
        return []
      }

      let formattedDates = []
      dates.forEach((date) => {
        formattedDates.push(moment(date).format('YYYY-MM-DD'))
      })

      return formattedDates
    },
    parseDates(dates) {
      if (!dates || dates.length < 1) {
        return []
      }

      let parsedDates = []
      dates.forEach((date) => {
        parsedDates.push(moment(date).toDate())
      })

      return parsedDates
    },
    handleClose() {
      this.clearForm()
      this.$emit('close')
    },
    clearForm() {
      this.form.fields = {
        serviceId: this.$route.query.show,
        startTime: moment('00:00', 'HH:mm').toDate(),
        endTime: moment('00:00', 'HH:mm').toDate(),
        type: null,
        value: 0,
        applyType: 'everyday',
        applyTypeConfig: {
          weekDays: [],
          dates: [],
          dateRange: [],
        },
      }
    },
  },
}
</script>

<style scoped lang="scss">
.modal-card-head {
  p {
    margin-bottom: 0;
  }
}
.modal-card-body {
  min-height: 175px;
}
.modal-card-foot {
  background-color: #fff;
  border-top: none;
}
</style>
