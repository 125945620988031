<template>
    <div>
        <BLoading :is-full-page="true" v-model="loading"></BLoading>
        <section v-if="service" class="section height-full min-height-full">
            <div class="container container-extra-small p-0 height-full">
                <div class="is-flex is-flex-direction-column content-height has-background-white is-justify-content-center height-full">
                    <div class="columns flex-column p-5">
                        <div class="column has-background-white p-0">
                            <div class="bill p-5">
                                <div class="fs-20 has-text-black has-text-weight-semibold mb-3">{{ service.nameFinal }}</div>
                                <p class="has-text-weight-normal">{{ booking.date }} at {{ booking.time }} for {{ booking.duration}}</p>
                                <div class="is-flex justify-content-between py-3 has-text-grey">
                                    <div class="fs-16 has-text-black has-text-weight-normal">Person</div>
                                    <div class="fs-16 has-text-black has-text-weight-semibold">x{{ booking.group }}</div>
                                </div>
                                <div class="is-flex justify-content-between align-items-center py-3 has-text-grey" :class="{'border-bottom-grey': showTotalCost }">
                                    <div class="fs-16 has-text-black has-text-weight-normal">Duration</div>
                                    <div class="text-end">
                                        <div class="fs-16 has-text-black has-text-weight-semibold">{{ booking.duration }}</div>
                                        <div class="fs-12 app-grey has-text-weight-normal">{{ booking.quantity }} sessions</div>
                                    </div>
                                </div>
                                <div v-if="showTotalCost" class="is-flex justify-content-between is-size-4 py-3  border-bottom-grey">
                                    <div class="fs-14 has-text-black has-text-weight-semibold lh-36">TOTAL COST</div>
                                    <div class="fs-16 has-text-weight-semibold">In Package</div>
                                </div>

                                <div class="is-flex justify-content-between py-3">
                                    <BField class="is-flex-grow-1" label="Note">
                                        <BInput type="text" placeholder="Add your comment (Optional)" v-model="form.fields.note"></BInput>
                                    </BField>
                                </div>

                                <FormError field="message" :form="form"/>

                                <div class="is-flex py-3">
                                    <b-button class="button is-black border-radius-12 is-clickable is-fullwidth" @click="handleConfirmation">CONFIRM</b-button>
                                </div>
                            </div>
                            <div class="is-flex is-align-items-center is-justify-content-center is-clipped">
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                                <div class="bill-arrow-down"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import moment from "moment";
import {mapActions} from "vuex";
import mixin from "@/mixin";
import {
    Form,
} from '@/internal/modules';

import {
    Booking,
} from '@/internal';

export default {
    name: "BookActivityConfirmation",
    mixins: [mixin],
    data() {
        return {
            form: new Form({
                date: this.$route.query.date ? moment(this.$route.query.date).toDate() : new Date(),
                from: this.$route.query.from ? this.$route.query.from : null,
                quantity: 1,
                group: {
                    adults: this.$route.query.adultsNumber ? this.$route.query.adultsNumber : 1,
                    children: 0
                },
                note: null,
                origin_platform: Booking.originPlatformGuestApp
            }),
            serviceId: null,
            loading: false,
            timeRange: null,
        };
    },
    created() {
        this.fillFormFromStorage();
        this.loadService();
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
        booking() {
            return {
                date: this.form.fields.date ? this.dateFormat(this.form.fields.date) : "",
                time: this.timeFormatTimezone(this.form.fields.from).format("HH:mm"),
                price: this.totalPrice,
                quantity: this.form.fields.quantity,
                group: this.groupFormat(this.form.fields.group),
                duration: this.timeFormat(this.form.fields.quantity * this.service.duration)
            }
        },
        date() {
            return this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null;
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        totalPrice() {
            return this.form.fields.quantity * this.service.price * this.totalPersons;
        },
        totalPersons() {
            const adults_number = this.form.fields.group ? this.form.fields.group.adults : 0;
            const children_number = this.form.fields.group ? this.form.fields.group.children : 0;

            return adults_number + children_number;
        },
        showTotalCost() {
            return this.form.fields.nonScheduledBookingId;
        }
    },
    methods: {
        ...mapActions({
            bookingStore: 'booking/store',
            serviceShow: 'service/show',
            nonScheduledBookingReset: 'nonScheduledBooking/reset'
        }),
        handleConfirmation() {
            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
                date: this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null,
                adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                children_number: this.form.fields.group ? this.form.fields.group.children : null,
                service_id : this.service.id,
                service_type: this.service.type,
            }

            this.loading = true;

            this.bookingStore(fields).then((response) => {
                const route = window.app.findRouteByName('booking/confirmation', [], {
                    query: {
                        bookingDate: moment(this.form.fields.date).format('YYYY-MM-DD')
                    },
                });

                this.$router.push(route);

                this.clearFormStorage();
                // Clear store for non-scheduled bookings
                this.nonScheduledBookingReset();
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        groupFormat(value) {
            let values = [];

            if(value.adults) {
                if(value.adults > 1) {
                    values.push(value.adults + " adults");
                } else {
                    values.push(value.adults + " adult");
                }
            }

            if(value.children) {
                if(value.children > 1) {
                    values.push(value.children + " children");
                } else {
                    values.push(value.children + " child");
                }
            }

            return values.join(" ");
        },
        timeFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        loadService() {
            this.loading = true;

            const data = {
                id: this.form.fields.serviceId,
            }

            return this.serviceShow(data).then((response) => {
                this.serviceId = response[0];
            }).finally(() => {
                this.loading = false;
            });
        },
        fillFormFromStorage() {
            const fields =  window.app.storage.getItem('booking');

            this.form.fields = {
                ...fields,
                date: new Date(fields.date)
            };

        },
        clearFormStorage() {
            window.app.storage.removeItem('booking')
        },
        saveFormStorage() {
            window.app.storage.setItem('booking', this.form.fields)
        },
    },
    watch: {
        'form.fields': {
            async handler() {
                this.saveFormStorage();
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
.bill {
    box-shadow: 0 0 114px rgba(0, 0, 0, 0.1), 0 12px 39px rgba(0, 0, 0, 0.16);
    border-radius: 24px 24px 0 0;

    &-arrow-down {
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 24px solid #ffffff;

        bottom: -24px;
    }
}
</style>
