<template>
    <div>
        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/category">Categories</router-link>
                            </li>
                            <li>
                                {{ category ? category.name : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-field label="Name *" >
                                    <b-input type="text" v-model="form.fields.name">
                                    </b-input>
                                </b-field>
                                <FormError field="name" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Description" >
                                    <b-input type="textarea" v-model="form.fields.description">
                                    </b-input>
                                </b-field>
                                <FormError field="description" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Type">
                                    <b-select placeholder="Select type" v-model="form.fields.type">
                                        <option
                                            v-for="option in typeOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Parent Category">
                                    <b-select placeholder="Select category" v-model="form.fields.categoryId">
                                        <option
                                            v-for="option in categoryOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <label class="label">Images</label>
                                <FileUpload v-model="form.fields.files" :entity="category" :multiple="true" :extensions="['jpg','jpeg','png']"></FileUpload>
                            </div>
                            <hr/>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Category,
} from '@/internal';

import FileUpload from '@/views/Admin/components/FileUpload';

export default {
    name: 'Form',
    components: {
        FileUpload,
    },
    computed: {
        categories() {
            return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : [];
        },
        categoryOptions() {
            return this.categories ? [
                {
                    label: '-- Select Category --',
                    value: null,
                },
                ...this.categories.filter(category => {
                    // Skip listing current entity(category) if present
                    if(this.category) {
                        return category.id !== this.category.id
                    } else {
                        return true;
                    }
                }).map((category) => {
                    return {
                        label: category.name,
                        value: category.id,
                    };
                })
            ] : [];
        },
        category() {
            return this.$route.params.categoryId ? this.$store.getters['category/show'](this.$route.params.categoryId) : null;
        },
    },
    created() {
        this.loadCategories();

        if(this.category) {
            this.form.fields = {
                ...this.category,
                files: this.category.files ? this.category.files : []
            }
        }
    },
    data() {
        return {
            categoryIds: [],
            form: new Form({
                name: '',
                description: '',
                type: null,
                files: [],
                entityType: Category.entityTypeService
            }),
            loading: false,
            typeOptions: Category.typeOptions
        };
    },
    methods: {
        ...mapActions({
            categoryDestroy: 'category/destroy',
            categoryStore: 'category/store',
            categoryUpdate: 'category/update',
            categoryIndex: 'category/index'
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
              ...this.form.fields
            };

            let action = null;

            if (this.category) {
                action = this.categoryUpdate;
            } else {
                action = this.categoryStore;
            }

            action(Category.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/category');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Category',
                message: 'Are you sure you want to delete this category?',
                onConfirm: () => {
                    this.loading = true;
                    this.categoryDestroy({
                        id: this.category.id,
                    }).then(() => {
                        window.app.snackbar('Category Deleted');

                        this.$router.push('/admin/category');

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadCategories() {
            this.categoryIndex({
                params: {
                    entityType: Category.entityTypeService
                }
            }).then((response) => {
                this.categoryIds = response.ids;
            }).catch((error) => {

            }).finally(() => {

            });
        }
    },
    watch: {
        category() {
            if(this.category) {
                this.form.fields = {
                    ...this.category,
                    files: this.category.files ? this.category.files : [],
                }
            }
        },
    }
};
</script>

<style lang="scss">


</style>
