<template>
    <section class="section p-0 pb-100">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <h1 class="text-black has-text-weight-semibold fs-22 px-5 py-3 position-relative has-background-white">Browse our available activities</h1>
                <h2 class="text-black px-5 mb-3">For: {{ date ? date.format("dddd, MMMM Do, YYYY") : '' }}</h2>
            </div>
            <ServiceCatalog />
        </div>
    </section>
</template>

<script>
import {mapActions} from "vuex";

import moment from 'moment';

import ServiceCatalog from "@/views/components/ServiceCatalog";

export default {
    name: "Add",
    components: {
        ServiceCatalog
    },
    computed: {
        date() {
            return this.$route.query.date ? moment(this.$route.query.date) : null;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    mounted() {
        this.query = {
            ...this.query,
            date: this.$route.query.date ? moment(this.$route.query.date).toDate() : null,
        }
    },
    data() {
        return {
            loading: false,
            query: {
                date: null,
            }
        }
    },
    methods: {
    }
}
</script>

<style lang="scss">

</style>


