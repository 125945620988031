import {
    Model,
} from '@/internal/modules';

import {
    ActiveBooking,
    Customer,
    Cart,
    Booking,
    UserService as Service
} from '@/internal';


export default class User extends Model {

  static get name() {
        return 'User';
  }

  static get vuexModuleKey() {
     return 'user';
  }

  static get service() {
      return Service;
  }

  static get fields () {
    return {
      twoFactorEnabled: {
        default: false,
        type: Boolean,
      },
      firstName: {
        required: true,
        type: String,
      },
      lastName: {
        required: true,
        type: String,
      },
      email: {
        required: true,
        type: String,
      },
    };
  }

  get activeBooking() {
      return this.hasOne(ActiveBooking);
  }

  get activityBookings() {
        return this.hasMany(Booking);
  }

  get customer() {
      return this.hasOne(Customer);
  }

  get cart() {
      return this.hasOne(Cart);
  }

  get bookings() {
      return this.hasMany(Booking);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  static get typeGuest() {
      return 'guest';
  };

  static get typeStaff() {
      return 'staff';
  };

}
