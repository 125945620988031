<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/service">Services</router-link>
                            </li>
                            <li>
                                {{ service ? service.name : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <div ref="publicUrlWrapper" v-if="service">
                        <b-button type="is-info is-light" icon-right="copy" :disabled="!service.hasPublicBookingUrl" @click="handleCopyPublicUrl()">
                            Public URL
                        </b-button>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">

            <FormMenu />

            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-field label="Name *" >
                                    <b-input type="text" v-model="form.fields.name">
                                    </b-input>
                                </b-field>
                                <FormError field="name" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Title" >
                                    <b-input type="text" v-model="form.fields.title">
                                    </b-input>
                                </b-field>
                                <FormError field="title" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Description" >
                                    <b-input type="textarea" v-model="form.fields.description">
                                    </b-input>
                                </b-field>
                                <FormError field="description" :form="form"/>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <b-field label="Duration (minutes)" >
                                            <b-input type="number" v-model="form.fields.duration">
                                            </b-input>
                                        </b-field>
                                        <FormError field="duration" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-field label="Buffer before (minutes)" >
                                            <b-input type="number" v-model="form.fields.bufferTimeBefore">
                                            </b-input>
                                        </b-field>
                                        <FormError field="buffer_time_before" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-field label="Buffer after (minutes)" >
                                            <b-input type="number" v-model="form.fields.bufferTimeAfter">
                                            </b-input>
                                        </b-field>
                                        <FormError field="buffer_time_after" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-field label="Timeslot duration" >
                                            <b-select placeholder="Select duration" v-model="form.fields.timeslotDuration">
                                                <option
                                                    v-for="option in timeslotDurationOptions"
                                                    :value="option.value"
                                                    :key="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <FormError field="duration" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-field label="Booking interval" >
                                            <b-select placeholder="No interval" v-model="form.fields.bookingInterval">
                                                <option
                                                    v-for="option in bookingIntervalOptions"
                                                    :value="option.value"
                                                    :key="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <FormError field="booking_interval" :form="form"/>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Resident price">
                                            <b-input type="number" step="any" v-model="form.fields.priceResident">
                                            </b-input>
                                        </b-field>
                                        <FormError field="price_resident" :form="form"/>
                                    </div>
                                </div>
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Non-resident price">
                                            <b-input type="number" step="any" v-model="form.fields.priceNonResident">
                                            </b-input>
                                        </b-field>
                                        <FormError field="price_non_resident" :form="form"/>
                                    </div>
                                </div>
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Tax" >
                                            <b-input type="number" step="any" v-model="form.fields.taxRate">
                                            </b-input>
                                        </b-field>
                                        <FormError field="tax_rate" :form="form"/>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Payment">
                                            <b-select placeholder="Select payment" class="select-width-100" v-model="form.fields.paymentType">
                                                <option
                                                    v-for="type in paymentTypeOptions"
                                                    :value="type.value"
                                                    :key="type.value">
                                                    {{ type.label }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <FormError field="payment_type" :form="form"/>
                                    </div>
                                </div>
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Charging">
                                            <b-select placeholder="Select charging" class="select-width-100" v-model="form.fields.chargingType">
                                                <option
                                                    v-for="status in chargingTypeOptions"
                                                    :value="status.value"
                                                    :key="status.value">
                                                    {{ status.label }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <FormError field="charging_type" :form="form"/>
                                    </div>
                                </div>
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Price description" >
                                            <b-input type="text" v-model="form.fields.priceDescription">
                                            </b-input>
                                        </b-field>
                                        <FormError field="price_description" :form="form"/>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Integration">
                                            <b-select placeholder="Select integration" v-model="form.fields.accountIntegrationId">
                                                <option>Internal</option>
                                                <option
                                                    v-for="option in accountIntegrations"
                                                    :value="option.id"
                                                    :key="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </b-select>
                                        </b-field>
                                        <FormError field="account_integration_id" :form="form"/>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="field">
                                <b-field label="Categories">
                                    <div class="block">
                                        <ul>
                                            <li v-for="category in topLevelCategories">
                                                <b-checkbox v-model="form.fields.categories"
                                                            :native-value="category.id">
                                                    {{ category.name }}
                                                </b-checkbox>
                                                <ul style="padding-inline-start:20px">
                                                    <li v-for="subcategory in category.subcategories">
                                                        <b-checkbox v-model="form.fields.categories"
                                                                    :native-value="subcategory.id">
                                                            {{ subcategory.name }}
                                                        </b-checkbox>
                                                    </li>
                                                </ul>

                                            </li>
                                        </ul>

                                    </div>
                                </b-field>
                                <FormError field="category_id" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Status">
                                    <b-select placeholder="Select status" v-model="form.fields.status">
                                        <option
                                            v-for="status in statusOptions"
                                            :value="status.value"
                                            :key="status.value">
                                            {{ status.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Guest availability">
                                    <b-select placeholder="Select availability" v-model="form.fields.guestAvailability">
                                        <option
                                            v-for="option in guestAvailabilityOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="guest_availability" :form="form"/>
                            </div>
                            <!--Show product select only if integration is outside of MEWS-->
                            <div v-if="form.fields.originName !== 'Mews'" class="field">
                                <b-field label="Products">
                                    <b-autocomplete
                                        v-model="selectedProductName"
                                        placeholder="Start typing and select a product..."
                                        :open-on-focus="true"
                                        :data="filteredProducts"
                                        field="nameWithOriginId"
                                        @select="handleProductSelect"
                                        :clear-on-select="true"
                                        :clearable="true"
                                    >
                                    </b-autocomplete>
                                </b-field>
                                <template v-if="form.fields.serviceProducts && form.fields.serviceProducts.length > 0">
                                    <table class="table">
                                        <tbody>
                                        <tr v-for="(serviceProduct, index) in form.fields.serviceProducts">
                                            <td>{{ serviceProduct.productName }}</td>
                                            <td>
                                                <b-radio v-model="serviceProduct.default" @input="handleDefaultServiceProduct(serviceProduct)" :native-value="true">
                                                    Default
                                                </b-radio>
                                            </td>
                                            <td>
                                                <b-button type="is-danger" @click="handleServiceProductRemove(index)" outlined>Remove</b-button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else >
                                    <p>No products added</p>
                                </template>
                                <FormError field="service_products" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Configuration">
                                    <ul>
                                        <li>
                                            <b-checkbox v-model="form.fields.configuration.flexibleTime">
                                                Flexible time
                                            </b-checkbox>
                                        </li>
                                    </ul>
                                </b-field>
                            </div>
                            <div class="field">
                                <b-field label="Related services">
                                    <b-taginput
                                        v-model="form.fields.relatedServices"
                                        :data="filteredServices"
                                        autocomplete
                                        :allow-new="false"
                                        :open-on-focus="true"
                                        :allow-duplicates="false"
                                        :before-adding="checkRelatedServicesDuplicates"
                                        field="name"
                                        placeholder="Add a service"
                                        @typing="getFilteredServices">
                                    </b-taginput>
                                </b-field>
                            </div>
                            <div class="field">
                                <label class="label">Origin</label>
                                <template v-if="form.fields.originId">
                                    <p>{{ form.fields.originName }} ( {{ form.fields.originId }} )</p>
                                </template>
                                <template v-else>
                                    <p>Internal</p>
                                </template>
                            </div>
                            <div class="field">
                                <label class="label">Images</label>
                                <FileUpload v-model="form.fields.files" :entity="service" :multiple="true" :extensions="['jpg','jpeg','png']"></FileUpload>
                            </div>
                            <hr/>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Category,
    Service,
} from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload';
import FormMenu from '@/views/Admin/Service/FormMenu';

export default {
    name: 'Form',
    components: {
        FileUpload,
        FormMenu
    },
    computed: {
        categories() {
            return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : [];
        },
        topLevelCategories() {
            return this.categories.filter((category) => { return !category.categoryId });
        },
        products() {
            return this.productIds ? this.$store.getters['product/collection'](this.productIds) : [];
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        services() {
            return this.serviceIds ? this.$store.getters['service/collection'](this.serviceIds) : null;
        },
        accountIntegrations() {
            return this.accountIntegrationIds ? this.$store.getters['accountIntegration/collection'](this.accountIntegrationIds) : null;
        },
        filteredProducts() {
            return this.products ? this.products.filter(option => {
                return (
                    option.name
                        .toLowerCase()
                        .indexOf(this.selectedProductName.toLowerCase()) >= 0
                )
            }) : [];
        },
        selectedProduct() {
            return this.products && this.form.fields.originData && this.form.fields.originData.productId ?
                this.products.find(product => {
                    return product.id === this.form.fields.originData.productId;
                }) : null;
        }
    },
    async created() {
        this.loadCategories();
        this.loadProducts();
        await this.loadAccountIntegrations();

        await this.loadServices();

        if(this.$route.params.serviceId) {
            await this.loadService();
        }

    },
    data() {
        return {
            serviceId: null,
            categoryIds: [],
            productIds: [],
            serviceIds: [],
            accountIntegrationIds: [],
            form: new Form({
                name: '',
                description: '',
                category_id: null,
                files: [],
                categories: [],
                services: [],
                relatedServices: [],
                serviceProducts: [],
                originData: {},
                configuration: {},
                type: 'activity',
                status: Service.statusOptions[0].value,
                chargingType: Service.chargingTypeOptions[0].value,
                limitationType: Service.chargingTypeOptions[0].value
            }),
            loading: false,
            statusOptions: Service.statusOptions,
            guestAvailabilityOptions: Service.guestAvailabilityOptions,
            chargingTypeOptions: Service.chargingTypeOptions,
            timeslotDurationOptions: Service.timeslotDurationOptions,
            paymentTypeOptions: Service.paymentTypeOptions,
            limitationTypeOptions: Service.limitationTypeOptions,
            bookingIntervalOptions: Service.bookingIntervalOptions,
            selectedProductName: "",
            filteredServices: [],
        };
    },
    methods: {
        ...mapActions({
            categoryIndex: 'category/index',
            serviceDestroy: 'service/destroy',
            serviceStore: 'service/store',
            serviceUpdate: 'service/update',
            productIndex: 'product/index',
            serviceShow: 'service/show',
            serviceIndex: 'service/index',
            accountIntegrationIndex: 'accountIntegration/index'
        }),
        fillForm() {
            this.form.fields = {
                ...this.service,
                originData: this.service.originData ? this.service.originData : {},
                categories: this.service.categories ? this.service.categories.map(c => c.id) : [],
                files: this.service.files ? this.service.files : [],
                configuration: this.service.configuration ? this.service.configuration : {},
                relatedServices: this.service.relatedServices ? this.service.relatedServices : [],
                serviceProducts: this.service.serviceProducts ? this.service.serviceProducts : [],
            }
            // Very stupid solution
            //this.productName = this.selectedProduct ? this.selectedProduct.nameWithOriginId : '';
        },
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
              ...this.form.fields,
              configuration: !this.isEmpty(this.form.fields.configuration) ? this.form.fields.configuration : null,
            };

            let action = null;

            if (this.service) {
                action = this.serviceUpdate;
            } else {
                action = this.serviceStore;
            }

            action(Service.preparePayload(fields)).then((response) => {
                window.app.snackbar({
                    message: 'Saved successfully!',
                });

                if(!this.form.fields.id) {
                    let route = window.app.findRouteByName('admin/service/update', [], {
                        params: {
                            serviceId: response
                        },
                    });
                    this.$router.push(route);
                    this.loadService();
                } else {
                    this.$router.push('/admin/service');
                }
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Service',
                message: 'Are you sure you want to delete this service?',
                onConfirm: () => {
                    this.loading = true;
                    this.serviceDestroy({
                        id: this.service.id,
                    }).then(() => {
                        window.app.snackbar('Service deleted');

                        this.$router.push('/admin/service');

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadCategories() {
            return this.categoryIndex({
                params: {
                    entityType: Category.entityTypeService
                }
            }).then((response) => {
                this.categoryIds = response.ids;
            }).catch((error) => {

            }).finally(() => {

            });
        },
        loadProducts() {
            this.loading = true;

            return this.productIndex({
                params: {
                    status: 'published'
                }
            }).then((response) => {
                this.productIds = response.ids;
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        loadAccountIntegrations() {
            return this.accountIntegrationIndex().then((response) => {
                this.accountIntegrationIds = response.ids;
            }).catch((error) => {

            }).finally(() => {

            });
        },
        loadServices() {
            this.loading = true;
            let params = {}

            if(this.$route.params.serviceId) {
                params.id_not_in = this.$route.params.serviceId;
            }

            return this.serviceIndex({
                params: params
            }).then((response) => {
                this.serviceIds = response.ids;

                this.filteredServices = this.services.map((option) => {
                    return {
                        id: option.id,
                        name: option.name
                    }
                });
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        loadService() {
            this.loading = true;

            return this.serviceShow({
                id: this.$route.params.serviceId,
                params: {
                    include: ['categories', 'relatedServices', 'serviceProducts']
                }
            }).then((response) => {
                this.serviceId = response[0];
                this.fillForm();
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        getFilteredServices(text) {
            this.filteredServices = this.services.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0 && option.id !== this.serviceId
            }).map((option) => {
               return {
                   id: option.id,
                   name: option.name
               }
            });
        },
        checkRelatedServicesDuplicates(service) {
            return !this.form.fields.relatedServices.find(item => item.id === service.id);
        },
        handleCopyPublicUrl(){
            if(!this.service.publicBookingUrl) {
                return;
            }

            const storage = document.createElement('textarea');
            storage.value = this.service.publicBookingUrl;
            this.$refs.publicUrlWrapper.appendChild(storage);
            storage.select();
            storage.setSelectionRange(0, 99999);
            document.execCommand('copy');
            this.$refs.publicUrlWrapper.removeChild(storage);

            window.app.snackbar({
                message: "Public booking URL copied to your clipboard",
                type: 'is-success',
            });
        },
        handleDefaultServiceProduct(serviceProduct) {
            this.form.fields.serviceProducts.forEach(item => {
                if(item.productId === serviceProduct.productId) {
                    item.default = true;
                } else {
                    item.default = false;
                }
            });
        },
        handleServiceProductRemove(index) {
            this.form.fields.serviceProducts.splice(index, 1);
            this.setDefaultServiceProduct();
        },
        handleProductSelect(product) {
            if(!product) {
                return;
            }

            // Check if exists already
            let isProductAdded = false;
            this.form.fields.serviceProducts.forEach(item => {
                console.log(String(item.productId));
                console.log(String(product.id));
                if(String(item.productId) === String(product.id)) {
                    isProductAdded = true;
                    return false;
                }
            });

            if(isProductAdded) {
                return false;
            }

            this.form.fields.serviceProducts = [
                ...this.form.fields.serviceProducts,
                {
                    productName: product.name,
                    productId: product.id,
                    serviceId : this.service.id,
                    default: false
                }
            ];
            this.setDefaultServiceProduct();
            this.selectedProductName = "";
        },
        setDefaultServiceProduct() {
            if(!this.form.fields.serviceProducts || this.form.fields.serviceProducts.length < 1) {
                return;
            }
            let hasDefaultServiceProduct = this.form.fields.serviceProducts.find(item => item.default);
            if(!hasDefaultServiceProduct) {
                this.form.fields.serviceProducts[0].default = true;
            }
        }
    }
};
</script>

<style lang="scss">


</style>
