<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column text-center is-size-3 font-primary">
                        {{ user.name }}
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div :class="['field', {'input--error': form.errors.has('first_name')}]">
                            <BField class="mb-2" label="First name">
                                <BInput type="text" v-model="form.fields.firstName"></BInput>
                            </BField>
                            <FormError field="first_name" :form="form"/>
                        </div>
                        <div :class="['field', {'input--error': form.errors.has('last_name')}]">
                            <BField class="mb-2" label="Last name">
                                <BInput type="text" v-model="form.fields.lastName"></BInput>
                            </BField>
                            <FormError field="last_name" :form="form"/>
                        </div>
                        <div :class="['field', {'input--error': form.errors.has('email')}]">
                            <BField class="mb-2" label="Email">
                                <BInput type="email" disabled="" v-model="form.fields.email"></BInput>
                            </BField>
                            <FormError field="email" :form="form"/>
                        </div>
                        <div class="field">
                            <BField class="mb-2" label="Phone">
                                <div class="phone-input">
                                    <div class="w-100">
                                        <div class="column px-0">
                                            <MazPhoneNumberInput
                                                v-model="phoneNumber"
                                                @update="handlePhoneNumberUpdate"
                                                :default-phone-number="user ? user.phone : ''"
                                                :default-country-code="defaultCountryCode"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </BField>
                            <FormError field="phone" :form="form"/>
                        </div>
                    </div>
                </div>
                <div class="mt-auto pb-4">
                    <BButton type="is-black" class="border-radius-12" expanded @click="handleUpdate" >SAVE</BButton>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import Vue from "vue";

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Profile',
    computed: {
        countries() {
            return this.$store.getters['country/all'];
        },
        defaultCountryCode() {
            const code = this.countries.filter(country => {
                const code = country.dialCode.substring(1)
                if (country.preferred) {
                    return this.user.phoneCountryCode === code && country.preferred;
                } else {
                    return this.user.phoneCountryCode === code
                }
            })

            return code.length ? code[0].code : 'US'
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    created() {
        // Scroll to bottom if country code dropdown is focused
        this.observePhoneCountryCodeFocus();

        if (this.user) {
            this.form.fields = {
                ...this.user
            }
        }
    },
    data() {
        return {
            form: new Form({}),
            loading: false,
            phoneNumber: null
        };
    },
    methods: {
        ...mapActions({
            userUpdate: 'auth/userUpdate'
        }),
        handleUpdate() {
            this.loading = true;

            this.form.errors.clear();

            this.userUpdate({
                ...this.form.fields
            }).then(() => {

                window.app.snackbar({
                    message: `<i class="fa-solid fa-check mr-4"></i> Successfully saved!`,
                    actionText: null,
                });

            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handlePhoneNumberUpdate(event) {
            this.form.fields.phone = event.phoneNumber;
            this.form.fields.phoneCountryCode = event.countryCallingCode;
        },
        observePhoneCountryCodeFocus() {
            Vue.nextTick(()=> {
                let el = document.querySelectorAll(".country-selector .maz-input")[0];

                let observer = new MutationObserver(function (event) {
                    let target = event[0].target;

                    if(target.classList.contains('is-focused')) {
                        setTimeout(() => {
                            window.scrollTo(0, document.body.scrollHeight);
                        },400);
                    }
                })

                observer.observe(el, {
                    attributes: true,
                    attributeFilter: ['class'],
                    childList: false,
                    characterData: false
                })
            });
        }
    },
}
</script>

<style lang="scss">
.phone-input {
    display: flex;
    align-items: center;
    border-radius: 10pt;
    font-family: 'Poppins', sans-serif;
    height: 48px !important;
    //box-shadow: inset 2pt 2pt 2pt 0 rgb(0 0 0 / 35%);
    background-color: #ffffff;
}

.maz-border {
    border: none !important;
}

.maz-input__input {
    border: 1px solid #ced4da;
}

.maz-input__label {
    color: var(--maz-placeholder-color) !important;
}

.maz-input {
    border-radius: 12px !important;
    height: 48px !important;
    min-height: 48px !important;
    //border-color: #ced4da;
    //border-width: 1px;
    border: none;

    &:first-of-type {
        margin-right: 10px;
    }

    &:focus {
        border-width: 2px;
        border-color: #000000;
    }

    &.is-focused .maz-input__input {
        border: 2px solid #000000;
        //border-color: #000000;
    }

    &.has-value,
    &.has-hint {
        .maz-input__label {
            opacity: 0 !important;
        }

        &:not(.has-no-label) {
            .maz-input__input {
                padding-top: 0 !important;
            }
        }
    }

    &.is-valid .maz-input__icon path.arrow {
        fill: #dedede !important;
    }

    &.is-focused.is-valid .maz-input__icon path.arrow {
        fill: #000000 !important;
    }
}

.maz-phone-number-input__country-flag {
    bottom: 1.1rem !important;
}


</style>
