<template>
    <section class="section authentication height-full min-height-full pb-0">
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column">
                        <div class="mb-2">
                            <img src="/images/email-sent.png" alt="compass">
                        </div>

                        <div class="has-text-black has-text-weight-semibold mb-4 fs-22">
                            Check your email
                        </div>

                        <div class="has-text-black mb-4">
                            Instructions may take a while to arrive. If you can't find them, check your spam folder.
                        </div>

                    </div>
                </div>
                <div class="mt-auto pb-4">
                    <div class="controls has-margin-top">
                        <BButton type="is-black" class="border-radius-12" expanded @click="$router.push('/auth/login')">UNDERSTOOD</BButton>
                    </div>
                    <div class="controls has-margin-top">
                        <BButton class="button-ghost border-radius-12" expanded @click="$router.push('/auth/forgot-password')">instructions not received</BButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Confirmation"
}
</script>

<style scoped>

</style>
