<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="container is-align-content-center" style="min-height: 100vh;">
            <div class="column is-two-fifths mx-auto">
                <div class="login-form">
                    <div class="line-height-1 has-text-centered mb-5">
                        <img src="/images/admin/logo-honne.svg">
                    </div>
                    <div :class="['field is-relative', {'input--error': form.errors.has('password')}]">
                        <BField class="mb-5 form-field" label="Email">
                            <b-input type="email"
                                     icon="user"
                                     custom-class="rounded-8"
                                     v-model="form.fields.email">
                            </b-input>
                        </BField>
                    </div>

                    <div :class="['field is-relative', {'input--error': form.errors.has('password')}]">
                        <BField class="mb-5 form-field" label="Password">
                            <b-input type="password"
                                     icon="suitcase"
                                     custom-class="rounded-8"
                                     placeholder="Password reveal input"
                                     v-model="form.fields.password"
                                     password-reveal>
                            </b-input>
                        </BField>
                    </div>

                    <div v-if="form.errors.has('password')" class="error-message mb-4">
                        <img src="/images/admin/info-error.svg">
                        <div class="ml-2">
                            Incorrect email or password. Try again or reset your password.
                        </div>
                    </div>

                    <div class="mt-auto pt-4">
                        <div class="controls has-margin-top">
                            <BButton class="border-radius-12 button--primary rounded-8" expanded @click="handleLogin">
                                <span class="font--primary has-text-weight-bolder">Log in</span>
                            </BButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import CardComponent from '@/views/Admin/components/CardComponent.vue'
import {mapActions} from "vuex";

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Login',
    components: {
        CardComponent
    },
    computed: {
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        forgotPasswordRoute() {
            return window.app.findRouteByName('auth/forgot-password');
        },
        isDev() {
            return window.app.env.environment === 'local';
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    created() {
        if (this.authenticated) {
            this.$router.push('/admin/booking');
        }
    },
    data() {
        return {
            form: new Form({
                email: '',
                password: ''
            }),
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            loginAdmin: 'auth/loginAdmin',
        }),
        handleLogin() {
            this.loading = true;

            this.loginAdmin({
                device: navigator.userAgent || navigator.vendor,
                ...this.form.fields,
            }).then(() => {
            }).catch((error) => {
                this.form.recordErrors(error);

            }).finally(() => {
                this.loading = false;
            });
        }
    },
};

</script>
<style lang="scss">
.login-form {
    .form-field {
        .label {
            color: #5B6676;
        }

        .input {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .control.has-icons-right .input {
            ~ .icon.is-right.has-text-primary {
                color: #dbdbdb !important;
            }

            &:focus ~ .icon.is-right.has-text-primary {
                color: #4a4a4a !important;
            }
        }
    }

    .error-message {
        background-color: #FDD3D1;
        color: #4D0B08;
        border-radius: 12px;
        display: flex;
        padding: 12px;
        align-items: flex-start;
    }
}
</style>


