var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('BLoading',{attrs:{"is-full-page":true},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_vm._v(" "),(_vm.booking && _vm.service)?_c('section',{staticClass:"section p-0"},[_c('div',{staticClass:"container container-extra-small p-0"},[_c('div',{staticClass:"column is-flex is-flex-direction-column content-height"},[[_c('div',{staticClass:"columns flex-column"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field mb-5"},[_c('label',{staticClass:"mb-2 has-text-weight-semibold has-text-black"},[_vm._v(_vm._s(_vm.service.nameFinal))]),_vm._v(" "),_c('b-field',[_c('HorizontalCalendar',{model:{value:(_vm.form.fields.date),callback:function ($$v) {_vm.$set(_vm.form.fields, "date", $$v)},expression:"form.fields.date"}})],1),_vm._v(" "),_c('FormError',{attrs:{"field":"date","form":_vm.form}}),_vm._v(" "),(_vm.service.isClosed)?[_vm._m(0)]:_vm._e(),_vm._v(" "),(!_vm.service.isAvailable)?[_vm._m(1)]:[_c('b-field',[_c('div',{staticClass:"columns m-0 is-mobile is-flex-direction-column"},[(_vm.showTimeRangePicker)?_c('div',{staticClass:"column p-0 mb-4"},[_c('TimeRangePicker',{attrs:{"selected":_vm.form.fields.from,"options":_vm.timeslotOptions},model:{value:(_vm.form.fields.timeRange),callback:function ($$v) {_vm.$set(_vm.form.fields, "timeRange", $$v)},expression:"form.fields.timeRange"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column p-0"},[_c('div',{staticClass:"time-list columns is-mobile has-background-white is-multiline m-0"},_vm._l((_vm.filteredTimeslotOptions),function(option,index){return (_vm.visibleTimeslot(option))?_c('div',{class:['column is-half text-center p-0 time-list__item', {'disabled': option.disabled}],attrs:{"data-status":option.status},on:{"click":function($event){!option.disabled ? _vm.selectTimeSlot(option) : null}}},[_c('div',{staticClass:"column border-radius-12 text-center p-3 mx-1",class:{
                                                        'mt-3': index > 1,
                                                        'disabled': option.disabled,
                                                        'is-clickable border-grey app-grey': !option.disabled && _vm.form.fields.from !== option.value,
                                                        'has-background-black has-text-white border-black': _vm.form.fields.from === option.value
                                                    }},[(option.disabled && option.selfOwned)?_c('img',{attrs:{"src":"/images/ticketVoucher.svg"}}):_vm._e(),_vm._v(" "+_vm._s(option.label)+"\n                                                        ")])]):_vm._e()}),0),_vm._v(" "),_c('FormError',{attrs:{"field":"from","form":_vm.form}})],1)])]),_vm._v(" "),(_vm.service.configuration && _vm.service.configuration.flexibleTime)?_c('div',{staticClass:"field"},[_c('label',{staticClass:"mb-2 has-text-weight-semibold has-text-black"},[_vm._v("For how long?")]),_vm._v(" "),_c('b-field',[_c('div',{staticClass:"duration-list columns is-mobile has-background-white is-multiline m-0 position-relative"},_vm._l((_vm.timeForOptions),function(option,index){return _c('div',{staticClass:"column is-one-third text-center p-0 duration-list__item is-clipped",on:{"click":function($event){return _vm.selectTimeFor(option)}}},[_c('div',{staticClass:"column text-center position-absolute is-flex is-justify-content-center is-align-items-center",class:{
                                                    'disabled': option.disabled,
                                                    'is-clickable': !option.disabled,
                                                    'has-background-black has-text-white border-radius-12': _vm.form.fields.quantity === option.value
                                                },attrs:{"data-status":option.status}},[_vm._v(" "+_vm._s(option.label)+" ")])])}),0)]),_vm._v(" "),_c('FormError',{attrs:{"field":"quantity","form":_vm.form}})],1):_vm._e(),_vm._v(" "),_c('FormError',{attrs:{"field":"message","form":_vm.form}}),_vm._v(" "),_c('FormError',{attrs:{"field":"phone","form":_vm.form}}),_vm._v(" "),_c('FormError',{attrs:{"field":"product","form":_vm.form}})]],2)])]),_vm._v(" "),(!_vm.isClosed)?_c('div',{staticClass:"controls has-margin-top"},[_c('b-button',{staticClass:"button is-black border-radius-12 is-clickable is-fullwidth",attrs:{"disabled":!_vm.isAvailable},on:{"click":function($event){return _vm.handleReschedule()}}},[_vm._v("BOOK")])],1):_vm._e()]],2)])]):_vm._e(),_vm._v(" "),_c('AvailabilityIssueModal',{attrs:{"show":_vm.availabilityIssueModalActive},on:{"ok":_vm.toggleAvailabilityIssueModalActive,"cancel":_vm.toggleAvailabilityIssueModalActive}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field mb-5"},[_c('div',{staticClass:"columns m-0 is-mobile is-flex-direction-column"},[_c('div',{staticClass:"text-center mt-5"},[_c('p',{staticClass:"has-text-black has-text-weight-semibold fs-18 my-5"},[_vm._v("This activity is closed on this day")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field mb-5"},[_c('div',{staticClass:"columns m-0 is-mobile is-flex-direction-column"},[_c('div',{staticClass:"text-center mt-5"},[_c('p',{staticClass:"has-text-black has-text-weight-semibold fs-18 my-5"},[_vm._v("This activity is not available on this day")])])])])
}]

export { render, staticRenderFns }