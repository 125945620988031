<template>
      <section class="section authentication height-full min-height-full pb-0">
          <b-loading :is-full-page="true" v-model="loading"></b-loading>
          <div class="container container-extra-small p-0 height-full">
              <div class="flex-column is-flex is-flex-direction-column height-full">
                  <div class="columns">
                      <div class="column">
                          <div :class="['field is-relative', {'input--error': form.errors.has('email')}]">
                              <BField class="has-text-weight-medium mb-2" label="Email">
                                  <BInput type="email" v-model="form.fields.email"></BInput>
                              </BField>
                              <img v-if="form.errors.has('email')" src="/images/warning2-red.svg" class="input__icon" />
                              <FormError field="email" :form="form"/>
                          </div>

                          <div :class="['field is-relative', {'input--error': form.errors.has('password')}]">
                              <BField class="mb-2" label="Password">
                                  <input autocomplete="on" class="input" :type="passwordInputType ? 'text' : 'password'" v-model="form.fields.password">
                              </BField>
                              <img @click="passwordInputType = !passwordInputType"
                                   :src="`/images/${form.errors.has('password') ? 'eye2-red' : 'eye2'}.svg`"
                                   class="input__icon"
                              />
                              <FormError field="password" :form="form"/>
                          </div>

                          <router-link :to="forgotPasswordRoute" class="is-block has-text-right has-margin-vertical has-text-black">
                              Forgot Password?
                          </router-link>
                      </div>
                  </div>
                  <div class="mt-auto pb-4">
                      <div class="controls has-margin-top">
                          <BButton type="is-black" class="border-radius-12" expanded @click="handleLogin">SIGN IN</BButton>
                      </div>

                      <div class="controls has-margin-top">
                          <BButton class="button--ghost border-radius-12" expanded @click="goToSignUp" >
                              Need an account?
                              <i class="fa-solid fa-arrow-right ml-2"></i>
                          </BButton>
                      </div>
                  </div>
              </div>
          </div>
      </section>
</template>

<script>
import {mapActions} from 'vuex';

import {
  Form,
} from '@/internal/modules';

export default {
  name: 'Login',
  components: {},
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    forgotPasswordRoute() {
      return window.app.findRouteByName('auth/forgot-password');
    },
    isDev() {
      return window.app.env.environment === 'local';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
      if (this.authenticated) {
        this.$router.push('/');
      }
  },
  data() {
    return {
      form: new Form({
        email: '',
        password: ''
      }),
      loading: false,
      passwordInputType: false,
    };
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    handleLogin() {
      this.loading = true;

      this.login({
        device: navigator.userAgent || navigator.vendor,
        ...this.form.fields,
      }).then(() => {

        if(!this.user.activeBooking) {
            this.$router.push(window.app.findRouteByName('booking/active'));
        } else {
            this.$router.push(window.app.auth.redirectRouteAfterLogin());
            window.app.auth.clearRedirectRoute();
        }

      }).catch((error) => {
        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    goToSignUp() {
        this.$router.push('/auth/sign-up');
    }
  },
};
</script>

<style scoped lang="scss">
#login-page {
  .logo {
    height: 100px;
  }
}
</style>
