<template>
    <div v-if="hasAnnouncements">
        <div class="text-black has-text-weight-semibold fs-22 px-5 py-3 position-relative has-background-white">
            News
        </div>
        <div class="news px-5 py-3">
            <template v-for="announcement in announcements">
                <div v-if="announcement.imageUrl" class="news-card border-radius-24 mb-4 is-flex overflow-hidden">
                    <div class="news-image" :style="{'background-image' : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${announcement.imageUrl})`}"></div>
                    <div class="is-flex">
                        <div class="pl-3 pr-5 is-flex is-justify-content-center is-flex-direction-column">
                            <div class="mr-auto has-text-weight-semibold">{{ announcement.title }}</div>
                            <div class="app-grey fs-14 mt-2">{{ announcement.content }}</div>
                        </div>
                    </div>
                </div>
                <div v-else class="news-card border-radius-24 is-flex mb-4 is-justify-content-center is-flex-direction-column px-5">
                    <div>
                        <img src="/images/bullhorn.svg" class="mr-2">
                        <span class="mr-auto has-text-weight-semibold">{{ announcement.title }}</span>
                    </div>
                    <div class="app-grey fs-14 mt-2">{{ announcement.content }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "News",
    mounted() {
        this.loadData();
    },
    computed: {
        announcements() {
            return this.ids ? this.$store.getters['announcement/collection'](this.ids) : [];
        },
        hasAnnouncements() {
            return this.announcements.length > 0;
        }
    },
    data() {
        return {
            loading: false,
            ids: []
        }
    },
    methods: {
        ...mapActions({
            announcementIndex: 'announcement/index',
        }),
        loadData() {
            this.loading = true;

            this.announcementIndex({
                query: {},
                params: {
                    status: 'published',
                    origin_platform: 'guest-app'
                }
            }).then((response) => {
                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style scoped>
.news-card {
    height: 112px;
    box-shadow: 0 3px 14px -1px rgba(28, 52, 84, 0.16), 0 1px 4px -1px rgba(28, 48, 74, 0.16);
}
.news-image {
    flex-shrink: 0;
    width: 120px;
    height: 112px;
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/images/film2.png");
}
.news {
    line-height: 1.2;
}
</style>
