<template>
    <div>
        <BLoading :is-full-page="true" v-model="loading"></BLoading>

        <section v-if="service && category" class="section p-0 pt-3 pb-100">
            <div class="container container-extra-small">
                <div class="column modal-page-content">
                    <template v-if="step === 'book'">
                        <div class="columns flex-column">
                            <div class="column border-bottom-black">
                                <p class="subtitle mb-0 font-primary fs-40">
                                    Please choose check-in and check-out dates, time of arrival and number of guests.
                                </p>
                            </div>
                            <div class="column">
                                <div class="is-flex justify-content-between py-3">
                                    <h2>{{ category.name }}</h2>
                                </div>
                                <div class="is-flex justify-content-between py-3">
                                    <p>{{ category.description }}</p>
                                </div>
                                <div class="field">
                                    <b-field>
                                        <b-datepicker
                                            v-model="form.fields.dateFrom"
                                            placeholder="Check-in"
                                            class="box-shadow"
                                            append-to-body
                                            :date-formatter="dateFormat"
                                            :day-names="['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                                            :min-date="minDateFrom">
                                        </b-datepicker>
                                    </b-field>
                                    <FormError field="date_from" :form="form"/>
                                </div>
                                <div class="field">
                                    <b-field>
                                        <b-datepicker
                                            v-model="form.fields.dateTo"
                                            placeholder="Check-out"
                                            class="box-shadow"
                                            append-to-body
                                            :date-formatter="dateFormat"
                                            :icon-right="form.fields.dateTo ? 'close-circle' : ''"
                                            icon-right-clickable
                                            :day-names="['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                                            :min-date="minDateTo"
                                            @icon-right-click="clearDate">
                                        </b-datepicker>
                                    </b-field>
                                    <FormError field="date_to" :form="form"/>
                                </div>
                                <div class="field">
                                    <i class="fa-solid fa-users"></i>
                                    <b-field>
                                        <GroupInput v-model="form.fields.group" class="box-shadow" :full-width="false" :show-icon="false" />
                                    </b-field>
                                    <FormError field="adults_number" :form="form"/>
                                </div>
                                <div class="field">
                                    <b-field label="Arrival time">
                                        <b-select v-model="form.fields.time" class="box-shadow" placeholder="Select time" expanded>
                                            <option
                                                v-for="option in timeslotOptions"
                                                :value="option.value"
                                                :key="option.value"
                                                :disabled="option.disabled">
                                                {{ option.label }} <span v-if="option.disabled"> - {{ option.status }}</span>
                                            </option>
                                        </b-select>
                                    </b-field>

                                    <FormError field="from" :form="form"/>
                                </div>
                                <FormError field="message" :form="form"/>
                                <FormError field="phone" :form="form"/>
                            </div>
                        </div>
                        <div class="controls has-margin-top">
                            <b-button class="button is-black is-rounded is-clickable is-fullwidth" @click="handleNext()" :loading="loading">next</b-button>
                        </div>
                    </template>
                    <template v-if="step === 'checkout'">
                        <div class="columns flex-column">
                            <div class="column">
                                <div class="position-relative">
                                    <template v-if="service.imageUrl">
                                        <div class="service-card-img" :style="{ 'background-image': 'url('+service.imageUrl+')' }">
                                        </div>
                                    </template >
                                    <template v-else>
                                        <div class="service-card-img" :style="{ 'background-image': 'url(https://bulma.io/images/placeholders/1280x960.png)' }"></div>
                                    </template>
                                </div>
                            </div>
                            <div class="column border-bottom-black">
                                <p class="subtitle mb-0 font-primary fs-40">
                                    {{ category.name }}
                                </p>
                            </div>
                            <div class="column border-bottom-black">
                                <div class="is-flex justify-content-center">
                                    <div class="is-size-4 font-primary">
                                        {{ booking.quantity }} nights, {{ booking.group }} for £{{ booking.price }}
                                    </div>
                                </div>
                            </div>
                            <div class="column border-bottom-black has-background-white">
                                <div class="is-flex justify-content-between font-primary is-size-4 py-3 has-text-grey border-bottom-grey">
                                    <div>Prepayment</div>
                                    <div class="has-text-weight-bold">£ {{ booking.price }}</div>
                                </div>
                                <div class="is-flex justify-content-between font-primary is-size-4 py-3 has-text-grey border-bottom-grey">
                                    <div>Upon arrival</div>
                                    <div class="has-text-weight-bold">£ 0</div>
                                </div>
                                <div class="is-flex justify-content-between font-primary is-size-4 py-3">
                                    <div>Total</div>
                                    <div class="has-text-weight-bold">£ {{ booking.price }}</div>
                                </div>
                                <div class="controls has-margin-top">
                                    <template v-if="activeBooking">
                                        <b-button class="button is-black is-rounded is-clickable is-fullwidth" @click="handleBook" :loading="loading">book now</b-button>
                                    </template>
                                    <template v-else>
                                        <b-button class="button is-black is-rounded is-clickable is-fullwidth" @click="handlePayment" :loading="loading">confirm with payment</b-button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </section>

        <b-modal
            v-model="phoneInputModalActive"
            style="padding: 1rem;"
            has-modal-card
            trap-focus
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Phone number input modal"
            close-button-aria-label="Close"
            aria-modal>
            <template #default="props">
                <PhoneInputModal @success="handlePhoneUpdated" />
            </template>
        </b-modal>

    </div>

</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Booking,
} from '@/internal'

import moment from "moment";
import GroupInput from "../components/GroupInput";
import PhoneInputModal from "@/views/components/PhoneInputModal";


export default {
    name: 'BookAccommodation',
    components: {
        GroupInput,
        PhoneInputModal
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
        booking() {
            return {
                group: this.groupFormat(this.form.fields.group),
                price: this.pricing ? this.pricing.BasePrices.reduce((partialSum, a) => partialSum + a, 0) : 0,
                quantity: this.pricing ? this.pricing.BasePrices.length : 0,
                currency: this.pricing ? this.pricing.currency : null
            }
        },
        category() {
            return this.categoryId ? this.$store.getters['category/show'](this.categoryId) : null;
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        dateFrom() {
          return this.form.fields.dateFrom ? moment(this.form.fields.dateFrom).format('YYYY-MM-DD') : null;
        },
        minDateFrom() {
          return moment().subtract(1,'day').toDate();
        },
        dateTo() {
            return this.form.fields.dateTo ? moment(this.form.fields.dateTo).format('YYYY-MM-DD') : null;
        },
        minDateTo(){
            return moment(this.form.fields.dateFrom).add(1, 'day').toDate();
        },
        timeslotOptions() {
            return this.timeslots ? [
                {
                    label: 'Select time',
                    value: null,
                },
                ...this.timeslots.map((timeslot) => {
                    return {
                        label: timeslot,
                        value: timeslot
                    };
                })
            ] : [];
        },
        step() {
            return this.$route.query.step === 'checkout' ? 'checkout' : 'book';
        }
    },
    async created() {
        await this.loadService();
        this.loadCategory();
        // Arrival time
        this.createTimeslots();

        // If cancel/back URL from Stripe
        if(this.$route.query.referer === 'stripe') {
            this.fillFormFromStorage();
        } else {
            this.clearFormStorage();
        }
    },
    data() {
        return {
            form: new Form({
                dateFrom: this.$route.query.dateFrom ? moment(this.$route.query.dateFrom).toDate() : moment().toDate(),
                dateTo: this.$route.query.dateTo ? moment(this.$route.query.dateTo).toDate() : null,
                time: this.$route.query.time ? this.$route.query.time : null,
                group: {
                    adults: 0,
                    children: 0
                },
            }),
            timeslots: [],
            serviceId: null,
            categoryId: null,
            loading: false,
            pricing: null,
            phoneInputModalActive: false
        };
    },
    methods: {
        ...mapActions({
            bookingStore: 'booking/store',
            categoryShow: 'category/show',
            serviceAvailability: 'service/availability',
            servicePricing: 'service/pricing',
            paymentSession: 'payment/session'
        }),
        clearDate() {

        },
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        groupFormat(value) {
            let values = [];

            if(value.adults) {
                if(value.adults > 1) {
                    values.push(value.adults + " adults");
                } else {
                    values.push(value.adults + " adult");
                }
            }

            if(value.children) {
                if(value.children > 1) {
                    values.push(value.children + " children");
                } else {
                    values.push(value.children + " child");
                }
            }

            return values.join(" ");
        },
        handleBook() {
            const fields = {
                ...this.form.fields,
                date_from: this.form.fields.dateFrom ? moment(this.form.fields.dateFrom).format('YYYY-MM-DD') : null,
                date_to: this.form.fields.dateTo ? moment(this.form.fields.dateTo).format('YYYY-MM-DD') : null,
                adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                children_number: this.form.fields.group ? this.form.fields.group.children : null,
                service_id : this.service.id,
                service_type: this.service.type,
                category_id: this.category.id,
            }

            this.loading = true;

            this.bookingStore(fields).then((response) => {
                const route = window.app.findRouteByName('booking/confirmation', [], {
                    params: {
                        bookingUuid: response
                    },
                });
                this.$router.push(route);
            }).catch((error) => {
                // Redirect to step 1
                const route = Object.assign({}, this.$route);
                this.$router.push({
                    name: route.name,
                    params: route.params,
                    query: {
                        ...route.query,
                        step: 'book'
                    }
                });

                this.form.recordErrors(error);
                window.app.snackbarError(error);

            }).finally(() => {
                this.loading = false;
            });

        },
        handlePayment() {
            this.loading = true;

            const fields = {
                product_name: this.category.name,
                product_description: `${this.booking.quantity} nights, ${this.booking.group }`,
                amount: (this.booking.price * 100),
                cancel_url: process.env.APP_URL + this.$route.fullPath + '&referer=stripe',
                success_url: process.env.APP_URL + '/booking/confirmation',
                metadata: {
                    date_from: this.form.fields.dateFrom ? moment(this.form.fields.dateFrom).format('YYYY-MM-DD') : null,
                    date_to: this.form.fields.dateTo ? moment(this.form.fields.dateTo).format('YYYY-MM-DD') : null,
                    time: this.form.fields.time,
                    adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                    children_number: this.form.fields.group ? this.form.fields.group.children : null,
                    service_id : this.service.id,
                    service_type: this.service.type,
                    category_id: this.category.id,
                }
            }

            this.paymentSession(fields).then((response) => {
                window.location.href = response.url;
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleNext() {
            const fields = {
                ...this.form.fields,
                date_from: this.form.fields.dateFrom ? moment(this.form.fields.dateFrom).format('YYYY-MM-DD') : null,
                date_to: this.form.fields.dateTo ? moment(this.form.fields.dateTo).format('YYYY-MM-DD') : null,
                adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                children_number: this.form.fields.group ? this.form.fields.group.children : null,
                service_id : this.service.id,
                service_type: this.service.type,
                category_id: this.category.id,
                validate_only : true
            }

            this.loading = true;

            this.bookingStore(fields).then((response) => {

                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    params: route.params,
                    query: {
                        ...route.query,
                        step: 'checkout'
                    }
                });

            }).catch((error) => {
                this.form.recordErrors(error);

                if(this.form.errors.has('phone')) {
                    this.togglePhoneInputModalActive();
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        handlePhoneUpdated() {
            this.togglePhoneInputModalActive();
            this.handleNext();
        },
        loadCategory() {
            this.loading = true;

            return this.categoryShow({
                id: this.$route.query.categoryId
            }).then((response) => {
                this.categoryId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        loadService() {
            this.loading = true;

            const data = {
                id: this.$route.params.serviceUuid,
                params: {
                    type: 'accommodation',
                    date: this.dateFrom ? moment(this.dateFrom).format('YYYY-MM-DD') : null
                }
            }

            return this.serviceAvailability(data).then((response) => {
                this.serviceId = response[0];
            }).finally(() => {
                this.loading = false;
            });
        },
        loadPricing() {
            if(!this.form.fields.dateFrom || !this.form.fields.dateTo) {
                return;
            }

            this.loading = true;

            const data = {
                id: this.service.id,
                params: {
                    category_id: this.category.id,
                    date_from: moment(this.form.fields.dateFrom).format('YYYY-MM-DD'),
                    date_to: moment(this.form.fields.dateTo).format('YYYY-MM-DD')
                }
            }

            return this.servicePricing(data).then((response) => {
                this.pricing = response;
            }).finally(() => {
                this.loading = false;
            });
        },
        createTimeslots() {
            let config = {
                nextSlot: 15,
                breakTime: [],
                // breakTime: [
                //     ['11:00', '14:00'], ['16:00', '18:00']
                // ],
                startTime: '8:00',
                endTime: '20:00'
            };

            let slotTime = moment(config.startTime, "HH:mm");
            let endTime = moment(config.endTime, "HH:mm");

            while (slotTime < endTime)
            {
                if (!this.isInBreak(slotTime, config.breakTime)) {
                    this.timeslots.push(slotTime.format("HH:mm"));
                }
                slotTime = slotTime.add(config.nextSlot, 'minutes');
            }
        },
        isInBreak(slotTime, breakTimes) {
            return breakTimes.some((br) => {
                return slotTime >= moment(br[0], "HH:mm") && slotTime < moment(br[1], "HH:mm");
            });
        },
        saveFormStorage() {
            window.app.storage.setItem('booking', this.form.fields)
        },
        clearFormStorage() {
            window.app.storage.removeItem('booking')
        },
        fillFormFromStorage() {
            const fields =  window.app.storage.getItem('booking');

            console.log(fields);
            this.form.fields = {
                ...fields
            };
        },
        togglePhoneInputModalActive() {
            this.phoneInputModalActive = !this.phoneInputModalActive;
        }
    },
    watch: {
        async dateFrom() {
            await this.loadService();

            this.loadPricing();
        },
        async dateTo() {
            await this.loadService();

            this.loadPricing();
        },
        'form.fields': {
            handler() {
                this.saveFormStorage();
            },
            deep: true
        }
    }
};
</script>

<style scoped lang="scss">

</style>
