import {
    Model
} from '@/internal/modules';

import {
    ServiceItemService as ServiceItemService
} from '@/internal';


export default class ServiceItem extends Model {

    static get name() {
        return 'ServiceItem';
    }

    static get vuexModuleKey() {
        return 'serviceItem';
    }

    static get service() {
        return ServiceItemService;
    }

    static get fields () {
        return {};
    }

    static get typeOptions() {
        return [
            {
                label: 'Table',
                value: 'table',
            },
        ]
    }
}
