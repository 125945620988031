var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SideDrawer',{attrs:{"_class":"w:900@lg"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[(_vm.isEdit)?_c('TabNav',{attrs:{"tabs":[
        ['General', 'general'],
        ['Advanced', 'advanced'],
        ['Working Hours', 'working-hours'],
        ['Rooms & Tables', 'items', { hide: _vm.service && _vm.service.subtype !== 'restaurant' }],
        ['Emails', 'emails'],
      ]},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}}):_vm._e()],1),_vm._v(" "),(_vm.activeTab === 'general')?_c('div',[_c('GeneralTab',{ref:"generalTab",on:{"onSave":function($event){return _vm.$emit('close')}}})],1):_vm._e(),_vm._v(" "),(_vm.activeTab === 'working-hours')?_c('div',[_c('WorkingHoursTab')],1):_vm._e(),_vm._v(" "),(_vm.activeTab === 'advanced')?_c('div',[_c('RulesTab')],1):_vm._e(),_vm._v(" "),(_vm.activeTab === 'items')?_c('div',[_c('ItemsTab')],1):_vm._e(),_vm._v(" "),(_vm.activeTab === 'emails')?_c('div',[_c('EmailsTab',{ref:"emailsTab",on:{"saved":function($event){return _vm.$emit('close')}}})],1):_vm._e(),_vm._v(" "),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex:between|center",class:{ 'flex:1': _vm.isEdit }},[(_vm.isEdit)?_c('button',{staticClass:"reset flex:|center gap:8 f:16 semi c:primary py:12 r:8 o:0.6:hover",on:{"click":_vm.handleCopyPublicUrl}},[_c('Icon',{attrs:{"admin":"","name":"link"}}),_vm._v("\n        Copy Public URL\n      ")],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex:|center gap:16"},[(_vm.isEdit)?_c('button',{staticClass:"reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover",on:{"click":_vm.handleDelete}},[_c('Icon',{attrs:{"admin":"","name":"trash"}}),_vm._v("\n          Delete\n        ")],1):_vm._e(),_vm._v(" "),(_vm.showSaveBtn.includes(_vm.activeTab))?_c('button',{staticClass:"reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover",on:{"click":_vm.handleSave}},[_c('Icon',{attrs:{"admin":"","name":"check"}}),_vm._v("\n          Save\n        ")],1):_vm._e()])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }