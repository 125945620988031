import { ResourceService } from '@/internal/modules';

export default class AccountIntegrationService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'account-integration';
    }

    syncAll(payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'post',
            url: `${this.baseUrl}/sync-all`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

    syncBookings(id, payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'post',
            url: `${this.baseUrl}/${id}/sync/bookings`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

    syncResources(id, payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'post',
            url: `${this.baseUrl}/${id}/sync/resources`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }
}
