import {
    File as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'file',
    model: Model,
    actions: {
        store({commit}, payload = {}) {
            return service.store(payload).then((response) => {
                return response.data.data;
            });
        },
    }
});

export default store.toObject();
