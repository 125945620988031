import {
    Model,
} from '@/internal/modules';

import {ActivityLogService, User} from '@/internal';

export default class ActivityLog extends Model {
    static get name() {
        return 'ActivityLog';
    }
    static get vuexModuleKey() {
        return 'activityLog';
    }
    static get service() {
        return ActivityLogService;
    }

    static get fields () {
        return {};
    }

    get user() {
        return this.hasOne(User)
    }
}
