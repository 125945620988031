import {
    CartService as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'cartService',
    model: Model,
});

export default store.toObject();
