import {
    Model,
} from '@/internal/modules';

import {
    Booking,
    BookingGroupService as BookingGroupService
} from '@/internal';

export default class BookingGroup extends Model {
    static get name() {
        return 'BookingGroup';
    }
    static get vuexModuleKey() {
        return 'bookingGroup';
    }
    static get service() {
        return BookingGroupService;
    }

    static get fields () {
        return {
            name: {
                required: true,
                type: String,
            },
            startAt: {
                type: Date,
            },
            endAt: {
                type: Date,
            }
        };
    }

    get bookings() {
        return this.hasMany(Booking);
    }


}
