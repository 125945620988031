import {
    Model,
} from '@/internal/modules';

import {
    OrderProductService as Service
} from '@/internal';

export default class OrderProduct extends Model {
    static get name() {
        return 'OrderProduct';
    }

    static get vuexModuleKey() {
        return 'orderProduct';
    }

    static get service() {
        return Service;
    }
}
