<template>
    <section class="section authentication height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column">
                        <div class="mb-8">
                            <img src="/images/compass.svg" alt="compass" class="compass-bg">
                        </div>

                        <div class="has-text-black has-text-weight-semibold mb-4 fs-22">
                            Reset your password
                        </div>

                        <div class="has-text-black mb-4">
                            Your new password must have more than 6 characters, 1 number and 1 special character.
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('password')}]">
                            <BField label="Password">
                                <input autocomplete="on" class="input" :type="passwordInputType ? 'text' : 'password'" v-model="form.fields.password">
                            </BField>
                            <img @click="passwordInputType = !passwordInputType"
                                 :src="`/images/${form.errors.has('password') ? 'eye2-red' : 'eye2'}.svg`"
                                 class="input__icon"
                            />
                            <FormError field="password" :form="form"/>
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('passwordConfirmation')}]">
                            <BField label="Confirm Password">
                                <input autocomplete="on" class="input" :type="passwordConfirmationInputType ? 'text' : 'password'" v-model="form.fields.passwordConfirmation">
                            </BField>
                            <img @click="passwordConfirmationInputType = !passwordConfirmationInputType"
                                 :src="`/images/${form.errors.has('passwordConfirmation') ? 'eye2-red' : 'eye2'}.svg`"
                                 class="input__icon"
                            />
                            <FormError field="passwordConfirmation" :form="form"/>
                            <FormError field="email" :form="form"/>
                        </div>
                    </div>
                </div>
                <div class="mt-auto pb-4">
                    <div class="controls has-margin-top">
                        <BButton type="is-black" class="border-radius-12" expanded @click="handleResetPassword">RESET PASSWORD</BButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal/modules';

export default {
  name: 'ResetPassword',
  components: {},
  computed: {
  },
  data() {
    return {
      form: new Form({

      }),
      loading: false,
      passwordInputType: false,
      passwordConfirmationInputType: false,
    };
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    handleResetPassword() {

      this.loading = true;

      this.resetPassword({
        device: navigator.userAgent || navigator.vendor,
        token: this.$route.params.resetToken,
        ...this.form.fields,
      }).then(() => {

        this.$router.push(window.app.findRouteByName('auth/login'));

        window.app.snackbar({
            message: `<div class="has-text-weight-semibold"><i class="fa-solid fa-check"></i>  Succesfully saved!</div>`,
            position: 'is-top',
            actionText: null,
        });

      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
