import { ResourceService } from '@/internal/modules';

export default class NotificationService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'notification';
    }

    read(id, payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'put',
            url:`notification/${id}/read`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

}
