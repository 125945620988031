<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>
        <div class="p:12 flex:between|center">
          <div class="font--primary f:36 bold">
            Services
          </div>
          <button
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="routeShowCreate"
          >
            <Icon admin name="spark" />
            New service
          </button>
        </div>

        <div class="p:12 flex:end">
          <b-input
            placeholder="Search..."
            type="search"
            icon="magnifying-glass"
            v-model="search"
            @input="handleSearch">
          </b-input>
        </div>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">

              <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                <div class="column">
                            <b-table
                              sticky-header
                              height="calc(100vh - 324px)"
                                :data="data"
                                :columns="columns"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true"
                                @click="routeShowEdit"
                                @sort="handleChangeSort">
                            </b-table>
                        </div>
                    </div>
                    <div v-if="paginator"  class="columns">
                        <div class="column">
                            <Paginator :paginator="paginator"></Paginator>
                        </div>
                    </div>

                </div>
            </div>
            <ServiceModal v-if="serviceUpdateModalActive && dataLoaded" @close="$router.back()" />
        </section>

    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    debounce,
} from 'lodash';

import Paginator from '@/views/Admin/components/Paginator';
import ServiceModal from '@/components/admin/Service/ServiceModal/ServiceModal';

import { sideDrawerMixin } from '@/components/admin/SideDrawer.vue';

export default {
    name: 'Index',
    mixins: [sideDrawerMixin],
    components: {
        Paginator,
        ServiceModal
    },
    computed: {
      data() {
       return this.ids ? this.$store.getters['service/collection'](this.ids) : [];
      },
      fullPath () {
          return this.$route.fullPath;
      },
      serviceUpdateModalActive() {
          return !!this.$route.query.show;
      }
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            ids: [],
            columns: [
                {
                    field: 'name',
                    label: 'Name',
                },
                {
                    field: 'categoryName',
                    label: 'Category',
                },
                {
                    field: 'accountIntegrationName',
                    label: 'Integration',
                },
                {
                    field: 'status',
                    label: 'Status',
                }
            ],
            paginator: null,
            loading: false,
            search: this.$route.query.search || null,
            performSearch: debounce(() => {
                const route = Object.assign({}, this.$route);

                let query = {...route.query};

                if(this.search && this.search !== "") {
                    query.search = this.search;
                } else {
                    delete query.search;
                }

                this.$router.push({
                    name: route.name,
                    query: query
                });
            }, 1000),
            dataLoaded: false
        }
    },
    methods: {
        ...mapActions({
            serviceIndex: 'service/index',
        }),
        handleSearch() {
            this.performSearch();
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        loadData() {
            this.loading = true;

            const query = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 10,
                sortBy: this.$route.query.sortBy || 'id',
                sortOrder: this.$route.query.sortOrder || 'asc',
                type: this.$route.query.type || null,
                search: this.$route.query.search || null,
            };

            if (this.search) {
                query.search = this.search;
            }

            this.serviceIndex({
                params: {
                    ...query,
                    include: ['categories']
                }
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
                this.dataLoaded = true;
            })
        }
    },
    watch: {
        fullPath () {
          if (this.serviceUpdateModalActive) {
            return;
          }

          this.loadData();
        },
    },
}
</script>
