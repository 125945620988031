import {
    ServiceItem as Model
} from '@/internal';

import {
  Paginator,
  ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'serviceItem',
    model: Model,
    actions: {
      available({commit}, payload = {}) {
        return service.available(payload).then((response) => {
          return ResourceStore.processData('serviceItem', commit, response.data, payload && payload.replaceStore === true);
        });
      },
    }
});

export default store.toObject();
