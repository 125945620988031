<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/integration-log">Integration logs</router-link>
                            </li>
                            <li>
                                {{ integrationLog ? integrationLog.fullName : '' }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">

                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div v-if="integrationLog" class="box">
                    <BField label="Name">
                        <p>{{ integrationLog.fullName }}</p>
                    </BField>
                    <BField  label="Status">
                        <p>{{ integrationLog.status }}</p>
                    </BField>
                    <BField label="Request time">
                        <p>{{ integrationLog.requestTime ? integrationLog.requestTime : 'N/A' }}</p>
                    </BField>
                    <BField label="Date">
                        <p>{{ integrationLog.createdAt }}</p>
                    </BField>
                    <BField  label="Message">
                        <p>{{ integrationLog.message }}</p>
                    </BField>
                    <BField  label="Description">
                        <p>{{ integrationLog.description }}</p>
                    </BField>
                    <BField v-if="integrationLog.status === 'error'" label="API response">
                        <p>{{ integrationLog.apiResponse }}</p>
                    </BField>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';


export default {
    name: 'Show',
    components: {},
    computed: {
        integrationLog() {
            return this.$route.params.integrationLogId ? this.$store.getters['integrationLog/show'](this.$route.params.integrationLogId) : null;
        },
    },
    mounted() {

    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        ...mapActions({
            integrationLogShow: 'integrationLog/show'
        }),
        loadIntegrationLog() {
            this.integrationLogShow().then((response) => {
                console.log(response);
            }).catch((error) => {

            }).finally(() => {

            });
        }
    }
};
</script>

<style scoped lang="scss">

</style>
