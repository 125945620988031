<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section p-3">
            <div class="level">
                <div class="level-left">
                    <div class="fs-36 font--primary has-text-weight-bold">
                        Orders
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="is-right">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3">
            <div class="is-flex">
                <div>
                    <CalendarInput v-model="params.date"
                                   @input="handleDateChange"
                                   @clear="handleClearDate"
                    />

                </div>

                <div class="ml-2">
                  <ComboInput
                    v-model="selectedStatuses"
                    @input="handleStatusChange"
                    :options="statusOptions"
                  />
                </div>

                <div class="ml-auto">
                    <div class="new-input">
                        <b-input placeholder="Search..."
                                 ref="search"
                                 type="search"
                                 icon="magnifying-glass"
                                 v-model="search"
                                 @input="handleSearch"
                        >
                        </b-input>
                        <div v-show="!search || search === ''" class="new-input-icon new-input-icon--right new-input--search">
                            ⌘F
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">
              <template v-if="data.length">
                <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                  <div class="column">
                                <b-table
                                  sticky-header
                                  height="calc(100vh - 324px)"
                                    :data="data"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true"
                                    @click="handleEdit"
                                    @sort="handleChangeSort">

                                    <b-table-column field="createdAt" label="Date" v-slot="props">
                                        {{ dateFormat(props.row.createdAt) }}
                                    </b-table-column>

                                    <b-table-column field="product" label="Product" v-slot="props">
                                        {{ props.row.productNameQuantity }}
                                    </b-table-column>

                                    <b-table-column field="customer" label="Customer" v-slot="props">
                                        {{ props.row.customerName }}
                                    </b-table-column>

                                  <b-table-column field="origin" label="Origin" v-slot="props">
                                    {{ props.row.originPlatformDescription }}
                                  </b-table-column>

                                    <b-table-column field="note" label="Notes" v-slot="props">
                                        {{ props.row.note }}
                                    </b-table-column>

                                    <b-table-column field="status" label="Status" v-slot="props">
                                        <b-dropdown :ref="`statusRow${props.row.id}`" aria-role="list" position="is-bottom-left"
                                                    class="has-text-left is-clickable">

                                            <template #trigger="{ active }">

                                                <div @click.prevent.stop="handleStatusActionClick(props.row)" >
                                                    <b-button outlined
                                                        :label="props.row.status"
                                                        type="is-info"
                                                        :icon-right="active ? 'chevron-up' : 'chevron-down'" />
                                                </div>
                                            </template>

                                            <template v-for="statusOption in editableStatusOptions">
                                                <b-dropdownItem aria-role="listitem" :disabled="statusOption.value === props.row.status" >
                                                    <div @click.prevent.stop="handleStatusUpdate(props.row, statusOption)">
                                                        {{ statusOption.label }}
                                                    </div>
                                                </b-dropdownItem>
                                            </template>

                                        </b-dropdown>
                                    </b-table-column>

                                    <b-table-column field="action" label="" v-slot="props">
                                        <div class="is-flex">
                                            <div style="min-width:50px">
                                                <i v-if="props.row.checkoutType === 'paid'" class="fa-solid fa-circle-dollar-to-slot" title="Paid"></i>
                                                <i v-if="props.row.checkoutType === 'add-to-bill'" class="fa-solid fa-file-invoice-dollar" title="Added to bill"></i>
                                            </div>
                                            <b-dropdown :ref="`contextRow${props.row.id}`"
                                                        aria-role="list"
                                                        class="is-clickable"
                                                        position="is-bottom-left">
                                                <template #trigger="{ active }">
                                                    <div class="px-2" @click.prevent.stop="handleContextActionClick(props.row)">
                                                        <i class="fa-solid fa-ellipsis-vertical"></i>
                                                    </div>
                                                </template>

                                                <b-dropdownItem aria-role="listitem" >
                                                    <div @click.prevent.stop="handleEdit(props.row)">
                                                        View
                                                    </div>
                                                </b-dropdownItem>
                                            </b-dropdown>
                                        </div>
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                        <div v-if="paginator"  class="columns">
                            <div class="column">
                                <Paginator :paginator="paginator"></Paginator>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <section class="hero">
                            <div class="hero-body">
                                <p class="has-text-centered">
                                    No orders found for selected date
                                </p>
                            </div>
                        </section>
                    </template>
                </div>
            </div>

        </section>

        <OrderUpdateModal v-if="$route.query.show" :id="$route.query.show" @close="$router.back()" />

    </div>
</template>

<script>

import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';
import BookingModal from '@/views/Admin/components/BookingModal';
import CalendarInput from "@/components/admin/CalendarInput";
import ComboInput from '@/views/Admin/components/ComboInput';
import {debounce} from "lodash";
import moment from "moment-timezone";
import mixin from "@/mixin";
import _ from 'lodash';
import OrderUpdateModal from '@/components/admin/Order/OrderUpdateModal';

import {
    Order,
} from '@/internal'

export default {
    name: 'Index',
    mixins: [mixin],
    components: {
        Paginator,
        BookingModal,
        CalendarInput,
        ComboInput,
        OrderUpdateModal
    },
    computed: {
      timezone() {
        return this.$store.state.system.timezone;
      },
      data() {
        return this.ids ? this.$store.getters['order/collection'](this.ids) : [];
      },
      fullPath () {
        return this.$route.fullPath;
      },
      selectedStatusString() {
          if(this.params.status.length > 2) {
            const arr = this.params.status;
            return arr.slice(0,2).join(",") + "...";
          } else if(this.params.status.length > 0 && this.params.status.length <= 2) {
            return this.params.status.join(",");
          } else {
            return "Any status";
          }
      },
      editableStatusOptions() {
          return this.statusOptions.filter(status => status.editable);
      }
    },
    created() {
        this.loadData(true);

        this.params.status.forEach(s => {
         this.selectedStatuses.push(this.statusOptions.find(status => status.value === s))
        })
    },
    data() {
        return {
            ids: [],
            paginator: null,
            search: this.$route.query.search || null,
            performSearch: debounce(() => {
                if(this.search && this.search !== "") {
                    this.updateQuery('search', this.search);
                } else {
                    this.updateQuery('search', null);
                }
            }, 1000),
            statusOptions: Order.statusOptions,
            loading: false,
            params: {
                date: this.$route.query.date ? moment(this.$route.query.date).toDate() : new Date(),
                search: this.$route.query.search || null,
                status: this.$route.query.status ? this.$route.query.status.split(",") : ['requested','confirmed']
            },
            selectedStatuses: []
        }
    },
    methods: {
        ...mapActions({
            orderIndex: 'order/index',
            orderDestroy: 'order/destroy',
            orderStatusUpdate: 'order/statusUpdate'
        }),
        formatDatepickerDate(date) {
            return date ? date.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) : ''
        },
        dateFormat(value) {
            return this.dateFormatTimezone(value).format('ddd, D MMM YYYY');
        },
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        handleClearDate() {
            this.updateQuery('date', null);
            this.params.date = null;
        },
        handleEdit(row) {
          this.updateQuery('show', row.id);
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        handleSearch() {
            this.performSearch();
        },
        handleDateChange() {
            this.updateQuery('date', moment(this.params.date).format("YYYY-MM-DD"))
        },
        handleStatusChange(status) {
          this.params.status = status.map(s => s.value)
          this.updateQuery('status', status.length ? status.map(s => s.value).join(",") : null)
        },
        updateQuery(key, value) {
            const route = Object.assign({}, this.$route);

            const query = {
                ...route.query,
            };

            if(value) {
                query[key] = value;
            } else {
                delete query[key];
            }


            this.$router.push({
                name: route.name,
                query
            });
        },
        handleStatusActionClick(row) {
            this.$refs['statusRow' + row.id].toggle();
        },
        handleContextActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        handleRowActionUpdate(row) {
            const route = window.app.findRouteByName('admin/order/update', [], {
                params: {
                    orderId: row.id
                },
            });

            this.$router.push(route);
        },
        handleStatusUpdate(row, statusOption) {
            if(row.status === statusOption.value) {
                return;
            }

            window.app.confirm({
                title: 'Change status',
                message: `Are you sure you want to change order status to ${statusOption.label}?`,
                type: 'is-info',
                onConfirm: () => {
                    this.loading = true;

                    this.orderStatusUpdate({
                        id: row.id,
                        status: statusOption.value
                    }).then(() => {
                        window.app.snackbar('Order status updates');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleRowActionDelete(row) {
            window.app.confirm({
                title: 'Delete Order',
                message: 'Are you sure you want to delete this order?',
                onConfirm: () => {
                    this.loading = true;

                    this.orderDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Order deleted');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });

        },
        loadData(initial) {
            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 100,
                sortBy: this.$route.query.sortBy || 'created_at',
                sortOrder: this.$route.query.sortOrder || 'asc',
                show: this.$route.query.show || null,
            };

            if (this.search) {
                params.search = this.search;
            }

            if(this.params.date) {
                params.date = moment(this.params.date).format("YYYY-MM-DD");
            }

            if(this.params.status.length > 0) {
                params.status = this.params.status.join(",");
            }

            // First load
            if(initial) {
                // Workaround: If query is the same router will not detect a change on first load
                if(_.isEqual(this.$route.query, params)) {
                    this.loadData();
                }

                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    query: {
                        ...params,
                    }
                });

                return;
            }

            this.orderIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
