import {
    OrderProduct as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const store = new ResourceStore({
    key: 'orderProduct',
    model: Model,
});

export default store.toObject();
