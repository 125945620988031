import { Account as Model } from '@/internal'

import { ResourceStore } from '@/internal/modules'

const service = new Model.service()

const store = new ResourceStore({
  key: 'account',
  namespaced: true,
  model: Model,
  state: {
    currentId: null,
  },
  getters: {
    current: (state) => {
      const account = state.currentId ? state.items[state.currentId] : null
      return account ? new Model(account) : null
    },
  },
  mutations: {
    currentId(state, currentId) {
      state.currentId = currentId
    },
  },
  actions: {
    current({ commit }, payload = {}) {
      return service.current(payload).then((response) => {
        commit('currentId', response.data.data.id)
        return ResourceStore.processData('account', commit, response.data)
      })
    },
  },
})

export default store.toObject()
