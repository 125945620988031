import Vue from 'vue';
import Buefy from 'buefy';

import App from '@/views/App.vue';

import router, { routerBuilder } from './router';
import store from './store';

import AuthDriver from '@/drivers/AuthDriver';
import LocalStorageDriver from '@/drivers/LocalStorageDriver';

import FormError from '@/views/components/FormError';

import { Framework } from '@/internal/modules';

import { MazPhoneNumberInput } from 'maz-ui'

Vue.use(MazPhoneNumberInput)

// Import main CSS
import '@/assets/scss/app.scss';

Vue.use(Buefy, { defaultIconPack: 'fas' });

Vue.component('FormError', FormError);

// Wire Up Vue
const framework = new Framework();
framework.addVue(Vue, App);
framework.addStore(store);
framework.addRouter(router, routerBuilder);

// Environment variables
framework.env({
    appName: process.env.APP_NAME,
    apiBaseUrl: process.env.APP_API_BASE_URL,
    environment: process.env.APP_ENV,
    vueAppUrl: process.env.APP_URL,
    googleClientId: process.env.GOOGLE_CLIENT_ID,
    googleSecret: process.env.GOOGLE_SECRET,
});

framework.auth(AuthDriver);
framework.storage(LocalStorageDriver);
framework.boot();


