import moment from 'moment';

import {
    Model,
} from '@/internal/modules';

import {
    AccountIntegrationService as Service
} from '@/internal';

export default class AccountIntegration extends Model {
    static get name() {
        return 'AccountIntegration';
    }
    static get vuexModuleKey() {
        return 'accountIntegration';
    }
    static get service() {
        return Service;
    }

    static get fields() {
        return {
            enabled: {
                formatter: (row) => {
                    return row.enabled ? 'Yes' : 'No';
                },
                type: Boolean,
            },
            lastUpdateSyncedAt: {
                preparePayload: (value) => {
                    return value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : null;
                }
            },
        }
    }

}
