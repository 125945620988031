<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/account-integration">Integrations</router-link>
                            </li>
                            <li>
                                {{ integration ? integration.name : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">

                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <h1 class="title">{{ form.fields.name }}</h1>
                    <div class="columns">
                        <div class="column is-half">
                            <div class="field">
                                <b-field>
                                    <b-checkbox v-model="form.fields.enabled">Enabled</b-checkbox>
                                </b-field>
                                <FormError field="enabled" :form="form"/>
                            </div>
                            <hr>
                            <label class="label">Bookings</label>
                            <div class="columns">
                                <div class="column">
                                    <b-datetimepicker
                                        v-model="syncBookingsForm.fields.startDate"
                                        placeholder="Start date"
                                        icon="calendar"
                                        horizontal-time-picker
                                        editable
                                        :locale="datePickerLocale">
                                    </b-datetimepicker>
                                </div>
                                <div class="column">
                                    <b-datetimepicker
                                        v-model="syncBookingsForm.fields.endDate"
                                        placeholder="End date"
                                        icon="calendar"
                                        horizontal-time-picker
                                        editable
                                        :locale="datePickerLocale">
                                    </b-datetimepicker>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <button class="button is-info" @click="handleSyncBookings">
                                        <span class="icon">
                                           <i class="fa-solid fa-arrows-rotate"></i>
                                        </span>
                                        <span>Sync bookings</span>
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <b-field label="Resources">
                                <button class="button is-info" @click="handleSyncResources">
                                        <span class="icon">
                                           <i class="fa-solid fa-arrows-rotate"></i>
                                        </span>
                                    <span>Sync resources</span>
                                </button>
                            </b-field>
                            <hr>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import moment from 'moment';

import {
    Form,
} from '@/internal/modules'

import {
    AccountIntegration,
} from '@/internal'

export default {
    name: 'Form',
    components: {
        AccountIntegration
    },
    computed: {
        integration() {
            return this.$route.params.accountIntegrationId ? this.$store.getters['accountIntegration/show'](this.$route.params.accountIntegrationId) : null;
        },
    },
    mounted() {
        if(this.integration) {
            this.fillForm();
        }
    },
    data() {
        return {
            form: new Form({
                enabled: true,
            }),
            syncBookingsForm: new Form({
                startDate: new Date(),
                endDate: null
            }),
            loading: false,
            datePickerLocale: undefined
        };
    },
    methods: {
        ...mapActions({
            accountIntegrationUpdate: 'accountIntegration/update',
            syncBookings: 'accountIntegration/syncBookings',
            syncResources: 'accountIntegration/syncResources'
        }),
        handleSave() {
            const fields = {
                ...this.form.fields
            }

            this.loading = true;
            this.form.errors.clear();

            let action = null;

            action = this.accountIntegrationUpdate;

            action(AccountIntegration.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/account-integration');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleSyncBookings() {
            this.loading = true;

            const payload  = {
                id: this.integration.id,
                startDate : this.syncBookingsForm.fields.startDate ? moment(this.syncBookingsForm.fields.startDate).format('YYYY-MM-DD') : null,
                endDate : this.syncBookingsForm.fields.endDate ? moment(this.syncBookingsForm.fields.endDate).format('YYYY-MM-DD') : null,
            };

            console.log(payload);

            this.syncBookings(payload).then((response) => {
                console.log(response);
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleSyncResources() {
            this.loading = true;

            this.syncResources({id: this.integration.id}).then((response) => {
                console.log(response);
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        fillForm() {
            this.form.fields = {
                ...this.integration,
                lastUpdateSyncedAt: this.integration.lastUpdateSyncedAt ? new Date(this.integration.lastUpdateSyncedAt) : null
            }
        }
    },
    watch: {
        integration() {
            if(this.integration) {
                this.fillForm();
            }
        },
    }
};
</script>

<style scoped lang="scss">

</style>
