<template>
  <div class="flex r:8 ofh b:base-20">
    <button
      v-for="[title, slug, visibility] in tabs"
      v-if="!visibility || !visibility.hide"
      :key="slug"
      class="reset c:primary-80 f:16 ff:primary semi p:4|16"
      :class="{ 'bg:accent-10': value == slug }"
      @click="selectTab(slug)"
    >
      {{ title }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TabNav',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        const hash = newRoute.hash?.slice(1)
        if (!hash) return
        const [_, slug] = this.tabs.find(([_, slug]) => slug === hash)
        if (slug) {
          this.$emit('input', slug)
        }
      },
    },
  },
  methods: {
    selectTab(slug) {
      this.$router.replace({
        hash: `#${slug}`,
        query: this.$route.query,
      })
    },
  },
}
</script>
