import { ResourceService } from '@/internal/modules';

export default class ServiceService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'service';
    }

    available(payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/available`,
            params: this.prepareParams(params),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    availability(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/${id}/availability`,
            params: this.prepareParams(params),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    catalog(payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/catalog`,
            params: this.prepareParams(params),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    pricing(id, payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/${id}/pricing`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

    showBySlug(slug) {

        const { params, ...rest } = slug;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/show/${slug}`,
            params: this.prepareParams(params),
        });
    }
}
