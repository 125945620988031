import {
    Model
} from '@/internal/modules';

import {
    ServiceProductService as ServiceProductService
} from '@/internal';


export default class ServiceProduct extends Model {

    static get name() {
        return 'ServiceProduct';
    }

    static get vuexModuleKey() {
        return 'serviceProduct';
    }

    static get service() {
        return ServiceProductService;
    }

    static get fields () {
        return {
            default: {
                type: Boolean,
            },
        };
    }

}
