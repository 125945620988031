<template>
    <div :class="['app-wrapper', {'modal-page' : !$route.meta.showNavbar}]">
        <Navbar v-if="$route.meta.showNavbar"/>
        <div :class="[
            'main-container',
            {'main-container--navbar' : $route.meta.showNavbar},
            {'main-container--menu' : $route.meta.showMainMenu},
            'hide-scrollbar'
        ]">
            <router-view></router-view>
        </div>
        <MainMenu v-if="showMainMenu" />
    </div>
</template>
<script>

import MainMenu from '@/views/components/MainMenu';
import Navbar from '@/views/components/Navbar';
import {mapActions} from "vuex";

export default {
    name: "App",
    components: {
        MainMenu,
        Navbar
    },
    computed: {
        account() {
          return this.$store.getters['account/current'];
        },
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        routeTitle () {
            return this.$route.meta ? this.$route.meta.title : '';
        },
        showMainMenu() {
            return this.$route.meta.hasOwnProperty('showMainMenu') ? this.$route.meta.showMainMenu : false;
        }
    },
    data() {
        return {
            mobileMenuActive: false
        }
    },
    mounted() {
        // Uncomment this when working on multitenant/white-label
        this.loadCurrentAccount()
    },
    methods: {
        ...mapActions({
            currentAccount: 'account/current'
        }),
        handleToggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive;
        },
        loadCurrentAccount() {
            this.loading = true;

            return this.currentAccount().finally(() => {
                this.loading = false;
            });
        }
    }
}

</script>

<style lang="scss">

.app-wrapper.modal-page {
    .navbar {
        background-color: transparent;
    }
}

.main-container {
    height: 100%;

    &.main-container--navbar {
        height: calc(100% - 69px);
        overflow-y: scroll;

        &.main-container--menu {
            height: calc(100% - 150px);
        }
    }
}
</style>
