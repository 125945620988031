<template>
    <div>
        <BLoading :is-full-page="true" v-model="loading"></BLoading>

        <section v-if="service" class="section p-0">
            <div class="container container-extra-small p-0">
                <div class="column is-flex is-flex-direction-column content-height">
                    <template>
                        <div class="columns flex-column">
                            <div class="position-relative">
                                <div @click="handleBack" class="activity-button position-absolute button small has-background-white border-radius-12">
                                    <i class="fa-solid fa-arrow-left fa-lg"></i>
                                </div>
                                <div class="activity-image" :style="{'background-image' : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.77%), url(${service.imageUrl})`}" alt=""></div>
                                <div class="activity-name position-absolute has-text-white font-primary fs-55 text-center">{{ service.nameFinal }}</div>
                            </div>
                            <div class="column activity-description position-relative has-background-white">
                                <div class="columns is-flex">
                                    <div v-if="service.showPricing" class="column is-half">
                                        <div class="app-grey fs-14">
                                            Cost by person
                                        </div>
                                        <div class="has-text-black has-text-weight-semibold fs-22">
                                            <template v-if="service.isNonScheduled">
                                                    In Package
                                            </template>
                                            <template v-else>
                                                <template v-if="service.priceDescription">
                                                    {{ service.priceDescription }}
                                                </template>
                                                <template v-else >
                                                    £{{ service.price }}
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="column is-half">
                                        <div class="app-grey fs-14">Session duration</div>
                                        <div class="has-text-black has-text-weight-semibold fs-22">{{ service.duration }} min</div>
                                    </div>
                                </div>
                                <p v-html="service.description" class="has-text-black mb-0 pre-formatted"></p>
                            </div>
                            <div class="position-relative border-top m-3">
                                <span class="activity-description-image position-absolute px-3 has-background-white app-grey">
                                    <img src="/images/compass.svg" width="32">
                                </span>
                            </div>
                            <div class="column">
                                <div class="field mb-5">
                                    <label class="mb-2 has-text-weight-semibold has-text-black">For how many people?</label>
                                    <GroupInput show-icon :full-width="false" v-model="form.fields.group.adults" />
                                    <FormError field="adults_number" :form="form"/>
                                </div>
                                <div class="field ">
                                    <label class="mb-2 has-text-weight-semibold has-text-black">Choose your start time</label>
                                    <b-field>
                                        <HorizontalCalendar v-model="form.fields.date"/>
                                    </b-field>
                                    <FormError field="date" :form="form"/>
                                </div>
                                <template v-if="service.isClosed">
                                    <div class="field mb-5">
                                        <div class="columns m-0 is-mobile is-flex-direction-column">
                                            <div class="text-center mt-5">
                                                <p class="has-text-black has-text-weight-semibold fs-18 my-5">This activity is closed on this day</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="!service.isAvailable">
                                    <div class="field mb-5">
                                        <div class="columns m-0 is-mobile is-flex-direction-column">
                                            <div class="text-center mt-5">
                                                <p class="has-text-black has-text-weight-semibold fs-18 my-5">This activity is not available on this day</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="field mb-5">
                                        <b-field>
                                            <div class="columns m-0 is-mobile is-flex-direction-column">
                                                <div v-if="showTimeRangePicker" class="column p-0 mb-4">
                                                    <TimeRangePicker v-model="form.fields.timeRange" :options="timeslotOptions" />
                                                </div>
                                                <div class="column p-0">
                                                    <div class="time-list columns is-mobile has-background-white is-multiline m-0">
                                                        <div
                                                            v-for="(option, index) in filteredTimeslotOptions"
                                                            v-if="visibleTimeslot(option)"
                                                            :class="['column is-half text-center p-0 time-list__item', {'disabled': option.disabled}]"
                                                            @click="!option.disabled ? selectTimeSlot(option) : null"
                                                        >
                                                            <div
                                                                class="column border-radius-12 text-center p-3 mx-1"
                                                                :class="{
                                                            'mt-3': index > 1,
                                                            'disabled': option.disabled,
                                                            'is-clickable border-grey app-grey': !option.disabled && form.fields.from !== option.value,
                                                            'has-background-black has-text-white border-black': form.fields.from === option.value
                                                        }"
                                                                :data-status="option.status"
                                                            >
                                                                <img v-if="option.disabled && option.selfOwned" src="/images/ticketVoucher.svg" /> {{ option.label }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError field="from" :form="form"/>
                                                </div>
                                            </div>
                                        </b-field>
                                    </div>
                                    <div v-if="service.configuration && service.configuration.flexibleTime" class="field">
                                        <label class="mb-2 has-text-weight-semibold has-text-black">For how long?</label>
                                        <b-field>
                                            <div class="duration-list columns is-mobile has-background-white is-multiline m-0 position-relative">
                                                <div
                                                    v-for="(option, index) in timeForOptions"
                                                    class="column is-one-third text-center p-0 duration-list__item is-clipped"
                                                    @click="selectTimeFor(option)"
                                                >
                                                    <div
                                                        class="column text-center position-absolute is-flex is-justify-content-center is-align-items-center"
                                                        :class="{
                                                        'disabled': option.disabled,
                                                        'is-clickable': !option.disabled,
                                                        'has-background-black has-text-white border-radius-12': form.fields.quantity === option.value
                                                    }"
                                                        :data-status="option.status"
                                                    > {{ option.label }} </div>
                                                </div>
                                            </div>
                                        </b-field>
                                        <FormError field="quantity" :form="form"/>
                                    </div>
                                    <FormError field="message" :form="form"/>
                                    <FormError field="phone" :form="form"/>
                                    <FormError field="product" :form="form"/>
                                </template>
                                <template v-if="showExternalAvailability">
                                    <!-- DEBUGGING-->
                                    <h3>Resmio availability</h3>
                                    <p v-for="externalTimeslot in service.externalAvailability">
                                              {{ externalTimeslot.localTimeFormatted }} : {{ externalTimeslot.available }} available
                                    </p>
                                </template>
                            </div>
                        </div>
                        <div v-if="!service.isClosed" class="controls has-margin-top">
                            <b-button class="button is-black border-radius-12 is-clickable is-fullwidth" :disabled="!service.isAvailable" @click="handleBook()">BOOK</b-button>
                        </div>
                    </template>
                </div>
            </div>
        </section>

        <PhoneInputModal :show="phoneInputModalActive" @success="handlePhoneUpdated" @cancel="togglePhoneInputModalActive" />

        <AvailabilityIssueModal :show="availabilityIssueModalActive" @ok="toggleAvailabilityIssueModalActive" @cancel="toggleAvailabilityIssueModalActive" />

    </div>

</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Booking,
} from '@/internal';

import moment from "moment-timezone";
import GroupInput from "../components/GroupInput";
import HorizontalCalendar from "../components/HorizontalCalendar";
import TimeRangePicker from '@/views/components/TimeRangePicker'
import PhoneInputModal from "@/views/components/PhoneInputModal";
import AvailabilityIssueModal from "@/views/components/AvailabilityIssueModal";
import Router from '@/internal/modules/Router';
import mixin from "@/mixin";

export default {
    name: 'BookActivity',
    mixins: [mixin],
    components: {
        GroupInput,
        HorizontalCalendar,
        TimeRangePicker,
        PhoneInputModal,
        AvailabilityIssueModal
    },
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
        booking() {
          return {
              date: this.form.fields.date ? this.dateFormat(this.form.fields.date) : "",
              time: this.form.fields.from,
              price: this.totalPrice,
              group: this.groupFormat(this.form.fields.group),
              duration: this.timeFormat(this.form.fields.quantity * this.service.duration)
          }
        },
        fullPath () {
            return this.$route.fullPath;
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        nextStepUrl() {
            // Services that not required to be paid or will be paid on the spot
            if(this.service.bookFinalStep === 'confirmation' || this.service.isNonScheduled) {
                return "service/activity/confirmation"
            } else { // Services that require payment or room charge
               return  "service/activity/checkout";
            }
        },
        date() {
          return this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null;
        },
        minDate() {
            return moment().subtract(1,'day').toDate();
        },
        quantity() {
            return this.form.fields.quantity;
        },
        totalPrice() {
          return this.form.fields.quantity * this.service.price * this.totalPersons;
        },
        totalPersons() {
            const adults_number = this.form.fields.group ? this.form.fields.group.adults : 0;
            const children_number = this.form.fields.group ? this.form.fields.group.children : 0;

            return adults_number + children_number;
        },
        timeslotOptions() {
            return this.timeslots ? this.timeslots.filter((filterTimeslot) => !['closed'].includes(filterTimeslot.status)).map((mapTimeslot) => {
                    return {
                        label: this.dateFormatTimezone(mapTimeslot.start).format("HH:mm"),
                        value: moment.utc(mapTimeslot.start).format("HH:mm"),
                        disabled: !mapTimeslot.available,
                        status: mapTimeslot.status,
                        selfOwned: mapTimeslot.self_owned,
                    };
                }) : [];
        },
        filteredTimeslotOptions() {
          if(!this.showTimeRangePicker) {
            return this.timeslotOptions;
          }

          const timeRangeArr = this.form.fields.timeRange.split("-");
          const timeRangeFrom = timeRangeArr[0];
          const timeRangeTo = timeRangeArr[1];

          return this.timeslotOptions.filter((timeslot) => {
              return (timeslot.value >= timeRangeFrom && timeslot.value <= timeRangeTo);
          });
        },
        timeForOptions() {
            let options = [];

            if(this.service && this.service.duration) {
                for(let i = 1; i < 4; i++) {
                    options = [
                        ...options,
                        {
                            label: this.timeFormat(i * this.service.duration),
                            value: i,
                        }
                    ]
                }
            }

            return options;
        },
        showTimeRangePicker() {
            return this.timeslotOptions.length > 20;
        },
        showExternalAvailability() {
            return this.$route.query.show_external_availability;
        }
    },
    async created() {
        await this.loadService();

        // Prefill form
        if(this.activeBooking) {
            if(this.$route.query.date) {
                this.form.fields.date = moment(this.$route.query.date).toDate();
            } else {
                this.form.fields.date = this.activeBooking.startAt.toDate();
            }
            this.form.fields.group.adults = this.activeBooking.totalPersons;
        } else {
            if(this.$route.query.date) {
                this.form.fields.date = moment(this.$route.query.date).toDate();
            }
        }

        if(this.previousRouteName === 'service/activity/checkout' || this.previousRouteName === 'service/activity/confirmation') {
            this.fillFormFromStorage();
        } else {
            this.clearFormStorage();
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.previousRouteName = from.name;
        });
    },
    data() {
        return {
            form: new Form({
                serviceId: this.$route.params.serviceUuid,
                nonScheduledBookingId: this.$route.query.non_scheduled_booking,
                date: new Date(),
                from: null,
                quantity: 1,
                timeRange: '09:00-12:00',
                group: {
                    adults: 1,
                    children: 0
                },
                origin_platform: Booking.originPlatformGuestApp
            }),
            serviceId: null,
            timeslots: [],
            loading: false,
            phoneInputModalActive: false,
            availabilityIssueModalActive: false,
            previousRoute: null
        };
    },
    methods: {
        ...mapActions({
            bookingStore: 'booking/store',
            serviceAvailability: 'service/availability',
            nonScheduledBookingReset: 'nonScheduledBooking/reset'
        }),
        handleBack() {
            Router.goToLastRoute(this.$router);
        },
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        selectTimeSlot(option) {
            if(option.disabled) {
                return;
            }

            this.form.fields.from = option.value;
        },
        selectTimeFor(option) {
            this.form.fields.quantity = option.value;
        },
        timeFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        groupFormat(value) {
            let values = [];

            if(value.adults) {
                if(value.adults > 1) {
                    values.push(value.adults + " adults");
                } else {
                    values.push(value.adults + " adult");
                }
            }

            if(value.children) {
                if(value.children > 1) {
                    values.push(value.children + " children");
                } else {
                    values.push(value.children + " child");
                }
            }

            return values.join(" ");
        },
        handleBook() {
            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
                date: this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null,
                adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                children_number: this.form.fields.group ? this.form.fields.group.children : null,
                service_id : this.service.id,
                service_type: this.service.type,
                validate_only : true // only to validate
            }

            this.loading = true;

            this.bookingStore(fields).then((response) => {
                const route = window.app.findRouteByName(this.nextStepUrl);

                this.$router.push(route);
            }).catch((error) => {

                this.form.recordErrors(error);

                if(this.form.errors.has('phone')) {
                    this.togglePhoneInputModalActive();
                }

            }).finally(() => {
                this.loading = false;
            });
        },
        handlePhoneUpdated() {
            this.togglePhoneInputModalActive();
            this.handleBook();
        },
        loadService() {
            this.loading = true;

            const data = {
                id: this.$route.params.serviceUuid,
                params: {
                    type: 'activity',
                    date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
                    quantity: this.form.fields.quantity,
                    adults_number: this.form.fields.group ? this.form.fields.group.adults : null,
                    children_number: this.form.fields.group ? this.form.fields.group.children : null,
                    non_scheduled_booking: this.$route.query.non_scheduled_booking
                }
            }

            return this.serviceAvailability(data).then((response) => {
                this.serviceId = response.ids[0];
                this.timeslots = response.availability;
            }).finally(() => {
                this.loading = false;
            });
        },
        saveFormStorage() {
            window.app.storage.setItem('booking', this.form.fields)
        },
        clearFormStorage() {
            window.app.storage.removeItem('booking')
        },
        fillFormFromStorage() {
            const booking =  window.app.storage.getItem('booking');

            if(booking) {
                this.form.fields = {
                    ...booking,
                    date: new Date(booking.date)
                };
            }

        },
        checkIsSelectedTimeslotAvailable() {
          if(this.form.fields.from) {
              const selectedTimeslot = this.filteredTimeslotOptions.find((timeslot) => {
                  return timeslot.value === this.form.fields.from;
              })

              if(selectedTimeslot && selectedTimeslot.disabled) {
                  this.form.fields.from = null;
                  this.toggleAvailabilityIssueModalActive();
              }
          }
        },
        togglePhoneInputModalActive() {
            this.phoneInputModalActive = !this.phoneInputModalActive;
        },
        toggleAvailabilityIssueModalActive() {
            this.availabilityIssueModalActive = !this.availabilityIssueModalActive;
        },
        visibleTimeslot(slot) {
            // Hide timeslots with closing status
            return slot.status !== 'closing';
        }
    },
    watch: {
        'form.fields': {
            async handler() {
                await this.loadService();

                this.saveFormStorage();

                this.checkIsSelectedTimeslotAvailable();
            },
            deep: true
        }
    }
};
</script>

<style scoped lang="scss">
.duration-list {
    height: 52px;

    &__item {
        border-top: 2px solid #ced4da;
        border-bottom: 2px solid #ced4da;

        & > div {
            height: 100%;
            width: inherit;
            top: 0;
        }

        &:nth-of-type(1) {
            border-left: 2px solid #ced4da;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        &:nth-of-type(3) {
            border-right: 2px solid #ced4da;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}
.activity-image {
    height: 390px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.activity-name {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    line-height: 1;
}
.activity-button {
    top: 16px;
    left: 16px;
}
.activity-description {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    margin-top: -20px;
    z-index: 1;
}
.activity-description-image {
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
}
</style>
