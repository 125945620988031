import moment from "moment-timezone";

export default {
    computed: {
        timezone() {
            return this.$store.state.system.timezone;
        }
    },
    data() {
        return { }
    },
    created: function () {},
    methods: {
        dateFormatTimezone: function (date) {
            // If DST (summertime) all good as we store UTC summertime in DB
            if(moment.utc(date).tz(this.timezone).isDST()) {
                return moment.utc(date).tz(this.timezone);
            } else { // If not DST (wintertime) we need to add one hour
                return moment.utc(date).add(1,'hour').tz(this.timezone);
            }
        },
        timeFormatTimezone: function (time) {
            const date = moment.utc("2023-07-01 " + time);
            // If DST (summertime) all good as we store UTC summertime in DB
            if(date.tz(this.timezone).isDST()) {
                return date.tz(this.timezone);
            } else { // If not DST (wintertime) we need to add one hour
                return date.add(1,'hour').tz(this.timezone);
            }
        },
    },
}
