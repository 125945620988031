import {
    Model,
} from '@/internal/modules';

import {
    File,
    Category,
    ProductService as Service
} from '@/internal';

export default class Product extends Model {
    static get name() {
        return 'Product';
    }

    static get vuexModuleKey() {
        return 'product';
    }

    static get service() {
        return Service;
    }

    static get fields () {
        return {
            files: {
                preparePayload: (value) => {
                    return value ? value.map(file => file.id) : [];
                },
                type: File,
            },
        };
    }

    get categories() {
        return this.hasMany(Category)
    }

    get files() {
        return this.hasMany(File);
    }

    static get statusOptions() {
        return [
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Published',
                value: 'published',
            }
        ]
    }

}
