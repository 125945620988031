<template>
    <section class="section p-0 pt-3 pb-100">
        <BLoading :is-full-page="true" v-model="loading"></BLoading>
    </section>
</template>

<script>
export default {
    name: 'Confirmation',
    components: {},
    computed: {},
    created() {
        let params = {};

        const route = window.app.findRouteByName('home', [], params);

        this.$router.push(route);

        this.showSuccessBar();
        this.clearFormStorage();
    },
    data() {
        return {
            loading: true
        };
    },
    methods: {
        showSuccessBar() {
            window.app.snackbar({
                message: `<div class="has-text-weight-semibold">Order created</div><div class="has-text-weight-normal">We will deliver it soon</div`,
                position: 'is-bottom',
                actionText: null,
            });
        },
        clearFormStorage() {
            window.app.storage.removeItem('order')
        },
    }
};
</script>

<style scoped lang="scss">

</style>
