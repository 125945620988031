<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div class="p:24">
      <label class="--label mb:32">
        Title
        <input type="text" class="--input mt:4" v-model="form.fields.title" />
      </label>

      <label class="--label mb:32">
        Content
        <textarea class="--textarea mt:4 h-min:120" v-model="form.fields.content" />
      </label>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <label class="--label mb:4">Status</label>
          <SelectInput v-model="form.fields.status" :options="statusOptions" />
        </div>
      </div>

      <label class="--label mb:4">Images</label>
      <FileUpload
        v-model="form.fields.files"
        :entity="information"
        :multiple="true"
        :extensions="['jpg', 'jpeg', 'png']"
      />
    </div>

    <template slot="footer">
      <button
        v-if="isEdit"
        @click="handleDelete"
        class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover"
      >
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import { mapActions } from 'vuex'

import { Form } from '@/internal/modules'

import { Information } from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload.vue'
import SideDrawer from '@/components/admin/SideDrawer.vue'

export default {
  name: 'InformationModal',
  components: {
    FileUpload,
    SideDrawer,
  },
  data() {
    return {
      form: new Form({
        title: '',
        content: '',
        status: 'draft',
        files: [],
      }),
      loading: false,
      statusOptions: Information.statusOptions,
    }
  },
  computed: {
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
    information() {
      return this.isEdit ? this.$store.getters['information/show'](this.id) : null
    },
  },
  mounted() {
    if (this.information) {
      this.form.fields = {
        ...this.information,
        files: this.information.files ? this.information.files : [],
      }
    }
  },
  methods: {
    ...mapActions({
      informationDestroy: 'information/destroy',
      informationStore: 'information/store',
      informationUpdate: 'information/update',
      informationIndex: 'information/index',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null

      if (this.isEdit) {
        action = this.informationUpdate
      } else {
        action = this.informationStore
      }

      action(Information.preparePayload(fields))
        .then(() => {
          window.app.snackbar({
            message: this.isEdit ? 'Updated successfully!' : 'Created successfully!',
          })
          this.$router.push('/admin/information')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Information',
        message: 'Are you sure you want to delete this information?',
        onConfirm: () => {
          this.loading = true
          this.informationDestroy({
            id: this.information.id,
          })
            .then(() => {
              window.app.snackbar('Information Deleted')

              this.$router.push('/admin/information')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
  },
  watch: {
    information() {
      if (this.information) {
        this.form.fields = {
          ...this.information,
          files: this.information.files ? this.information.files : [],
        }
      }
    },
  },
}
</script>
