<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading" ></b-loading>
    <div v-if="service">
      <section class="section is-title-bar hero is-hero-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <ul>
                <li>
                  <router-link to="/admin">Home</router-link>
                </li>
                <li>
                  <router-link to="/admin/service">Items</router-link>
                </li>
                <li v-if="service">
                  <router-link :to="{ name:'admin/service/update', params: { serviceId: service.id }}">{{ service.name }}</router-link>
                </li>
                <li>
                  Items
                </li>
              </ul>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button  @click="serviceItemModalActive = true" class="mr-1" type="is-primary">Add Item</b-button>
            </div>
          </div>
        </div>
      </section>

      <section class="section">

        <FormMenu />

        <div class="card">
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <b-table
                  :data="items"
                  :striped="true"
                  :hoverable="true"
                  :sortable="true">

                  <b-table-column field="title" label="Name" v-slot="props">
                    {{ props.row.title }}
                  </b-table-column>

                  <b-table-column field="capacity" label="Capacity" v-slot="props">
                    {{ props.row.capacity }}
                  </b-table-column>

                  <b-table-column label="" v-slot="props">
                    <b-dropdown :ref="`contextRow${props.row.id}`"
                                aria-role="list"
                                position="is-bottom-left"
                                class="has-text-left is-clickable">

                      <template #trigger="{ active }">
                        <div @click.prevent.stop="handleRowActionClick(props.row)" >
                          <BIcon icon="ellipsis"/>
                        </div>
                      </template>

                      <b-dropdownItem aria-role="listitem" class="pr-0">
                        <div @click.prevent.stop="handleUpdate(props.row)">
                          <BIcon icon="pencil-alt"/>
                          Update
                        </div>
                      </b-dropdownItem>

                      <b-dropdownItem aria-role="listitem" class="pr-0">
                        <div @click.prevent.stop="handleDelete(props.row)">
                          <BIcon icon="trash-alt"/>
                          Delete
                        </div>
                      </b-dropdownItem>

                    </b-dropdown>
                  </b-table-column>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <b-modal
        v-model="serviceItemModalActive"
        trap-focus
        :destroy-on-hide="true"
        :can-cancel="false"
        aria-role="dialog"
        aria-label="Service Item Modal"
        aria-modal>
        <template #default="props">
          <ServiceItemModal :item="activeItem" :service="service" @success="handleItemAddSuccess" @close="props.close" />
        </template>
      </b-modal>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormMenu from '@/views/Admin/Service/FormMenu';
import Paginator from '@/views/Admin/components/Paginator';
import ServiceItemModal from '@/components/admin/Service/ServiceItemModal';

export default {
  name: 'Rules',
  components: {
    FormMenu,
    Paginator,
    ServiceItemModal
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
    },
    items() {
      return this.service ? this.service.items : [];
    }
  },
  mounted() {
    this.loadService();
  },
  data() {
    return {
      paginator: null,
      loading: false,
      serviceId: null,
      serviceItemModalActive: false,
      activeItem: null,
    };
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceItemDestroy: 'serviceItem/destroy',
    }),
    handleRowActionClick(row) {
      this.$refs['contextRow' + row.id].toggle();
    },
    handleUpdate(row) {
      this.activeItem = row;
      this.serviceItemModalActive = true
    },
    handleItemAddSuccess() {
      this.serviceItemModalActive = false;
      this.activeItem = null;

      window.app.snackbar({
        message: 'Added successfully!',
      });
      this.loadService();
    },
    handleDelete(row) {
      window.app.confirm({
        title: 'Delete Item',
        message: 'Are you sure you want to delete this item?',
        onConfirm: () => {
          this.loading = true;

          this.serviceItemDestroy({id: row.id}).then(() => {
            window.app.snackbar('Item deleted');
            this.loadService();
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    loadService() {
      this.loading = true;

      return this.serviceShow({
        id: this.$route.params.serviceId,
        params: {
          include: ['serviceItems']
        }
      }).then((response) => {
        this.serviceId = response[0];
      }).catch((error) => {

      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss">

</style>
