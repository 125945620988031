<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/order">Orders</router-link>
                            </li>
                            <li>
                                {{ order ? order.reference : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <template v-if="order">
                        <i v-if="order.checkoutType === 'paid'" class="fa-solid fa-circle-dollar-to-slot" title="Paid"></i>
                        <i v-if="order.checkoutType === 'add-to-bill'" class="fa-solid fa-file-invoice-dollar" title="Added to bill"></i>
                    </template>
                </div>
            </div>
        </section>
        <section v-if="order" class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Order reference: {{ order.reference }}</label>
                            </div>
                            <div class="field">
                                <label class="label">Products</label>
                                <template v-for="orderProduct in order.orderProducts">
                                    <p>{{ orderProduct.productName }}, {{ orderProduct.quantity }} x £{{ orderProduct.price }}</p>
                                </template>
                                <p>Total: £{{ order.price }}</p>
                                <p>Checkout: {{ order.checkoutTypeName }}</p>
                            </div>
                            <div class="field">
                                <label class="label">Customer</label>
                                <div v-if="order.customer"  class="control has-icons-left has-icons-right">
                                    Name: {{ order.customer.name }} <br>
                                    Email: {{ order.customer.email }} <br>
                                    Phone: {{ order.customer.phoneE164 }} <br>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Status</label>
                                <p>{{ order.status ? order.status : 'N/A'  }}</p>
                            </div>
                            <div class="field">
                                <label class="label">Created</label>
                                <p>{{ dateTimeFormat(order.createdAt) }}</p>
                            </div>

                            <div class="field">
                                <b-field label="Notes" >
                                    <b-input type="textarea" v-model="form.fields.note">
                                    </b-input>
                                </b-field>
                                <FormError field="note" :form="form"/>
                            </div>

                            <div class="controls has-margin-top">
                                <b-button type="is-success" @click="handleSave" >Save</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import mixin from "@/mixin";

import {
    Form,
} from '@/internal/modules'

import {
    Order,
} from '@/internal'

export default {
    name: 'Form',
    mixins: [mixin],
    components: {},
    computed: {
        order() {
            return this.orderId ? this.$store.getters['order/show'](this.orderId) : null;
        }
    },
    async mounted() {
        await this.loadOrder();

        this.fillForm();
    },
    data() {
        return {
            form: new Form({
                note: null
            }),
            loading: false,
            statusOptions: Order.statusOptions,
            orderId: null,
        };
    },
    methods: {
        ...mapActions({
            orderDestroy: 'order/destroy',
            orderStore: 'order/store',
            orderUpdate: 'order/update',
            orderShow: 'order/show',
        }),
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        dateFormat(value) {
            return this.dateFormatTimezone(value).format("D MMM YY");
        },
        dateTimeFormat(value) {
            return this.dateFormatTimezone(value).format("D MMM YY hh:mm A");
        },
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            this.orderUpdate({
                ...this.form.fields,
                params: {
                    include: ['customer', 'orderProducts']
                }
            }).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        loadOrder() {
            this.loading = true;

            return this.orderShow({
                id: this.$route.params.orderId,
                params: {
                    include: ['customer', 'orderProducts']
                }
            }).then((response) => {
                this.orderId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Order',
                message: 'Are you sure you want to delete this order?',
                onConfirm: () => {
                    this.loading = true;

                    this.orderDestroy({id: this.booking.id}).then(() => {
                        window.app.snackbar('Order deleted');

                        this.$router.push({
                            name: 'admin/order',
                        });

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        fillForm() {
            if(this.order) {
                this.form.fields = {
                    id: this.order.id,
                    note: this.order.note
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">

</style>
