import PaymentService from './PaymentService';

const service = new PaymentService();

export default {
  key: 'payment',
  namespaced: true,
  state: {

  },
  actions: {
    session ({ commit }, payload = {}) {
      return service.session(payload).then((response) => {
          return response.data.data;
      });
    }
  }
};
