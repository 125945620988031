<template>
    <div class="py-2 px-4 non-scheduled-booking-container mt-4">
        <div v-for="(nonScheduledBooking, index) in nonScheduledBookings" :class="['non-scheduled-booking-card', 'mr-5']">
            <div class="is-flex">
                <template v-if="nonScheduledBooking.serviceImage">
                    <div class="non-scheduled-booking-img" :style="{ 'background-image': 'url('+nonScheduledBooking.serviceImage+')' }"></div>
                </template >
                <template v-else>
                    <div class="non-scheduled-booking-img" :style="{ 'background-image': 'url(https://bulma.io/images/placeholders/1280x960.png)' }"></div>
                </template>
                <div class="is-flex is-flex-direction-column is-justify-content-center p-4">
                    <div>
                        <p class="title is-6 mb-2 has-text-weight-bold">SCHEDULE YOUR EXTRAS</p>
                    </div>
                    <div>
                        <p class="mb-2 fs-12">You have added {{ nonScheduledBooking.serviceName }} to your reservation, please choose the time for your experience.</p>
                    </div>
                    <div>
                        <BButton type="is-black" class="border-radius-12" @click="handleSchedule(nonScheduledBooking)">SCHEDULE NOW</BButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NonScheduledBookings",
    props: {
        nonScheduledBookings: {
            type: Array,
            required: true
        }
    },
    methods: {
        handleSchedule(nonScheduledBooking) {
            const route = window.app.findRouteByName('service/activity/book', [], {
                params: {
                    serviceUuid: nonScheduledBooking.serviceId
                },
                query: {
                    ...this.$route.query,
                    non_scheduled_booking: nonScheduledBooking.id
                }
            });

            this.$router.push(route);
        },
    }
}
</script>

<style lang="scss">
.non-scheduled-booking-container {
    overflow: auto;
    white-space: nowrap;
}

.non-scheduled-booking-card {
    display: inline-block;
    width: calc(100% - 50px);
    max-height: 200px;
    box-shadow: 0 5px 20px -10px rgba(28, 50, 79, 0.5), 0px 5px 20px -10px rgba(28, 55, 90, 0.5);
    border-radius: 24px;
    margin-bottom: 16px;
    white-space: normal;
    overflow: hidden;

}
.non-scheduled-booking-img {
    height: auto;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
</style>
