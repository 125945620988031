<template>
    <div>
        <div v-if="show" class="new-modal">
            <div class="new-modal--inner has-text-black">
                <div class="is-flex justify-content-between align-items-center mb-4">
                    <h4 class="has-text-weight-semibold fs-22">Availability issue</h4>
                    <img @click="handleCancel" class="is-clickable" src="/images/close2.svg" width="20"/>
                </div>
                <p class="mb-4">You can´t book this activity for this number of sessions at this hour.</p>
                <p class="mb-4">Please choose another duration</p>
                <BButton type="is-black" class="border-radius-12" expanded @click="handleOk">UNDERSTOOD</BButton>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AvailabilityIssueModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    methods: {
        handleOk() {
            this.$emit('ok')
        },
        handleCancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
