import SystemService from './SystemService';

const service = new SystemService();

import appData from '@/../../package.json';

import storage from '@/drivers/LocalStorageDriver';

const app = storage.getItem('system') || {};

const timezone = storage.getItem('timezone') || null;

if (!storage.getItem('system')) {
  storage.setItem('system', app);
}

import router from '@/router';

const updateRequired = (state) => {
  const appVersion = state.version.app.split('.');
  const apiVersion = state.version.api.split('.');
  return apiVersion[0] !== appVersion[0] || apiVersion[1] !== appVersion[1];
};

export default {
  key: 'system',
  namespaced: true,
  state: {
    maintenance: false,
    updateAvailable: false,
    updateRequired: false,
    timezone: "Europe/Dublin", // ATM we work in this timezone
    version: {
      app: appData.version,
      api: null,
    },
  },
  actions: {
    index ({ commit }) {
      return service.index().then((response) => {
        if (response && response.data && response.data.data) {
          const system = response.data.data;
          commit('system', system);
          return system;
        }

        return null;
      });
    }
  },
  mutations: {
    system (state, payload = {}) {
      state.maintenance = payload.maintenance;
      state.version.api = payload.version;

      state.updateRequired = updateRequired(state);
      state.updateAvailable = state.version.app !== payload.version;
    }
  },
  getters: {
     timezone: (state, getters, rootState) => {
         return state.timezone;
     },
  },
};
