import Vue from 'vue';
import VueRouter from 'vue-router';

import { RouterBuilder } from '@/internal/modules';

Vue.use(VueRouter);

import AdminMiddleware from '@/router/middleware/AdminMiddleware';
import AuthMiddleware from '@/router/middleware/AuthMiddleware';
import GuestMiddleware from '@/router/middleware/GuestMiddleware';

import AdminRoutes from '@/router/routes/AdminRoutes';
import PublicRoutes from '@/router/routes/PublicRoutes';
import UserRoutes from '@/router/routes/UserRoutes';

export const routerBuilder = new RouterBuilder();

routerBuilder.registerRouteMiddleware('admin', AdminMiddleware);
routerBuilder.registerRouteMiddleware('auth', AuthMiddleware);
routerBuilder.registerRouteMiddleware('guest', GuestMiddleware);

// Authorized as admin user
routerBuilder.addRoutes(AdminRoutes, ['admin']);

// Authorized as customer user
routerBuilder.addRoutes(UserRoutes, ['auth']);

// Authorized or not
routerBuilder.addRoutes(PublicRoutes, ['guest']);


routerBuilder.resetRouter = () => {

    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: routerBuilder.getRoutes(),
    });

    const originalPush = router.__proto__.push;

    router.__proto__.push = function push (location) {
        return originalPush.call(this, location).catch((error) => {
            if (!error.message.includes('Redirected when going from')
                && !error.message.includes('NavigationDuplicated')
                && !error.message.includes('Avoided redundant navigation')) {
                throw error;
            }
        });
    };

    router.beforeEach((to, from, next) => routerBuilder.beforeEach(to, from, next));

    router.afterEach((to, from) => routerBuilder.afterEach(to, from));

    return router;
};

const router = routerBuilder.resetRouter();

export default router;
