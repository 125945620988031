import {
    Service as Model
} from '@/internal';

import {
    ResourceStore,
    Paginator
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'service',
    model: Model,
    actions: {
        available({commit}, payload = {}) { // Multiple services availability per day
            return service.available(payload).then((response) => {
                const ids = ResourceStore.processData('service', commit, response.data, payload && payload.replaceStore === true);

                let paginator = null;

                if (response.data.meta && response.data.meta.pagination) {
                    paginator = new Paginator(response.data.meta.pagination);
                }

                return {
                    ids,
                    paginator,
                };
            });
        },
        availability({commit}, payload = {}) { // Single service availability per day
            return service.availability(payload.id, payload).then((response) => {
                const ids = ResourceStore.processData(this.key, commit, response.data);

                let availability = [];
                let isAvailable = true;
                let isClosed = false;
                let isNonScheduled = false;

                if(response.data.data && response.data.data.attributes) {
                    availability = response.data.data.attributes.availability;
                    isAvailable = response.data.data.attributes.is_available;
                    isClosed = response.data.data.attributes.is_closed;
                    isNonScheduled = response.data.data.attributes.is_non_scheduled;
                }

                return {
                    ids,
                    availability,
                    isAvailable,
                    isClosed,
                    isNonScheduled
                };
            });
        },
        catalog({commit}, payload = {}) { // List services grouped by category
            return service.catalog(payload).then((response) => {
                const ids = ResourceStore.processData('service', commit, response.data, payload && payload.replaceStore === true);

                let paginator = null;

                if (response.data.meta && response.data.meta.pagination) {
                    paginator = new Paginator(response.data.meta.pagination);
                }

                return {
                    ids,
                    paginator,
                };
            });
        },
        pricing({commit}, payload = {}) {
            return service.pricing(payload.id, payload).then((response) => {
                return response.data;
            });
        },
        showBySlug({commit}, slug) {
            return service.showBySlug(slug).then((response) => {
                return response.data;
            });
        }
    }
});

export default store.toObject();
