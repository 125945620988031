import store from '@/store';

export default (to, from, next) => {

    const authenticated = store.state.auth.authenticated;

    const path = window.app.findRouteByName('welcome').path;

    if (!authenticated && to.path !== path) {

        window.app.storage.setItem('redirectRoute', to);

        window.app.d(`[authMiddleware] Redirected to ${path}`);

        return next({ path });

    } else {

        return next();

    }

}
