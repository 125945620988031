import {
    User as Model
} from '@/internal';

import {
  ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
  key: 'user',
  model: Model,
  actions: {
      setActiveBooking({commit}, payload = {}) {
          return service.setActiveBooking(payload).then((response) => {
              return ResourceStore.processData(this.key, commit, response.data);
          });
      },
      activeBookingSummary({commit}, payload = {}) {
          return service.activeBookingSummary(payload).then((response) => {
              return ResourceStore.processData(this.key, commit, response.data);
          });
      },
      booking ({ commit }, payload = {}) {
          return service.booking(payload).then(response => {
              return ResourceStore.processData(this.key, commit, response.data);
          });
      },
      calendar ({ commit }, payload = {}) {
          return service.calendar(payload.id, payload).then(response => {
              return response.data;
          });
      },
      loginUrl ({ commit }, id) {
          return service.loginUrl(id).then((response) => {
              return response.data;
          });
      },
      notificationSettings ({ commit }, payload = {}) {
          return service.notificationSettings(payload).then(response => {
              return ResourceStore.processData(this.key, commit, response.data);
          });
      },
      requestDataDownload ({ commit }, payload = {}) {
          return service.requestDataDownload(payload).then(response => {
              return response.data;
          });
      },
      requestAccountDelete ({ commit }, payload = {}) {
          return service.requestAccountDelete(payload).then(response => {
              return response.data;
          });
      },

  },
});

export default store.toObject();
