import { ResourceService } from '@/internal/modules';

export default class FileService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'file';
    }

    store(payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'post',
            url: this.baseUrl,
            params: this.prepareParams(params),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    destroy(id, payload = {}) {
        const { params, ...rest } = payload;

        return this.request({
            method: 'delete',
            url: `${this.baseUrl}/${id}`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }
}
