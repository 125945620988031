<template>
    <section class="section p-0 pt-3 pb-100">
        <BLoading :is-full-page="true" v-model="loading"></BLoading>
    </section>
</template>

<script>
export default {
    name: 'Cancellation',
    components: {},
    computed: {},
    created() {
        const route = window.app.findRouteByName('calendar');

        this.$router.push(route);

        this.showSuccessBar();
    },
    data() {
        return {
            loading: true
        };
    },
    methods: {
        showSuccessBar() {
            window.app.snackbar({
                message: `<div class="has-text-weight-semibold">Experience Cancelled</div><div class="has-text-weight-normal">We’ve removed it from your calendar</div`,
                position: 'is-bottom',
                actionText: null,
            });
        },
    }
};
</script>

<style scoped lang="scss">

</style>
