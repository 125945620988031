import {
    Group as Model
} from '@/internal';

import {
  ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
  key: 'group',
  model: Model,
  actions: {
    customerAdd({commit}, payload = {}) {
      return service.customerAdd(payload.id, payload).then((response) => {
        return response.data;
      });
    },
    customerRemove({commit}, payload = {}) {
      return service.customerRemove(payload.id, payload).then((response) => {
        return response.data;
      });
    },
  }

});

export default store.toObject();
