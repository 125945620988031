<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <template v-if="user.activeBookingId">
                <div v-if="activeBooking" class="column is-flex is-flex-direction-column content-height ">
                    <div class="columns mb-auto flex-column">
                        <div class="px-3 booking-summary">
                            <div class="has-text-black" style="padding: 40px;">
                                <div class="has-text-weight-normal">Your booking number:</div>
                                <div class="fs-40 font-primary has-text-weight-normal">{{ activeBooking.number }}</div>
                                <div class="fs-22 has-text-weight-semibold">{{ activeBooking.customerName }}</div>
                            </div>

                            <div style="border-bottom: 2px dashed #C4CAD3; height: 1px; margin: 0 30px;"></div>

                            <template v-if="activeBooking.isGroup">
                                <div class="has-text-black" style="padding: 40px;">
                                    <div class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/users2.svg"/>
                                    </span>
                                        {{ activeBooking.totalPersons }} guests
                                    </div>
                                    <div class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/key2.svg"/>
                                    </span>
                                        {{ activeBooking.group.categoryName }}
                                    </div>
                                    <div class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/calendar2from.svg"/>
                                    </span>
                                        {{ activeBooking.group.startAt.format("D MMM YY") }}
                                        <span style="width: 24px;" class="text-center is-inline-block ml-2">
                                        <img src="/images/calendar2to.svg"/>
                                    </span>
                                        {{ activeBooking.group.endAt.format("D MMM YY") }}
                                    </div>
                                    <div class="has-text-weight-normal">
                                        <span style="width: 24px;" class="text-center is-inline-block"><img src="/images/moon2.svg"/></span>
                                        {{ activeBooking.group.nightsCount }} nights
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="has-text-black" style="padding: 40px;">
                                    <div class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/users2.svg"/>
                                    </span>
                                        {{ activeBooking.totalPersons }} guests
                                    </div>
                                    <div v-if="activeBooking.category" class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/key2.svg"/>
                                    </span>
                                        {{ activeBooking.category.name }}
                                    </div>
                                    <div class="has-text-weight-normal mb-2">
                                    <span style="width: 24px;" class="text-center is-inline-block">
                                        <img src="/images/calendar2from.svg"/>
                                    </span>
                                        {{ activeBooking.startAt.format("D MMM YY") }}
                                        <span style="width: 24px;" class="text-center is-inline-block ml-2">
                                        <img src="/images/calendar2to.svg"/>
                                    </span>
                                        {{ activeBooking.endAt.format("D MMM YY") }}
                                    </div>
                                    <div class="has-text-weight-normal">
                                        <span style="width: 24px;" class="text-center is-inline-block"><img src="/images/moon2.svg"/></span>
                                        {{ activeBooking.nightsCount }} nights
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="fs-40 font-primary line-height-1 px-3 mb-4 has-text-black">Experiences</div>

                        <div class="mb-6">
                            <div
                              v-for="booking of pendingNonScheduledBookings" :key="booking.id"
                              @click="handleSchedule(booking)"
                              class="is-flex is-justify-content-space-between border-bottom p-3"
                              role="button"
                            >
                              <div class="">
                                <div class="card-header-title mb-0 px-0 py-2 has-text-black is-flex is-justify-content-space-between fs-22 line-height-1">
                                  <div class="has-text-weight-semibold">{{ booking.serviceName }}</div>
                                </div>
                                <div class="line-height-1" style="color: #eab308">Schedule now</div>
                              </div>
                              <div class="card-header-icon p-0">
                                <b-icon icon="angle-right"></b-icon>
                              </div>
                            </div>
                            <BCollapse
                                class="show border-bottom is-shadowless has-background-white"
                                animation="slide"
                                v-for="(booking, index) of bookings"
                                :key="index"
                                :open="isBookingsOpen === index"
                                @open="isBookingsOpen = index"
                                :aria-id="'contentId-' + index">
                                <template #trigger="props">
                                    <div
                                        class="is-shadowless is-radiusless border-0 px-3 py-3"
                                        role="button"
                                        :aria-controls="'contentId-' + index"
                                        :aria-expanded="props.open"
                                    >
                                        <div class="is-flex is-justify-content-space-between">
                                            <div class="card-header-title mb-0 px-0 py-2 has-text-black is-flex is-justify-content-space-between fs-22 line-height-1">
                                                <div class="has-text-weight-semibold">{{ booking.service.nameFinal }}</div>
                                                <div v-if="booking.price" class="has-text-weight-semibold">£ {{ booking.price }}</div>
                                            </div>
                                            <a class="card-header-icon p-0">
                                                <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
                                            </a>
                                        </div>
                                        <div class="has-text-weight-normal line-height-1">{{ dateFormat(booking.startAt)}} at {{ timeFormat(booking.startAt) }}</div>
                                    </div>
                                </template>
                                <div class="card-content pt-3">
                                    <div v-if="booking.service.showPricing" class="content mb-1 has-text-black is-flex is-justify-content-space-between">
                                        <div class="has-text-weight-normal">Cost by person & Session</div>
                                        <div class="has-text-weight-semibold">
                                            <template v-if="booking.service.priceDescription">
                                                {{ booking.service.priceDescription }}
                                            </template>
                                            <template v-else >
                                                £ {{ booking.service.price }}
                                            </template>
                                        </div>
                                    </div>
                                    <div class="content mb-1 has-text-black is-flex is-justify-content-space-between">
                                        <div class="has-text-weight-normal">Person</div>
                                        <div class="has-text-weight-semibold">x{{ booking.totalPersons }}</div>
                                    </div>
                                    <div class="content mb-1 has-text-black is-flex is-justify-content-space-between is-align-items-center">
                                        <div class="has-text-weight-normal">Duration</div>
                                        <div class="has-text-weight-semibold line-height-1 text-center">
                                            <div>{{ durationFormat(booking.quantity * booking.service.duration) }}</div>
                                            <div class="fs-12">({{ booking.quantity }} sessions)</div>
                                        </div>
                                    </div>
                                </div>
                            </BCollapse>
                        </div>

                        <div class="fs-40 font-primary line-height-1 px-3 mb-4 has-text-black">Services</div>

                        <BCollapse
                            class="show border-bottom is-shadowless has-background-white"
                            animation="slide"
                            v-for="(order, index) of orders"
                            :key="index"
                            :open="isOrdersOpen === index"
                            @open="isOrdersOpen = index"
                            :aria-id="'contentId-' + index">
                            <template #trigger="props">
                                <div
                                    class="card-header is-shadowless is-radiusless border-0 px-3 pb-3"
                                    role="button"
                                    :aria-controls="'contentId-' + index"
                                    :aria-expanded="props.open"
                                >
                                    <div class="card-header-title mb-0 px-0 py-2 has-text-black is-flex is-justify-content-space-between fs-22 line-height-1">
                                        <div class="has-text-weight-semibold">{{ order.productNameQuantity }}</div>
                                        <div class="has-text-weight-semibold">£ {{ order.price }}</div>
                                    </div>
                                    <a class="card-header-icon p-0">
                                        <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
                                    </a>
                                </div>
                            </template>
                            <div class="card-content pt-3">
                                <div>{{ order.productDescription }}</div>
                            </div>
                        </BCollapse>

                    </div>
                </div>
            </template>
            <template v-else>
                <div class="column is-flex is-flex-direction-column content-height">
                    <div class="columns mb-auto flex-column">
                        <div class="mb-8">
                            <img src="/images/compass.svg" alt="compass" class="compass-bg">
                        </div>

                        <div class="has-text-black has-text-weight-semibold mb-4 fs-22">
                            You have not active booking
                        </div>

                        <div class="has-text-black mb-4">
                            Introduce your booking number to start your experience.
                        </div>

                        <div class="field">
                            <BField class="mb-2" label="Booking number">
                                <BInput type="text" v-model="form.fields.number"></BInput>
                            </BField>
                            <FormError field="number" :form="form"/>
                        </div>
                    </div>

                    <div class="columns flex-column">
                        <div class="controls has-margin-top">
                            <BButton type="is-black"
                                     expanded
                                     :disabled="!form.fields.number.length"
                                     class="border-radius-12"
                                     @click="handleSubmit"
                            >
                                NEXT
                                <i class="fa-solid fa-arrow-right ml-2"></i>
                            </BButton>
                        </div>

                        <div class="controls has-margin-top">
                            <BButton v-if="!form.fields.number.length"
                                     expanded
                                     class="button--ghost border-radius-12"
                                     @click="handleSkip"
                            >
                                I don't have one
                            </BButton>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex';
import mixin from "@/mixin";

import {
    Form,
} from '@/internal/modules';
import moment from "moment";

export default {
    name: "Booking",
    mixins: [mixin],
    computed: {
        activeBooking() {
            return this.activeBookingId ? this.$store.getters['activeBooking/show'](this.activeBookingId) : null;
        },
        pendingNonScheduledBookings() {
          return this.activeBooking
            ? this.activeBooking.nonScheduledBookings.filter((booking) => booking.status === 'pending')
            : [];
        },
        bookings() {
            return this.activeBooking ? this.activeBooking.bookings : [];
        },
        orders() {
            return this.activeBooking ? this.activeBooking.orders : [];
        },
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        }
    },
    data() {
        return {
            activeBookingId: null,
            form: new Form({
                number: '',
            }),
            loading: false,
            isBookingsOpen: null,
            isOrdersOpen: null,
        };
    },
    created() {
        if(this.user.activeBookingId) {
            this.loadActiveBookingSummary();
        }
    },
    methods: {
        ...mapActions({
            setActiveBooking: 'user/setActiveBooking',
            activeBookingSummary: 'user/activeBookingSummary',
        }),
        loadActiveBookingSummary() {
            this.loading = true;

            return this.activeBookingSummary({
                params: {
                    include: [
                        'nonScheduledBookings',
                        'group.bookings',
                        'bookings',
                        'orders'
                    ]
                }
            }).then((response) => {
                this.activeBookingId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleSubmit() {
            this.loading = true;
            this.form.errors.clear();

            this.setActiveBooking(this.form.fields).then((response) => {
                this.loadActiveBookingSummary();
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleSkip() {
            const route = window.app.findRouteByName('home');
            this.$router.push(route);
        },
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        groupFormat(value) {
            let values = [];

            if(value.adults) {
                if(value.adults > 1) {
                    values.push(value.adults + " adults");
                } else {
                    values.push(value.adults + " adult");
                }
            }

            if(value.children) {
                if(value.children > 1) {
                    values.push(value.children + " children");
                } else {
                    values.push(value.children + " child");
                }
            }

            return values.join(" ");
        },
        durationFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        timeFormat(value) {
            const time = moment(value).format("HH:mm")
            return this.timeFormatTimezone(time).format("HH:mm");
        },
        handleSchedule(nonScheduledBooking) {
            const route = window.app.findRouteByName('service/activity/book', [], {
                params: {
                    serviceUuid: nonScheduledBooking.serviceId
                },
                query: {
                    ...this.$route.query,
                    non_scheduled_booking: nonScheduledBooking.id
                }
            });

            this.$router.push(route);
        },
    }
}
</script>

<style lang="scss" scoped>
.booking-summary {
    background: radial-gradient(40px at left, transparent 98%, #fff) left, radial-gradient(40px at right, transparent 98%, #fff) right;
    background-size: 51% 100%;
    background-repeat: no-repeat;
    filter: drop-shadow(0 0 4px rgba(28, 48, 74, 0.4));
    border-radius: 20px;
    margin: 30px 0;
}

.collapse {
    .card-content {
        padding-left: 0.75em;
    }
}

.booking-date-range {
    background-color: #e8ebef;
}

.bg-grey-gradient {
    background: linear-gradient(0deg, transparent, #f4f5f7);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

</style>
