<template>
    <div>
        <BLoading :is-full-page="true" v-model="loading"></BLoading>

        <section v-if="product" class="section p-0">
            <div class="container container-extra-small p-0">
                <div class="column is-flex is-flex-direction-column content-height">
                    <div class="columns flex-column">
                        <div class="position-relative">
                            <div @click="handleBack" class="back-button position-absolute button small has-background-white border-radius-12">
                                <i class="fa-solid fa-arrow-left fa-lg"></i>
                            </div>
                            <div class="product-image" :style="{'background-image' : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.77%), url(${product.imageUrl})`}" alt=""></div>
                        </div>
                        <div class="product-description position-relative has-background-white p-4">
                            <div class="has-text-black has-text-weight-semibold fs-22 mb-4">
                                £{{ product.price }}
                            </div>
                            <div class="has-text-black has-text-weight-semibold fs-22 mb-2">
                                {{ product.name }}
                            </div>
                            <p v-html="product.description" class="has-text-black mb-0 pre-formatted"></p>
                        </div>
                        <div v-if="product.importantInfo" class="p-4 mb-4">
                            <div class="border-radius-16 border-grey--light--thin p-4">
                                <div><img src="/images/info2-1.svg" class="mr-2" alt="">IMPORTANT INFO</div>
                                <p class="fs-14">{{ product.importantInfo }}</p>
                            </div>
                        </div>
                        <div class="position-relative border-top m-4">
                            <span class="product-description-image position-absolute px-3 has-background-white app-grey">
                                <img src="/images/compass.svg" width="32">
                            </span>
                        </div>
                    </div>
                    <div class="p-1">
                        <GroupInput :show-icon="false" full-width v-model="form.fields.quantity" class="mt-4" />
                        <BButton type="is-black" class="w-100 border-radius-12 mb-0 mt-4" @click="handleCheckout">
                            <img src="/images/cart2-white.svg" class="mr-2" alt="" />
                            ADD
                        </BButton>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import GroupInput from "@/views/components/GroupInput";
import {Form} from '@/internal/modules';
import Router from '@/internal/modules/Router';

import {mapActions} from "vuex";

export default {
    name: 'Show',
    components: {GroupInput},
    computed: {
        product() {
            return this.productId ? this.$store.getters['product/show'](this.productId) : null;
        }
    },
    data() {
        return {
            productId: null,
            loading: false,
            form: new Form({
                id: null,
                quantity: 1,
                price: null
            }),
        };
    },
    async created() {
        await this.loadProduct();
        this.fillForm();
    },
    methods: {
        ...mapActions({
            productShow: 'product/show',
        }),
        handleCheckout() {
            this.saveFormStorage();

            const route = window.app.findRouteByName('products/checkout',[],{});
            this.$router.push(route);
        },
        handleBack() {
            Router.goToLastRoute(this.$router);
        },
        loadProduct() {
            this.loading = true;

            return this.productShow({id : this.$route.params.productUuid}).then((response) => {
                this.productId = response[0];
            }).finally(() => {
                this.loading = false;
            });
        },
        saveFormStorage() {
            window.app.storage.setItem('order', this.form.fields)
        },
        clearFormStorage() {
            window.app.storage.removeItem('order')
        },
        fillForm() {
            this.form.fields = {
                ...this.form.fields,
                id: this.product.id,
                name: this.product.name,
                price: this.product.price,
                imageUrl: this.product.imageUrl
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.product {
    &-image {
        height: 390px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-description {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        margin-top: -20px;
        z-index: 1;
    }

    &-description-image {
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1;
    }
}
.back-button {
    top: 16px;
    left: 16px;
}
</style>
