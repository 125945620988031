<template>
    <section v-if="booking" class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>

        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns is-flex-direction-column">
                    <div class="column">
                        <div class="fs-22 has-text-black has-text-weight-semibold mb-3">{{ booking.service.nameFinal }}</div>
                        <p class="has-text-weight-semibold app-grey">{{ booking.startAt.tz(this.timezone).format("dddd, MMMM Do, YYYY") }}</p>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="mb-2 has-text-weight-semibold app-grey fs-14">Time</label>
                            <div class="is-flex justify-content-between align-items-center py-3 has-text-grey">
                                <div class="is-flex justify-content-between align-items-center">
                                    <img src="/images/clock2.svg" class="mr-2" />
                                    <p class="has-text-black has-text-weight-normal mb-0">{{ timeFormat(booking.startAt) }} - {{ timeFormat(booking.endAt) }}</p>
                                </div>
                                <b-button class="button--grey border-radius-12 is-clickable" @click="handleReschedule">
                                    <img src="/images/edit2-black.svg" class="mr-2" />
                                    Reschedule
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <label class="mb-2 has-text-weight-semibold app-grey fs-14">Guests</label>
                            <div class="is-flex justify-content-between align-items-center py-3 has-text-grey">
                                <GroupInput @input="setInputValue" show-icon :full-width="false" v-model="form.fields.group.adults" :disabled="true" />
                                <FormError field="adults_number" :form="form"/>
                            </div>
                        </div>
                    </div>

                    <div class="column">
                        <div class="field">
                            <BButton class="border-radius-12 button--light-red mb-4"
                                     @click="toggleCancelActivityModal"
                                     expanded
                                     :loading="loading">
                                <img src="/images/stop2-red.svg" class="mr-2" />
                                Cancel activity
                            </BButton>
                            <div class="py-2 px-5 fs-12 has-text-black">
                                <p class="has-text-centered">There may be a penalty for cancelling this activity</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cancel activity modal-->
        <div v-if="cancelActivityModal" class="new-modal">
            <div class="new-modal--inner has-text-black">
                <div class="is-flex justify-content-between align-items-center mb-4">
                    <h4 class="has-text-weight-semibold fs-22">Are you sure?</h4>
                    <img @click="toggleCancelActivityModal" class="is-clickable" src="/images/close2.svg" width="20"/>
                </div>
                <template v-if="booking.hasCancellationFee">
                    <p class="mb-4">If you cancel less than 24hrs before the start of your experience then the cost is payable in full.</p>
                    <p class="mb-4">
                        For more information check our
                        <a class="has-text-black text-underline" href="https://finnlough.com/terms-and-conditions" target="_blank">Cancellation policy</a>
                    </p>
                </template>
                <BButton class="border-radius-12 button--light-red mb-4" @click="handleCancel" expanded :loading="loading">
                    Cancel anyway
                </BButton>
                <BButton type="is-black" class="border-radius-12" expanded @click="toggleCancelActivityModal">DO NOT CANCEL</BButton>
            </div>
        </div>

    </section>
</template>

<script>
import {mapActions} from 'vuex';
import mixin from "@/mixin";

import {
    Form,
} from '@/internal/modules'

import GroupInput from "../components/GroupInput";

export default {
    name: 'Edit',
    mixins: [mixin],
    components: {
        GroupInput
    },
    computed: {
        booking() {
            return this.bookingId ? this.$store.getters['booking/show'](this.bookingId) : null;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
    },
    created() {
        this.loadBooking();
    },
    data() {
        return {
            form: new Form({
                group: {
                    adults: 1,
                    children: 0
                }
            }),
            bookingId: false,
            loading: false,
            disabled: true,
            cancelActivityModal: false
        };
    },
    methods: {
        ...mapActions({
            bookingShow: 'booking/show',
            bookingCancel: 'booking/cancel'
        }),
        timeFormat(value){
          return this.dateFormatTimezone(value).format('hh:mm A');
        },
        setInputValue(value) {
            this.disabled = this.booking.totalPersons === value;

        },
        loadBooking() {
            this.loading = true;

            return this.bookingShow({
                id: this.$route.params.bookingUuid,
            }).then((response) => {
                this.bookingId = response[0];
                this.form.fields.group.adults = this.booking.totalPersons;
            }).finally(() => {
                this.loading = false;
            });
        },
        handleReschedule() {
            const route = window.app.findRouteByName('booking/reschedule', [], {
                params: {
                    bookingUuid: this.booking.id
                },
            });

            this.$router.push(route);
        },
        handleCancel() {
            this.loading = true;

            return this.bookingCancel({
                id: this.booking.id,
            }).then((response) => {
                const route = window.app.findRouteByName('booking/cancellation');
                this.$router.push(route);
            }).catch((error) => {
                console.log("error");
            }).finally(() => {
                this.loading = false;
            });
        },
        toggleCancelActivityModal() {
            this.cancelActivityModal = !this.cancelActivityModal;
        }
    },
};
</script>

<style scoped lang="scss">

</style>
