import {
    Model,
} from '@/internal/modules';

import {
    File,
    Service,
    CategoryService as CategoryService
} from '@/internal';
import _ from "lodash";

export default class Category extends Model {

    static get name() {
        return 'Category';
    }

    static get vuexModuleKey() {
        return 'category';
    }

    static get service() {
        return CategoryService;
    }

    static get fields () {
        return {
            name: {
                required: true,
                type: String,
            },
            files: {
                preparePayload: (value) => {
                    return value ? value.map(file => file.id) : [];
                },
                type: File,
            }
        };
    }

    get parent() {
        return this.hasOne(Category);
    }

    get subcategories() {
        return this.hasMany(Category);
    }

    get services() {
        return this.hasMany(Service);
    }

    get files() {
        return this.hasMany(File);
    }

    static get typeOptions() {
        return [
            {
                label: 'Accommodation',
                value: 'accommodation',
            },
            {
                label: 'Activity',
                value: 'activity',
            },
            {
                label: 'Dining',
                value: 'dining',
            },
            {
                label: 'Spa',
                value: 'spa',
            }
        ]

    }

    static get entityTypeService() {
        return 'service';
    }

    static get entityTypeProduct() {
        return 'product';
    }


}
