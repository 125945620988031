<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div class="p:24">
      <label class="--label mb:32">
        Name
        <input type="text" class="--input mt:4" v-model="form.fields.username" />
      </label>
      <label class="--label mb:32">
        Email
        <input type="text" class="--input mt:4" v-model="form.fields.email" :disabled="isEdit" />
      </label>
    </div>

    <template slot="footer">
      <button
        v-if="isEdit"
        @click="handleDelete"
        class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover"
      >
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

import { Form } from '@/internal/modules'

import SideDrawer from '@/components/admin/SideDrawer'
export default {
  name: 'UserModal',
  components: {
    SideDrawer,
  },
  computed: {
    user() {
      return this.id ? this.$store.getters['user/show'](this.id) : null
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
  },
  mounted() {
    if (this.user) {
      this.fillForm()
    }
  },
  data() {
    return {
      form: new Form({
        username: '',
        email: '',
        sendInvitation: false,
      }),
      loading: false,
      calendar: null,
      moment,
      currentDate: moment().format('YYYY-MM-DD'),
    }
  },
  methods: {
    ...mapActions({
      serviceIndex: 'service/index',
      userDestroy: 'user/destroy',
      userStore: 'user/store',
      userUpdate: 'user/update',
      userCalendar: 'user/calendar',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      let action = null

      if (this.isEdit) {
        action = this.userUpdate
      } else {
        action = this.userStore
      }

      action({
        ...this.form.fields,
      })
        .then(() => {
          window.app.snackbar({
            message: this.isEdit ? 'Updated successfully!' : 'Created successfully!',
          })
          this.$router.push('/admin/user')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete User',
        message: 'Are you sure you want to delete this user?',
        onConfirm: () => {
          this.loading = true
          this.userDestroy({
            id: this.user.id,
          })
            .then(() => {
              window.app.snackbar('User Deleted')
              this.$router.push('/admin/user')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    loadCalendar(date) {
      this.currentDate = date
      this.loading = true

      const payload = {
        id: this.$route.params.userId,
        params: {
          date,
        },
      }

      this.userCalendar(payload)
        .then((response) => {
          this.calendar = response
        })
        .finally(() => {
          this.loading = false
        })
    },
    fillForm() {
      if (!this.user) {
        return
      }

      this.form.fields = {
        id: this.user.id,
        email: this.user.email,
        username: this.user.username,
      }
    },
  },
  watch: {
    user() {
      this.fillForm()
    },
  },
}
</script>
