import ForgotPassword from '@/views/Auth/ForgotPassword';
import ForgotPasswordEmailConfirmation from '@/views/Auth/ForgotPasswordEmailConfirmation';
import Welcome from '@/views/Auth/Welcome';
import WelcomeBook from '@/views/Auth/WelcomeBook';
import Login from '@/views/Auth/Login';
import LoginCallback from "@/views/Auth/LoginCallback";
import ResetPassword from '@/views/Auth/ResetPassword';
import SignUp from '@/views/Auth/SignUp';

const routes = [
    {
        name: 'welcome',
        path: '/welcome',
        component: Welcome,
        meta: {
            title: 'Welcome',
            showNavbar: false,
            showMainMenu: false
        },
    },
    {
        name: 'welcome/book',
        path: '/book/:slug',
        component: WelcomeBook,
        meta: {
            title: 'Continue to Book',
            showNavbar: false,
            showMainMenu: false,
            allowAuthenticated: true
        },
    },
    {
        name: 'auth/login',
        path: '/auth/login',
        component: Login,
        meta: {
            title: 'Login',
            showNavbar: true,
            showLeftIcon: true,
            leftIcon: 'close'
        },
    },
    {   // Used for social login redirect
        name: 'auth/login/callback',
        path: '/auth/login/callback',
        component: LoginCallback,
        meta: {
            title: 'LOGGING IN...',
        },
    },
    {
        name: 'auth/sign-up',
        path: '/auth/sign-up',
        component: SignUp,
        meta: {
            middleware: ['guest'],
            title: 'Sign up',
            showNavbar: true,
            showLeftIcon: true,
            leftIcon: 'close'
        },
    },
    {
        name: 'auth/forgot-password',
        path: '/auth/forgot-password',
        component: ForgotPassword,
        meta: {
            middleware: ['guest'],
            title: 'Forgot password',
            showNavbar: true,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    {
        name: 'auth/forgot-password/email-confirmation',
        path: '/auth/forgot-password/email-confirmation',
        component: ForgotPasswordEmailConfirmation,
        meta: {
            middleware: ['guest'],
            title: 'Forgot password',
            showNavbar: true,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    {
        name: 'auth/reset-password',
        path: '/auth/reset-password/:resetToken',
        component: ResetPassword,
        meta: {
            middleware: ['guest'],
            title: 'Reset password',
            showNavbar: true,
            showLeftIcon: false
        },
    }
];

export default routes;
