import store from '@/store';

export default (to, from, next) => {

  // Allow unauthorized for login page
  if(to.name === 'admin/login') {

      return next();

  } else {

      const user = store.getters['auth/user'];

      if (!user || !user.admin) {

          window.app.snackbar(`You are not authorized.`);

          window.app.d(`[adminMiddleware] Redirected to /`);

          window.location.href = "/admin/login";

      } else {
          return next();
      }
  }


}
