import {
    NonScheduledBooking as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const store = new ResourceStore({
    key: 'nonScheduledBooking',
    model: Model
});

export default store.toObject();
