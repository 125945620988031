<template>
    <div>
        <div class="is-flex text-nowrap">
            <div v-if="morningOptions.length > 0" :class="['column text-center is-clickable',
                        activeTimeTab === 'morning' ? 'border-bottom-black has-text-black' : 'border-bottom-2--light-1 app-grey']"
                 @click="setActiveTimeTab('morning')"
            >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.334 1C12.8863 1 13.334 1.44772 13.334 2V4C13.334 4.55228 12.8863 5 12.334 5C11.7817 5 11.334 4.55228 11.334 4V2C11.334 1.44772 11.7817 1 12.334 1Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M12.334 19C12.8863 19 13.334 19.4477 13.334 20V22C13.334 22.5523 12.8863 23 12.334 23C11.7817 23 11.334 22.5523 11.334 22V20C11.334 19.4477 11.7817 19 12.334 19Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33398 12C6.33398 8.68629 9.02028 6 12.334 6C15.6477 6 18.334 8.68629 18.334 12C18.334 15.3137 15.6477 18 12.334 18C9.02028 18 6.33398 15.3137 6.33398 12ZM12.334 8C10.1248 8 8.33398 9.79086 8.33398 12C8.33398 14.2091 10.1248 16 12.334 16C14.5431 16 16.334 14.2091 16.334 12C16.334 9.79086 14.5431 8 12.334 8Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M6.04109 4.29289C5.65057 3.90237 5.0174 3.90237 4.62688 4.29289C4.23635 4.68342 4.23635 5.31658 4.62688 5.70711L6.04109 7.12132C6.43162 7.51184 7.06478 7.51184 7.4553 7.12132C7.84583 6.7308 7.84583 6.09763 7.4553 5.70711L6.04109 4.29289Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M18.769 17.0208C18.3785 16.6303 17.7453 16.6303 17.3548 17.0208C16.9643 17.4113 16.9643 18.0445 17.3548 18.435L18.769 19.8492C19.1595 20.2398 19.7927 20.2398 20.1832 19.8492C20.5738 19.4587 20.5738 18.8256 20.1832 18.435L18.769 17.0208Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M1.33398 12C1.33398 11.4477 1.7817 11 2.33398 11H4.33398C4.88627 11 5.33398 11.4477 5.33398 12C5.33398 12.5523 4.88627 13 4.33398 13H2.33398C1.7817 13 1.33398 12.5523 1.33398 12Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M19.334 12C19.334 11.4477 19.7817 11 20.334 11H22.334C22.8863 11 23.334 11.4477 23.334 12C23.334 12.5523 22.8863 13 22.334 13H20.334C19.7817 13 19.334 12.5523 19.334 12Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M20.1832 5.70711C20.5738 5.31658 20.5738 4.68342 20.1832 4.29289C19.7927 3.90237 19.1595 3.90237 18.769 4.29289L17.3548 5.70711C16.9643 6.09763 16.9643 6.7308 17.3548 7.12132C17.7453 7.51184 18.3785 7.51184 18.769 7.12132L20.1832 5.70711Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                    <path d="M7.45531 18.435C7.84583 18.0445 7.84583 17.4113 7.4553 17.0208C7.06478 16.6303 6.43162 16.6303 6.04109 17.0208L4.62688 18.435C4.23635 18.8256 4.23635 19.4587 4.62688 19.8492C5.0174 20.2398 5.65057 20.2398 6.04109 19.8492L7.45531 18.435Z" :fill="activeTimeTab === 'morning' ? '#000000' : '#77828F'"/>
                </svg>

               <div>Morning</div>
            </div>
            <div v-if="afternoonOptions.length > 0" :class="['column text-center is-clickable',
                        activeTimeTab === 'afternoon' ? 'border-bottom-black has-text-black' : 'border-bottom-2--light-1 app-grey']"
                 @click="setActiveTimeTab('afternoon')"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10V8Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 18C6 14.6863 8.68629 12 12 12C15.3137 12 18 14.6863 18 18C18 19.0929 17.7078 20.1175 17.1973 21H23C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23H1C0.447716 23 0 22.5523 0 22C0 21.4477 0.447715 21 1 21H6.80269C6.29218 20.1175 6 19.0929 6 18ZM9.35418 21H14.6458C15.4762 20.2671 16 19.1947 16 18C16 15.7909 14.2091 14 12 14C9.79086 14 8 15.7909 8 18C8 19.1947 8.52375 20.2671 9.35418 21Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                    <path d="M5.70711 10.2929C5.31658 9.90237 4.68342 9.90237 4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071L5.70711 13.1213C6.09763 13.5118 6.7308 13.5118 7.12132 13.1213C7.51184 12.7308 7.51184 12.0976 7.12132 11.7071L5.70711 10.2929Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                    <path d="M2 17C1.44772 17 1 17.4477 1 18C1 18.5523 1.44772 19 2 19H4C4.55228 19 5 18.5523 5 18C5 17.4477 4.55228 17 4 17H2Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                    <path d="M20 17C19.4477 17 19 17.4477 19 18C19 18.5523 19.4477 19 20 19H22C22.5523 19 23 18.5523 23 18C23 17.4477 22.5523 17 22 17H20Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                    <path d="M19.8492 10.2929C20.2398 10.6834 20.2398 11.3166 19.8492 11.7071L18.435 13.1213C18.0445 13.5118 17.4113 13.5118 17.0208 13.1213C16.6303 12.7308 16.6303 12.0976 17.0208 11.7071L18.435 10.2929C18.8256 9.90237 19.4587 9.90237 19.8492 10.2929Z" :fill="activeTimeTab === 'afternoon' ? '#000000' : '#77828F'"/>
                </svg>

                <div>Afternoon</div>
            </div>
            <div v-if="eveningOptions.length > 0" :class="['column text-center is-clickable',
                        activeTimeTab === 'evening' ? 'border-bottom-black has-text-black' : 'border-bottom-2--light-1 app-grey']"
                 @click="setActiveTimeTab('evening')">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.91043 1.2322C7.12781 1.44957 7.20785 1.76897 7.11868 2.06317C6.9332 2.67513 6.83317 3.32519 6.83317 4.00011C6.83317 7.68201 9.81794 10.6668 13.4998 10.6668C14.1748 10.6668 14.8248 10.5668 15.4368 10.3813C15.731 10.2921 16.0504 10.3721 16.2678 10.5895C16.4851 10.8069 16.5652 11.1263 16.476 11.4205C15.4391 14.8416 12.2615 17.3334 8.49984 17.3334C3.89746 17.3334 0.166504 13.6025 0.166504 9.00011C0.166504 5.23841 2.65831 2.06087 6.07946 1.02395C6.37366 0.934776 6.69306 1.01482 6.91043 1.2322ZM5.20415 3.20345C3.1903 4.35097 1.83317 6.5178 1.83317 9.00011C1.83317 12.682 4.81794 15.6668 8.49984 15.6668C10.9822 15.6668 13.149 14.3096 14.2965 12.2958C14.0342 12.3207 13.7684 12.3334 13.4998 12.3334C8.89746 12.3334 5.1665 8.60249 5.1665 4.00011C5.1665 3.73154 5.17924 3.46579 5.20415 3.20345Z"
                          :fill="activeTimeTab === 'evening' ? '#000000' : '#77828F'"/>
                </svg>

                <div>Evening</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "TimeRangePicker",
    data() {
        return {
            activeTimeTab: 'morning',
            timeOptions: {
                morning: '09:00-12:00',
                afternoon: '12:00-17:00',
                evening: '17:00-21:00'
            }
        }
    },
    computed: {
        morningOptions() {
            const timeRangeArr = this.timeOptions.morning.split("-");
            const timeRangeFrom = timeRangeArr[0];
            const timeRangeTo = timeRangeArr[1];

            return this.options.filter((timeslot) => {
                return (timeslot.value >= timeRangeFrom && timeslot.value <= timeRangeTo);
            });
        },
        afternoonOptions() {
            const timeRangeArr = this.timeOptions.afternoon.split("-");
            const timeRangeFrom = timeRangeArr[0];
            const timeRangeTo = timeRangeArr[1];

            return this.options.filter((timeslot) => {
                return (timeslot.value >= timeRangeFrom && timeslot.value <= timeRangeTo);
            });
        },
        eveningOptions() {
            const timeRangeArr = this.timeOptions.evening.split("-");
            const timeRangeFrom = timeRangeArr[0];
            const timeRangeTo = timeRangeArr[1];

            return this.options.filter((timeslot) => {
                return (timeslot.value >= timeRangeFrom && timeslot.value <= timeRangeTo);
            });
        }
    },
    mounted() {
        if(this.selected) {
            this.setActiveTimeTabBySelectedOption();
        } else if(this.value) {
            this.setActiveTimeTabByValue();
        }

        // Select next tab if there are no available options
        if(!this[this.activeTimeTab + 'Options'].length) {
            this.setFirstAvailableTab();
        }
    },
    methods: {
        setActiveTimeTab(tab) {
            this.activeTimeTab = tab;

            this.$emit('input', this.timeOptions[this.activeTimeTab]);
        },
        setActiveTimeTabByValue() {
            let activeTimeTab;

            Object.keys(this.timeOptions).forEach((key) => {
                if(this.timeOptions[key] === this.value) {
                    activeTimeTab = key;
                }
            });

            this.setActiveTimeTab(activeTimeTab);
        },
        setActiveTimeTabBySelectedOption() {
            const morningOption = this.morningOptions.find((timeslot) => {
                return (timeslot.value === this.selected);
            });
            if(morningOption) {
                this.setActiveTimeTab('morning');
            }

            const afternoonOption = this.afternoonOptions.find((timeslot) => {
                return (timeslot.value === this.selected);
            });

            if(afternoonOption) {
                this.setActiveTimeTab('afternoon');
            }

            const eveningOption = this.eveningOptions.find((timeslot) => {
                return (timeslot.value === this.selected);
            });

            if(eveningOption) {
                this.setActiveTimeTab('evening');
            }
        },
        setFirstAvailableTab() {
            let activeTimeTab;

            Object.keys(this.timeOptions).every((key) => {
                if(this[key + 'Options'].length > 0) {
                    activeTimeTab = key;
                    return false;
                }

                return true;
            });

            if(activeTimeTab) {
                this.setActiveTimeTab(activeTimeTab);
            }
        }
    },
    props: {
        value: {
            type: String,
            required: false
        },
        options: {
            type: Array,
            required: false
        },
        selected: {
            type: String,
            required: false
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
