import {
    Model
} from '@/internal/modules';

import {
    ServiceClosedHourService as ServiceClosedHourService
} from '@/internal';

export default class ServiceClosedHour extends Model {

    static get name() {
        return 'ServiceClosedHour';
    }

    static get vuexModuleKey() {
        return 'serviceClosedHour';
    }

    static get service() {
        return ServiceClosedHourService;
    }

    static get fields () {
        return {};
    }

}
