<template>
    <section class="section authentication height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column">
                        <div :class="['field is-relative', {'input--error': form.errors.has('first_name')}]">
                            <BField class="mb-2" label="Name" >
                                <BInput type="text" v-model="form.fields.firstName"></BInput>
                            </BField>
                            <img v-if="form.errors.has('first_name')" src="/images/warning2-red.svg" class="input__icon" />
                            <FormError field="first_name" :form="form"/>
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('last_name')}]">
                            <BField class="mb-2" label="Surname" >
                                <BInput type="text" v-model="form.fields.lastName"></BInput>
                            </BField>
                            <img v-if="form.errors.has('last_name')" src="/images/warning2-red.svg" class="input__icon" />
                            <FormError field="last_name" :form="form"/>
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('email')}]">
                            <BField class="mb-2" label="Email" >
                                <BInput type="email" v-model="form.fields.email"></BInput>
                            </BField>
                            <img v-if="form.errors.has('email')" src="/images/warning2-red.svg" class="input__icon" />
                            <FormError field="email" :form="form"/>
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('password')}]">
                            <BField class="mb-2" label="Password">
                                <input autocomplete="on" class="input" :type="passwordInputType ? 'text' : 'password'" v-model="form.fields.password">
                            </BField>
                            <img @click="passwordInputType = !passwordInputType"
                                 :src="`/images/${form.errors.has('password') ? 'eye2-red' : 'eye2'}.svg`"
                                 class="input__icon"
                            />
                            <FormError field="password" :form="form"/>
                            <div :class="['fs-12', form.errors.has('password') ? 'has-text-danger' : 'has-text-grey']">
                                Must be 6 or more characters and contain 1 number and 1 special character
                            </div>
                        </div>

                        <div :class="['is-flex mb-2', {'checkbox--error': form.errors.has('tosAndPpAccepted')}]">
                            <BField>
                                <BCheckbox
                                    :value="true"
                                    v-model="form.fields.tosAndPpAccepted"
                                    type="is-black">
                                </BCheckbox>
                            </BField>
                            <div class="has-text-grey">
                                I have read and understood the
                                <a class="has-text-black text-underline" href="https://finnlough.com/terms-and-conditions" target="_blank">Terms & Conditions</a> and the
                                <a class="has-text-black text-underline" href="https://finnlough.com/privacy-policy" target="_blank">Privacy Policy</a>
                            </div>
                        </div>
                        <div>
                            <FormError field="tos_and_pp_accepted" :form="form"/>
                        </div>
                    </div>
                </div>
                <div class="mt-auto pb-4">
                    <div class="controls has-margin-top">
                        <BButton type="is-black" class="border-radius-12" expanded @click="handleSignUp" >SIGN UP</BButton>
                    </div>

                    <div class="controls has-margin-top">
                        <BButton class="button--ghost border-radius-12" expanded @click="goToLogin" >
                            Already a user?
                            <i class="fa-solid fa-arrow-right ml-2"></i>
                        </BButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
  import {mapActions} from 'vuex';

  import {
    Form,
  } from '@/internal/modules'

  export default {
    name: 'SignUp',
    components: {},
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
    },
    created() {

    },
    data() {
      return {
        form: new Form({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirmation: '',
        }),
        loading: false,
        passwordInputType: false,
      };
    },
    methods: {
      ...mapActions({
        signUp: 'auth/signUp',
      }),
      handleSignUp() {
        this.loading = true;

        this.form.errors.clear();

        this.signUp({
          device: navigator.userAgent || navigator.vendor,
          ...this.form.fields,
        }).then(() => {
          if(!this.user.activeBooking) {
              this.$router.push(window.app.findRouteByName('booking/active'));
          } else {
              this.$router.push(window.app.auth.redirectRouteAfterLogin());
              window.app.auth.clearRedirectRoute();
          }

        }).catch((error) => {
          this.form.recordErrors(error);
          console.log(error);
        }).finally(() => {
          this.loading = false;
        });
      },
      goToLogin() {
        this.$router.push('/auth/login');
      }
    },
  };
</script>

<style scoped lang="scss">
    #sign-up-page {
        .logo {
            height: 100px;
        }
    }
</style>
