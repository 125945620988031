<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading"></b-loading>

        <div class="app-grey has-text-weight-semibold py-2">
            <span @click="previousMonth" class="is-clickable">
                <i class="fa-solid fa-chevron-left"></i>
            </span>
            <span class="mx-3">{{ currentDate }}</span>
            <span @click="nextMonth" class="is-clickable">
                <i class="fa-solid fa-chevron-right"></i>
            </span>
        </div>
        <div id="calendar-container" ref="container" class="box horizontal-calendar hide-scrollbar px-0 py-2 is-shadowless mb-3 is-inline-block w-100">
            <div v-for="(day, index) in days" :id="'date-' + moment(day).format('YYYY-MM-DD')" class="is-inline-block has-text-centered horizontal-calendar-day"
                 :class="[
                     {'horizontal-calendar-day-grey-light is-clickable app-grey' : activeBooking && isDayInBooking(day)},
                     {'is-disabled': activeBooking && !isDayInBooking(day) },
                     {'left-border-radius': activeBooking && isStartOfBooking(day)},
                     {'right-border-radius': activeBooking && isEndOfBooking(day)}
                 ]"
                 >

                <div @click="selectDate(day)"
                     class="horizontal-calendar-day-content is-clickable"
                     :class="[
                         {'has-text-white has-background-black' :
                             moment(selectedDay).format('DD-MM-YYYY') === moment(day).format('DD-MM-YYYY')
                         },
                         {'has-border' : moment(day).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')},
                     ]">
                        <div class="fs-12 is-uppercase mt-1">{{ weekDay[moment(day).day()] }}</div>
                        <div class="mb-1">{{ moment(day).date() }}</div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {mapActions} from "vuex";
import Vue from "vue";
import {scroller} from 'vue-scrollto/src/scrollTo'

export default {
    name: "HorizontalCalendar",
    computed: {
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
        activeBookingStartAt() {
            if(this.activeBooking) {
                return this.activeBooking.isGroup ? this.activeBooking.groupStartAt : this.activeBooking.startAt;
            }
        },
        activeBookingEndAt() {
            if(this.activeBooking) {
                return this.activeBooking.isGroup ? this.activeBooking.groupEndAt : this.activeBooking.endAt;
            }
        },
        bookings() {
            return this.bookingIds ? this.$store.getters['booking/collection'](this.bookingIds) : [];
        },
        upcomingBookings() {
            return this.bookings.filter((booking) => {
                return moment(booking.startAt).toDate() >= moment().toDate();
            });
        },
        user() {
            return this.$store.getters['auth/user'];
        }
    },
    props: {
        value: {
            type: Date,
            required: false
        }
    },
    data() {
      return {
          bookingIds: [],
          days: [],
          weekDay: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          selectedDay: '',
          currentDate: null,
          observer: null,
          falseIntersectingDay: null,
          trueIntersectingDay: null,
          loading: false,
          moment
      }
    },
    async created() {
        let startDate, endDate;
        await this.loadBookings();

        console.log(this.bookings)
;
        if (this.activeBooking) { // Has active accommodation booking
            startDate = moment(this.activeBookingStartAt).subtract(3, 'days');
            endDate =  moment(this.activeBookingEndAt).add(90, 'days')

            this.selectedDay = moment(this.activeBookingStartAt).toDate();

        } else if (this.upcomingBookings.length) { // Has upcoming activity bookings
            let firstBooking = this.upcomingBookings[0];
            let lastBooking = this.upcomingBookings[this.upcomingBookings.length - 1];

            startDate = moment(firstBooking.startAt).subtract(3, 'days');
            endDate =  moment(lastBooking.startAt).add(90, 'days')

            if(firstBooking) {
                this.selectedDay = moment(firstBooking.startAt).toDate();
            } else {
                this.selectedDay = moment().toDate()
            }

        } else { // Has nothing
            startDate = moment().subtract(3, 'days');
            endDate =  moment(startDate.toDate()).add(90, 'days')

            this.selectedDay = moment().toDate()
        }

        // Props
        if(this.value) {
            this.selectedDay = this.value;
        }

        // Create days
        this.days = this.getDatesInRange(startDate.toDate(), endDate.toDate());

        // Scroll to selected day and observer scroll
        Vue.nextTick(() => {
            this.initScrollObserver();
            this.scrollToSelectedDay();
        });
    },
    methods: {
        ...mapActions({
            userBookings: 'user/booking'
        }),
        selectDate(day) {
            if(this.activeBooking && !this.isDayInBooking(day)) {
                console.log('Not available if active booking', day);
                return false;
            }

            return this.selectedDay = day;
        },
        getDatesInRange(startDate, endDate) {
            const date = new Date(startDate.getTime());
            const dates = [];

            while (date <= endDate) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }

            return dates;
        },
        isDayInBooking(day) {
            const startAt = moment(this.activeBookingStartAt).set('hour', 1).set('minute', 0).toDate();
            const endAt = moment(this.activeBookingEndAt).set('hour', 23).set('minute', 0).toDate();

           return this.activeBooking && (day >= startAt && day <= endAt);
        },
        isStartOfBooking(day) {
            const startDay = moment(this.activeBookingStartAt);
            return moment(day).isSame(startDay, 'day');
        },
        isEndOfBooking(day) {
            const endDay = moment(this.activeBookingEndAt);
            return moment(day).isSame(endDay, 'day');
        },
        loadBookings() {
            this.loading = true;

            const params = {
                type: 'accommodation'
            };

            return this.userBookings({
                params,
            }).then((response) => {
                this.bookingIds = response;
            }).finally(() => {
                this.loading = false;
            });
        },
        previousMonth() {
            const previousMonth = moment(this.selectedDay).clone().subtract(1, 'months');
            this.selectDate(new Date(previousMonth.startOf('month')));
        },
        nextMonth() {
            const nextMonth = moment(this.selectedDay).clone().add(1, 'months');
            this.selectDate(new Date(nextMonth.startOf('month')));
        },
        initScrollObserver() {
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if(entry.isIntersecting && !entry.target.classList.contains('is-disabled')) {
                            const dateId = entry.target.id.replace('date-','');
                            const date = new Date(dateId);
                            this.currentDate = moment(date).format('MMMM, YYYY')
                        }
                    })
                },
                {
                    root: document.querySelector("#calendar-container"),
                    threshold: 1
                }
            );

            setTimeout(() => {
                document.querySelectorAll('.horizontal-calendar-day').forEach((day) => {
                    this.observer.observe(day)
                })
            }, 500)
        },
        scrollToSelectedDay() {
            const firstScrollTo = scroller()
            const elementId = "#date-" + moment(this.selectedDay).format('YYYY-MM-DD');

            firstScrollTo(elementId, {
                container: '#calendar-container',
                easing: 'ease-in',
                lazy: false,
                offset: -60,
                force: true,
                cancelable: true,
                onStart: function(element) {
                    //console.log('scrolling started');
                },
                onDone: function(element) {
                    //console.log('scrolling is done');
                },
                onCancel: function() {
                    //console.log('scrolling has been interrupted');
                },
                x: true,
                y: false
            });
        }
    },
    watch: {
        selectedDay () {
            this.$emit('input', this.selectedDay);
            this.$emit('select-date', this.selectedDay)

            Vue.nextTick(() => {
                this.scrollToSelectedDay();

                this.currentDate = moment(this.selectedDay).format('MMMM, YYYY');
            });

        },
    },
    beforeDestroy() {
        this.observer.disconnect();
    }
}
</script>

<style lang="scss" scoped>
.horizontal-calendar {
    overflow-x: scroll;
    white-space: nowrap;
    border-radius: 25px;

    &-day {
        width: calc(100% / 7);
        min-width: 50px;

        &.horizontal-calendar-day-grey-light {
            background-color: #f4f5f7;

            &.left-border-radius {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            &.right-border-radius {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }

        &.is-disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .has-background-black {
            border-radius: 8px;

        }

        .has-border {
            border: 2px solid #000000;
            border-radius: 8px;
        }

        > :not(.has-border) {
            border: 2px solid transparent;
            color: #5b6676;
        }

    }
    &-day-content {
        box-sizing: border-box;
        max-width: 50px;
        margin: 0 auto;

        div {
            display: flex;
            justify-content: center;
            height: 22px;

            &:first-of-type {
                align-items: end;
                padding-bottom: 1px;
            }
            &:last-of-type {
                align-items: start;
                padding-top: 1px;
            }
        }
    }
}

</style>
