import {
    Model,
} from '@/internal/modules';

import {
    Booking,
    NonScheduledBookingService as NonScheduledBookingService
} from '@/internal';

export default class NonScheduledBooking extends Model {
    static get name() {
        return 'NonScheduledBooking';
    }
    static get vuexModuleKey() {
        return 'nonScheduledBooking';
    }
    static get service() {
        return NonScheduledBookingService;
    }

    static get fields() {
        return {}
    }

    get booking() {
        return this.hasOne(Booking)
    }

    static get statusPending() {
      return 'pending';
    };

    static get statusBooked() {
      return 'booked';
    };

    get activeBooking() {
      return this.hasOne(Booking)
    }

}
