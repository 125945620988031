<template>
    <div>
        <div v-if="show" class="new-modal">
            <div class="new-modal--inner has-text-black">
                <div class="is-flex justify-content-between align-items-center mb-4">
                    <h4 class="has-text-weight-semibold fs-22">Add phone number</h4>
                    <img @click="handleCancel" class="is-clickable" src="/images/close2.svg" width="20"/>
                </div>
                <p class="mb-4">To book an activity we need your phone number.</p>
                <div class="field">
                    <label class="mb-2 has-text-weight-semibold app-grey fs-14">Phone number</label>
                    <MazPhoneNumberInput
                        v-model="phoneNumber"
                        @update="handlePhoneNumberUpdate"
                        :default-phone-number="user.phone"
                        :default-country-code="defaultCountryCode"
                    />
                    <FormError field="phone" :form="form"/>
                </div>
                <BButton type="is-black" class="border-radius-12" expanded @click="handleSave">CONTINUE</BButton>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Form,
} from '@/internal/modules';

import {mapActions} from "vuex";

export default {
    name: 'PhoneInputModal',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        countries() {
            return this.$store.getters['country/all'];
        },
        defaultCountryCode() {
            const code = this.countries.filter(country => {
                const code = country.dialCode.substring(1)
                if (country.preferred) {
                    return this.user.phoneCountryCode === code && country.preferred;
                } else {
                    return this.user.phoneCountryCode === code
                }
            })

            return code.length ? code[0].code : 'US'
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    data() {
        return {
            form: new Form({
                phone: null,
                phone_country_code: null
            }),
            phoneNumber: null,
            loading: false
        };
    },
    methods: {
        ...mapActions({
            userPhoneUpdate: 'auth/userPhoneUpdate',
        }),
        handlePhoneNumberUpdate(event) {
            this.form.fields.phone = event.phoneNumber;
            this.form.fields.phoneCountryCode = event.countryCallingCode;
        },
        handleSave() {
            this.loading = true;

            this.form.errors.clear();

            this.userPhoneUpdate({
                ...this.form.fields
            }).then(() => {
                this.$emit('success')
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleCancel() {
            this.$emit('cancel');
        }
    }
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
