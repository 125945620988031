<template>
  <Dialog width="480px" v-model="open" @close="handleClose" @submit="handleSave" title="New Customer">
    <div class="p:24">
      <div class="flex-col gap:16">
        <label class="--label">
          First Name *
          <input class="--input mt:4" type="text" v-model="form.fields.firstName" />
          <FormError field="first_name" :form="form" />
        </label>

        <label class="--label">
          Last Name *
          <input class="--input mt:4" type="text" v-model="form.fields.lastName" />
          <FormError field="last_name" :form="form" />
        </label>

        <label class="--label">
          Email *
          <input class="--input mt:4" type="email" v-model="form.fields.email" />
          <FormError field="email" :form="form" />
        </label>

        <div>
          <label class="--label mb:4">Phone *</label>
          <MazPhoneNumberInput
            v-model="phoneNumber"
            @update="handlePhoneNumberUpdate"
            :default-phone-number="''"
            default-country-code="GB"
          />
          <FormError field="phone" :form="form" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { Form } from '@/internal/modules'

import { mapActions } from 'vuex'
import { MazPhoneNumberInput } from 'maz-ui'
import mixin from '@/mixin'

import Dialog from '@/components/admin/Dialog'

export default {
  name: 'CreateCustomerDialog',
  mixins: [mixin],
  components: {
    MazPhoneNumberInput,
    Dialog,
  },
  data() {
    return {
      loading: false,
      form: new Form({
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneCountryCode: null,
      }),
      open: false,
      phoneNumber: null,
    }
  },
  computed: {
    countries() {
      return this.$store.getters['country/all']
    },
  },
  methods: {
    ...mapActions({
      customerStore: 'customer/store',
    }),
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      this.customerStore({
        ...this.form.fields,
      })
        .then((response) => {
          this.clearForm()
          this.open = false
          this.$emit('onSave', response)
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.open = false
    },
    clearForm() {
      this.form.fields = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneCountryCode: null,
      }
    },
    handlePhoneNumberUpdate(event) {
      if (!event.isValid) {
        return
      }
      this.form.fields.phone = event.phoneNumber
      this.form.fields.phoneCountryCode = event.countryCallingCode
    },
  },
}
</script>
