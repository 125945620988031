<template>
  <SideDrawer _class="w:900@lg" @close="$emit('close')">
    <template slot="header">
      <TabNav
        v-if="isEdit"
        v-model="activeTab"
        :tabs="[
          ['General', 'general'],
          ['Advanced', 'advanced'],
          ['Working Hours', 'working-hours'],
          ['Rooms & Tables', 'items', { hide: service && service.subtype !== 'restaurant' }],
          ['Emails', 'emails'],
        ]"
      />
    </template>

    <div v-if="activeTab === 'general'">
      <GeneralTab ref="generalTab" @onSave="$emit('close')" />
    </div>

    <div v-if="activeTab === 'working-hours'">
      <WorkingHoursTab />
    </div>

    <div v-if="activeTab === 'advanced'">
      <RulesTab />
    </div>

    <div v-if="activeTab === 'items'">
      <ItemsTab />
    </div>

    <div v-if="activeTab === 'emails'">
      <EmailsTab ref="emailsTab" @saved="$emit('close')" />
    </div>

    <template slot="footer">
      <div class="flex:between|center" :class="{ 'flex:1': isEdit }">
        <button
          v-if="isEdit"
          class="reset flex:|center gap:8 f:16 semi c:primary py:12 r:8 o:0.6:hover"
          @click="handleCopyPublicUrl"
        >
          <Icon admin name="link" />
          Copy Public URL
        </button>
        <div class="flex:|center gap:16">
          <button
            v-if="isEdit"
            class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover"
            @click="handleDelete"
          >
            <Icon admin name="trash" />
            Delete
          </button>

          <button
            v-if="showSaveBtn.includes(activeTab)"
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="handleSave"
          >
            <Icon admin name="check" />
            Save
          </button>
        </div>
      </div>
    </template>
  </SideDrawer>
</template>

<script>
import SideDrawer from '@/components/admin/SideDrawer.vue'
import TabNav from '@/components/admin/TabNav.vue'
import GeneralTab from './GeneralTab.vue'
import WorkingHoursTab from './WorkingHoursTab.vue'
import RulesTab from './RulesTab.vue'
import EmailsTab from './EmailsTab.vue'
import ItemsTab from './ItemsTab.vue'

import { mapActions } from 'vuex'

export default {
  name: 'ServiceModal',
  components: {
    SideDrawer,
    TabNav,
    GeneralTab,
    WorkingHoursTab,
    RulesTab,
    EmailsTab,
    ItemsTab,
  },
  data() {
    return {
      activeTab: 'general',
      showSaveBtn: ['general', 'emails'],
    }
  },
  computed: {
    service() {
      return this.id ? this.$store.getters['service/findBy'](this.id) : null
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceDestroy: 'service/destroy',
    }),
    handleSave() {
      if (this.activeTab === 'general') {
        this.$refs.generalTab.handleSave()
      } else if (this.activeTab === 'emails') {
        this.$refs.emailsTab.handleSave()
      }
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Service',
        message: 'Are you sure you want to delete this service?',
        onConfirm: () => {
          this.loading = true
          this.serviceDestroy({
            id: this.service.id,
          })
            .then(() => {
              window.app.snackbar('Service deleted')

              this.$router.push('/admin/service')
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    handleCopyPublicUrl() {
      if (!this.service.publicBookingUrl) {
        return
      }

      const storage = document.createElement('textarea')
      storage.value = this.service.publicBookingUrl
      document.body.appendChild(storage)
      storage.select()
      storage.setSelectionRange(0, 99999)

      navigator.clipboard
        .writeText(storage.value)
        .then(() => {
          window.app.snackbar({
            message: 'Public booking URL copied to your clipboard',
            type: 'is-success',
          })
        })
        .catch((err) => {
          console.error('Failed to copy: ', err)
        })
        .finally(() => {
          document.body.removeChild(storage)
        })
    },
  },
}
</script>
