export default {
  getToken() {
    return window.app.storage.getItem('token') ? window.app.storage.getItem('token') : null;
  },
  setToken(token) {
    return window.app.storage.setItem('token', token);
  },
  removeToken() {
    return window.app.storage.removeItem('token');
  },
  redirectRouteAfterLogin() {
      if(window.app.storage.getItem('redirectRoute')) {
          return window.app.storage.getItem('redirectRoute');
      } else {
          return window.app.findRouteByName('home');
      }
  },
  clearRedirectRoute() {
      window.app.storage.removeItem('redirectRoute')
  },
  hasRedirectRoute() {
      return window.app.storage.getItem('redirectRoute');
  },
  unauthenticated() {

    console.log('Unauthenticated, removing token');

    window.app.auth.removeToken();

  },
};
