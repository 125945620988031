<template>
    <div>
        <div class="columns px-3 pb-3">

            <div class="column is-flex is-align-items-center is-three-quarters p-0">

                <div class="paginator">
                    <strong class="text--primary">{{ paginator.total }}</strong>
                    <span class="text--light-grey">{{ pluralize('Result', paginator.total) }} |</span>
                </div>

                <div class="per-page is-flex is-align-items-center text--primary" style="margin-left: 10px;">
                    Per Page:
                    <b-select
                        class="new-input ml-2"
                        placeholder="Any service"
                        v-model="perPage"
                        @input="handleChangePerPage"
                    >
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                    </b-select>
                </div>
            </div>

            <div class="column has-text-right is-flex is-justify-content-end is-align-items-center p-0">

                <strong class="text--primary mr-1">{{ paginator.currentPage }}</strong>
                <span class="text--light-grey">of</span>
                <strong class="text--primary mx-1">{{ paginator.totalPage }}</strong>

                <b-button class="light outlined rounded-8 ml-1" :disabled="paginator.currentPage === 1" @click="handlePrevPage">
                    <BIcon icon="long-arrow-left"/>
                </b-button>

                <b-button class="light outlined rounded-8" style="margin-left: 10px;" :disabled="paginator.currentPage === paginator.totalPage"
                        @click="handleNextPage">
                    <BIcon icon="long-arrow-right"/>
                </b-button>

            </div>
        </div>
    </div>

</template>

<script>
import pluralize from 'pluralize';


export default {
    name: 'Paginator',
    computed: {
        error() {
            return this.form.errors.has(this.field) ? this.form.errors.get(this.field) : false;
        },
    },
    created() {

    },
    data() {
        return {
            perPage: this.paginator.perPage || 10
        };
    },
    methods: {
        handleChangePerPage () {

            const route = Object.assign({}, this.$route);

            this.page = 1;

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    page: this.page,
                    perPage: this.perPage,
                },
            });
        },
        handlePrevPage () {
            if (this.paginator && this.paginator.currentPage > 1) {

                const newPage = this.paginator.currentPage - 1;

                this.page = newPage;

                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    query: {
                        ...route.query,
                        page: newPage,
                    },
                });
            }
        },
        handleNextPage () {

            if (this.paginator && this.paginator.currentPage < this.paginator.totalPage) {

                const newPage = this.paginator.currentPage + 1;

                this.page = newPage;

                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    query: {
                        ...route.query,
                        page: newPage,
                    },
                });
            }
        },
        pluralize (word, value) {
            return pluralize(word, value);
        }
    },
    props: {
        paginator: {
            required: true,
            type: Object,
        }
    },
}
</script>

<style scoped>

</style>

