<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>
        <div v-if="service">
            <section class="section is-title-bar hero is-hero-bar">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <ul>
                                <li>
                                    <router-link to="/admin">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/service">Services</router-link>
                                </li>
                                <li v-if="service">
                                    <router-link :to="{ name:'admin/service/update', params: { serviceId: service.id }}">{{ service.name }}</router-link>
                                </li>
                                <li>
                                    Working hours
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-button  @click="workingHoursModalActive = true" class="mr-1" type="is-primary">Add Working Hours</b-button>
                            <b-button  @click="closedHoursModalActive = true" type="is-dark">Add Closed Days</b-button>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">

                <FormMenu />

                <div class="card">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column">
                                <b-table
                                    :data="workingHours"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true">

                                    <b-table-column field="dayOfTheWeek" label="Day Of The Week" v-slot="props">
                                        {{ dayOfWeekMapper(props.row.weekDay) }}
                                    </b-table-column>

                                    <b-table-column field="from" label="Hours" v-slot="props">
                                        {{ convertTime(props.row.from).format("HH:mm") }} -
                                        {{ convertTime(props.row.to).format("HH:mm") }}
                                    </b-table-column>

                                    <b-table-column label="" v-slot="props">
                                        <b-dropdown :ref="`contextRow${props.row.id}`"
                                                    aria-role="list"
                                                    position="is-bottom-left"
                                                    class="has-text-left is-clickable">

                                            <template #trigger="{ active }">
                                                <div @click.prevent.stop="handleRowActionClick(props.row)" >
                                                    <BIcon icon="ellipsis"/>
                                                </div>
                                            </template>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleUpdate(props.row)">
                                                    <BIcon icon="pencil-alt"/>
                                                    Update
                                                </div>
                                            </b-dropdownItem>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleDelete(props.row)">
                                                    <BIcon icon="trash-alt"/>
                                                    Delete
                                                </div>
                                            </b-dropdownItem>

                                        </b-dropdown>
                                    </b-table-column>
                                </b-table>
                            </div>
                        </div>
                        <div v-if="closedHours && closedHours.length > 0" class="columns">
                            <div class="column">
                                <b-table
                                    :data="closedHours"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true">

                                    <b-table-column  label="Closed days" v-slot="props">
                                        <span v-if="props.row.startDate === props.row.endDate">
                                            {{ props.row.startDate }}
                                        </span>
                                        <span v-else>
                                            {{ props.row.startDate }} - {{ props.row.endDate }}
                                        </span>
                                    </b-table-column>

                                    <b-table-column field="from" label="Hours" v-slot="props">
                                        <span v-if="isAllDay(props.row.startTime, props.row.endTime)">
                                            All day
                                        </span>
                                        <span v-else>
                                             {{ formatHours(props.row.startTime) }} -
                                             {{ formatHours(props.row.endTime) }}
                                        </span>
                                    </b-table-column>

                                    <b-table-column label="" v-slot="props">
                                        <b-dropdown :ref="`contextRow${props.row.id}`"
                                                    aria-role="list"
                                                    position="is-bottom-left"
                                                    class="has-text-left is-clickable">

                                            <template #trigger="{ active }">
                                                <div @click.prevent.stop="handleRowActionClick(props.row)" >
                                                    <BIcon icon="ellipsis"/>
                                                </div>
                                            </template>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleUpdateClosedHour(props.row)">
                                                    <BIcon icon="pencil-alt"/>
                                                    Update
                                                </div>
                                            </b-dropdownItem>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleDeleteClosedHour(props.row)">
                                                    <BIcon icon="trash-alt"/>
                                                    Delete
                                                </div>
                                            </b-dropdownItem>

                                        </b-dropdown>
                                    </b-table-column>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <b-modal
                v-model="closedHoursModalActive"
                trap-focus
                :destroy-on-hide="true"
                :can-cancel="false"
                aria-role="dialog"
                aria-label="Closed Days Modal"
                aria-modal>
                <template #default="props">
                    <ClosedHoursModal :closedHour="activeClosedHour" :service="service" @success="handleClosedHoursSuccess"  @close="props.close"  />
                </template>
            </b-modal>


            <!-- Working Hours Modal-->
            <b-modal v-model="workingHoursModalActive">
                <div class="modal-card mx-auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Working Hours</p>
                    </header>
                    <section class="modal-card-body">
                        <div class="field">
                            <b-field label="Day">
                                <b-select placeholder="Select day" v-model="form.fields.weekDay" expanded>
                                    <option
                                        v-for="day in daysOfWeek"
                                        :value="day.value"
                                        :key="day.value">
                                        {{ day.label}}
                                    </option>
                                </b-select>
                            </b-field>
                            <FormError field="week_day" :form="form"/>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <b-field label="From">
                                        <b-timepicker
                                            placeholder=""
                                            icon="clock"
                                            :incrementMinutes="15"
                                            append-to-body
                                            v-model="form.fields.from"
                                        >
                                        </b-timepicker>
                                    </b-field>
                                    <FormError field="from" :form="form"/>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <b-field label="To">
                                        <b-timepicker
                                            placeholder=""
                                            icon="clock"
                                            :incrementMinutes="15"
                                            append-to-body
                                            v-model="form.fields.to"
                                        >
                                        </b-timepicker>
                                    </b-field>
                                    <FormError field="to" :form="form"/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button label="Save" type="is-black is-rounded" @click="handleSave"/>
                    </footer>
                </div>
            </b-modal>
            <!-- End Working Hours Modal-->

        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Form } from '@/internal/modules';
import FormMenu from '@/views/Admin/Service/FormMenu';
import Paginator from '@/views/Admin/components/Paginator';
import ServiceWorkingHour from '@/domain/ServiceWorkingHour/ServiceWorkingHour';
import ClosedHoursModal from '@/views/Admin/components/ClosedHoursModal';

import moment from 'moment-timezone';

export default {
    name: 'WorkingHours',
    components: {
        FormMenu,
        Paginator,
        ServiceWorkingHour,
        ClosedHoursModal
    },
    computed: {
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
        workingHours() {
            return this.service ? this.service.workingHours : [];
        },
        closedHours() {
            return this.service ? this.service.closedHours : [];
        }
    },
    mounted() {
        this.loadService();
    },
    data() {
        return {
            form: new Form({
                weekDay: null,
                from: null,
                to: null,
                service_id: this.$route.params.serviceId
            }),
            daysOfWeek: [
                {label: 'Monday', value: 1},
                {label: 'Tuesday', value: 2},
                {label: 'Wednesday', value: 3},
                {label: 'Thursday', value: 4},
                {label: 'Friday', value: 5},
                {label: 'Saturday', value: 6},
                {label: 'Sunday', value: 7}
            ],
            paginator: null,
            loading: false,
            serviceId: null,
            workingHoursModalActive: false,
            closedHoursModalActive: false,
            activeClosedHour: null
        };
    },
    methods: {
        ...mapActions({
            serviceShow: 'service/show',
            workingHoursDestroy: 'serviceWorkingHour/destroy',
            workingHoursStore: 'serviceWorkingHour/store',
            workingHoursUpdate: 'serviceWorkingHour/update',
            closedHoursDestroy: 'serviceClosedHour/destroy'
        }),
        dayOfWeekMapper(value) {
            return this.daysOfWeek.find(day => day.value === value).label
        },
        handleRowActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        convertTime(time) {
            // Convert from UTC to local timezone
            return moment.utc("2023-07-22 " + time).tz(this.timezone);
        },
        formatHours(time) {
            return moment.utc("2023-07-22 " + time).format("HH:mm");
        },
        handleUpdate(row) {
            this.form.fields = {
                ...row,
                from: new Date(`2023-07-22 ${this.convertTime(row.from).format("HH:mm")}`),
                to: new Date(`2023-07-22 ${this.convertTime(row.to).format("HH:mm")}`)
            }

            this.workingHoursModalActive = true
        },
        handleUpdateClosedHour(row) {
            this.activeClosedHour = row;
            this.closedHoursModalActive = true
        },
        handleClosedHoursSuccess() {
            this.closedHoursModalActive = false;

            window.app.snackbar({
                message: 'Update successfully!',
            });
            this.loadService();
        },
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
                from: this.form.fields.from ? moment(this.form.fields.from).format('HH:mm:ss') : null,
                to: this.form.fields.to ? moment(this.form.fields.to).format('HH:mm:ss') : null
            };

            let action = null;

            if (this.form.fields.id) {
                action = this.workingHoursUpdate;
            } else {
                action = this.workingHoursStore;
            }

            action(ServiceWorkingHour.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.loadService();

                this.workingHoursModalActive = false;
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete(row) {
            window.app.confirm({
                title: 'Delete Working Hours',
                message: 'Are you sure you want to delete this working hours?',
                onConfirm: () => {
                    this.loading = true;

                    this.workingHoursDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Service working hours deleted');
                        this.loadService();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        handleDeleteClosedHour(row) {
            window.app.confirm({
                title: 'Delete Closed Days',
                message: 'Are you sure you want to delete this closed days?',
                onConfirm: () => {
                    this.loading = true;

                    this.closedHoursDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Closed days deleted');
                        this.loadService();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadService() {
            this.loading = true;

            return this.serviceShow({
                id: this.$route.params.serviceId,
                params: {
                    include: ['serviceWorkingHours', 'serviceClosedHours']
                }
            }).then((response) => {
                this.serviceId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        isAllDay(startTime, endTime) {
            return startTime === '00:00:00' && endTime === '23:45:00';
        }
    },
    watch: {
        workingHoursModal(isOpen) {
            if(!isOpen) {
                this.form.fields = {
                    weekDay: null,
                    from: null,
                    to: null,
                    service_id: this.$route.params.serviceId
                }
            }
        },
    }
};
</script>

<style lang="scss">

</style>
