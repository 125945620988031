import {
    Model,
} from '@/internal/modules';

import {
    Customer,
    Service,
    Category,
    Booking,
    Order,
    BookingGroup,
    NonScheduledBooking,
    ActiveBookingService as ActiveBookingService
} from '@/internal';

export default class ActiveBooking extends Model {
    static get name() {
        return 'ActiveBooking';
    }
    static get vuexModuleKey() {
        return 'activeBooking';
    }
    static get service() {
        return ActiveBookingService;
    }

    static get fields() {
        return {
            startAt: {
                type: Date,
            },
            endAt: {
                type: Date,
            }
        }
    }

    get customer() {
        return this.hasOne(Customer)
    }

    get services() {
        return this.hasMany(Service);
    }

    get category() {
        return this.hasOne(Category)
    }

    get service() {
        return this.services.length ? this.services[0] : null;
    }

    get bookings() {
        return this.hasMany(Booking);
    }

    get orders() {
        return this.hasMany(Order);
    }

    get group()
    {
      return this.hasOne(BookingGroup)
    }

    get nonScheduledBookings() {
      return this.hasMany(NonScheduledBooking);
    }

}
