import {
    ActivityLog as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const store = new ResourceStore({
    key: 'activityLog',
    model: Model
});

export default store.toObject();
