<template>
  <SideDrawer width="640px" :loading="loading" @close="$emit('close')">
    <template slot="header">
      <div class="ml:24 f:20 semi">Reschedule booking</div>
    </template>
    <div class="p:24 flex:between|center">
      <div>
        <div v-if="booking.originId">
          <div class="f:14 bg:accent-10 c:accent-70 r:4 p:4|12 flex:|center semi">
            {{ booking.originName }} ({{ booking.number }})
          </div>
        </div>
        <div v-else>
          <div class="f:14 bg:accent-10 c:accent-70 r:4 p:4|12 flex:|center medium">
            {{ booking.number }}
          </div>
        </div>
      </div>
      <Status :status="booking.status" />
    </div>
    <div class="ofy:auto flex:1 p:20 c:accent-70">
      <div class="columns">
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="book" />
            Booking number
          </div>
          <div class="semi">{{ booking.number }}</div>
        </div>
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="spark" />
            Service
          </div>
          <div class="semi">
            {{ booking.service ? booking.service.name : 'N/A' }}
          </div>
          <p v-if="booking.category">Category: {{ booking.category.name }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="user-square" />
            Customers
          </div>
          <div class="semi mb:8">{{ booking.customer.name }}</div>
          <div class="mb:8">{{ booking.customer.email }}</div>
          <div class="mb:8">{{ booking.customer.phoneE164 }}</div>
        </div>
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="user-group" />
            Guests
          </div>
          <GroupInput class="new-input" :disabled="!canUpdateGroup" :show-icon="false" :full-width="false" v-model="form.fields.adults_number" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="calendar" />
            Date
          </div>
          <CalendarInput v-model="datepicker"
                         @input="handleDateChange"
                         :allow-clear="false" />
        </div>
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="clock" />
            Time
          </div>
          <TimeslotInput v-model="form.fields.from" :timeslots="timeslots" allow-custom-time />
        </div>
      </div>

      <div v-if="service.configuration && service.configuration.flexibleTime" class="mb:24">
        <div class="c:base-50 flex:|center gap:8 semi mb:8">
          <Icon admin name="clock" />
          Session duration
        </div>
        <b-select placeholder="Select duration" v-model="form.fields.quantity">
          <option v-for="option in durationOptions" :value="option.value" :key="option.value">
            {{ option.label }}
          </option>
        </b-select>
      </div>

      <div class="columns">
        <div class="column">
          <div class="c:base-50 flex:|center gap:8 semi mb:8">
            <Icon admin name="note" />
            Notes
          </div>
          <textarea class="styled r:8 p:12" rows="8" v-model="form.fields.note" />
          <FormError field="note" :form="form" />
        </div>
      </div>
    </div>

    <div slot="footer" class="t:right">
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        Reschedule
      </button>
    </div>
  </SideDrawer>
</template>

<script>
import { Form } from '@/internal/modules'
import { Booking } from '@/internal'

import { mapActions } from 'vuex'
import moment from 'moment'
import mixin from '@/mixin'
import SideDrawer from '@/components/admin/SideDrawer'
import Status from '@/components/admin/Status'
import GroupInput from "@/components/admin/GroupInput";
import CalendarInput from "@/components/admin/CalendarInput";
import TimeslotInput from "@/components/admin/TimeslotInput";

export default {
  name: 'BookingRescheduleModal',
  mixins: [mixin],
  components: {
    SideDrawer,
    Status,
    GroupInput,
    CalendarInput,
    TimeslotInput,
  },
  props: {
    booking: {
      type: Object || null,
      default: () => null,
    },
  },
  computed: {
    service() {
      return this.booking ? this.booking.service : null
    },
    formattedBooking() {
      return {
        date: this.form.fields.date ? this.dateFormat(this.form.fields.date) : '',
        time: this.form.fields.from ? this.timeFormatTimezone(this.form.fields.from).format('HH:mm') : '',
        quantity: this.form.fields.quantity,
        price: this.booking.price,
        duration: this.timeFormat(this.form.fields.quantity * this.service.duration),
      }
    },
    durationOptions() {
      let options = []

      if (this.service && this.service.duration) {
        for (let i = 1; i < 4; i++) {
          options = [
            ...options,
            {
              label: this.timeFormat(i * this.service.duration),
              value: i,
            },
          ]
        }
      }
      return options
    },
    totalPrice() {
      return this.form.fields.quantity * this.service.price * this.totalPersons
    },
    totalPersons() {
      return this.form.fields.adults_number + this.form.fields.children_number
    },
    guestsOptions() {
      let options = []

      for (let i = 1; i < 11; i++) {
        options = [
          ...options,
          {
            label: i,
            value: i,
          },
        ]
      }

      return options
    },
    isFormReady() {
      return this.form.fields.date && this.form.fields.from
    },
    canUpdateGroup() {
      return ['free', 'on-spot'].includes(this.service.paymentType)
    },
  },
  data() {
    return {
      form: new Form({
        id: this.booking.id,
        customer_id: this.booking.customer.id,
        reschedule_booking_id: this.booking.id,
        date: this.booking.startAt ? moment(this.booking.startAt).toDate() : new Date(),
        from: this.booking.startAt ? moment(this.booking.startAt).format('HH:mm') : null,
        quantity: 1,
        adults_number: 1,
        children_number: 0,
        note: null,
        origin_platform: Booking.originPlatformBackoffice,
      }),
      timeslots: [],
      loading: false,
      search: null,
      datepicker: null,
    }
  },
  async mounted() {
    await this.loadServiceAvailability()

    this.fillForm()
    this.datepicker = moment(this.form.fields.date).toDate()
  },
  methods: {
    ...mapActions({
      bookingReschedule: 'booking/reschedule',
      serviceAvailability: 'service/availability',
    }),
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      this.bookingReschedule({
        ...this.form.fields,
      })
        .then(() => {
          this.clearForm()
          this.$emit('success')
        })
        .catch((response) => {
          if (response.response.data && response.response.data.message) {
            this.showError(response.response.data.message)
          } else if (response.response.data && response.response.data.errors && response.response.data.errors.length) {
            this.showError(response.response.data.errors[0].detail)
          }
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.$emit('close')
    },
    handleDateChange(date) {
      if (this.form.fields.date !== moment(date).format('YYYY-MM-DD')) {
        this.form.fields.from = null
      }
      this.form.fields.date = this.datepicker ? moment(this.datepicker).format('YYYY-MM-DD') : null
      this.loadServiceAvailability()
    },
    loadServiceAvailability() {
      this.loading = true

      const data = {
        id: this.service.id,
        params: {
          type: 'activity',
          ...this.form.fields,
        },
      }

      return this.serviceAvailability(data)
        .then((response) => {
          this.timeslots = response.availability
        })
        .finally(() => {
          this.loading = false
        })
    },
    dateFormat(value) {
      return moment(value).format('ddd, D MMM YYYY')
    },
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        date: moment(this.booking.startAt).format('YYYY-MM-DD'),
        from: moment(this.booking.startAt).format('HH:mm'),
        quantity: this.booking.quantity,
        adults_number: this.booking.adultsNumber,
        note: this.booking.note,
      }
    },
    clearForm() {
      this.form.fields = {
        date: null,
        from: null,
        quantity: 1,
        adults_number: 1,
        children_number: 0,
      }
    },
    timeFormat(value) {
      const hours = Math.floor(value / 60)
      const minutes = value % 60

      let values = []

      if (hours) {
        if (hours > 1) {
          values.push(hours + ' hours')
        } else {
          values.push(hours + ' hour')
        }
      }

      if (minutes) {
        values.push(minutes + ' minutes')
      }

      return values.join(' ')
    },
    showError(message) {
      this.$buefy.dialog.alert({
        title: 'Error',
        message: message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true,
      })
    },
    formatDatepickerDate(date) {
      return date
        ? date.toLocaleDateString('en-us', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : ''
    },
  },
}
</script>

<style scoped lang="scss">
textarea.styled {
  background-color: rgb(244, 245, 247);
  border: unset;
  width: 100%;
  resize: vertical;
  font-weight: 500;
  color: #5b6676;
  border: 1px solid #c4cad3;
}
</style>
