import {
    Model
} from '@/internal/modules';

import {
    ServiceRuleService as ServiceRuleService
} from '@/internal';


export default class ServiceRule extends Model {

    static get name() {
        return 'ServiceRule';
    }

    static get vuexModuleKey() {
        return 'serviceRule';
    }

    static get service() {
        return ServiceRuleService;
    }

    static get fields () {
        return {};
    }

    static get typeOptions() {
        return [
            {
                label: 'Max persons per booking',
                value: 'max-persons-per-booking',
            },
            {
                label: 'Max persons at same time',
                value: 'max-persons-at-same-time',
            },
            {
                label: 'Max bookings at same time',
                value: 'max-bookings-at-same-time',
            },
            {
                label: 'Max persons starting at same time',
                value: 'max-persons-starting-at-same-time',
            },
            {
                label: 'Max bookings starting at same time',
                value: 'max-bookings-starting-at-same-time',
            },
            {
                label: 'Max persons per interval',
                value: 'max-persons-per-interval',
            },
            {
                label: 'Max bookings per interval',
                value: 'max-bookings-per-interval',
            },


        ]
    }

    static get applyTypeOptions() {
        return [
            {
                label: 'Everyday',
                value: 'everyday',
            },
            {
                label: 'Days of week',
                value: 'days-of-week',
            },
            {
                label: 'Specific dates',
                value: 'specific-dates',
            },
            {
                label: 'Date interval',
                value: 'date-interval',
            },
        ]
    }

    static get weekDaysOptions() {
        return [
            {
                label: 'Mondays',
                value: 1,
            },
            {
                label: 'Tuesdays',
                value: 2,
            },
            {
                label: 'Wednesdays',
                value: 3,
            },
            {
                label: 'Thursdays',
                value: 4,
            },
            {
                label: 'Fridays',
                value: 5,
            },
            {
                label: 'Saturdays',
                value: 6,
            },
            {
                label: 'Sundays',
                value: 7,
            },
        ]
    }

}
