import {
    Model
} from '@/internal/modules';

import {
    File,
    InformationService as InformationService
} from '@/internal';

export default class Information extends Model {

    static get name() {
        return 'Information';
    }

    static get vuexModuleKey() {
        return 'information';
    }

    static get service() {
        return InformationService;
    }

    static get fields () {
        return {
            files: {
                preparePayload: (value) => {
                    return value ? value.map(file => file.id) : [];
                },
                type: File,
            }
        };
    }

    get files() {
        return this.hasMany(File);
    }

    static get statusOptions() {
        return [
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Published',
                value: 'published',
            }
        ]
    }

}
