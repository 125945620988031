import {
    Model,
} from '@/internal/modules';

import {
    RelatedServiceService
} from '@/internal';

export default class RelatedService extends Model {
    static get name() {
        return 'RelatedService';
    }

    static get vuexModuleKey() {
        return 'relatedService';
    }

    static get service() {
        return RelatedServiceService;
    }

    static get fields () {
        return {};
    }

}
