import { ResourceService } from '@/internal/modules';

export default class ServiceItemService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'service-item';
    }

  available(payload = {}) {

    const { params, formData, ...rest } = payload;

    return this.request({
      method: 'get',
      url: `${this.baseUrl}/available`,
      params: this.prepareParams(params),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
