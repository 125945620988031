import {
    Model
} from '@/internal/modules';

import {
    ServiceWorkingHourService as ServiceWorkingHourService
} from '@/internal';

import moment from 'moment-timezone';

export default class ServiceWorkingHour extends Model {

    static get name() {
        return 'ServiceWorkingHour';
    }

    static get vuexModuleKey() {
        return 'serviceWorkingHour';
    }

    static get service() {
        return ServiceWorkingHourService;
    }

    static get fields () {
        return {
            from: {
                preparePayload: (value) => {
                    return moment.tz("2023-07-22 " + value, window.app.vue.$store.getters['system/timezone']).utc().format("HH:mm");
                },
            },
            to: {
                preparePayload: (value) => {
                    return moment.tz("2023-07-22 " + value, window.app.vue.$store.getters['system/timezone']).utc().format("HH:mm");
                },
            }
        };
    }

}
