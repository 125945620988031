import {
    ActiveBooking as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const store = new ResourceStore({
    key: 'activeBooking',
    model: Model
});

export default store.toObject();
