<template>
  <Dialog _class="w:540@md" v-model="open" :title="isCreateForm ? 'Create working hour' : 'Update working hour'" @submit="handleSave">
    <div class="field">
      <label class="--label mb:4">Day</label>

      <SelectInput placeholder="Select day" v-model="form.fields.weekDay" :options="daysOfWeek" />
      <FormError field="week_day" :form="form" />
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="--label mb:4">From</label>
          <b-timepicker placeholder="" icon="clock" :incrementMinutes="15" append-to-body v-model="form.fields.from" />
          <FormError field="from" :form="form" />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="--label mb:4">To</label>
          <b-timepicker placeholder="" icon="clock" :incrementMinutes="15" append-to-body v-model="form.fields.to" />
          <FormError field="to" :form="form" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import { Form } from '@/internal/modules'
import ServiceWorkingHour from '@/domain/ServiceWorkingHour/ServiceWorkingHour'

import Dialog from '@/components/admin/Dialog.vue'

export default {
  name: 'WorkingHoursDialog',
  components: {
    Dialog,
  },
  computed: {
    isCreateForm() {
      return !this.form.fields.id
    },
  },
  data() {
    return {
      open: false,
      form: new Form({
        weekDay: null,
        from: null,
        to: null,
        service_id: this.$route.query.show,
      }),
      daysOfWeek: [
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 },
        { label: 'Sunday', value: 7 },
      ],
    }
  },
  methods: {
    ...mapActions({
      workingHoursStore: 'serviceWorkingHour/store',
      workingHoursUpdate: 'serviceWorkingHour/update',
    }),
    setFormFields(fields) {
      this.form.fields = fields
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        from: this.form.fields.from ? moment(this.form.fields.from).format('HH:mm:ss') : null,
        to: this.form.fields.to ? moment(this.form.fields.to).format('HH:mm:ss') : null,
      }

      let action = null

      if (this.isCreateForm) {
        action = this.workingHoursStore
      } else {
        action = this.workingHoursUpdate
      }

      action(ServiceWorkingHour.preparePayload(fields))
        .then(() => {
          window.app.snackbar({
            message: `${this.isCreateForm ? 'Created' : 'Updated'} successfully!`,
          })
          this.setFormFields({
            weekDay: null,
            from: null,
            to: null,
            service_id: this.$route.query.show,
          })
          this.open = false
          this.$emit('onSave')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
