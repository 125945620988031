<template>
  <div>
    <div ref="editor" class="pell" />
  </div>
</template>

<script>
import pell from 'pell'
import markdownit from 'markdown-it'
import * as domador from 'domador'

const md = markdownit('commonmark')

export default {
  name: 'MarkdownEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const instance = pell.init({
      element: this.$refs.editor,
      defaultParagraphSeparator: 'p',
      onChange: (html) => {
        this.$emit(
          'input',
          domador(html, {
            inline: true,
            transform: function (el) {
              if (el.tagName == 'BR') {
                return '<br>'
              }
            },
          })
        )
      },
      actions: [
        'bold',
        'italic',
        'underline',
        {
          name: 'link',
          result: () => {
            const url = window.prompt('Enter the link URL')
            if (url) pell.exec('createLink', this.ensureHTTP(url))
          },
        },
      ],
    })
    instance.content.innerHTML = md.render(this.value || '')
  },
  methods: {
    ensureHTTP: (str) => (/^https?:\/\//.test(str) && str) || `http://${str}`,
  },
}
</script>

<style lang="scss">
.pell {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
}

.pell-button {
  border: none;
  padding: 8px 12px;
  margin-right: 4px;
  border-radius: 3px;
  background: white;

  &:hover {
    cursor: pointer;
    background: #f4f4f5;
  }
}

.pell-actionbar {
  border-bottom: 1px solid #dbdbdb;
}

.pell-content {
  padding: 8px;

  p {
    margin: 16px 0;
  }
}

#html,
#markdown {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
