<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <h2 class="modal-card-title fs-2">Closed days</h2>
                <button
                    type="button"
                    class="delete"
                    @click="handleClose"/>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <b-datepicker
                                v-model="form.fields.startDate"
                                placeholder="Start date"
                                icon="calendar"
                                horizontal-time-picker
                                editable
                                :locale="datePickerLocale">
                            </b-datepicker>
                            <FormError field="start_date" :form="form"/>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <b-datepicker
                                v-model="form.fields.endDate"
                                placeholder="End date"
                                icon="calendar"
                                horizontal-time-picker
                                editable
                                :locale="datePickerLocale">
                            </b-datepicker>
                            <FormError field="end_date" :form="form"/>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <b-timepicker
                                :disabled="allDay"
                                placeholder="From"
                                icon="clock"
                                :incrementMinutes="15"
                                append-to-body
                                v-model="form.fields.startTime"
                            >
                            </b-timepicker>
                            <FormError field="start_time" :form="form"/>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <b-timepicker
                                :disabled="allDay"
                                placeholder="To"
                                icon="clock"
                                :incrementMinutes="15"
                                append-to-body
                                v-model="form.fields.endTime"
                            >
                            </b-timepicker>
                            <FormError field="end_time" :form="form"/>
                        </div>
                    </div>
                    <div class="column">
                        <b-checkbox v-model="allDay" @input="handleAllDayToggle">
                            All day
                        </b-checkbox>
                    </div>
                </div>
                <div class="field">
                    <b-field label="Description" >
                        <b-input type="textarea" v-model="form.fields.description">
                        </b-input>
                    </b-field>
                    <FormError field="description" :form="form"/>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Close"
                    @click="handleClose" />
                <b-button
                    class="is-pulled-right"
                    label="Save" @click="handleSave"
                    type="is-primary" />
            </footer>
        </div>
    </div>
</template>

<script>
import {
    Form,
} from '@/internal/modules';

import {mapActions} from "vuex";
import mixin from "@/mixin";
import moment from "moment";

export default {
    name: 'ClosedHoursModal',
    mixins: [mixin],
    components: {},
    computed: {
    },
    props: {
        closedHour: {
            type: Object || null,
            required: false
        },
        service: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            form: new Form({
                serviceId: this.service.id,
                startDate: new Date(),
                endDate: new Date(),
                startTime: moment("00:00","HH:mm").toDate(),
                endTime: moment("23:45","HH:mm").toDate(),
                description: null,
            }),
            allDay: true,
            datePickerLocale: undefined
        };
    },
    mounted() {
        if(this.closedHour) {
            this.fillForm();
            this.allDay = this.isAllDay();
        }
    },
    methods: {
        ...mapActions({
            serviceClosedHourStore: 'serviceClosedHour/store',
            serviceClosedHourUpdate: 'serviceClosedHour/update',
        }),
        fillForm() {
            this.form.fields = {
                ...this.closedHour,
                startDate: moment(this.closedHour.startDate).toDate(),
                endDate: moment(this.closedHour.endDate).toDate(),
                startTime: moment(this.closedHour.startTime,"HH:mm:ss").toDate(),
                endTime: moment(this.closedHour.endTime,"HH:mm:ss").toDate(),
            }
        },
        isAllDay() {
            return moment(this.form.fields.startTime).format("HH:mm") === '00:00' &&
                   moment(this.form.fields.endTime).format("HH:mm") === '23:45';
        },
        handleSave() {
            this.loading = true;

            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
                startDate: this.form.fields.startDate ? moment(this.form.fields.startDate).format("YYYY-MM-DD") : null,
                endDate: this.form.fields.endDate ? moment(this.form.fields.endDate).format("YYYY-MM-DD") : null,
                startTime: this.form.fields.startDate ? moment(this.form.fields.startTime).format("HH:mm") : null,
                endTime: this.form.fields.endTime ? moment(this.form.fields.endTime).format("HH:mm") : null,
            }

            this.serviceClosedHourStore(fields).then((response) => {
                this.clearForm();
                this.$emit('success', response);
            }).catch((response) => {
                this.form.recordErrors(response);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleClose() {
            this.clearForm();
            this.$emit('close');
        },
        clearForm() {
            this.form.fields = {
                startDate: new Date(),
                endDate: new Date(),
                startTime: moment("00:00","HH:mm").toDate(),
                endTime: moment("23:45","HH:mm").toDate(),
                description: null
            };
        },
        handleAllDayToggle() {
            if(this.allDay) {
                this.form.fields = {
                    ...this.form.fields,
                    startTime: moment("00:00","HH:mm").toDate(),
                    endTime: moment("23:45","HH:mm").toDate(),
                }
            }
        },
    }
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
