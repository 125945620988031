<template>
    <b-datepicker
        class="datepicker"
        ref="datepicker"
        v-model="selectedDate"
        :locale="undefined"
        placeholder="Any date"
        icon="calendar-o"
        :icon-right="selectedDate && allowClear ? 'close-circle' : $refs.datepicker && $refs.datepicker.isFocused ? 'chevron-up' : 'chevron-down'"
        icon-right-clickable
        icon-pack="fa"
        icon-prev="arrow-left"
        icon-next="arrow-right"
        :open-on-focus="false"
        :first-day-of-week="1"
        :date-formatter="formatDatepickerDate"
        @input="inputEvent"
        @icon-right-click="handleClearEvent"
        :day-names="['Sun','Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat']"
        :trap-focus="false"
        :position="position"
        :range="range"
        :multiple="multiple"
    />
</template>

<script>
export default {
    name: 'CalendarInput',
    props: {
        value: {
            type: [Date, Array],
            required: false,
        },
        allowClear: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'is-bottom-right'
        },
        range: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedDate: null,
        }
    },
    mounted() {
        this.selectedDate = this.value;
    },
    methods: {
        formatDatepickerDate(date) {
            const formatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            const formatDate = (d) => d.toLocaleDateString('en-US', formatOptions);
            return Array.isArray(date) ? date.map(formatDate).join(', ') : date ? formatDate(date) : '';
        },
        inputEvent() {
            this.$emit('input', this.selectedDate);
        },
        handleClearEvent() {
            if (this.allowClear) {
                this.selectedDate = null;
                this.$emit('clear');
            }
        },
        setValue(value) {
            this.selectedDate = value;
            this.inputEvent();
        },
    },
    watch: {
        value(value) {
            this.setValue(value);
        }
    }
}
</script>

<style lang="scss" scoped>
.datepicker::v-deep {
    input {
        border-radius: 8px;
    }

    i {
        color: #000000;
    }

    a {
        border-radius: 8px;

        i {
            color: #000000;
        }
    }

    .navbar-link:not(.is-arrowless)::after,
    .select:not(.is-multiple):not(.is-loading)::after {
        color: #000000;
    }

    .datepicker-table .datepicker-header .datepicker-cell {
        color: #8d98a4;
    }

    .datepicker-table .datepicker-body .datepicker-cell.is-unselectable {
        color: #c4cad3;
    }

    .datepicker-table .datepicker-body .datepicker-cell {
        &.is-selected {
            background-color: #ffffff;
            color: #7b61ff;
            border: 2px solid #7b61ff;
        }

        &.is-today {
            border: 1px solid #7b61ff;
        }
    }

    .dropdown .input[readonly]:focus,
    .dropdown .input[readonly].is-focused,
    .dropdown .input[readonly]:active,
    .dropdown .input[readonly].is-active,
    .dropdown-trigger .input[readonly]:focus,
    .dropdown-trigger .input[readonly].is-focused,
    .dropdown-trigger .input[readonly]:active,
    .dropdown-trigger .input[readonly].is-active {
        &:active,
        &:focus {
            border-color: #000000;

        }
    }

    .dropdown .input[readonly],
    .dropdown-trigger .input[readonly] {
        box-shadow: unset;
    }

    .pagination-previous:focus,
    .pagination-next:focus,
    .pagination-link:focus {
        border: 1px solid #7b61ff;
    }

    .dropdown-content {
        padding: 8px;
        border-radius: 8px !important;
    }
}

</style>
