<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section p-3">
            <div class="level">
                <div class="level-left">
                  <div class="font--primary f:36 bold">
                    Dashboard
                  </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <div class="mr-4">
                          <SelectInput
                            v-model="option"
                            :options="options"
                            placeholder="Select activity"
                            @input="handleOptionsSelect" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3">

            <div class="card">
                <div class="card-content">

                    <div class="columns">
                        <div class="column">
                            <div>Users ({{this.usersData.total}})</div>
                            <Bar
                                id="users-chart"
                                :options="chartOptions"
                                :data="usersData"
                            />
                        </div>
                        <div class="column">
                            <div>Bookings ({{this.bookingsData.total}})</div>
                            <Bar
                                id="bookings-chart"
                                :options="chartOptions"
                                :data="bookingsData"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div>Bookings by service</div>
                            <Bar
                                id="bookings-by-service-chart"
                                :options="chartOptions"
                                :data="bookingsByServiceData"
                            />
                        </div>
                        <div class="column">
                            <div>Bookings by category</div>
                            <Bar
                                id="bookings-by-category-chart"
                                :options="chartOptions"
                                :data="bookingsByCategoryData"
                            />
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="box">
                                <h2 class="pl-3">Bookings by service & checkout type</h2>
                                <b-table
                                    :data="bookingsByCheckoutType"
                                    :striped="true"
                                    :hoverable="false"
                                    :sortable="false"
                                    :show-header="true">

                                    <b-table-column field="name" label="Service" v-slot="props">
                                        {{ props.row.name }}
                                    </b-table-column>

                                    <b-table-column field="add-to-bill" label="Add to bill" v-slot="props">
                                        {{ props.row.addToBill }}
                                    </b-table-column>

                                    <b-table-column field="paid" label="Paid" v-slot="props">
                                        {{ props.row.paid }}
                                    </b-table-column>

                                    <b-table-column field="confirmed" label="Confirmed" v-slot="props">
                                        {{ props.row.confirmed }}
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                        <div class="column">
                            <div class="box">
                                <h2 class="pl-3">Bookings by service & origin platform</h2>
                                <b-table
                                    :data="bookingsByOriginPlatform"
                                    :striped="true"
                                    :hoverable="false"
                                    :sortable="false"
                                    :show-header="true">

                                    <b-table-column field="name" label="Service" v-slot="props">
                                        {{ props.row.name }}
                                    </b-table-column>

                                    <b-table-column field="app" label="App" v-slot="props">
                                        {{ props.row.guestApp }}
                                    </b-table-column>

                                    <b-table-column field="back-office" label="Back-office" v-slot="props">
                                        {{ props.row.backOffice }}
                                    </b-table-column>

                                    <b-table-column field="integration" label="Integration" v-slot="props">
                                        {{ props.row.integration }}
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="box">
                                <h2 class="pl-3">Service by total money spent</h2>
                                <b-table
                                    :data="bookingsByPrice"
                                    :striped="true"
                                    :hoverable="false"
                                    :sortable="false"
                                    :show-header="true">

                                    <b-table-column field="service" label="Service" v-slot="props">
                                        {{ props.row.service }}
                                    </b-table-column>

                                    <b-table-column field="total" label="Total (£)" v-slot="props">
                                        {{ props.row.total }}
                                    </b-table-column>

                                </b-table>
                            </div>
                        </div>
                        <div class="column">

                        </div>
                    </div>

                </div>
            </div>

        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { Bar } from 'vue-chartjs'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, CategoryScale)

export default {
    name: "Dashboard",
    components: { Bar },
    data() {
        return {
            loading: false,
            options: [
                { label: 'Last 7 days', value: 'last_7_days' },
                { label: 'Last 30 days', value: 'last_30_days' },
                { label: 'Last 90 days', value: 'last_90_days' },
            ],
            option: 'last_7_days',
            paramOptions: {
                last_7_days: {
                    value: 'last_7_days',
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    group_by: 'day'
                },
                last_30_days: {
                    value: 'last_30_days',
                    start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    group_by: 'day'
                },
                last_90_days: {
                    value: 'last_90_days',
                    start_date: moment().subtract(90, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    group_by: 'day'
                }
            },
            usersData: {
                labels: [],
                datasets: [{
                    data: [],
                    label: 'Total users',
                    backgroundColor: '#212121'
                }],
                total: 0
            },
            bookingsData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        label: 'Total bookings',
                        backgroundColor: '#212121'
                    },
                    {
                        data: [],
                        label: 'Bookings via Honne',
                        backgroundColor: '#0e863c'
                    }
                ],
                total: 0
            },
            bookingsByCategoryData: {
                labels: [],
                datasets: [{
                    data: [],
                    label: 'Bookings by category',
                    backgroundColor: '#212121'
                }],
            },
            bookingsByServiceData: {
                labels: [],
                datasets: [{
                    data: [],
                    label: 'Bookings by service',
                    backgroundColor: '#212121'
                }],
            },
            bookingsByCheckoutType: [],
            bookingsByPrice: [],
            bookingsByOriginPlatform: [],
            chartOptions: {
                responsive: true,
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                            stepSize: 1,
                        }
                    },
                }
            },
        }
    },
    created() {
        this.option = this.$route.query.period ? this.$route.query.period : 'last_7_days';

        this.handleOptionsSelect(this.option);
    },
    methods: {
        ...mapActions({
            index: 'dashboard/index',
        }),
        handleOptionsSelect(option) {
            this.loading = true

            this.$router.push({
                name: this.$route.name,
                query: {
                    ...this.$route.query,
                    'period' : this.paramOptions[option].value,
                    'group_by': this.paramOptions[option].group_by
                },
            });

            let startDate = moment(this.paramOptions[option].start_date);
            let endDate = moment(this.paramOptions[option].end_date);

            let days = [];
            while (startDate.isSameOrBefore(endDate)) {
                days.push(startDate.clone().format('DD MMM'));
                startDate.add(1, 'days');
            }

            const usersChartValue = Array(days.length).fill(0)
            const bookingsChartValue = Array(days.length).fill(0)
            const bookingsViaAppChartValue = Array(days.length).fill(0);

            this.bookingsData = {
                ...this.bookingsData,
                datasets: [{
                        ...this.bookingsData.datasets[0],
                        data: bookingsChartValue
                    },
                    {
                        ...this.bookingsData.datasets[1],
                        data: bookingsViaAppChartValue
                    }],
                labels: days,
            }

            this.usersData = {
                ...this.usersData,
                datasets: [{ ...this.usersData.datasets[0], data: usersChartValue }],
                labels: days
            }

            this.index({params: this.paramOptions[option]})
                .then(res => {

                    // Bookings
                    res.bookings.forEach(booking => {
                        let idx = this.bookingsData.labels.indexOf(moment(booking.date).format('DD MMM'))
                        bookingsChartValue[idx] = booking.total
                    })

                    res.bookingsViaApp.forEach(booking => {
                        let idx = this.bookingsData.labels.indexOf(moment(booking.date).format('DD MMM'))
                        bookingsViaAppChartValue[idx] = booking.total
                    })

                    this.bookingsData = {
                        ...this.bookingsData,
                        datasets: [
                            {
                                ...this.bookingsData.datasets[0],
                                data: [
                                    ...this.bookingsData.datasets[0].data,
                                    bookingsChartValue
                                ]
                            },
                            {
                                ...this.bookingsData.datasets[1],
                                data: [
                                    ...this.bookingsData.datasets[1].data,
                                    bookingsViaAppChartValue
                                ]
                            }
                        ],
                        labels: days,
                        total: res.bookingsTotal
                    }

                    // Users
                    res.users.forEach(user => {
                        let idx = this.usersData.labels.indexOf(moment(user.date).format('DD MMM'))
                        usersChartValue[idx] = user.total
                    })

                    this.usersData = {
                        ...this.usersData,
                        datasets: [{
                            ...this.usersData.datasets[0],
                            data: [
                                ...this.usersData.datasets[0].data,
                                usersChartValue
                            ]
                        }],
                        labels: days,
                        total: res.usersTotal
                    }


                    // Bookings by service
                    const bookingsByServiceChartValue = [];
                    const bookingsByServiceLabels = [];
                    res.bookingsByService.forEach((bookingByService, index) => {
                        bookingsByServiceLabels.push(bookingByService.name)
                        bookingsByServiceChartValue.push(bookingByService.total);
                    })

                    this.bookingsByServiceData = {
                        ...this.bookingsByServiceData,
                        datasets: [{
                            ...this.bookingsByServiceData.datasets[0],
                            data: bookingsByServiceChartValue
                        }],
                        labels: bookingsByServiceLabels,
                    }

                    // Bookings by category
                    const bookingsByCategoryChartValue = [];
                    const bookingsByCategoryLabels = [];
                    res.bookingsByCategory.forEach((bookingByCategory, index) => {
                        bookingsByCategoryLabels.push(bookingByCategory.name)
                        bookingsByCategoryChartValue.push(bookingByCategory.total);
                    })

                    this.bookingsByCategoryData = {
                        ...this.bookingsByCategoryData,
                        datasets: [{
                            ...this.bookingsByCategoryData.datasets[0],
                            data: bookingsByCategoryChartValue
                        }],
                        labels: bookingsByCategoryLabels,
                    }

                    // Bookings by checkout type
                    this.bookingsByCheckoutType = res.bookingsByCheckoutType.map(item => {
                        return {
                            'name': item.name,
                            'addToBill': item.data.find(row => row.checkout_type === 'add-to-bill')?.total,
                            'paid': item.data.find(row => row.checkout_type === 'paid')?.total,
                            'confirmed': item.data.find(row => row.checkout_type === 'confirmed')?.total
                        }
                    });

                    // Booking by price
                    this.bookingsByPrice = res.bookingsByPrice;

                    // Bookings by checkout type
                    this.bookingsByOriginPlatform = res.bookingsByOriginPlatform.map(item => {
                        return {
                            'name': item.name,
                            'guestApp': item.data.find(row => row.origin_platform === 'guest-app')?.total,
                            'backOffice': item.data.find(row => row.origin_platform === 'back-office')?.total,
                            'integration': item.data.find(row => row.origin_platform === 'integration')?.total
                        }
                    });

                })
                .finally(() => this.loading = false);
        }
    }
}
</script>
<style lang="scss">
.fs-36 {
    font-size: 36px;
    color: #292055;
}

.users-chart,
.bookings-chart {
    width: 300px;
}
@media only screen and (max-width: 600px) {
    .users-chart,
    .bookings-chart {
        width: 100px;
    }
}
</style>
