<template>
  <div>
    <SelectInput :value="value" @input="$emit('input', $event)" :options="timeslotOptions" />
    <button
      v-if="allowCustomTime && !enableAllTimeslots"
      @click="enableAllTimeslots = true"
      class="button is-ghost pl-0"
    >
      Use custom time
    </button>
  </div>
</template>

<script>
import moment from 'moment'
import mixin from '@/mixin'

export default {
  name: 'TimeslotInput',
  mixins: [mixin],
  props: {
    allowCustomTime: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    timeslots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      enableAllTimeslots: false,
    }
  },
  computed: {
    timeslotOptions() {

      if (this.enableAllTimeslots) {
        return this.allTimeslotsOptions
      }

      if (!this.timeslots) return []

      const timeslots = this.timeslots
        .filter((timeslot) => !['closed'].includes(timeslot.status))
        .map((timeslot) => {
          return {
            label: this.dateFormatTimezone(timeslot.start).format('HH:mm'),
            value: moment.utc(timeslot.start).format('HH:mm'),
            disabled: !timeslot.available,
            status: timeslot.status,
            selfOwned: timeslot.self_owned,
          }
        })

      if (!this.value) return timeslots

      // if value is in timeslots, return timeslots
      if (timeslots.find((t) => t.value === this.value)) return timeslots

      // if value is not in timeslots (custom time), add it so it can be selected
      return [
        ...timeslots,
        {
          label: this.timeFormatTimezone(this.value).format('HH:mm'),
          value: this.value,
          disabled: false,
        },
      ]
    },
    allTimeslotsOptions() {

      let timeslotDuration = 15 // minutes
      let timeslotsCount = 1440 / timeslotDuration // minutes in one day
      let firstTimeslot = moment.utc().hour(0).minutes(0)
      let timeslots = [
        {
          label: this.dateFormatTimezone(firstTimeslot).format('HH:mm'), //local zone
          value: firstTimeslot.format('HH:mm'), // utc
        },
      ]

      for (let i = 1; i < timeslotsCount; i++) {
        firstTimeslot.add(timeslotDuration, 'minutes')
        timeslots.push({
          label: this.dateFormatTimezone(firstTimeslot).format('HH:mm'), //local zone
          value: firstTimeslot.format('HH:mm'), // utc
        })
      }

      return timeslots.sort((a, b) => {
        // Extract hours and minutes from the time strings
        const [aHour, aMinute] = a.label.split(':').map(Number)
        const [bHour, bMinute] = b.label.split(':').map(Number)

        // Compare hours first
        if (aHour !== bHour) {
          return aHour - bHour
        }

        // If hours are equal, compare minutes
        return aMinute - bMinute
      })
    },
  },
}
</script>
