<template>
  <Dialog width="540px" v-model="open" :title="title" @submit="handleSave" @close="handleClose">
    <div>
      <label class="--label mb:16">
        Title
        <input class="--input mt:4" type="text" v-model="form.fields.title" />
        <FormError field="title" :form="form" />
      </label>

      <label class="--label mb:16">
        Description (Optional)
        <input class="--input mt:4" type="text" v-model="form.fields.description" />
      </label>

      <div class="flex:|center gap:16 mb:16">
        <label class="--label flex:1">
          Capacity
          <input class="--input mt:4" type="number" v-model="form.fields.capacity" />
          <FormError field="capacity" :form="form" />
        </label>

        <label class="--label flex:1">
          Order
          <input class="--input mt:4" type="number" v-model="form.fields.priority" />
          <FormError field="priority" :form="form" />
        </label>
      </div>

      <div v-if="isGroup">
        <label class="--label flex:1 mb:4">Choose tables to combine</label>
        <label v-for="item in availableItems" class="--label flex:|center gap:12 mb:12">
          <input
            class="--checkbox"
            type="checkbox"
            v-model="form.fields.group"
            :value="item.id"
          />
          {{ item.title }} - {{ item.capacity }} {{ item.capacity == 1 ? 'seat' : 'seats' }}
        </label>
        <FormError field="group" :form="form" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/admin/Dialog.vue'

import { Form } from '@/internal/modules'
import { mapActions } from 'vuex'
import Vue from 'vue'
import mixin from '@/mixin'

export default {
  name: 'ItemsDialog',
  mixins: [mixin],
  components: { Dialog },
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
  computed: {
    service() {
      return this.$route.query.show ? this.$store.getters['service/show'](this.$route.query.show) : null
    },
    availableItems() {
      return this.items.filter((item) => {
        if (item.group) {
          return false
        }
        if (parseInt(this.form.fields.serviceSpaceId) === parseInt(item.serviceSpaceId)) {
          if (this.form.fields.id) {
            return item.id !== parseInt(this.form.fields.id)
          } else {
            return true
          }
        }

        return false
      })
    },
    title() {
      if (this.isGroup) {
        return this.form.fields.id ? 'Update combinated table' : 'Create combinated table'
      } else {
        return this.form.fields.id ? 'Update table' : 'Create table'
      }
    },
    selectedCapacity() {
      return this.availableItems.reduce((acc, item) => {
        if (this.form.fields.group.includes(item.id)) {
          return acc + item.capacity
        }

        return acc
      }, 0)
    },
  },
  watch: {
    'form.fields.group'() {
      if (this.isGroup) {
        this.form.fields.capacity = this.selectedCapacity
      }
    },
  },
  data() {
    return {
      loading: false,
      open: false,
      isGroup: false,
      form: new Form({
        serviceId: this.$route.query.show,
        priority: 1,
        type: 'table',
        title: null,
        description: null,
        capacity: 1,
        group: [],
      }),
    }
  },
  methods: {
    ...mapActions({
      serviceItemStore: 'serviceItem/store',
      serviceItemUpdate: 'serviceItem/update',
    }),
    setFormFields(fields) {
      this.form.fields = {
        ...this.form.fields,
        ...fields,
      }
      if (this.form.fields.group && this.form.fields.group.length) {
        this.isGroup = true
      }
    },
    setIsGroup(isGroup) {
      this.isGroup = isGroup
    },
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null
      if (this.form.fields.id) {
        action = this.serviceItemUpdate
      } else {
        action = this.serviceItemStore
      }

      action(fields)
        .then((response) => {
          window.app.snackbar({
            message: `${this.form.fields.id ? 'Updated' : 'Created'} successfully!`,
          })
          this.$emit('success', response)
          this.clearForm()
          this.open = false
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.open = false
      this.$emit('close')
    },
    clearForm() {
      this.form.errors.clear()
      this.form.fields = {
        serviceId: this.$route.query.show,
        priority: 1,
        type: 'table',
        title: null,
        description: null,
        group: [],
        capacity: 1,
      }
      this.isGroup = false
    },
  },
}
</script>
