<template>
    <div class="new-dropdown" v-click-outside="close">
        <div class="new-dropdown-input" @click="toggleOpen">
            <div style="width: 252px;" :class="['px-3 py-2', {'active': open}]">
                <template v-if="value.length === 0">
                    <div class="rounded-16 line-height-1 px-2 py-1 mr-1">Any status</div>
                </template>
                <template v-else>
                    <div class="selected-status-items">
                        <div
                            v-for="option in value"
                            class="selected-status-item is-inline-block rounded-16 line-height-1 px-2 py-1 mr-1"
                            :class="`selected-status-item--${option.color}`"
                        >
                            {{ option.label }}
                            <span @click.stop="$emit('select', option.value)">
                                <svg data-v-9f6c994a="" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path data-v-9f6c994a="" fill-rule="evenodd" clip-rule="evenodd" d="M10.8 9.83702L6.9625 5.99792L10.75 2.20467C11.0167 1.93789 11.0167 1.50438 10.75 1.2376C10.4833 0.970821 10.05 0.970821 9.78333 1.2376L5.99583 5.02668L2.16667 1.20008C1.9 0.933306 1.46667 0.933306 1.2 1.20008C0.933333 1.46686 0.933333 1.90038 1.2 2.16715L5.02917 5.99792L1.2 9.82451C0.933333 10.0913 0.933333 10.5248 1.2 10.7916C1.46667 11.0584 1.9 11.0584 2.16667 10.7916L5.99583 6.96082L9.83333 10.7999C10.1 11.0667 10.5333 11.0667 10.8 10.7999C11.0667 10.5331 11.0667 10.0996 10.8 9.83285V9.83702Z" fill="#000000"></path>
                                </svg>
                            </span>
                        </div>
                    </div>
                </template>
            </div>
            <i :class="['fas', open ? 'fa-chevron-up' : 'fa-chevron-down']" aria-hidden="true"></i>
        </div>
        <transition name="fade">
            <div @click.stop v-if="open" class="new-dropdown-multiselect-items rounded-8">
                <div
                    class="new-dropdown-multiselect-item rounded-16 line-height-1 px-2 py-1 mr-1 mt-1 pointer"
                    :class="[`new-dropdown-multiselect-item--${option.color}`, {'active': value.find(s => s.value === option.value)}]"
                    v-for="option in options"
                    :key="option.value"
                    @click="toggleOption(option)"
                >
                    <svg v-if="value.find(s => s.value === option.value)" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9568 4.70014C13.2172 4.96049 13.2172 5.3826 12.9568 5.64295L7.29999 11.2998C7.03964 11.5602 6.61754 11.5602 6.35719 11.2998L3.52876 8.47138C3.26841 8.21103 3.26841 7.78892 3.52876 7.52857C3.78911 7.26822 4.21122 7.26822 4.47157 7.52857L6.82859 9.88559L12.014 4.70014C12.2744 4.4398 12.6965 4.4398 12.9568 4.70014Z" fill="#192434"/>
                    </svg>
                    {{ option.label }}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>


export default {
    name: 'ComboInput',
    props: {
        value: {
            required: true,
            type: Array,
        },
        options: {
            required: true,
            type: Array,
        },
    },
    data() {
      return {
       open: false
      }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open
        },
        close() {
            this.open = false
        },
        toggleOption(selectedOption) {
            if (this.value.find(value => value === selectedOption)) {
                this.$emit('input', this.value.filter(value => value !== selectedOption))
            } else {
                this.$emit('input', [...this.value, selectedOption])
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.new-dropdown {
    position: relative;

    &-input {
        position: relative;

        > div,
        input {
            height: 40px;
            color: #000000;
            cursor: pointer;
            font-size: 16px;
            border: 1px solid #C4CAD3;
            border-radius: 8px;
            padding-right: 36px !important;

            &.active {
                border-color: #000000;
            }
        }

        i {
            color: #000000;
            position: absolute;
            top: 12px;
            right: 12px;
        }

    }

    &-items {
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
        position: absolute;
        top: 45px;
        z-index: 1;
        background-color: #ffffff;
    }

    &-item {
        color: #000000;
        background-color: #ffffff;
        padding: 8px;
        border-radius: 8px;

        &:hover {
            color: #292055;
            background-color: #e7ebef;
            cursor: pointer;
        }
    }

    &-multiselect-items {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
        position: absolute;
        top: 45px;
        z-index: 5;
        background-color: #ffffff;
        width: 312px;
        padding: 12px;
        transition: 0.2s;
    }

    &-multiselect-item {
        color: #192434;
        display: inline-block;
        border: 1px solid #DBDFE5;


        &--default {
            &.active {
                background-color: #DBDFE5;
            }
        }

        &--success {
            color: #003D19;
            border-color: #C5F1D7;

            svg > path {
                fill: #003D19;
            }

            &.active {
                background-color: #C5F1D7;
            }
        }

        &--error {
            color: #4D0B08;
            border-color: #FDD3D1;

            svg > path {
                fill: #4D0B08;
            }

            &.active {
                background-color: #FDD3D1;
            }
        }
    }

    .selected-status-items {
        white-space: nowrap;
        overflow: hidden;
    }

    .selected-status-item {
        color: #192434;
        background-color: #DBDFE5;

        &--success {
            color: #003D19;
            background-color: #C5F1D7;

            svg > path {
                fill: #003D19;
            }
        }

        &--error {
            color: #4D0B08;
            background-color: #FDD3D1;

            svg > path {
                fill: #4D0B08;
            }
        }
    }
}
</style>
