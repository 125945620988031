<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div class="p:24">
      <div class="section-title">
        <Icon admin name="calendar-x" />
        Closed Days
      </div>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <label class="--label mb:4">From</label>
          <CalendarInput v-model="form.fields.startDate" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">To</label>
          <CalendarInput v-model="form.fields.endDate" position="is-bottom-left" />
        </div>
      </div>

      <div class="section-title">
        <Icon admin name="clock-x" />
        Closed Hours
      </div>

      <div class="flex:|center gap:16 mb:16">
        <div class="flex:1">
          <label class="--label mb:4">From</label>
          <b-timepicker :value="form.fields.startTime" :disabled="allDay" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">To</label>
          <b-timepicker :value="form.fields.endTime" :disabled="allDay" />
        </div>
      </div>

      <label class="--label flex:|center gap:8 mb:32">
        <input class="--checkbox" type="checkbox" v-model="allDay" @change="handleAllDayToggle" />
        All day
      </label>

      <div class="section-title">
        <Icon admin name="pencil" />
        Description
      </div>
      <textarea v-model="form.fields.description" class="--textarea" />
    </div>

    <template slot="footer">
      <button @click="handleDelete" class="reset flex:|center gap:8 f:16 semi c:error py:12 r:8 o:0.6:hover">
        <Icon admin name="trash" />
        Delete
      </button>
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import { mapActions } from 'vuex'
import { Form } from '@/internal/modules'
import moment from 'moment'

import SideDrawer from '@/components/admin/SideDrawer'
import CalendarInput from '@/components/admin/CalendarInput'

export default {
  name: 'ClosedHourModal',
  components: {
    SideDrawer,
    CalendarInput,
  },
  computed: {
    closedHour() {
      return this.closedHourId ? this.$store.getters['closedHour/show'](this.closedHourId) : null
    },
    isEdit() {
      return this.$route.query.show !== 'create'
    },
    id() {
      return this.isEdit ? this.$route.query.show : null
    },
  },
  async mounted() {
    if (this.isEdit) {
      await this.loadClosedHour()
      this.fillForm()
    }
  },
  data() {
    return {
      closedHourId: null,
      form: new Form({
        startDate: new Date(),
        endDate: new Date(),
        startTime: moment('00:00', 'HH:mm').toDate(),
        endTime: moment('23:45', 'HH:mm').toDate(),
        description: null,
      }),
      allDay: true,
      loading: false,
      datePickerLocale: undefined,
    }
  },
  methods: {
    ...mapActions({
      closedHourDestroy: 'closedHour/destroy',
      closedHourStore: 'closedHour/store',
      closedHourUpdate: 'closedHour/update',
      closedHourShow: 'closedHour/show',
      closedHourCancel: 'closedHour/cancel',
    }),
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      let action = null

      if (this.closedHour) {
        action = this.closedHourUpdate
      } else {
        action = this.closedHourStore
      }

      const fields = {
        ...this.form.fields,
        startDate: this.form.fields.startDate ? moment(this.form.fields.startDate).format('YYYY-MM-DD') : null,
        endDate: this.form.fields.endDate ? moment(this.form.fields.endDate).format('YYYY-MM-DD') : null,
        startTime: this.form.fields.startDate ? moment(this.form.fields.startTime).format('HH:mm') : null,
        endTime: this.form.fields.endTime ? moment(this.form.fields.endTime).format('HH:mm') : null,
      }

      action(fields)
        .then(() => {
          window.app.snackbar({
            message: `${this.isEdit ? 'Updated' : 'Created'} successfully!`,
          })
          this.$router.push('/admin/closed-hour')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadClosedHour() {
      this.loading = true

      return this.closedHourShow({
        id: this.id,
        params: {},
      })
        .then((response) => {
          this.closedHourId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleAllDayToggle() {
      if (this.allDay) {
        this.form.fields = {
          ...this.form.fields,
          startTime: moment('00:00', 'HH:mm').toDate(),
          endTime: moment('23:45', 'HH:mm').toDate(),
        }
      }
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Hours',
        message: 'Are you sure you want to delete this hours?',
        onConfirm: () => {
          this.loading = true

          this.closedHourDestroy({ id: this.closedHour.id })
            .then(() => {
              window.app.snackbar('Hours deleted')

              this.$router.push({
                name: 'admin/closed-hour',
              })
            })
            .catch((e) => {
              if (e.response && e.response.data) {
                window.app.snackbar({
                  message: e.response.data.message,
                  type: 'is-danger',
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    fillForm() {
      if (this.closedHour) {
        this.form.fields = {
          ...this.closedHour,
          startDate: moment(this.closedHour.startDate).toDate(),
          endDate: moment(this.closedHour.endDate).toDate(),
          startTime: moment(this.closedHour.startTime, 'HH:mm:ss').toDate(),
          endTime: moment(this.closedHour.endTime, 'HH:mm:ss').toDate(),
        }

        this.allDay = this.closedHour.startTime === '00:00:00' && this.closedHour.endTime === '23:45:00'
      }
    },
  },
}
</script>
