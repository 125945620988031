import {
    Model,
} from '@/internal/modules';

import {
    NotificationService,
} from '@/internal';

export default class Notification extends Model {
    static get name() {
        return 'Notification';
    }
    static get vuexModuleKey() {
        return 'notification';
    }
    static get service() {
        return NotificationService;
    }

    static get fields () {
        return {

        };
    }

}
