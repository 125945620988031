import { ResourceService } from '@/internal/modules';

export default class GroupService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'group'
    }

  customerAdd(id, payload = {}) {

    const { params, formData, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/customer/add`,
      params: this.prepareParams(params),
      data: this.preparePayload(payload)
    });
  }

  customerRemove(id, payload = {}) {

    const { params, formData, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/customer/remove`,
      params: this.prepareParams(params),
      data: this.preparePayload(payload)
    });
  }
}
