import { render, staticRenderFns } from "./AnnoucementModal.vue?vue&type=template&id=056d2276"
import script from "./AnnoucementModal.vue?vue&type=script&lang=js"
export * from "./AnnoucementModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports