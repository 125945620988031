<template>
    <div>
        <!-----------------------  Not used ATM but it will used in the near future   -------------------->
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>
        <div v-if="service">
            <section class="section is-title-bar hero is-hero-bar">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <ul>
                                <li>
                                    <router-link to="/admin">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/service">Services</router-link>
                                </li>
                                <li v-if="service">
                                    <router-link :to="{ name:'admin/service/update', params: { serviceId: service.id }}">{{ service.name }}</router-link>
                                </li>
                                <li>
                                    Calendar
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">

                        </div>
                    </div>
                </div>
            </section>

            <section class="section">

                <FormMenu />

                <div class="card">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column is-flex is-flex-direction-row">
                                <div class="field mr-4">
                                    <div class="label">Duration</div>
                                    <b-select placeholder="Select duration" v-model="query.quantity"
                                              :disabled="!(service.configuration && service.configuration.flexibleTime)">
                                        <option
                                            v-for="option in durationOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </div>
                                <div class="field">
                                    <div class="label">Guests</div>
                                    <b-select placeholder="Select duration" v-model="query.adults_number">
                                        <option
                                            v-for="option in guestsOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="label">Date range</div>
                                    <div class="is-flex">
                                        <div class="pointer" @click="changeWeek('subtract')">
                                            <BIcon icon="chevron-left"/>
                                        </div>
                                        <div class="mx-4 is-flex is-flex-direction-column is-align-items-center">
                                            <div>{{ this.formatDate(query.date_from) }} - {{ this.formatDate(query.date_to) }}</div>
                                        </div>
                                        <div class="pointer" @click="changeWeek('add')">
                                            <BIcon icon="chevron-right"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <WeeklyCalendar
                            :dates="availability"
                            @select="handleTimeslotSelect"
                        />
                    </div>
                </div>

            </section>

            <b-modal
                v-model="bookingModalActive"
                trap-focus
                :destroy-on-hide="true"
                :can-cancel="false"
                aria-role="dialog"
                aria-label="Booking Modal"
                aria-modal>
                <template #default="props">
                    <BookingModal @success="handleBookingSave" :data="bookingData" :service="service" @close="props.close"  />
                </template>
            </b-modal>

        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormMenu from '@/views/Admin/Service/FormMenu';
import Paginator from '@/views/Admin/components/Paginator';
import WeeklyCalendar from '@/views/Admin/components/WeeklyCalendar';
import BookingModal from '@/views/Admin/components/BookingModal';
import mixin from "@/mixin";

import moment from 'moment';
import Vue from "vue";

export default {
    name: 'Calendar',
    mixins: [mixin],
    components: {
        FormMenu,
        WeeklyCalendar,
        BookingModal,
        Paginator
    },
    computed: {
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        durationOptions() {
            let options = [];

            if(this.service && this.service.duration) {
                for(let i = 1; i < 4; i++) {
                    options = [
                        ...options,
                        {
                            label: this.timeFormat(i * this.service.duration),
                            value: i,
                        }
                    ]
                }
            }

            return options;
        },
        guestsOptions() {
            let options = [];

            for(let i = 1; i < 11; i++) {
                options = [
                    ...options,
                    {
                        label: i,
                        value: i,
                    }
                ]
            }

            return options;
        },
        fullPath () {
            return this.$route.fullPath;
        }
    },
    mounted() {
        this.loadServiceAvailability();
    },
    data() {
        return {
            availability: null,
            loading: false,
            serviceId: null,
            bookingModalActive: false,
            bookingData: null,
            query: {
                date_from: this.$route.query.date_from ? moment(this.$route.query.date_from).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                date_to: this.$route.query.date_to ? moment(this.$route.query.date_to).format('YYYY-MM-DD') : moment().add(7,'days').format('YYYY-MM-DD'),
                quantity: this.$route.query.quantity || 1,
                adults_number: this.$route.query.adults_number  || 1,
                children_number: 0
            }
        };
    },
    methods: {
        ...mapActions({
            serviceAvailability: 'service/availability'
        }),
        loadServiceAvailability() {
            this.loading = true;

            const params = {
                ...this.query
            }

            return this.serviceAvailability({
                id: this.$route.params.serviceId,
                params: params
            }).then((response) => {
                this.serviceId = response.ids[0];
                this.availability = response.availability;
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleTimeslotSelect(timeslot) {
            this.bookingData = {
                date: timeslot.start,
                quantity: this.query.quantity,
                adults_number: this.query.adults_number
            }

            this.toggleBookingModalActive();
        },
        handleBookingSave(booking) {
            this.toggleBookingModalActive();
        },
        toggleBookingModalActive() {
            this.bookingModalActive = !this.bookingModalActive;
        },
        formatDate(date) {
            return moment(date).format('dddd, MMMM Do YYYY');
        },
        timeFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        changeWeek(direction) {
            this.query = {
                ...this.query,
                date_from: moment(this.query.date_from)[direction](1, 'week').format('YYYY-MM-DD'),
                date_to: moment(this.query.date_to)[direction](1, 'week').format('YYYY-MM-DD'),
            }

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...this.query
                },
            });
        }
    },
    watch: {
        'query': {
            handler() {
                const route = Object.assign({}, this.$route);

                this.$router.push({
                    name: route.name,
                    query: {
                        ...this.query
                    },
                });
            },
            deep: true
        },
        fullPath() {
            this.loadServiceAvailability();
        }
    },
};
</script>

<style lang="scss">

</style>
