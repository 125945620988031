<template>
    <div>
        <BLoading :is-full-page="true" v-model="loading"></BLoading>

        <section v-if="booking && service" class="section p-0">
            <div class="container container-extra-small p-0">
                <div class="column is-flex is-flex-direction-column content-height">
                    <template>
                        <div class="columns flex-column">
                            <div class="column">
                                <div class="field mb-5">
                                    <label class="mb-2 has-text-weight-semibold has-text-black">{{ service.nameFinal }}</label>
                                    <b-field>
                                        <HorizontalCalendar v-model="form.fields.date"/>
                                    </b-field>
                                    <FormError field="date" :form="form"/>

                                    <template v-if="service.isClosed">
                                        <div class="field mb-5">
                                            <div class="columns m-0 is-mobile is-flex-direction-column">
                                                <div class="text-center mt-5">
                                                    <p class="has-text-black has-text-weight-semibold fs-18 my-5">This activity is closed on this day</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="!service.isAvailable">
                                        <div class="field mb-5">
                                            <div class="columns m-0 is-mobile is-flex-direction-column">
                                                <div class="text-center mt-5">
                                                    <p class="has-text-black has-text-weight-semibold fs-18 my-5">This activity is not available on this day</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <b-field>
                                            <div class="columns m-0 is-mobile is-flex-direction-column">
                                                <div v-if="showTimeRangePicker" class="column p-0 mb-4">
                                                    <TimeRangePicker v-model="form.fields.timeRange" :selected="form.fields.from" :options="timeslotOptions" />
                                                </div>
                                                <div class="column p-0">
                                                    <div class="time-list columns is-mobile has-background-white is-multiline m-0">
                                                        <div
                                                            v-for="(option, index) in filteredTimeslotOptions"
                                                            v-if="visibleTimeslot(option)"
                                                            :class="['column is-half text-center p-0 time-list__item', {'disabled': option.disabled}]"
                                                            @click="!option.disabled ? selectTimeSlot(option) : null"
                                                            :data-status="option.status"
                                                        >
                                                            <div
                                                                class="column border-radius-12 text-center p-3 mx-1"
                                                                :class="{
                                                            'mt-3': index > 1,
                                                            'disabled': option.disabled,
                                                            'is-clickable border-grey app-grey': !option.disabled && form.fields.from !== option.value,
                                                            'has-background-black has-text-white border-black': form.fields.from === option.value
                                                        }"
                                                            >
                                                                <img v-if="option.disabled && option.selfOwned" src="/images/ticketVoucher.svg" /> {{ option.label }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormError field="from" :form="form"/>
                                                </div>
                                            </div>
                                        </b-field>
                                        <div v-if="service.configuration && service.configuration.flexibleTime" class="field">
                                            <label class="mb-2 has-text-weight-semibold has-text-black">For how long?</label>
                                            <b-field>
                                                <div class="duration-list columns is-mobile has-background-white is-multiline m-0 position-relative">
                                                    <div
                                                        v-for="(option, index) in timeForOptions"
                                                        class="column is-one-third text-center p-0 duration-list__item is-clipped"
                                                        @click="selectTimeFor(option)"
                                                    >
                                                        <div
                                                            class="column text-center position-absolute is-flex is-justify-content-center is-align-items-center"
                                                            :class="{
                                                        'disabled': option.disabled,
                                                        'is-clickable': !option.disabled,
                                                        'has-background-black has-text-white border-radius-12': form.fields.quantity === option.value
                                                    }"
                                                            :data-status="option.status"
                                                        > {{ option.label }} </div>
                                                    </div>
                                                </div>
                                            </b-field>
                                            <FormError field="quantity" :form="form"/>
                                        </div>
                                        <FormError field="message" :form="form"/>
                                        <FormError field="phone" :form="form"/>
                                        <FormError field="product" :form="form"/>
                                    </template>
                                </div>

                            </div>
                        </div>
                        <div v-if="!isClosed" class="controls has-margin-top">
                            <b-button class="button is-black border-radius-12 is-clickable is-fullwidth" :disabled="!isAvailable" @click="handleReschedule()" >BOOK</b-button>
                        </div>
                    </template>
                </div>
            </div>
        </section>

        <AvailabilityIssueModal :show="availabilityIssueModalActive" @ok="toggleAvailabilityIssueModalActive" @cancel="toggleAvailabilityIssueModalActive" />

    </div>
</template>

<script>
import {mapActions} from 'vuex';
import mixin from "@/mixin";

import {
    Form,
} from '@/internal/modules'
import moment from "moment-timezone";
import TimeRangePicker from '@/views/components/TimeRangePicker';
import HorizontalCalendar from '@/views/components/HorizontalCalendar';
import AvailabilityIssueModal from "@/views/components/AvailabilityIssueModal";

export default {
    name: 'Reschedule',
    mixins: [mixin],
    components: {
        TimeRangePicker,
        HorizontalCalendar,
        AvailabilityIssueModal
    },
    computed: {
        booking() {
            return this.bookingId ? this.$store.getters['booking/show'](this.bookingId) : null;
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        date() {
            return this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null;
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
        timeslotOptions() {
            return this.timeslots ? this.timeslots.filter((filterTimeslot) => !['closed'].includes(filterTimeslot.status)).map((mapTimeslot) => {
                return {
                    label: this.dateFormatTimezone(mapTimeslot.start).format("HH:mm"),
                    value: moment.utc(mapTimeslot.start).format("HH:mm"),
                    disabled: !mapTimeslot.available,
                    status: mapTimeslot.status,
                    selfOwned: mapTimeslot.selfOwned,
                };
            }) : [];
        },
        filteredTimeslotOptions() {
            if(!this.showTimeRangePicker) {
                return this.timeslotOptions;
            }

            const timeRangeArr = this.form.fields.timeRange.split("-");
            const timeRangeFrom = timeRangeArr[0];
            const timeRangeTo = timeRangeArr[1];

            return this.timeslotOptions.filter((timeslot) => {
                return (timeslot.value >= timeRangeFrom && timeslot.value <= timeRangeTo);
            });
        },
        showTimeRangePicker() {
            return this.timeslotOptions.length > 20;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        timeForOptions() {
            let options = [];

            if(this.service && this.service.duration) {
                for(let i = 1; i < 4; i++) {
                    options = [
                        ...options,
                        {
                            label: this.timeFormat(i * this.service.duration),
                            value: i,
                        }
                    ]
                }
            }

            return options;
        }
    },
    async created() {
        await this.loadBooking();

        this.fillForm();
    },
    data() {
        return {
            bookingId: null,
            form: new Form({
                date: new Date(),
                timeRange: '09:00-12:00',
                from: null,
                quantity: 1,
            }),
            timeslots: [],
            serviceId: null,
            loading: false,
            availabilityIssueModalActive: false,
            isAvailable: true,
            isClosed: false
        };
    },
    methods: {
        ...mapActions({
            bookingShow: 'booking/show',
            bookingUpdate: 'booking/update',
            bookingReschedule: 'booking/reschedule',
            serviceAvailability: 'service/availability'
        }),
        handleReschedule() {
            const fields = {
                ...this.form.fields,
                date: this.form.fields.date ? moment(this.form.fields.date).format('YYYY-MM-DD') : null,
            }

            this.loading = true;

            this.bookingReschedule(fields).then((response) => {
                const route = window.app.findRouteByName('booking/confirmation');
                this.$router.push(route);
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        fillForm() {
            this.form.fields = {
                id: this.booking.id,
                date: this.booking.startAt ? moment(this.booking.startAt).toDate() : new Date(),
                from: this.booking.startAt ? moment(this.booking.startAt).format('HH:mm') : null,
                quantity: this.booking.quantity ? this.booking.quantity : 1,
                timeRange: '08:00-11:00' // UTC
            }
        },
        loadBooking() {
            this.loading = true;

            return this.bookingShow({
                id: this.$route.params.bookingUuid
            }).then((response) => {
                this.bookingId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        loadServiceAvailability() {
            this.loading = true;

            const data = {
                id: this.booking.service.id,
                params: {
                    date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
                    quantity: this.form.fields.quantity,
                    adults_number: this.booking.adultsNumber ?? 1,
                    children_number: this.booking.childrenNumber ?? 0,
                    reschedule_booking_id: this.booking.id
                }
            }

            return this.serviceAvailability(data).then((response) => {
                this.serviceId = response.ids[0];
                this.timeslots = response.availability;
                this.isAvailable = response.isAvailable;
                this.isClosed = response.isClosed;
            }).finally(() => {
                this.loading = false;
            });
        },
        timeFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        selectTimeSlot(option) {
            if(option.disabled) {
                return;
            }

            this.form.fields.from = option.value;
        },
        selectTimeFor(option) {
            this.form.fields.quantity = option.value;
        },
        checkIsSelectedTimeslotAvailable() {
            if(this.form.fields.from) {
                const selectedTimeslot = this.filteredTimeslotOptions.find((timeslot) => {
                    return timeslot.value === this.form.fields.from;
                })

                if(selectedTimeslot && selectedTimeslot.disabled) {
                    this.form.fields.from = null;
                    this.toggleAvailabilityIssueModalActive();
                }
            }
        },
        toggleAvailabilityIssueModalActive() {
            this.availabilityIssueModalActive = !this.availabilityIssueModalActive;
        },
        visibleTimeslot(slot) {
            // Hide timeslots with closing status
            return slot.status !== 'closing';
        }
    },
    watch: {
        'form.fields': {
            async handler() {
                await this.loadServiceAvailability();

                this.checkIsSelectedTimeslotAvailable();
            },
            deep: true
        }
    }
};
</script>

<style scoped lang="scss">

.duration-list {
    height: 52px;

    &__item {
        border-top: 2px solid #ced4da;
        border-bottom: 2px solid #ced4da;

        & > div {
            height: 100%;
            width: inherit;
            top: 0;
        }

        &:nth-of-type(1) {
            border-left: 2px solid #ced4da;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        &:nth-of-type(3) {
            border-right: 2px solid #ced4da;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}
</style>
