import DashboardService from './DashboardService';

const service = new DashboardService();

export default {
  key: 'dashboard',
  namespaced: true,
  state: {},
  actions: {
    index ({ commit }, payload) {
      return service.index(payload).then((response) => {
        if (response && response.data && response.data.data) {
          return response.data.data;
        }

        return null;
      });
    }
  },
  mutations: {},
  getters: {},
};
