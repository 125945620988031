<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div v-if="notification" class="container container-extra-small p-4 position-relative">
            <span class="notification-date position-absolute pr-5 has-background-white app-grey">
                {{ moment(notification.createdAt).format('ddd, MMM Do') }}
            </span>
            <p class="text-black has-text-weight-normal notification-message pt-5 ml-0 border-top">{{ notification.data.message }}</p>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

import moment from 'moment';

export default {
    name: "Show",
    components: {},
    computed: {
        notification() {
            return this.notificationId ? this.$store.getters['notification/show'](this.notificationId) : null;
        },
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    async mounted() {
        await this.loadNotification();

        this.readNotification();
    },
    data() {
        return {
            notificationId: null,
            loading: false,
            paginator: null,
            moment
        }
    },
    methods: {
        ...mapActions({
            notificationShow: 'notification/show',
            notificationRead: 'notification/read'
        }),
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        loadNotification() {
            this.loading = true;

            return this.notificationShow({
                id: this.$route.params.notificationUuid
            }).then((response) => {
                this.notificationId = response[0];

                this.updatePageTitle();

            }).finally(() => {
                this.loading = false;
            })
        },
        readNotification() {
            return this.notificationRead({
                id: this.notification.id
            }).then((response) => {
                window.app.vue.$emit(`notificationRead`);
            });
        },
        updatePageTitle() {
            window.app.vue.$emit(`pageTitleUpdate`, this.notification.data.subject);
        }
    }
}
</script>

<style lang="scss">
.notification-date {
    top: 7px;
    font-size: 12px;
    //color: #5B6676;

}
</style>


