<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div v-if="service" class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <h2 class="modal-card-title fs-2">{{ title }} <strong>{{ service.name }}</strong></h2>
                <button
                    type="button"
                    class="delete"
                    @click="handleClose"/>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <div class="label">Customer
                                <template v-if="selectedCustomer">
                                    <a v-if="!isNonScheduledBooking" @click="handleClearCustomer"  class="is-pulled-right"><i class="fas fa-times"></i>  Clear</a>
                                </template>
                                <template v-else>
                                    <a @click="handleCreateCustomer" class="is-pulled-right"><i class="fas fa-plus"></i>  Create new</a>
                                </template>
                            </div>
                            <b-field v-if="!selectedCustomer">
                                <b-autocomplete
                                    v-model="search"
                                    placeholder="Search by id, email or phone number"
                                    :data="filteredCustomers"
                                    field="label"
                                    @select="handleCustomerSelect"
                                    @input="handleCustomerSearch"
                                >
                                </b-autocomplete>
                            </b-field>
                        </div>
                        <div class="field">
                            <template v-if="selectedCustomer">
                                {{ selectedCustomer.name }} <br>
                                {{ selectedCustomer.email }} <br>
                                {{ selectedCustomer.phoneE164 }} <br>
                                <template v-if="activeBooking">
                                    <hr class="mt-1 mb-1">
                                    Booking #{{ activeBooking.number }} <br>
                                    {{ activeBooking.startAt.format("D MMM YY") }} - {{ activeBooking.endAt.format("D MMM YY") }} ({{ activeBooking.nightsCount }} nights) <br>
                                    <template v-if="activeBooking.category">
                                        {{ activeBooking.category.name }} <br>
                                    </template>
                                    {{ activeBooking.totalPersons }} guests <br>
                                </template>
                            </template>
                            <FormError field="customer_id" :form="form"/>
                        </div>
                        <div class="field">
                            <div class="label">Group</div>
                            <b-select placeholder="Select group" v-model="form.fields.adults_number">
                                <option
                                    v-for="option in guestsOptions"
                                    :value="option.value"
                                    :key="option.value">
                                    {{ option.label }}
                                </option>
                            </b-select>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="field">
                                    <div class="label">Date</div>
                                    <b-datepicker
                                        ref="datepicker"
                                        v-model="datepicker"
                                        :locale="undefined"
                                        placeholder="Choose date"
                                        icon="calendar"
                                        :open-on-focus="false"
                                        :date-formatter="formatDatepickerDate"
                                        :first-day-of-week="1"
                                        @input="handleDateChange">
                                    </b-datepicker>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field">
                                    <div class="label">Time</div>
                                    <b-select placeholder="Select timeslot"  v-model="form.fields.from">
                                        <option
                                            v-for="option in timeslotOptions"
                                            :disabled="option.disabled"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                    <div v-if="!enableAllTimeslots">
                                        <button @click="handleEnableAllTimeslots" class="button is-ghost pl-0">Use custom time</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.configuration && service.configuration.flexibleTime" class="field">
                            <div class="label">Session duration</div>
                            <b-select placeholder="Select duration"  v-model="form.fields.quantity">
                                <option
                                    v-for="option in durationOptions"
                                    :value="option.value"
                                    :key="option.value">
                                    {{ option.label }}
                                </option>
                            </b-select>
                        </div>
                        <div class="field">
                            <div class="label">Note</div>
                            <b-input type="textarea" v-model="form.fields.note"></b-input>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <div class="label">Service</div>
                            <p>{{ service ? service.name : ''}}</p>
                        </div>
                        <div class="field">
                            <div class="label">Date & time</div>
                            <p>{{ booking.date }} <span v-if="booking.time">@ {{ booking.time }} </span></p>
                        </div>
                        <div class="field">
                            <div class="label">Duration</div>
                            <p>{{ booking.duration }}</p>
                        </div>
                        <div class="field">
                            <div class="label">Price</div>
                            <p v-if="isNonScheduledBooking">In Package</p>
                            <p v-else>£ {{ booking.price }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot is-justify-content-space-between">
                <b-button
                    label="Close"
                    @click="handleClose" />
                <!--  Free or payment on spot -->
                <template v-if="service.bookFinalStep === 'confirmation' || isNonScheduledBooking ">
                    <b-button
                        :disabled="!isFormReady"
                        label="Book" @click="handleConfirm"
                        type="is-primary" />
                </template>
                <!--  Upfront payment -->
                <template v-else >
                    <div>
                        <!--  If customer has active booking (accommodation) -->
                        <b-button v-if="activeBooking"
                                  :disabled="!isFormReady"
                                  class=""
                                  label="Add to bill" @click="handleAddToBill"
                                  type="is-primary" />
                        <b-button
                            :disabled="!isFormReady"
                            class=""
                            label="Pay" @click="handlePay"
                            type="is-primary" />
                    </div>
                </template>

            </footer>
        </div>

        <b-modal
            v-model="newCustomerModalActive"
            trap-focus
            :destroy-on-hide="true"
            :can-cancel="false"
            aria-role="dialog"
            aria-label="Customer Modal"
            aria-modal>
            <template #default="props">
                <NewCustomerModal @success="handleNewCustomer"  @close="props.close"  />
            </template>
        </b-modal>
    </div>
</template>

<script>
import {
    Form,
} from '@/internal/modules';
import {
    Booking,
} from '@/internal';

import NewCustomerModal from '@/views/Admin/components/NewCustomerModal';
import {mapActions} from "vuex";
import {debounce} from "lodash";
import moment from "moment";
import mixin from "@/mixin";

export default {
    name: 'BookingModal',
    mixins: [mixin],
    components: {
        NewCustomerModal
    },
    props: {
        date: {
           type: Date || null,
           required: false
        },
        service: {
            type: Object || null,
            required: false
        },
        nonScheduledBooking: {
            type: Object || null,
            required: false
        },
        customer: {
            type: Object || null,
            required: false
        }
    },
    computed: {
        title() {
          return this.nonScheduledBooking ? "Schedule" : "Book";
        },
        activeBooking() {
            return this.selectedCustomer?.activeBookingId ? this.$store.getters['booking/show'](this.selectedCustomer.activeBookingId) : null;
        },
        newCustomer() {
            return this.newCustomerId ? this.$store.getters['customer/findBy'](this.newCustomerId, 'id') : null;
        },
        booking() {
            return {
                date: this.form.fields.date ? this.dateFormat(this.form.fields.date) : "",
                time: this.form.fields.from ? this.timeFormatTimezone(this.form.fields.from).format("HH:mm") : "",
                price: this.totalPrice,
                quantity: this.form.fields.quantity,
                group: this.groupFormat(this.form.fields.adults_number, this.form.fields.children_number),
                duration: this.timeFormat(this.form.fields.quantity * this.service.duration)
            }
        },
        filteredCustomers() {
            return this.searchedCustomers.map((customer) => {
                let label = customer.attributes.name;

                if(customer.attributes.origin_name) {
                    label += " ("+customer.attributes.origin_name+")";
                }
                if(customer.attributes.user_id) {
                    label += " - U"
                }

                return {
                    id: customer.id,
                    name: customer.attributes.name,
                    email: customer.attributes.email,
                    phoneE164: customer.attributes.phone_e164,
                    user_id: customer.attributes.user_id,
                    activeBookingId: customer.attributes.active_booking_id,
                    label: label
                }
            })
        },
        timeslotOptions() {
            if (this.enableAllTimeslots) {
                return this.allTimeslotsOptions;
            } else {
                return this.timeslots ? this.timeslots.filter((filterTimeslot) => !['closed'].includes(filterTimeslot.status)).map((mapTimeslot) => {
                    return {
                        label: this.dateFormatTimezone(mapTimeslot.start).format("HH:mm"),
                        value: moment.utc(mapTimeslot.start).format("HH:mm"),
                        disabled: !mapTimeslot.available,
                        status: mapTimeslot.status,
                        selfOwned: mapTimeslot.self_owned,
                    };
                }) : [];
            }
        },
        allTimeslotsOptions() {
            if (!this.service) {
                return;
            }

            let timeslotDuration = 15; // minutes
            let timeslotsCount = 1440 / timeslotDuration; // minutes in one day
            let firstTimeslot = moment.utc().hour(0).minutes(0);
            let timeslots = [{
                label: this.dateFormatTimezone(firstTimeslot).format("HH:mm"), //local zone
                value: firstTimeslot.format("HH:mm") // utc
            }];

            for (let i = 1; i < timeslotsCount; i++) {
                firstTimeslot.add(timeslotDuration, 'minutes');
                timeslots.push({
                    label: this.dateFormatTimezone(firstTimeslot).format("HH:mm"), //local zone
                    value: firstTimeslot.format("HH:mm") // utc
                });
            }

            return timeslots.sort((a, b) => {
                // Extract hours and minutes from the time strings
                const [aHour, aMinute] = a.label.split(":").map(Number);
                const [bHour, bMinute] = b.label.split(":").map(Number);

                // Compare hours first
                if (aHour !== bHour) {
                    return aHour - bHour;
                }

                // If hours are equal, compare minutes
                return aMinute - bMinute;
            });
        },
        durationOptions() {
            let options = [];

            if(this.service && this.service.duration) {
                for(let i = 1; i < 4; i++) {
                    options = [
                        ...options,
                        {
                            label: this.timeFormat(i * this.service.duration),
                            value: i,
                        }
                    ]
                }
            }

            return options;
        },
        guestsOptions() {
            let options = [];

            for(let i = 1; i < 11; i++) {
                options = [
                    ...options,
                    {
                        label: i,
                        value: i,
                    }
                ]
            }

            return options;
        },
        totalPrice() {
            return this.form.fields.quantity * this.form.fields.service_price * this.totalPersons;
        },
        totalPersons() {
            return this.form.fields.adults_number + this.form.fields.children_number;
        },
        isFormReady() {
            return this.form.fields.customer_id && this.form.fields.from;
        }
    },
    data() {
        return {
            form: new Form({
                service_id: null,
                service_type: 'activity',
                service_price: null,
                date: null,
                from: null,
                quantity: 1,
                adults_number: 1,
                children_number: 0,
                customer_id: null,
                note: null,
                origin_platform: Booking.originPlatformBackoffice
            }),
            datepicker: new Date(),
            phoneNumber: null,
            timeslots: [],
            enableAllTimeslots: false,
            loading: false,
            search: null,
            performCustomerSearch: debounce(() => {
                if(!this.search) {
                    this.searchedCustomers = [];
                    return;
                }

                this.loading = true;

                const params = {
                    limit: 10,
                    search: this.search,
                    include: 'activeBooking'
                };

                this.customerSearch({
                    params,
                }).then((response) => {
                    this.searchedCustomers = response;
                }).finally(() => {
                    this.loading = false;
                })
            }, 500),
            searchedCustomers: [],
            selectedCustomer: null,
            newCustomerModalActive: false,
            newCustomerId: null,
            isNonScheduledBooking: false
        };
    },
    mounted() {
        this.fillForm();

        if(this.date) {
            this.loadServiceAvailability();
            this.datepicker = this.date;
        }

        if(this.customer) {
            this.selectedCustomer = this.customer;
        }
    },
    methods: {
        ...mapActions({
            bookingStore: 'booking/store',
            customerSearch: 'customer/search',
            serviceAvailability: 'service/availability',
            servicePricing: 'service/pricing',
            paymentSession: 'payment/session'
        }),
        handlePay() {
            const fields = {
                product_name: this.service.name,
                product_description: `${this.booking.group}, ${this.booking.date} at ${this.booking.time} for ${this.booking.duration}`,
                amount: (this.booking.price * 100),
                cancel_url: process.env.APP_URL + this.$route.fullPath,
                success_url: process.env.APP_URL + this.$route.fullPath,
                origin_platform: this.form.fields.origin_platform,
                metadata: {
                    ...this.form.fields,
                    service_id : this.service.id,
                    service_type: this.service.type,
                    price: this.booking.price,
                }
            }

            this.loading = true;

            this.paymentSession(fields).then((response) => {
                window.location.href = response.url;
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleAddToBill() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
                price: this.booking.price,
                add_to_bill: true // important
            }

            this.bookingStore(fields).then((response) => {
                this.clearForm();
                this.$emit('success');
            }).catch((response) => {
                if(response.response.data && response.response.data.message) {
                    this.showError(response.response.data.message);
                } else if(response.response.data && response.response.data.errors && response.response.data.errors.length) {
                    this.showError(response.response.data.errors[0].detail);
                }
                this.form.recordErrors(response);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleConfirm() {
            this.loading = true;

            this.form.errors.clear();

            this.bookingStore({
                ...this.form.fields,
                price: this.booking.price,
            }).then(() => {
                this.clearForm();
                this.$emit('success');
            }).catch((response) => {
                if(response.response.data && response.response.data.message) {
                    this.showError(response.response.data.message);
                } else if(response.response.data && response.response.data.errors && response.response.data.errors.length) {
                    this.showError(response.response.data.errors[0].detail);
                }
                this.form.recordErrors(response);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleClose() {
            this.clearForm();
            this.$emit('close');
        },
        handleCustomerSearch() {
            this.performCustomerSearch();
        },
        handleCustomerSelect(customer) {
            if(!customer) {
                return;
            }

            this.selectedCustomer = customer;

            setTimeout(() => {
                this.search = null;
            },300);
        },
        handleClearCustomer() {
            this.selectedCustomer = null;
        },
        handleCreateCustomer() {
            this.newCustomerModalActive = true;
        },
        handleNewCustomer(customerId) {
            this.newCustomerId = customerId;
            this.newCustomerModalActive = false;
            this.selectedCustomer = this.newCustomer;
        },
        handleDateChange(){
            this.form.fields.date = this.datepicker ? moment(this.datepicker).format('YYYY-MM-DD') : null;
            this.form.fields.from = null;
            this.loadServiceAvailability();
        },
        handleEnableAllTimeslots() {
            this.enableAllTimeslots = true;
        },
        loadServiceAvailability() {
            this.loading = true;

            const params = {
                type: 'activity',
                ...this.form.fields,
            }

            if(this.nonScheduledBooking) {
                params.non_scheduled_booking = this.nonScheduledBooking.id;
            }

            const data = {
                id: this.service.id,
                params: params
            }

            return this.serviceAvailability(data).then((response) => {
                this.timeslots = response.availability;
                this.isNonScheduledBooking = response.isNonScheduled;
            }).finally(() => {
                this.loading = false;
            });
        },
        loadServicePricing() {
            if(!this.selectedCustomer) {
                this.form.fields.service_price = this.service.price;
                return;
            }

            this.loading = true;

            const payload = {
                id: this.service.id,
                params: {
                    customer_id: this.selectedCustomer.id,
                    origin_platform: Booking.originPlatformBackoffice
                }
            }

            return this.servicePricing(payload).then((response) => {
                this.form.fields.service_price = response.price;
            }).finally(() => {
                this.loading = false;
            });
        },
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        fillForm() {
            this.form.fields = {
                ...this.form.fields,
                service_id: this.service.id,
                service_price: this.service.price,
                date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
                quantity: 1,
                adults_number: 1,
                non_scheduled_booking_id: this.nonScheduledBooking ? this.nonScheduledBooking.id : null
            }
        },
        clearForm() {
            this.form.fields = {
                service_id: null,
                service_type: 'activity',
                service_price: null,
                date: null,
                from: null,
                quantity: 1,
                adults_number: 1,
                children_number: 0,
                customer_id: null
            };
        },
        timeFormat(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            let values = [];

            if(hours) {
                if(hours > 1) {
                    values.push(hours + " hours")
                } else {
                    values.push(hours + " hour")
                }
            }

            if(minutes) {
                values.push(minutes + " minutes")
            }

            return values.join(" ");
        },
        groupFormat(adults, children) {
            let values = [];

            if(adults) {
                if(adults > 1) {
                    values.push(adults + " adults");
                } else {
                    values.push(adults + " adult");
                }
            }

            if(children) {
                if(children > 1) {
                    values.push(children + " children");
                } else {
                    values.push(children + " child");
                }
            }

            return values.join(" ");
        },
        showError(message) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },
        formatDatepickerDate(date) {
            return date ? date.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric"}) : ''
        },
    },
    watch: {
        selectedCustomer () {
            if(this.selectedCustomer) {
                this.form.fields.customer_id = this.selectedCustomer.id;
            } else {
                this.form.fields.customer_id = null
            }

            this.loadServicePricing();
        },
    },
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
