<template>
    <section class="section">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-extra-small">
            <h1 class="text-center">Please wait...</h1>
        </div>
    </section>
</template>

<script>
import {
    mapActions,
} from 'vuex';

export default {
    name: 'LoginCallback',
    components: {},
    computed: {
    },
    created() {
        this.handleLoginCallback();
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            loginCallback: 'auth/loginCallback',
            userShow: 'auth/userShow'
        }),
        handleLoginCallback() {
            this.loading = true;

            window.app.auth.setToken(this.$route.query.token)

            this.userShow().then(() => {
                this.$router.push(window.app.auth.redirectRouteAfterLogin());
                window.app.auth.clearRedirectRoute();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    props: {},
}
</script>

<style scoped lang="scss">

</style>
