import {
    Model,
} from '@/internal/modules';

import {
    Category,
    File,
    ServiceWorkingHour,
    ServiceClosedHour,
    ServiceProduct,
    RelatedService,
    ServiceRule,
    ServiceItem,
    ServiceService as ServiceService,
    ServiceSpace
} from '@/internal';

export default class Service extends Model {

    static get name() {
        return 'Service';
    }

    static get vuexModuleKey() {
        return 'service';
    }

    static get service() {
        return ServiceService;
    }

    static get fields () {
        return {
            name: {
                required: true,
                type: String,
            },
            files: {
                preparePayload: (value) => {
                    return value ? value.map(file => file.id) : [];
                },
                type: File,
            },
            relatedServices: {
                preparePayload: (value) => {
                    return value ? value.map(service => service.id) : [];
                },
                type: RelatedService,
            }
        };
    }

    get categories() {
        return this.hasMany(Category)
    }

    get files() {
        return this.hasMany(File);
    }

    get workingHours() {
        return this.hasMany(ServiceWorkingHour)
    }

    get closedHours() {
        return this.hasMany(ServiceClosedHour)
    }

    get relatedServices() {
        return this.hasMany(RelatedService)
    }

    get serviceProducts() {
        return this.hasMany(ServiceProduct);
    }

    get rules() {
        return this.hasMany(ServiceRule)
    }

    get items() {
      return this.hasMany(ServiceItem)
    }

    get spaces() {
      return this.hasMany(ServiceSpace)
    }

    static get statusOptions() {
        return [
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Published',
                value: 'published',
            }
        ]
    }

    static get guestAvailabilityOptions() {
        return [
            {
                label: 'Any guest',
                value: 'any',
            },
            {
                label: 'Resident only',
                value: 'resident',
            },
            {
                label: 'Non-resident only',
                value: 'non-resident',
            }
        ]
    }

    static get chargingTypeOptions() {
        return [
            {
                label: 'Once',
                value: 'once',
            },
            {
                label: 'Per person',
                value: 'per-person',
            },
            {
                label: 'Per time unit',
                value: 'per-time-unit',
            },
            {
                label: 'Per person per time unit',
                value: 'per-person-per-time-unit',
            },

        ]
    }

    static get paymentTypeOptions() {
        return [
            {
                label: 'Upfront',
                value: 'upfront',
            },
            {
                label: 'On spot',
                value: 'on-spot',
            },
            {
                label: 'Free',
                value: 'free',
            },
        ]
    }

    static get limitationTypeOptions() {
        return [
            {
                label: 'No limitation',
                value: null,
            },
            {
                label: 'Per timeslot',
                value: 'timeslot',
            },
            {
                label: 'Per working hours',
                value: 'working-hours',
            }
        ]
    }

    static get timeslotDurationOptions() {
        return [
            {
                label: '15 minutes',
                value: 15,
            },
            {
                label: '30 minutes',
                value: 30,
            },
            {
                label: '45 minutes',
                value: 45,
            },
            {
                label: '60 minutes',
                value: 60,
            },
            {
                label: '1 hour 30 minutes',
                value: 90,
            },
            {
                label: '2 hours',
                value: 120,
            },

        ]
    }

    static get bookingIntervalOptions() {
        return [
            {
                label: 'No interval',
                value: null,
            },
            {
                label: '15 minutes',
                value: 15,
            },
            {
                label: '30 minutes',
                value: 30,
            },
            {
                label: '45 minutes',
                value: 45,
            },
            {
                label: '60 minutes',
                value: 60,
            },
            {
                label: '1 hour 30 minutes',
                value: 90,
            },
            {
                label: '2 hours',
                value: 120,
            },

        ]
    }

    static get typeAccommodation() {
        return 'accommodation';
    }

    static get typeActivity() {
        return 'activity';
    }

  static get subtypeOptions() {
    return [
      {
        label: 'N/A',
        value: null,
      },
      {
        label: 'Restaurant',
        value: 'restaurant',
      }
    ]
  }
}
