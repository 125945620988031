var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_vm._v(" "),_c('div',{staticClass:"app-grey has-text-weight-semibold py-2"},[_c('span',{staticClass:"is-clickable",on:{"click":_vm.previousMonth}},[_c('i',{staticClass:"fa-solid fa-chevron-left"})]),_vm._v(" "),_c('span',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.currentDate))]),_vm._v(" "),_c('span',{staticClass:"is-clickable",on:{"click":_vm.nextMonth}},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])]),_vm._v(" "),_c('div',{ref:"container",staticClass:"box horizontal-calendar hide-scrollbar px-0 py-2 is-shadowless mb-3 is-inline-block w-100",attrs:{"id":"calendar-container"}},_vm._l((_vm.days),function(day,index){return _c('div',{staticClass:"is-inline-block has-text-centered horizontal-calendar-day",class:[
                 {'horizontal-calendar-day-grey-light is-clickable app-grey' : _vm.activeBooking && _vm.isDayInBooking(day)},
                 {'is-disabled': _vm.activeBooking && !_vm.isDayInBooking(day) },
                 {'left-border-radius': _vm.activeBooking && _vm.isStartOfBooking(day)},
                 {'right-border-radius': _vm.activeBooking && _vm.isEndOfBooking(day)}
             ],attrs:{"id":'date-' + _vm.moment(day).format('YYYY-MM-DD')}},[_c('div',{staticClass:"horizontal-calendar-day-content is-clickable",class:[
                     {'has-text-white has-background-black' :
                         _vm.moment(_vm.selectedDay).format('DD-MM-YYYY') === _vm.moment(day).format('DD-MM-YYYY')
                     },
                     {'has-border' : _vm.moment(day).format('DD-MM-YYYY') === _vm.moment().format('DD-MM-YYYY')},
                 ],on:{"click":function($event){return _vm.selectDate(day)}}},[_c('div',{staticClass:"fs-12 is-uppercase mt-1"},[_vm._v(_vm._s(_vm.weekDay[_vm.moment(day).day()]))]),_vm._v(" "),_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.moment(day).date()))])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }