import Dashboard from "@/views/Admin/Dashboard";
import Login from "@/views/Admin/Login";

import UserIndex from "@/views/Admin/User/Index";
import UserForm from "@/views/Admin/User/Form";
import UserAccount from "@/views/Admin/User/Account";

import BookingIndex from "@/views/Admin/Booking/Index";
import BookingForm from "@/views/Admin/Booking/Form";

import OrderIndex from "@/views/Admin/Order/Index";
import OrderForm from "@/views/Admin/Order/Form";

import AccountIntegrationIndex from "@/views/Admin/AccountIntegration/Index";
import AccountIntegrationForm from "@/views/Admin/AccountIntegration/Form";

import AnnouncementIndex from "@/views/Admin/Announcement/Index";
import AnnouncementForm from "@/views/Admin/Announcement/Form";

import CustomerIndex from "@/views/Admin/Customer/Index";
import CustomerForm from "@/views/Admin/Customer/Form";
import CustomerActivityLog from "@/views/Admin/Customer/ActivityLog";

import ClosedHourIndex from "@/views/Admin/ClosedHour/Index";
import ClosedHourForm from "@/views/Admin/ClosedHour/Form";

import IntegrationLogIndex from "@/views/Admin/IntegrationLog/Index";
import IntegrationLogShow from "@/views/Admin/IntegrationLog/Show";

import InformationIndex from "@/views/Admin/Information/Index";
import InformationForm from "@/views/Admin/Information/Form";

import ServiceIndex from "@/views/Admin/Service/Index";
import ServiceForm from "@/views/Admin/Service/Form";
import ServiceWorkingHours from "@/views/Admin/Service/WorkingHours";
import ServiceCalendar from "@/views/Admin/Service/Calendar";
import ServiceRules from "@/views/Admin/Service/Rules";
import ServiceEmail from "@/views/Admin/Service/Email";
import ServiceItems from "@/views/Admin/Service/Items";

import CategoryIndex from "@/views/Admin/Category/Index";
import CategoryForm from "@/views/Admin/Category/Form";

import ProductIndex from "@/views/Admin/Product/Index";
import ProductForm from "@/views/Admin/Product/Form";

const routes = [
    {
        name: 'admin/login',
        path: '/admin/login',
        component: Login
    },

    {
        name: 'admin/dashboard',
        path: '/admin/dashboard',
        component: Dashboard
    },

    // ANNOUNCEMENT
    {
        name: 'admin/announcement',
        path: '/admin/announcement',
        component: AnnouncementIndex
    },
    {
        name: 'admin/announcement/create',
        path: '/admin/announcement/create',
        component: AnnouncementForm
    },
    {
        name: 'admin/announcement/update',
        path: '/admin/announcement/:announcementId/update',
        component: AnnouncementForm
    },

    // USER

    {
        name: 'admin/user',
        path: '/admin/user',
        component: UserIndex
    },
    {
        name: 'admin/user/create',
        path: '/admin/user/create',
        component: UserForm
    },
    {
        name: 'admin/user/update',
        path: '/admin/user/:userId/update',
        component: UserForm
    },
    {
        name: 'admin/user/account',
        path: '/admin/user/account',
        component: UserAccount
    },

    // BOOKING
    {
        name: 'admin/booking',
        path: '/admin/booking',
        component: BookingIndex,
        meta: {
            title: 'Bookings'
        },
    },
    {
        name: 'admin/booking/update',
        path: '/admin/booking/:bookingId/update',
        component: BookingForm
    },

    // ORDER
    {
        name: 'admin/order',
        path: '/admin/order',
        component: OrderIndex,
        meta: {
            title: 'Orders'
        },
    },
    {
        name: 'admin/order/update',
        path: '/admin/order/:orderId/update',
        component: OrderForm
    },

 	// INTEGRATION
    {
        name: 'admin/account-integration',
        path: '/admin/account-integration',
        component: AccountIntegrationIndex
    },
    {
        name: 'admin/account-integration/update',
        path: '/admin/account-integration/:accountIntegrationId/update',
        component: AccountIntegrationForm
    },

    // INTEGRATION LOG
    {
        name: 'admin/integration-log',
        path: '/admin/integration-log',
        component: IntegrationLogIndex
    },
    {
        name: 'admin/integration-log/show',
        path: '/admin/integration-log/:integrationLogId',
        component: IntegrationLogShow
    },

    // INFORMATION
    {
        name: 'admin/information',
        path: '/admin/information',
        component: InformationIndex
    },
    {
        name: 'admin/information/create',
        path: '/admin/information/create',
        component: InformationForm
    },
    {
        name: 'admin/information/update',
        path: '/admin/information/:informationId/update',
        component: InformationForm
    },

    // CUSTOMER
    {
        name: 'admin/customer',
        path: '/admin/customer',
        component: CustomerIndex,
        meta: {
            title: 'Customers'
        },
    },
    {
        name: 'admin/customer/create',
        path: '/admin/customer/create',
        component: CustomerForm
    },
    {
        name: 'admin/customer/update',
        path: '/admin/customer/:customerId/update',
        component: CustomerForm
    },
    {
        name:'admin/customer/activity-log',
        path:'/admin/customer/:customerId/activity-log',
        component: CustomerActivityLog
    },

    // CLOSED HOURS

    {
        name: 'admin/closed-hour',
        path: '/admin/closed-hour',
        component: ClosedHourIndex
    },
    {
        name: 'admin/closed-hour/create',
        path: '/admin/closed-hour/create',
        component: ClosedHourForm
    },
    {
        name: 'admin/closed-hour/update',
        path: '/admin/closed-hour/:closedHourId/update',
        component: ClosedHourForm
    },

    // SERVICE
    {
        name: 'admin/service',
        path: '/admin/service',
        component: ServiceIndex
    },
    {
        name: 'admin/service/create',
        path: '/admin/service/create',
        component: ServiceForm
    },
    {
        name: 'admin/service/update',
        path: '/admin/service/:serviceId/update',
        component: ServiceForm
    },
    {
        name: 'admin/service/working-hours',
        path: '/admin/service/:serviceId/working-hours',
        component: ServiceWorkingHours
    },
    {
        name: 'admin/service/calendar',
        path: '/admin/service/:serviceId/calendar',
        component: ServiceCalendar
    },
    {
        name: 'admin/service/rules',
        path: '/admin/service/:serviceId/rules',
        component: ServiceRules
    },
    {
      name: 'admin/service/email',
      path: '/admin/service/:serviceId/email',
      component: ServiceEmail
    },
    {
      name: 'admin/service/items',
      path: '/admin/service/:serviceId/items',
      component: ServiceItems
    },
    // CATEGORY
    {
        name: 'admin/category',
        path: '/admin/category',
        component: CategoryIndex
    },
    {
        name: 'admin/category/create',
        path: '/admin/category/create',
        component: CategoryForm
    },
    {
        name: 'admin/category/update',
        path: '/admin/category/:categoryId/update',
        component: CategoryForm
    },

    // PRODUCT
    {
        name: 'admin/product',
        path: '/admin/product',
        component: ProductIndex
    },
    {
        name: 'admin/product/create',
        path: '/admin/product/create',
        component: ProductForm
    },
    {
        name: 'admin/product/update',
        path: '/admin/product/:productId/update',
        component: ProductForm
    },
];

export default routes;
