<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/customer">Customers</router-link>
                            </li>
                            <li>
                                {{ customer ? customer.name : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <template v-if="customer">
                        <i v-if="customer.userId" class="fa-solid fa-user mr-2" title="Has user account"></i>
                        <i v-if="customer.activeBookingId" class="fa-solid fa-house-chimney-user" title="Resident"></i>
                    </template>
                </div>
            </div>
        </section>
        <section class="section">

            <FormMenu />

            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <BField label="First Name *" >
                                            <BInput type="text" v-model="form.fields.firstName">
                                            </BInput>
                                        </BField>
                                        <FormError field="first_name" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <BField label="Last Name *" >
                                            <BInput type="text" v-model="form.fields.lastName">
                                            </BInput>
                                        </BField>
                                        <FormError field="last_name" :form="form"/>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <BField label="Email *" >
                                            <BInput type="email" v-model="form.fields.email">
                                            </BInput>
                                        </BField>
                                        <FormError field="email" :form="form"/>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <BField class="mb-2" label="Phone *">
                                            <div class="phone-input">
                                                <div class="w-100">
                                                    <div class="column px-0">
                                                        <MazPhoneNumberInput
                                                            v-model="phoneNumber"
                                                            @update="handlePhoneNumberUpdate"
                                                            :default-phone-number="form.fields.phone ? customer.phone : ''"
                                                            :default-country-code="defaultCountryCode"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </BField>
                                        <FormError field="phone" :form="form"/>
                                    </div>
                                </div>
                            </div>

                            <template v-if="customer">

                                <div class="field">
                                    <div class="label">Active booking</div>
                                    <template v-if="activeBooking">
                                        Booking #{{ activeBooking.number }} <br>
                                        {{ activeBooking.startAt.format("D MMM YY") }} - {{ activeBooking.endAt.format("D MMM YY") }} <br>
                                        <template v-if="activeBooking.category">
                                            {{ activeBooking.category.name }} <br>
                                        </template>
                                        {{ activeBooking.totalPersons }} guests <br>
                                        {{ activeBooking.nightsCount }} nights
                                    </template>
                                    <template v-else>
                                        No active booking
                                    </template>
                                </div>

                                <div class="field">
                                    <div class="label">Pending bookings</div>
                                    <template v-if="nonScheduledBookings.length">
                                        <div class="table-container">
                                            <table class="table is-striped">
                                                <tr v-for="booking in nonScheduledBookings">
                                                    <td class="pl-0">#{{ booking.id }}</td>
                                                    <td>{{ booking.serviceName }}</td>
                                                    <td>{{ booking.status }}</td>
                                                    <td>
                                                        <template v-if="booking.scheduledBookingId">
                                                            <router-link :to="`/admin/booking/${booking.scheduledBookingId}/update`">
                                                                Booking #{{booking.scheduledBookingNumber}}
                                                            </router-link>
                                                        </template>
                                                        <template v-else>
                                                            <button class="button is-warning" @click="handleSchedulePendingBooking(booking)">Schedule</button>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        No non-scheduled booking
                                    </template>
                                </div>

                                <div class="field">
                                    <div class="label">Activity bookings</div>
                                    <template v-if="activityBookings.length">
                                        <div class="table-container">
                                            <table class="table is-striped">
                                                <tr v-for="booking in activityBookings">
                                                    <td class="pl-0">#{{ booking.number }}</td>
                                                    <td>{{ booking.service && booking.service.name }}</td>
                                                    <td>{{ booking.startAt.format('dddd, DD-MM-YYYY') }}</td>
                                                    <td>{{ timeFormat(booking.startAt) }} - {{ timeFormat(booking.endAt) }}</td>
                                                    <td>{{ booking.status }}</td>
                                                    <td>
                                                        <button class="button" @click="handleBookingManage(booking)">Manage</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        No bookings
                                    </template>
                                </div>

                                <div class="field">
                                    <div class="label">Accommodation bookings</div>
                                    <template v-if="accommodationBookings.length">
                                        <div class="table-container">
                                            <table class="table is-striped">
                                                <tr v-for="booking in accommodationBookings">
                                                    <td class="pl-0">#{{ booking.number }}</td>
                                                    <td>
                                                        {{ booking.service && booking.service.name }}
                                                        <div v-if="booking.category" class="has-text-weight-normal mb-1">
                                                             ({{ booking.category.name }})
                                                        </div>
                                                    </td>
                                                    <td>{{ booking.totalPersons }} guests</td>
                                                    <td>{{ booking.startAt.format("D MMM YY") }}</td>
                                                    <td>{{ booking.endAt.format("D MMM YY") }}</td>
                                                    <td>{{ booking.status }}</td>
                                                    <td>
                                                        <button class="button" @click="handleBookingManage(booking)">Manage</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        No bookings
                                    </template>
                                </div>

                                <div class="field">
                                    <div class="label">Orders</div>
                                    <template v-if="orders.length">
                                        <div class="table-container">
                                            <table class="table is-striped">
                                                <tr v-for="order in orders">
                                                    <td class="pl-0">#{{ order.reference }}</td>
                                                    <td>
                                                        <template v-for="orderProduct in order.orderProducts">
                                                            {{ orderProduct.productName }} x {{ orderProduct.quantity }}
                                                        </template>
                                                    </td>
                                                    <td>£ {{ order.price }}</td>
                                                    <td>{{ order.originPlatformDescription }}</td>
                                                    <td>{{ order.status }}</td>
                                                    <td>{{ order.createdAt.format('dddd, DD-MM-YYYY')}} {{ timeFormat(order.createdAt) }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>
                                    <template v-else>
                                        No orders
                                    </template>
                                </div>

                                <div class="field">
                                    <div class="label">User account</div>
                                    <template v-if="customer.user">
                                        User #{{customer.user.id}}<br>
                                        {{ customer.user.name }} <br>
                                        {{ customer.user.email }} <br>
                                        <b-button @click="handleLoginAs(customer.user)">
                                            Login As
                                        </b-button>
                                    </template>
                                    <template v-else>
                                        No user account
                                    </template>
                                </div>

                                <div v-if="form.fields.originId" class="field">
                                    <BField label="Origin" >
                                        {{ form.fields.originName }} ( {{ form.fields.originId }} )
                                    </BField>
                                </div>

                            </template>

                            <div v-if="!form.fields.id" class="field">
                                <BField label="User account" >
                                    <b-checkbox v-model="form.fields.createUserAccount"
                                                :native-value="false">
                                        Create user account
                                    </b-checkbox>
                                </BField>
                            </div>

                            <hr>

                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" >Save</b-button>
                                <b-button outlined v-if="customer && customer.userId" type="is-info" @click="handleExportData" >Export</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            v-model="bookingModalActive"
            trap-focus
            :destroy-on-hide="true"
            :can-cancel="false"
            aria-role="dialog"
            aria-label="Booking Modal"
            aria-modal>
            <template #default="props">
                <BookingModal @success="handleBookingSuccess"
                              :date="activeBooking ? activeBooking.startAt.toDate() : null"
                              :service="bookingToSchedule ? bookingToSchedule.service : null"
                              :nonScheduledBooking="bookingToSchedule"
                              :customer="customer"
                              @close="props.close"  />
            </template>
        </b-modal>

    </div>
</template>

<script>
import {mapActions} from 'vuex';
import { MazPhoneNumberInput } from 'maz-ui'
import mixin from "@/mixin";
import {
    Form,
} from '@/internal/modules'
import FormMenu from '@/views/Admin/Customer/FormMenu';
import BookingModal from '@/views/Admin/components/BookingModal';
import Vue from 'vue';

export default {
    name: 'Form',
    mixins: [mixin],
    components: {
        MazPhoneNumberInput,
        FormMenu,
        BookingModal
    },
    computed: {
        bookings(){
          return this.bookingIds.length ? this.$store.getters['booking/collection'](this.bookingIds) : [];
        },
        activityBookings(){
            return this.bookings.filter((booking) => {
                return booking.type === 'activity';
            }).sort((a,b) => {
                return new Date(a.startAt) - new Date(b.startAt);
            });
        },
        accommodationBookings(){
            return this.bookings.filter((booking) => {
                return booking.type === 'accommodation';
            });
        },
        orders() {
          return this.customer ? this.customer.orders : [];
        },
        activeBooking() {
            return this.activeBookingId ? this.$store.getters['booking/show'](this.activeBookingId) : null;
        },
        nonScheduledBookings() {
            return this.activeBooking ? this.activeBooking.nonScheduledBookings : [];
        },
        customer() {
            return this.customerId ? this.$store.getters['customer/show'](this.customerId) : null;
        },
        countries() {
            return this.$store.getters['country/all'];
        },
        defaultCountryCode() {
            if(this.customer) {
                const code = this.countries.find(country => {
                    const code = country.dialCode.substring(1);
                    if (country.preferred) {
                        return this.customer.phoneCountryCode === code && country.preferred;
                    } else {
                        return this.customer.phoneCountryCode === code
                    }
                });
                return code ? code.code : 'US'
            } else {
                return 'US';
            }
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        services() {
            return this.serviceIds ? this.$store.getters['service/collection'](this.serviceIds) : [];
        },
        customerActiveBookingId() {
          if (this.customer.activeBookingId) {
            return this.customer.activeBookingId;
          } else if (this.customer.user && this.customer.user.activeBookingId) {
            return this.customer.user.activeBookingId;
          } else {
            return null;
          }
        }
    },
    async mounted() {
        if(this.$route.params.customerId) {
            await this.loadCustomer();
            this.loadBookings();

            if(this.customerActiveBookingId) {
                this.loadActiveBooking();
                this.loadServices();
            }
        }

        this.fillForm();
    },
    data() {
        return {
            form: new Form({
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            }),
            bookingIds: [],
            phoneNumber: null,
            loading: false,
            customerId: null,
            serviceIds: [],
            activeBookingId: null,
            bookingModalActive: false,
            bookingToSchedule: null

        };
    },
    methods: {
        ...mapActions({
            customerDestroy: 'customer/destroy',
            customerStore: 'customer/store',
            customerUpdate: 'customer/update',
            customerShow: 'customer/show',
            customerExportData: 'customer/exportData',
            bookingIndex: 'booking/index',
            bookingShow: 'booking/show',
            serviceIndex: 'service/index',
            userLoginUrl: 'user/loginUrl'
        }),
        async loadData() {
            await this.loadCustomer();
            this.loadBookings();

            if(this.customerActiveBookingId) {
                this.loadActiveBooking();
                this.loadServices();
            }
        },
        getActiveBookingId() {
           if (this.customer.activeBookingId) {
             return this.customer.activeBookingId;
           } else if (this.customer.user && this.customer.user.activeBookingId) {
             return this.customer.user.activeBookingId;
           }
           return null;
        },
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            let action = null;

            if (this.customer) {
                action = this.customerUpdate;
            } else {
                action = this.customerStore;
            }

            action({
                ...this.form.fields,
            }).then(() => {
                window.app.snackbar({
                    message: 'Saved successfully!',
                });
                this.$router.push('/admin/customer');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete () {
            window.app.confirm({
                title: 'Delete Customer',
                message: 'Are you sure you want to delete this customer?',
                onConfirm: () => {
                    this.loading = true;
                    this.customerDestroy({
                        id: this.customer.id,
                    }).then(() => {
                        window.app.snackbar('Customer Deleted');
                        this.$router.push('/admin/customer');
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        handleExportData() {
            this.loading = false;

            return this.customerExportData(this.customer.id).then((data) => {
                let fileName = this.customer.id + '_data.json';
                let fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
                let fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
            }).catch((error) => {
                window.app.snackbar({
                    message:error.response.data.message,
                    type: 'is-danger',
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        handlePhoneNumberUpdate(event) {
            if(!event.isValid) {
                return;
            }
            this.form.fields.phone = event.phoneNumber;
            this.form.fields.phoneCountryCode = event.countryCallingCode;
        },
        loadCustomer() {
            this.loading = true;

            return this.customerShow({
                id: this.$route.params.customerId,
                params: {
                    include: ['user', 'activeBooking.nonScheduledBookings', 'orders', 'orders.orderProducts']
                }
            }).then((response) => {
                this.customerId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        loadBookings() {
            this.loading = true;

            const params = {
                customerId: this.customer.id,
                perPage: 50
            };

            this.bookingIndex({
                params,
            }).then((response) => {
                this.bookingIds = response['ids'];
            }).finally(() => {
                this.loading = false;
            });
        },
        loadServices() {
            this.loading = true;

            this.serviceIndex({}).then((response) => {
                this.serviceIds = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        loadActiveBooking() {
            this.loading = true;

            return this.bookingShow({
                id: this.customerActiveBookingId,
                params: {
                    include: ['nonScheduledBookings']
                }
            }).then((response) => {
                this.activeBookingId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleBookingManage(booking) {
            const route = window.app.findRouteByName('admin/booking/update', [], {
                params: {
                    bookingId: booking.id
                },
            });

            this.$router.push(route);
        },
        handleSchedulePendingBooking(booking) {
            this.bookingToSchedule = booking;
            this.bookingToSchedule.service = this.services.find(service => parseInt(service.id) === parseInt(booking.serviceId));
            this.bookingModalActive = true;
        },
        handleBookingSuccess() {
            this.bookingModalActive = false;

            Vue.nextTick(()=> {
                this.bookingToSchedule = null;
                this.loadCustomer();
                this.loadBookings();
            });
        },
        handleLoginAs(user) {
            this.loading = true;

            this.userLoginUrl(user.id).then((response) => {
                window.location.href = response.url;
            }).finally(() => {
                this.loading = false;
            });
        },
        fillForm() {
            if(this.customer) {
                this.form.fields = {
                    ...this.customer
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">

</style>
