import store from '@/store';

export default (to, from, next) => {

  const authenticated = store.state.auth.authenticated;
  const path = window.app.auth.redirectRouteAfterLogin().path;

  if (authenticated && to.path !== path) {

    if(to.meta.allowAuthenticated) {
        return next();
    }

    window.app.d(`[guestMiddleware] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
