<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <template slot="header">
      <div class="flex:1 ml:16 f:20 fw:xbold">
        {{ integration.name }}
      </div>
    </template>
    <div v-if="integration" class="p:24">
      <label class="--label flex:|center gap:8 mb:32 f:16">
        <input class="--checkbox" type="checkbox" v-model="form.fields.enabled" />
        Enabled
      </label>

      <div class="section-title mb:8">
        <Icon admin name="book" />
        Bookings
      </div>

      <div class="flex:|center gap:16 mb:32">
        <div class="flex:1">
          <CalendarInput v-model="syncBookingsForm.fields.startDate" />
        </div>
        <div class="flex:1">
          <CalendarInput v-model="syncBookingsForm.fields.endDate" position="is-bottom-left" />
        </div>
      </div>
      <button
        class="reset flex:|center gap:8 f:16 semi c:white bg:primary p:8|12 r:8 o:0.9:hover mb:32"
        @click="handleSyncBookings"
      >
        <Icon admin name="reload" />
        Sync Bookings
      </button>

      <div class="section-title mb:8">
        <Icon admin name="cog" />
        Resources
      </div>

      <button
        class="reset flex:|center gap:8 f:16 semi c:white bg:primary p:8|12 r:8 o:0.9:hover"
        @click="handleSyncResources"
      >
        <Icon admin name="reload" />
        Sync Resources
      </button>
    </div>

    <template slot="footer">
      <button
        class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave"
      >
        <Icon admin name="check" />
        Save
      </button>
    </template>
  </SideDrawer>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

import { Form } from '@/internal/modules'

import { AccountIntegration } from '@/internal'

import SideDrawer from '@/components/admin/SideDrawer.vue'
import CalendarInput from '@/components/admin/CalendarInput.vue'

export default {
  name: 'Form',
  components: {
    AccountIntegration,
    SideDrawer,
    CalendarInput,
  },
  computed: {
    integration() {
      return this.$route.query.show ? this.$store.getters['accountIntegration/show'](this.$route.query.show) : null
    },
  },
  mounted() {
    if (this.integration) {
      this.fillForm()
    }
  },
  data() {
    return {
      form: new Form({
        enabled: true,
      }),
      syncBookingsForm: new Form({
        startDate: new Date(),
        endDate: null,
      }),
      loading: false,
      datePickerLocale: undefined,
    }
  },
  methods: {
    ...mapActions({
      accountIntegrationUpdate: 'accountIntegration/update',
      syncBookings: 'accountIntegration/syncBookings',
      syncResources: 'accountIntegration/syncResources',
    }),
    handleSave() {
      const fields = {
        ...this.form.fields,
      }

      this.loading = true
      this.form.errors.clear()

      let action = null

      action = this.accountIntegrationUpdate

      action(AccountIntegration.preparePayload(fields))
        .then(() => {
          window.app.snackbar({
            message: 'Update successfully!',
          })
          this.$router.push('/admin/account-integration')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSyncBookings() {
      this.loading = true

      const payload = {
        id: this.integration.id,
        startDate: this.syncBookingsForm.fields.startDate
          ? moment(this.syncBookingsForm.fields.startDate).format('YYYY-MM-DD')
          : null,
        endDate: this.syncBookingsForm.fields.endDate
          ? moment(this.syncBookingsForm.fields.endDate).format('YYYY-MM-DD')
          : null,
      }

      console.log(payload)

      this.syncBookings(payload)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSyncResources() {
      this.loading = true

      this.syncResources({ id: this.integration.id })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fillForm() {
      this.form.fields = {
        ...this.integration,
        lastUpdateSyncedAt: this.integration.lastUpdateSyncedAt ? new Date(this.integration.lastUpdateSyncedAt) : null,
      }
    },
  },
  watch: {
    integration() {
      if (this.integration) {
        this.fillForm()
      }
    },
  },
}
</script>
