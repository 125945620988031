import { ResourceService } from '@/internal/modules';

export default class OrderService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'order';
    }

    statusUpdate(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/status-update`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }
}
