<template>
    <p class="help is-danger mt-0" v-if="error">
        {{error}}
    </p>
</template>

<script>
export default {
    name: 'FormError',
    computed: {
        error() {
            return this.form.errors.has(this.field) ? this.form.errors.get(this.field) : false;
        },
    },
    created() {

    },
    data() {
        return {};
    },
    props: {
        field: {
            required: true,
            type: String,
        },
        form: {
            required: true,
            type: Object,
        },
    },
}
</script>

<style scoped>

</style>
