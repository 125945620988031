import {
    Model,
} from '@/internal/modules';

import {
    ClosedHourService as ClosedHourService
} from '@/internal';

export default class ClosedHour extends Model {
    static get name() {
        return 'ClosedHour';
    }
    static get vuexModuleKey() {
        return 'closedHour';
    }
    static get service() {
        return ClosedHourService;
    }

    static get fields () {
        return {};
    }

}
