import {
    ServiceWorkingHour as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';


const store = new ResourceStore({
    key: 'serviceWorkingHour',
    model: Model,
});

export default store.toObject();
