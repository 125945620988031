<template>
    <div>
        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/announcement">News</router-link>
                            </li>
                            <li>
                                {{ announcement ? announcement.title : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-field label="Title *" >
                                    <b-input type="text" v-model="form.fields.title">
                                    </b-input>
                                </b-field>
                                <FormError field="title" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Content" >
                                    <b-input type="textarea" v-model="form.fields.content">
                                    </b-input>
                                </b-field>
                                <FormError field="content" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Status">
                                    <b-select placeholder="Select status" v-model="form.fields.status">
                                        <option
                                            v-for="option in statusOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Guest visibility">
                                    <b-select placeholder="Select visibility" v-model="form.fields.guestVisibility">
                                        <option
                                            v-for="option in guestVisibilityOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="guest_visibility" :form="form"/>
                            </div>
                            <div class="field">
                                <label class="label">Image</label>
                                <FileUpload v-model="form.fields.files"
                                            :entity="announcement"
                                            :multiple="false" :extensions="['jpg','jpeg','png']"></FileUpload>
                            </div>
                            <hr/>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Announcement,
} from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload';

export default {
    name: 'Form',
    components: {
        FileUpload,
    },
    computed: {
        announcement() {
            return this.$route.params.announcementId ? this.$store.getters['announcement/show'](this.$route.params.announcementId) : null;
        },
    },
    created() {
        if(this.announcement) {
            this.form.fields = {
                ...this.announcement,
                files: this.announcement.files ? this.announcement.files : []
            }
        }
    },
    data() {
        return {
            form: new Form({
                title: '',
                content: '',
                status: 'draft',
                files: [],
            }),
            loading: false,
            statusOptions: Announcement.statusOptions,
            guestVisibilityOptions: Announcement.guestVisibilityOptions,
        };
    },
    methods: {
        ...mapActions({
            announcementDestroy: 'announcement/destroy',
            announcementStore: 'announcement/store',
            announcementUpdate: 'announcement/update',
            announcementIndex: 'announcement/index'
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
              ...this.form.fields
            };

            let action = null;

            if (this.announcement) {
                action = this.announcementUpdate;
            } else {
                action = this.announcementStore;
            }

            action(Announcement.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/announcement');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete News?',
                message: 'Are you sure you want to delete this news?',
                onConfirm: () => {
                    this.loading = true;
                    this.announcementDestroy({
                        id: this.announcement.id,
                    }).then(() => {
                        window.app.snackbar('News Deleted');

                        this.$router.push('/admin/announcement');

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        }
    },
    watch: {
        announcement() {
            if(this.announcement) {
                this.form.fields = {
                    ...this.announcement,
                    files: this.announcement.files ? this.announcement.files : [],
                }
            }
        },
    }
};
</script>

<style lang="scss">


</style>
