import Home from '@/views/Home';
import ServiceActivityBook from '@/views/Service/BookActivity';
import ServiceActivityBookCheckout from '@/views/Service/BookActivityCheckout';
import ServiceActivityBookConfirmation from '@/views/Service/BookActivityConfirmation';
import ServiceAccommodationBook from '@/views/Service/BookAccommodation';
import ServiceAccommodationShow from '@/views/Service/ShowAccommodation';
import BookingConfirmation from '@/views/Booking/Confirmation';
import BookingCancellation from '@/views/Booking/Cancellation';
import BookingEdit  from '@/views/Booking/Edit';
import BookingReschedule  from '@/views/Booking/Reschedule';
import Settings from '@/views/Settings/Index';
import BookingActive from '@/views/Booking/Active';
import CalendarIndex from "@/views/Calendar/Index";
import ProductsIndex from '@/views/Products/Index';
import ProductsShow from '@/views/Products/Show';
import ProductsCheckout from '@/views/Products/Checkout';
import OrderConfirmation from '@/views/Order/Confirmation';
import CalendarAdd from "@/views/Calendar/Add";
import Booking from '@/views/Settings/Booking';
import Profile from '@/views/Settings/Profile';
import Notifications from '@/views/Settings/Notifications';
import Data from '@/views/Settings/Data';
import NotificationIndex from "@/views/Notification/Index";
import NotificationShow from "@/views/Notification/Show";
import router from "../index";


const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            title: 'Home',
            showNavbar: false,
            showMainMenu: true,
            showLeftIcon: false,
        },
    },
    // Settings
    {
        name: 'user/settings',
        path: '/user/settings',
        component: Settings,
        meta: {
            title: 'Account',
            showNavbar: true,
            showMainMenu: true,
            showLeftIcon: false,
        },
    },
    {
        name: 'user/settings/booking',
        path: '/user/settings/booking',
        component: Booking,
        meta: {
            title: 'Booking summary',
            middleware: ['auth'],
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    {
        name: 'user/settings/profile',
        path: '/user/settings/profile',
        component: Profile,
        meta: {
            title: 'Account Settings',
            middleware: ['auth'],
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    {
        name: 'user/settings/notifications',
        path: '/user/settings/notifications',
        component: Notifications,
        meta: {
            title: 'Notification settings',
            middleware: ['auth'],
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    {
        name: 'user/settings/data',
        path: '/user/settings/data',
        component: Data,
        meta: {
            title: 'My data',
            middleware: ['auth'],
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    // Create activity booking
    {
        name: 'service/activity/book',
        path: '/service/activity/:serviceUuid/book',
        component: ServiceActivityBook,
        meta: {
            title: 'BOOK ACTIVITY',
            showNavbar: false,
            showMainMenu: false,
        }
    },
    // Activity booking checkout
    {
        name: 'service/activity/checkout',
        path: '/service/activity/checkout',
        component: ServiceActivityBookCheckout,
        meta: {
            title: 'Finalize your booking',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        }
    },
    // Activity booking confirmation
    {
        name: 'service/activity/confirmation',
        path: '/service/activity/confirmation',
        component: ServiceActivityBookConfirmation,
        meta: {
            title: 'Finalize your booking',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        }
    },
    // Create accommodation booking
    {
        name: 'service/accommodation/book',
        path: '/service/accommodation/:serviceUuid/book',
        component: ServiceAccommodationBook,
        meta: {
            title: 'ACCOMMODATION',
            showNavbar: false,
            showMainMenu: false
        }
    },
    // Show accommodation service
    {
        name: 'service/accommodation/show',
        path: '/service/accommodation/:serviceUuid/show',
        component: ServiceAccommodationShow,
        meta: {
            title: 'ACCOMMODATION',
            showMainMenu: false
        }
    },
    // Booking confirmation (general)
    {
        name: 'booking/confirmation',
        path: '/booking/confirmation',
        component: BookingConfirmation,
        meta: {
            title: 'CONFIRMED',
            showNavbar: false,
            showMainMenu: false
        }
    },
    // Booking cancellation
    {
        name: 'booking/cancellation',
        path: '/booking/cancellation',
        component: BookingCancellation,
        meta: {
            title: 'CANCELLED',
            showNavbar: false,
            showMainMenu: false
        }
    },
    // Booking edit
    {
        name: 'booking/edit',
        path: '/booking/:bookingUuid/edit',
        component: BookingEdit,
        meta: {
            title: 'Edit activity',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        }
    },
    // Booking reschedule
    {
        name: 'booking/reschedule',
        path: '/booking/:bookingUuid/reschedule',
        component: BookingReschedule,
        meta: {
            title: 'Reschedule',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        }
    },
    // Active booking (input booking number)
    {
        name: 'booking/active',
        path: '/booking/active',
        component: BookingActive,
        meta: {
            title: 'Active booking',
            showNavbar: false,
            showMainMenu: false,
        }
    },
    // Calendar
    {
        name: 'calendar',
        path: '/calendar',
        component: CalendarIndex,
        meta: {
            title: 'My calendar',
            showNavbar: true,
            showMainMenu: true,
        },
    },
    {
        name: 'calendar/add',
        path: '/calendar/add',
        component: CalendarAdd,
        meta: {
            title: 'Add activity',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    // Products
    {
        name: 'products',
        path: '/products',
        component: ProductsIndex,
        meta: {
            title: 'Products',
            showNavbar: true,
            showMainMenu: true
        },
    },
    {
        name: 'products/show',
        path: '/products/:productUuid/show',
        component: ProductsShow,
        meta: {
            title: '',
            showNavbar: false,
            showMainMenu: false,
            showLeftIcon: false,
            leftIcon: 'arrow-left',
            showRightIcon: false
        },
    },
    {
        name: 'products/checkout',
        path: '/products/checkout',
        component: ProductsCheckout,
        meta: {
            title: 'Checkout',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
    // Order confirmation
    {
        name: 'order/confirmation',
        path: '/order/confirmation',
        component: OrderConfirmation,
        meta: {
            title: 'CONFIRMED',
            showNavbar: false,
            showMainMenu: false
        }
    },
    // Notification
    {
        name: 'notification',
        path: '/notification',
        component: NotificationIndex,
        meta: {
            title: 'My notifications',
            showNavbar: true,
            showMainMenu: true,
        },
    },
    {
        name: 'notification/show',
        path: '/notification/:notificationUuid',
        component: NotificationShow,
        meta: {
            title: 'Notification',
            showNavbar: true,
            showMainMenu: false,
            showLeftIcon: true,
            leftIcon: 'arrow-left'
        },
    },
];

export default routes;
