<template>
    <div>
        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/information">Informations</router-link>
                            </li>
                            <li>
                                {{ information ? information.title : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-field label="Title *" >
                                    <b-input type="text" v-model="form.fields.title">
                                    </b-input>
                                </b-field>
                                <FormError field="title" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Content" >
                                    <b-input type="textarea" v-model="form.fields.content">
                                    </b-input>
                                </b-field>
                                <FormError field="content" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Status">
                                    <b-select placeholder="Select status" v-model="form.fields.status">
                                        <option
                                            v-for="option in statusOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <label class="label">Icon</label>
                                <FileUpload v-model="form.fields.files" :entity="information" :multiple="false" :extensions="['jpg','jpeg','png','svg']"></FileUpload>
                            </div>
                            <hr/>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Information,
} from '@/internal'

import FileUpload from '@/views/Admin/components/FileUpload';

export default {
    name: 'Form',
    components: {
        FileUpload,
    },
    computed: {
        information() {
            return this.$route.params.informationId ? this.$store.getters['information/show'](this.$route.params.informationId) : null;
        },
    },
    created() {
        if(this.information) {
            this.form.fields = {
                ...this.information,
                files: this.information.files ? this.information.files : []
            }
        }
    },
    data() {
        return {
            form: new Form({
                title: '',
                content: '',
                status: 'draft',
                files: [],
            }),
            loading: false,
            statusOptions: Information.statusOptions
        };
    },
    methods: {
        ...mapActions({
            informationDestroy: 'information/destroy',
            informationStore: 'information/store',
            informationUpdate: 'information/update',
            informationIndex: 'information/index'
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
              ...this.form.fields
            };

            let action = null;

            if (this.information) {
                action = this.informationUpdate;
            } else {
                action = this.informationStore;
            }

            action(Information.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/information');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Information',
                message: 'Are you sure you want to delete this information?',
                onConfirm: () => {
                    this.loading = true;
                    this.informationDestroy({
                        id: this.information.id,
                    }).then(() => {
                        window.app.snackbar('Information Deleted');

                        this.$router.push('/admin/information');

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
    },
    watch: {
        information() {
            if(this.information) {
                this.form.fields = {
                    ...this.information,
                    files: this.information.files ? this.information.files : [],
                }
            }
        },
    }
};
</script>

<style lang="scss">


</style>
