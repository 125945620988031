import { ResourceService } from '@/internal/modules';

export default class CustomerService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'customer'
    }

    search(payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

    exportData(id) {
        return this.request({
            method: 'get',
            url: `${this.baseUrl}/${id}/export-data`,
            headers: {
                responseType: 'arraybuffer'
            }
        });
    }
}
