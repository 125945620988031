<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>

        <div class="column is-flex is-flex-direction-column content-height">
            <div class="columns mb-auto flex-column">
                <div v-if="form.fields.products" class="container container-extra-small p-0">
                    <div v-for="(product,key) in form.fields.products" class="is-flex mb-5">
                        <div class="position-relative">
                            <template v-if="product.imageUrl">
                                <div class="product-img border-grey--light--thin" :style="{ 'background-image': 'url('+product.imageUrl+')' }"></div>
                            </template >
                            <template v-else>
                                <div class="product-img border-grey--light--thin" :style="{ 'background-image': 'url(https://bulma.io/images/placeholders/1280x960.png)' }"></div>
                            </template>
                        </div>
                        <div class="is-flex flex-column is-justify-content-space-between is-flex-grow-1 pl-4">
                            <div class="is-flex is-justify-content-space-between mb-2">
                                <div class="product-title">{{product.name}}</div>
                                <div class="product-remove bg-grey--light-1 is-flex is-justify-content-center is-flex-shrink-0 ml-2 " @click="removeProduct(key)">
                                    <img src="/images/trash2.svg" width="20" alt="" />
                                </div>
                            </div>
                            <div class="is-flex is-justify-content-space-between is-align-items-center">
                                <div class="fs-22 has-text-weight-semibold">£ {{product.price}}</div>
                                <GroupInput :full-width="false" :show-icon="false" v-model="product.quantity" />
                            </div>
                        </div>
                    </div>

                    <div class="is-flex is-justify-content-space-between is-align-items-center border-top-grey--thin">
                        <div class="fs-14 has-text-weight-semibold mt-2">TOTAL COST</div>
                        <div class="fs-22 has-text-weight-semibold">£ {{form.fields.amount}}</div>
                    </div>
                </div>
            </div>

            <div class="columns flex-column mt-6">
                <div v-if="activeBooking" class="controls mt-0">
                    <div class="is-flex pb-3">
                        <b-button class="button button--grey border-radius-12 is-clickable is-fullwidth" @click="handleAddToBill">Add to my bill</b-button>
                    </div>
                </div>

                <div class="controls mt-0">
                    <div class="is-flex">
                        <b-button class="button is-black border-radius-12 is-clickable is-fullwidth" @click="handlePayment">PAY NOW</b-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import GroupInput from "@/views/components/GroupInput";
import {Form} from '@/internal/modules';
import {Order} from '@/internal';
import {mapActions} from "vuex";
import moment from "moment";

export default {
    name: 'Checkout',
    components: {GroupInput},
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
    },
    data() {
        return {
            loading: false,
            form: new Form({
                products: [],
                amount: 0,
                origin_platform: Order.originPlatformGuestApp
            }),
        };
    },
    mounted() {
        this.fillFormFromStorage();
    },
    methods: {
        ...mapActions({
            orderStore: 'order/store',
            paymentSession: 'payment/session'
        }),
        fillFormFromStorage() {
            const order =  window.app.storage.getItem('order');

            if(order) {
                this.form.fields = {
                    ...this.form.fields,
                    products: [order]
                };
            }

        },
        clearFormStorage() {
            window.app.storage.removeItem('order')
        },
        removeProduct(key) {
            this.form.fields.products.splice(key,1);

            if(this.form.fields.products.length < 1) {
                this.$router.back();
            }
        },
        handlePayment() {
            let product = this.form.fields.products[0];

            const fields = {
                product_name: product.name,
                product_description: product.description,
                amount: product.price * 100,
                quantity: product.quantity,
                cancel_url: process.env.APP_URL + this.$route.fullPath,
                success_url: process.env.APP_URL + '/order/confirmation',
                metadata: {
                    product_id: product.id,
                    quantity: product.quantity,
                    unit_price: product.price,
                    price: this.form.fields.amount,
                    origin_platform: Order.originPlatformGuestApp
                }
            }

            this.loading = true;

            this.paymentSession(fields).then((response) => {
                window.location.href = response.url;
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleAddToBill() {

            const fields = {
                ...this.form.fields,
                checkout_type: Order.checkoutTypeAddToBill,
            }

            this.loading = true;

            this.orderStore(fields).then((response) => {
                const route = window.app.findRouteByName('order/confirmation', [], {});
                this.$router.push(route);

                this.clearFormStorage();
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    watch: {
        'form.fields.products': {
            handler() {
                this.form.fields.amount = this.form.fields.products.reduce((accumulator, product) => {
                    return accumulator + (product.price * product.quantity)}, 0);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.product {
    &-title {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }

    &-remove {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        cursor: pointer;
    }

    &-img {
        height: 109px;
        width: 109px;
        border-radius: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>
