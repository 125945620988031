<template>
<!--    <section class="section height-full is-flex active-booking">-->
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column">
                        <div class="mb-8">
                            <img src="/images/compass.svg" alt="compass" class="compass-bg">
                        </div>

                        <div class="subtitle mb-0 font-primary fs-40 has-text-black mb-4">
                            Welcome!
                        </div>

                        <div class="has-text-black mb-4">
                            Introduce your booking number to start your experience.
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('number')}]">
                            <BField class="mb-2" label="Booking number">
                                <BInput type="text" v-model="form.fields.number"></BInput>
                            </BField>
                            <img v-if="form.errors.has('number')" src="/images/warning2-red.svg" class="input__icon" />
                            <FormError field="number" :form="form"/>
                        </div>
                    </div>
                </div>

                <div class="mt-auto pb-4">
                    <div class="controls has-margin-top">
                        <BButton type="is-black"
                                 expanded
                                 :disabled="!form.fields.number.length"
                                 class="border-radius-12"
                                 @click="handleSubmit"
                                 :loading="loading"
                        >
                            NEXT
                            <i class="fa-solid fa-arrow-right ml-2"></i>
                        </BButton>
                    </div>

                    <div class="controls has-margin-top">
                        <BButton v-if="!form.fields.number.length"
                                 expanded
                                 class="button--ghost border-radius-12"
                                 @click="handleSkip"
                        >
                            I don't have one
                        </BButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Active',
    components: {},
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    data() {
        return {
            form: new Form({
                number: '',
            }),
            loading: false
        };
    },
    methods: {
        ...mapActions({
            setActiveBooking: 'user/setActiveBooking',
        }),
        handleSubmit() {
            this.loading = true;

            this.form.errors.clear();

            this.setActiveBooking(this.form.fields).then((response) => {
                this.handleRedirect();
            }).catch((error) => {
                this.form.recordErrors(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleSkip() {
            this.handleRedirect();
        },
        handleRedirect() {
            if(window.app.auth.hasRedirectRoute()) {
                this.$router.push(window.app.auth.redirectRouteAfterLogin());
                window.app.auth.clearRedirectRoute();
            } else {
                const route = window.app.findRouteByName('home');
                this.$router.push(route);
            }
        }
    },
};
</script>

<style scoped lang="scss">
    .active-booking {
        background-image: url("/images/flower2.svg");
        background-position: top 5px right -40px;
        background-repeat: no-repeat;
    }
</style>
