<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <div v-if="order" slot="header" class="flex:1 flex:between|center">
      <div class="ml:24 f:24 semi">{{ order.reference }}</div>
      <BookingStatus :status="order.status" />
    </div>

    <div v-if="order" class="px:24">
      <div class="p:24 b:base-20 r:8 c:accent-70 mb:32">
        <div v-if="order.checkoutTypeName"
          class="flex-inline:between|center gap:8 bg:primary-10 c:primary-80 r:8 p:8 mb:16 semi f:14">
          <Icon admin name="suitcase-plus" />
          {{ order.checkoutTypeName }}
        </div>
        <div class="flex:between|center semi f:22 mb:4" v-for="product in order.orderProducts">
          <div>
            {{ product.productName }}
          </div>
          <div>{{ product.quantity > 1 ? `${product.quantity} x ` : '' }} £{{ product.price }}</div>
        </div>
        <div class="f:14 mb:16">
          {{ order.createdAt.format('DD MMM YYYY') }} {{ timeFormat(order.createdAt) }}
        </div>
        <div class="flex:between|center">
          <div>Total</div>
          <div>£{{ order.price }}</div>
        </div>
      </div>
      <div class="section-title">
        <Icon admin name="user-square" />
        Customer
      </div>

      <div class="p:24 b:base-20 r:8 c:accent-70 mb:32">
        <div class="flex:between|center">
          <div>
            <div class="f:24 semi mb:4">{{ order.customer.name }}</div>
            <div>{{ order.customer.email }}</div>
            <div>{{ order.customer.phoneE164 }}</div>
          </div>
          <div class="flex-col:|end gap:12">
            <div class="bg:accent-10 f:14 semi p:4|8 r:4">
              User #{{ order.customer.id }}
            </div>
            <router-link class="flex-inline:|center gap:4 c:primary semi b:base-20 p:8|12 r:8"
              :to="`/admin/customer?show=${order.customer.id}`">
              View
              <Icon admin name="arrow-up-right" />
            </router-link>
          </div>
        </div>
      </div>

      <div class="section-title">
        <Icon admin name="note" />
        Notes
      </div>
      <div class="mb:32">
        <textarea class="--textarea" rows="8" v-model="form.fields.note" />
        <form-error field="note" :form="form" />
      </div>

    </div>

    <template slot="footer">
      <button class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
        @click="handleSave">
        <Icon admin name="check" />
        Save
      </button>
    </template>


  </SideDrawer>
</template>


<script>
import { mapActions } from 'vuex';
import mixin from "@/mixin";
import SideDrawer from '@/components/admin/SideDrawer';
import BookingStatus from '@/components/admin/Status';

import {
  Form,
} from '@/internal/modules'

import {
  Order
} from '@/internal'

export default {
  name: 'Form',
  mixins: [mixin],
  components: {
    SideDrawer,
    BookingStatus
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: new Form({
        note: null
      }),
      loading: false,
      statusOptions: Order.statusOptions,
      orderId: null,
    };
  },
  computed: {
    order() {
      return this.orderId ? this.$store.getters['order/show'](this.orderId) : null;
    }
  },
  async mounted() {
    await this.loadOrder(this.id);
    this.fillForm();
  },
  methods: {
    ...mapActions({
      orderDestroy: 'order/destroy',
      orderStore: 'order/store',
      orderUpdate: 'order/update',
      orderShow: 'order/show',
    }),
    timeFormat(value) {
      return this.dateFormatTimezone(value).format('hh:mm A');
    },
    dateFormat(value) {
      return this.dateFormatTimezone(value).format("D MMM YY");
    },
    dateTimeFormat(value) {
      return this.dateFormatTimezone(value).format("D MMM YY hh:mm A");
    },
    handleSave() {
      this.loading = true;
      this.form.errors.clear();

      this.orderUpdate({
        ...this.form.fields,
        params: {
          include: ['customer', 'orderProducts']
        }
      }).then(() => {
        window.app.snackbar({
          message: 'Update successfully!',
        });
        this.$emit('close');
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadOrder(id) {
      this.loading = true;
      return this.orderShow({
        id,
        params: {
          include: ['customer', 'orderProducts']
        }
      }).then((response) => {
        this.orderId = response[0];
      }).catch((error) => {

      }).finally(() => {
        this.loading = false;
      });
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Order',
        message: 'Are you sure you want to delete this order?',
        onConfirm: () => {
          this.loading = true;

          this.orderDestroy({ id: this.booking.id }).then(() => {
            window.app.snackbar('Order deleted');

            this.$router.push({
              name: 'admin/order',
            });

          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });

        },
      });
    },
    fillForm() {
      if (this.order) {
        this.form.fields = {
          id: this.order.id,
          note: this.order.note
        }
      }
    }
  }
};
</script>
