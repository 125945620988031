<template>
  <Dialog
    v-model="open"
    :title="isEdit ? 'Update closed days' : 'Add closed days'"
    @close="handleClose"
    @submit="handleSave"
  >
    <b-loading :is-full-page="true" v-model="loading" />
    <div>
      <div class="flex gap:16 mb:16">
        <div class="flex:1">
          <label class="--label mb:4">Day from</label>
          <CalendarInput v-model="form.fields.startDate" placeholder="Start date" />
          <FormError field="start_date" :form="form" />
        </div>
        <div class="flex:1">
          <label class="--label mb:4">Day to</label>
          <CalendarInput v-model="form.fields.endDate" placeholder="End date" position="is-bottom-left" />
          <FormError field="end_date" :form="form" />
        </div>
      </div>
      <div class="flex gap:16 mb:16">
        <div class="flex:1">
          <label class="--label mb:4">Hour from</label>
          <b-timepicker
            :disabled="allDay"
            placeholder="From"
            icon="clock"
            :incrementMinutes="15"
            append-to-body
            v-model="form.fields.startTime"
          />
          <FormError field="start_time" :form="form" />
        </div>

        <div class="flex:1">
          <label class="--label mb:4">Hour to</label>
          <b-timepicker
            :disabled="allDay"
            placeholder="To"
            icon="clock"
            :incrementMinutes="15"
            append-to-body
            v-model="form.fields.endTime"
          />
          <FormError field="end_time" :form="form" />
        </div>
      </div>
      <div class="mb:16">
        <label class="--label flex:|center gap:8 f:16">
          <input type="checkbox" v-model="allDay" @input="handleAllDayToggle" class="--checkbox" />
          All day
        </label>
      </div>
      <div class="mb:12">
        <label class="--label">
          Description
          <textarea class="--textarea mt:4" v-model="form.fields.description"></textarea>
        </label>
        <FormError field="description" :form="form" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import { Form } from '@/internal/modules'

import { mapActions } from 'vuex'
import mixin from '@/mixin'
import moment from 'moment'

import Dialog from '@/components/admin/Dialog.vue'
import CalendarInput from '@/components/admin/CalendarInput.vue'

export default {
  name: 'ClosedDaysDialog',
  mixins: [mixin],
  components: {
    Dialog,
    CalendarInput,
  },
  data() {
    return {
      loading: false,
      open: false,
      form: new Form({
        serviceId: this.$route.query.show,
        startDate: new Date(),
        endDate: new Date(),
        startTime: moment('00:00', 'HH:mm').toDate(),
        endTime: moment('23:45', 'HH:mm').toDate(),
        description: null,
      }),
      allDay: true,
      datePickerLocale: undefined,
    }
  },
  computed: {
    service() {
      return this.form.fields.serviceId ? this.$store.getters['service/show'](this.form.fields.serviceId) : null
    },
    isEdit() {
      return !!this.form.fields.id
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      serviceClosedHourStore: 'serviceClosedHour/store',
      serviceClosedHourUpdate: 'serviceClosedHour/update',
    }),
    setFormFields(fields) {
      this.form.fields = {
        ...this.form.fields,
        ...fields,
        startDate: moment(fields.startDate).toDate(),
        endDate: moment(fields.endDate).toDate(),
        startTime: moment(fields.startTime, 'HH:mm:ss').toDate(),
        endTime: moment(fields.endTime, 'HH:mm:ss').toDate(),
      }
      this.allDay = this.isAllDay()
    },
    isAllDay() {
      return (
        moment(this.form.fields.startTime).format('HH:mm') === '00:00' &&
        moment(this.form.fields.endTime).format('HH:mm') === '23:45'
      )
    },
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
        startDate: this.form.fields.startDate ? moment(this.form.fields.startDate).format('YYYY-MM-DD') : null,
        endDate: this.form.fields.endDate ? moment(this.form.fields.endDate).format('YYYY-MM-DD') : null,
        startTime: this.form.fields.startDate ? moment(this.form.fields.startTime).format('HH:mm') : null,
        endTime: this.form.fields.endTime ? moment(this.form.fields.endTime).format('HH:mm') : null,
      }

      const action = this.isEdit ? this.serviceClosedHourUpdate : this.serviceClosedHourStore

      action(fields)
        .then((response) => {
          this.clearForm()
          this.$emit('onSave', response)
          this.open = false
          window.app.snackbar({
            message: this.isEdit ? 'Updated successfully!' : 'Added successfully!',
          })
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.open = false
      this.$emit('close')
    },
    clearForm() {
      this.form.fields = {
        serviceId: this.$route.query.show,
        startDate: new Date(),
        endDate: new Date(),
        startTime: moment('00:00', 'HH:mm').toDate(),
        endTime: moment('23:45', 'HH:mm').toDate(),
        description: null,
      }
    },
    handleAllDayToggle() {
      if (this.allDay) {
        this.form.fields = {
          ...this.form.fields,
          startTime: moment('00:00', 'HH:mm').toDate(),
          endTime: moment('23:45', 'HH:mm').toDate(),
        }
      }
    },
  },
}
</script>
