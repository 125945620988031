<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0">
            <div class="columns is-flex flex-wrap mt-4">
                <template v-if="error">
                    <div class="column">
                        <p class="has-text-centered">{{ error }}</p>
                    </div>
                </template>
                <template v-else >
                    <template v-for="product in products">
                        <div class="column is-one-third-desktop is-half-tablet is-half-mobile">
                            <ProductCard :product="product" />
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import GroupInput from '@/views/components/GroupInput';
import ProductCard from '@/views/components/ProductCard'
import {mapActions} from "vuex";

export default {
    name: 'Products',
    components: {ProductCard, GroupInput},
    computed: {
        products() {
            return this.ids ? this.$store.getters['product/collection'](this.ids) : [];
        },
    },
    data() {
        return {
            ids: [],
            loading: false,
            error: null
        }
    },
    mounted() {
        this.loadProducts();
    },
    methods: {
        ...mapActions({
            productAvailable: 'product/available'
        }),
        loadProducts() {
            this.loading = true;

            this.productAvailable({
                params: {
                    include: ['category']
                }
            }).then((response) => {
                this.ids = response.ids;
            }).catch((error) => {
                if(error.response.data && error.response.data.errors) {
                    this.error = error.response.data.errors[0].detail
                }
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
