import {
    Notification as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'notification',
    model: Model,
    actions: {
        read ({ commit }, payload = {}) {
            return service.read(payload.id, payload).then(response => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        }
    },
});

export default store.toObject();
