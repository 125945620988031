import {
    ApiService
} from '@/internal/modules';


export default class PaymentService extends ApiService {

   constructor() {
        super();
        this.baseUrl = 'payment';
   }

  session(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/payment/session',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),

    });
  }

}
