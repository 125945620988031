<template>
    <section class="section height-full min-height-full pb-0">
        <div class="container container-extra-small p-0">
            <div class="field mb-5">
                <div class="is-flex">
                    <img class="mr-3" src="/images/envelope2.svg" />
                    <input id="EmailNotifications" type="checkbox" v-model="form.fields.mail"
                           class="switch--full switch is-rounded is-outlined is-rtl is-success" @change="handleToggleNotification">
                    <label for="EmailNotifications" class="notification-label has-text-weight-normal">Email notifications</label>
                </div>
                <p class="fs-14 ml-35">Receive notifications on the email with which you have registered</p>
            </div>
<!--            <div class="field mb-5">-->
<!--                <div class="is-flex">-->
<!--                    <img class="mr-3" src="/images/sms2.svg" />-->
<!--                    <input id="smsNotifications" type="checkbox" v-model="form.fields.vonage"-->
<!--                           class="switch&#45;&#45;full switch is-rounded is-outlined is-rtl is-success" @change="handleToggleNotification">-->
<!--                    <label for="smsNotifications" class="notification-label has-text-weight-normal">SMS notifications</label>-->
<!--                </div>-->
<!--                <p class="fs-14 ml-35">Receive notifications on your mobile phone</p>-->
<!--            </div>-->
            <div class="field mb-5">
                <div class="is-flex">
                    <img class="mr-3" src="/images/whatsapp2.svg" />
                    <input id="whatsappNotifications" type="checkbox" v-model="form.fields.whatsapp" @change="handleToggleNotification"
                           class="switch--full switch is-rounded is-outlined is-rtl is-success">
                    <label for="whatsappNotifications" class="notification-label has-text-weight-normal">Whatsapp notifications</label>
                </div>
                <p class="fs-14 ml-35">Receive notifications on your mobile phone</p>
            </div>
        </div>
    </section>

</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules'
import Vue from "vue";

export default {
    name: "Notifications",
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        }
    },
    data() {
        return {
            form: new Form({
                mail: false,
                vonage: false,
                whatsapp: false
            }),
        }
    },
    created() {
      this.fillForm();
    },
    methods: {
        ...mapActions({
            userNotificationSettings: 'user/notificationSettings',
        }),
        handleToggleNotification() {
            Vue.nextTick(()=> {
                const fields = {
                    ...this.form.fields
                }

                this.userNotificationSettings(fields).then(() => {

                }).catch((error) => {
                    window.app.snackbarError(error);
                });
            });
        },
        fillForm() {
            this.form.fields = {
                mail: this.user.notificationSettings ? this.user.notificationSettings.mail : false,
                vonage: this.user.notificationSettings ? this.user.notificationSettings.vonage : false,
                whatsapp: this.user.notificationSettings ? this.user.notificationSettings.whatsapp : false
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .notification-label {
        width: 100%
    }
</style>
