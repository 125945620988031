<template>
    <div class="main-menu-container">
        <div class="container p-0">
            <div class="main-menu">
                <div class="tabs is-fullwidth">
                    <ul class="border-0">
                        <li>
                            <div class="p-1">
                                <router-link to="/" class="menu-item app-grey">
                                    <span class="icon">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M8.43299 0.497288C9.18704 -0.162508 10.313 -0.162507 11.067 0.497289L18.067 6.62229C18.501 7.00207 18.75 7.55072 18.75 8.12744V16.9999C18.75 18.1045 17.8546 18.9999 16.75 18.9999H12.75C11.6454 18.9999 10.75 18.1045 10.75 16.9999V12.9999H8.75V16.9999C8.75 18.1045 7.85457 18.9999 6.75 18.9999H2.75C1.64543 18.9999 0.75 18.1045 0.75 16.9999V8.12744C0.75 7.55072 0.998961 7.00206 1.43299 6.62229L8.43299 0.497288ZM9.75 2.00244L2.75 8.12744V16.9999H6.75V12.9999C6.75 11.8953 7.64543 10.9999 8.75 10.9999H10.75C11.8546 10.9999 12.75 11.8953 12.75 12.9999V16.9999H16.75V8.12744L9.75 2.00244Z"
                                                  :fill="[$route.name === 'home' ? '#000000' :'#5B6676']"/>
                                        </svg>
                                    </span>
                                    <span :class="{'has-text-black' : $route.name === 'home'}">Home</span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <div class="p-2">
                                <router-link to="/calendar" class="menu-item app-grey">
                                        <span class="icon">
                                            <img :src="`/images/${$route.name !== 'calendar' ? 'calendar2' : 'calendar2-active'}.svg`" />
                                        </span>
                                        <span :class="{'has-text-black' : $route.name === 'calendar'}">Calendar</span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <div class="p-1">
                                <router-link to="/products" class="menu-item app-grey">
                                    <span class="icon">
                                        <img :src="`/images/${$route.name !== 'products' ? 'products2' : 'products2-active'}.svg`" />
                                    </span>
                                    <span :class="{'has-text-black' : $route.name === 'products'}">Products</span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <div class="p-2">
                                <router-link to="/notification" class="menu-item app-grey">
                                        <span class="icon position-relative">
                                            <img :src="`/images/${$route.name !== 'notification' ? 'notifications2' : 'notifications2-active'}.svg`" />
                                            <span v-if="unreadNotifications && unreadNotifications.length > 0" class="has-text-white position-absolute rounded-circle unread-notifications is-flex justify-content-center align-items-center">{{ unreadNotifications.length }}</span>
                                        </span>
                                        <span :class="{'has-text-black' : $route.name === 'notification'}">Notifications</span>
                                </router-link>
                            </div>
                        </li>
                        <li>
                            <div class="p-2">
                                <router-link to="/user/settings" class="menu-item app-grey">
                                        <span class="icon">
                                            <img :src="`/images/${$route.name !== 'user/settings' ? 'account2' : 'account2-active'}.svg`" />
                                        </span>
                                        <span :class="{'has-text-black' : $route.name === 'user/settings'}">Account</span>
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
} from 'vuex';
import Vue from "vue";

export default {
    name: "MainMenu",
    components: {},
    computed: {
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        unreadNotifications() {
            return this.unreadNotificationIds ? this.$store.getters['notification/collection'](this.unreadNotificationIds) : [];
        },
        routeTitle () {
            return this.$route.meta ? this.$route.meta.title : '';
        },
    },
    data() {
        return {
            mobileMenuActive: false,
            unreadNotificationIds: []
        }
    },
    created() {
        this.loadUnreadNotifications();

        Vue.nextTick(()=> {
            window.app.vue.$on('notificationRead', this.loadUnreadNotifications);
        });
    },
    methods: {
        ...mapActions({
            notificationIndex: 'notification/index'
        }),
        handleToggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive;
        },
        handleLogout () {
            this.logout().then(() => {

                window.app.snackbar({
                    message: 'You\'ve been logged out',
                });

                this.$router.push('/');

                window.location.reload();
            });
        },
        loadUnreadNotifications() {
            const fields = {
                params: {
                    unread: true
                }
            }

            this.notificationIndex(fields).then((response) => {
                this.unreadNotificationIds = response.ids;
            }).finally(() => {

            })
        }
    }
}

</script>

<style scoped lang="scss">
.main-menu-container {
    position: fixed;
    bottom: 0;
    height: auto;
    width: 100%;
    border-top: 1px solid #BFC5CC;
    background-color: #ffffff;

    .main-menu {

        .tabs {
            a {
                border: none;
                padding: 0.5rem;

                &.router-link-exact-active {
                    background: #F4F5F7;
                    border-radius: 12px;
                }

                &.menu-item {
                    flex-direction: column;
                    font-size: 12px;
                    //color: #5B6676;
                }

                &:hover {
                    border-bottom: none;
                }
            }

            .icon:first-child {
                margin: 0;
            }
        }
    }
}
.unread-notifications {
    width: 20px;
    height: 20px;
    top: -3px;
    right: -3px;
    background-color: #f52419;
    border: 2px solid #ffffff;
}

.fill-black {
    fill: #000000;
}
</style>
