import { ResourceService } from '@/internal/modules';

export default class ProductService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'product';
    }

    available(payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/available`,
            params: this.prepareParams(params),
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}
