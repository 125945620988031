<template>
  <Dialog _class="w:540@md" v-model="open" :title="form.fields.id ? 'Update room' : 'Create room'" @submit="handleSave">
    <div>
      <label class="--label mb:16">
        Title
        <input class="--input mt:4" type="text" v-model="form.fields.title" />
        <FormError field="title" :form="form" />
      </label>

      <label class="--label mb:16">
        Description (Optional)
        <input class="--input mt:4" type="text" v-model="form.fields.description" />
        <FormError field="description" :form="form" />
      </label>

      <label class="--label mb:16">
        Order
        <input class="--input mt:4" type="number" v-model="form.fields.priority" />
        <FormError field="priority" :form="form" />
      </label>
    </div>
  </Dialog>
</template>

<script>

import Dialog from '@/components/admin/Dialog.vue'

import { Form } from '@/internal/modules'
import { mapActions } from 'vuex'
import mixin from '@/mixin'

export default {
  name: 'SpacesDialog',
  mixins: [mixin],
  components: {
    Dialog,
  },
  computed: {
    service() {
      return this.$route.query.show ? this.$store.getters['service/show'](this.$route.query.show) : null
    }
  },
  data() {
    return {
      loading: false,
      open: false,
      form: new Form({
        serviceId: this.$route.query.show,
        title: null,
        description: null,
        status: 'open',
        priority: 1
      }),
    }
  },
  methods: {
    ...mapActions({
      serviceSpaceStore: 'serviceSpace/store',
      serviceSpaceUpdate: 'serviceSpace/update',
    }),
    setFormFields(fields) {
      this.form.fields = {
        ...this.form.fields,
        ...fields,
      }
    },
    handleSave() {
      this.loading = true

      this.form.errors.clear()

      const fields = {
        ...this.form.fields,
      }

      let action = null
      if (this.form.fields.id) {
        action = this.serviceSpaceUpdate
      } else {
        action = this.serviceSpaceStore
      }

      action(fields)
        .then((response) => {
          this.clearForm()
          this.open = false
          this.$emit('success', response)
        })
        .catch((response) => {
          this.form.recordErrors(response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.clearForm()
      this.$emit('close')
    },
    clearForm() {
      this.form.fields = {
        serviceId: this.$route.query.show,
        title: null,
        description: null,
        status: 'open',
        priority: 1
      }
    },
  },
}
</script>
