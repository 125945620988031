<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/customer">Customers</router-link>
                            </li>
                            <li>
                                <router-link v-if="customer" :to="'/admin/customer/' + customer.id +'/update'" >{{ customer.name }}</router-link>
                            </li>
                            <li>
                                Activity
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">

            <FormMenu />

            <div class="card">
                <div class="card-content">
                    <div class="columns">
                        <div class="column">
                            <b-table
                                :data="data"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true">

                                <b-table-column field="entityTypeAndEntityId" label="Entity" v-slot="props">
                                    <template  v-if="props.row.entityName === 'Booking'">
                                        <router-link :to="`/admin/booking/${props.row.entityId}/update`">
                                            Booking #{{props.row.bookingNumber}}
                                        </router-link>
                                    </template>
                                    <template v-else >
                                        {{ props.row.entityName }} <span v-if="props.row.entityId">#{{ props.row.entityId }}</span>
                                    </template>

                                </b-table-column>

                                <b-table-column field="data" label="Service" v-slot="props">
                                    {{ props.row.data ? props.row.data.serviceName : '' }}
                                </b-table-column>

                                <b-table-column field="action" label="Action" v-slot="props">
                                    {{ props.row.action }}
                                </b-table-column>

                                <b-table-column field="user" label="User" v-slot="props">
                                    {{ props.row.user.name }} ({{ props.row.user.email }})
                                </b-table-column>

                                <b-table-column field="date" label="Date" v-slot="props">
                                    {{ props.row.createdAt.format('ddd, D MMM YYYY hh:mm A') }}
                                </b-table-column>

                                <b-table-column field="data" label="Payment" v-slot="props">
                                    {{ props.row.data ? props.row.data.paymentStatus : '' }}
                                </b-table-column>

                            </b-table>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script>
import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';
import FormMenu from './FormMenu';


export default {
    name: 'ActivityLogIndex',
    components: {
        Paginator,
        FormMenu
    },
    computed: {
        data() {
            return this.ids ? this.$store.getters['activityLog/collection'](this.ids) : [];
        },
        fullPath () {
            return this.$route.fullPath;
        },
        customer() {
            return this.customerId ? this.$store.getters['customer/show'](this.customerId) : null;
        },
    },
    async mounted() {
        await Promise.all([
            this.loadCustomer(),
            this.loadData(),
        ]);
    },
    data() {
        return {
            ids: [],
            customerId: null,
            paginator: null,
            loading: false
        }
    },
    methods: {
        ...mapActions({
            customerShow: 'customer/show',
            activityLogIndex: 'activityLog/index'
        }),
        loadData() {
            this.loading = true;

            const params = {
                include: ['user'],
                customer_id: this.$route.params.customerId,
            };

            return this.activityLogIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        loadCustomer() {
            this.loading = true;

            return this.customerShow({
                id: this.$route.params.customerId,
                params: {
                    include: ['user']
                }
            }).then((response) => {
                this.customerId = response[0];
            }).catch((error) => {
                console.log('error', error);
            }).finally(() => {
                this.loading = false;
            });
        },
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
