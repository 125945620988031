<template>
    <div>
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="container nav-menu p-0">
                <div class="columns is-mobile nav-items m-0">
                    <div class="column nav-column nav-left p-0">
                        <div v-if="showLeftIcon" class="nav-item p-0" @click="handleBack">
                            <div class="button small">
                                <i :class="`fa-solid fa-${$route.meta.leftIcon} fa-lg`"></i>
                            </div>
                        </div>
                    </div>
                    <div class="column nav-column nav-middle is-half p-0 text-center">
                        <div class="nav-item">
                            {{ title }}
                        </div>
                    </div>
                    <div class="column nav-column nav-right p-0">
<!--                        <div class="nav-item">-->
<!--                            <i class="fa-solid fa-magnifying-glass"></i>-->
<!--                        </div>-->
<!--                        <div class="nav-item">-->
<!--                            <i class="fa-regular fa-comment-dots"></i>-->
<!--                        </div>-->
                        <div v-if="showRightIcon" class="nav-item p-0" @click="$route.meta.rightIconHandler">
                            <div class="button small">
                                <img src="/images/cart2.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import Vue from "vue";
import Router from '@/internal/modules/Router';

export default {
    name: "Navbar",
    components: {},
    computed: {
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        routeTitle() {
            return this.$route.meta ? this.$route.meta.title : '';
        },
        routeName() {
            return this.$route.name;
        },
        fullPath () {
            return this.$route.fullPath;
        },
        title() {
            return this.pageTitle ? this.pageTitle : this.routeTitle;
        },
        showLeftIcon() {
            return this.$route.meta && this.$route.meta.showLeftIcon !== undefined ? this.$route.meta.showLeftIcon : false;
        },
        showRightIcon() {
            return this.$route.meta && this.$route.meta.showRightIcon !== undefined ? this.$route.meta.showRightIcon : false;
        }
    },
    data() {
        return {
            pageTitle: null,
            mobileMenuActive: false
        }
    },
    created() {
        Vue.nextTick(()=> {
            window.app.vue.$on('pageTitleUpdate', this.handlePageTitleUpdate);
        });

        this.pageTitle = this.$route.meta ? this.$route.meta.title : ''

    },
    methods: {
        handleToggleMobileMenu() {
            this.mobileMenuActive = !this.mobileMenuActive;
        },
        handleBack() {
            Router.goToLastRoute(this.$router);
        },
        handlePageTitleUpdate(title) {
            this.pageTitle = title;
        },
        handRightIconClick() {
            console.log('handRightIconClick')
        }
    },
    watch: {
        fullPath () {
            this.pageTitle = null;
        },
    },
}

</script>

<style scoped lang="scss">
nav.navbar {
    border-bottom: none;
}

.navbar {
    .container {
        display: flex;
    }

    .nav-items {
        flex: 1;

        .nav-column {
            align-items: stretch;
            display: flex;

            &.nav-left {
                justify-content: left;
            }

            &.nav-middle {
                justify-content: center;
            }

            &.nav-right {
                justify-content: right;
            }
        }

        .nav-item {
            align-items: center;
            display: flex;
            color: #000000;
            line-height: 1.5;
            padding: 0.5rem 0.75rem;
            position: relative;

            .button.small {
                border: none;
            }
        }
    }

    border-bottom: 1px solid;

    .navbar-middle {
        align-items: stretch;
        display: flex;
    }
}
</style>
