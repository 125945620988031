import {
    Order as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'order',
    model: Model,
    actions: {
        statusUpdate({commit}, payload = {}) {
            return service.statusUpdate(payload.id, payload).then((response) => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        },
    }
});

export default store.toObject();
