<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/booking">Bookings</router-link>
                            </li>
                            <li>
                                {{ booking ? booking.number : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <template v-if="booking">
                        <i v-if="booking.customer && booking.customer.isResident" class="fa-solid fa-house-chimney-user" title="Resident"></i>
                        <i v-if="booking.checkoutType === 'paid'" class="fa-solid fa-circle-dollar-to-slot" title="Paid"></i>
                        <i v-if="booking.checkoutType === 'add-to-bill'" class="fa-solid fa-file-invoice-dollar" title="Added to bill"></i>
                    </template>
                </div>
            </div>
        </section>
        <section v-if="booking" class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label class="label">Booking number: {{ booking.number }}</label>
                            </div>
                            <div v-if="booking.originId" class="field">
                                <label class="label">Origin: {{ booking.originName }} ( {{ booking.originId }} )</label>
                            </div>
                            <template v-if="booking.group">
                                <div class="field">
                                    <label class="label">Group: {{ booking.group.name }}</label>
                                </div>
                                <div v-if="booking.isGroup" class="field">
                                     <div class="table-container">
                                         <table class="table is-striped">
                                             <tr v-for="booking in booking.group.bookings">
                                                 <td>#{{ booking.number }}</td>
                                                 <td>{{ booking.category ? booking.category.name : "N/A" }}</td>
                                                 <td>{{ booking.startAt.format("D MMM YY") }} - {{ booking.endAt.format("D MMM YY") }} ({{ booking.nightsCount}} nights)</td>
                                                 <td>{{ booking.status }}</td>
                                                 <td>
                                                     <template v-if="booking.id">
                                                         <router-link :to="`/admin/booking/${booking.id}/update`">
                                                             Manage
                                                         </router-link>
                                                     </template>
                                                 </td>
                                             </tr>
                                         </table>
                                     </div>
                                </div>
                            </template>
                            <div class="field">
                                <label class="label">Service</label>
                                <p>{{ booking.service ? booking.service.name : 'N/A' }}</p>
                                <p v-if="booking.category">Category: {{ booking.category.name }}</p>
                            </div>
                            <div class="field">
                                <label class="label">Customer</label>
                                <div v-if="booking.customer"  class="control has-icons-left has-icons-right">
                                    Name: {{ booking.customer.name }} <br>
                                    Email: {{ booking.customer.email }} <br>
                                    Phone: {{ booking.customer.phoneE164 }} <br>
                                </div>
                            </div>
                            <template v-if="booking.type === 'activity'">
                                <div class="field">
                                    <label class="label">Date</label>
                                    <p>{{ booking.startAt.format('dddd, DD-MM-YYYY') }}</p>
                                </div>
                                <div class="field">
                                    <label class="label">Time</label>
                                    <p>{{ timeFormat(booking.startAt) }} - {{ timeFormat(booking.endAt) }}</p>
                                </div>
                                <div class="field">
                                    <label class="label">Duration</label>
                                    <p>{{ booking.totalDuration }} minutes</p>
                                </div>
                            </template>
                            <template v-else-if="booking.type === 'accommodation'">
                                <div class="field">
                                    <label class="label">Date</label>
                                    <p>{{ dateFormat(booking.startAt) }} - {{ dateFormat(booking.endAt) }} ({{ booking.nightsCount}} nights)</p>
                                </div>
                            </template>

                            <div class="field">
                                <label class="label">Persons</label>
                                <p>{{ booking.totalPersons }}</p>
                            </div>
                            <div class="field">
                                <label class="label">Status</label>
                                <p>{{ booking.status ? booking.status : 'N/A'  }}</p>
                            </div>
                            <div class="field">
                                <label class="label">Created</label>
                                <p>{{ dateTimeFormat(booking.createdAt) }}</p>
                            </div>

                            <template v-if="booking.activityType === 'restaurant'">
                                <div class="field">
                                    <b-field label="Dietary requirements" >
                                        <b-input type="textarea" v-model="form.fields.dietary_requirements">
                                        </b-input>
                                    </b-field>
                                    <FormError field="dietary_requirements" :form="form"/>
                                </div>
                                <div class="field">
                                    <b-field label="Special occasion" >
                                        <b-input type="textarea" v-model="form.fields.special_occasion">
                                        </b-input>
                                    </b-field>
                                    <FormError field="special_occasion" :form="form"/>
                                </div>
                                <div class="field">
                                    <div class="label">Package</div>
                                    <b-select placeholder="Select group" v-model="form.fields.package">
                                        <option
                                            v-for="option in packageOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                    <FormError field="package" :form="form"/>
                                </div>
                            </template>

                            <template v-if="booking.activityType === 'spa'">
                                <div class="field">
                                    <b-field label="Shoe sizes" >
                                        <b-input type="text" v-model="form.fields.shoe_sizes">
                                        </b-input>
                                    </b-field>
                                    <FormError field="shoe_sizes" :form="form"/>
                                </div>
                                <div class="field">
                                    <div class="label">Form Status</div>
                                    <b-select placeholder="Select group" v-model="form.fields.form_status">
                                        <option
                                            v-for="option in formStatusOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                    <FormError field="form_status" :form="form"/>
                                </div>
                            </template>

                            <div class="field">
                                <b-field label="Notes" >
                                    <b-input type="textarea" v-model="form.fields.note">
                                    </b-input>
                                </b-field>
                                <FormError field="note" :form="form"/>
                            </div>

                            <div class="controls is-flex is-justify-content-end">
                                <b-button v-if="isSyncable" type="is-primary is-light" @click="handleSync" >Sync</b-button>

                                <b-button v-if="booking.isCancelable" class="button" @click="handleCancel" >Cancel</b-button>
                                <b-button class="button button--primary" @click="handleSave" >Save</b-button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import mixin from "@/mixin";

import {
    Form,
} from '@/internal/modules'

import {
    Booking,
} from '@/internal'

export default {
    name: 'Form',
    mixins: [mixin],
    components: {},
    computed: {
        booking() {
            return this.bookingId ? this.$store.getters['booking/show'](this.bookingId) : null;
        },
        isSyncable() {
            return this.booking && this.booking.originName === 'Mews' && this.booking.type === 'accommodation';
        }
    },
    async mounted() {
        await this.loadBooking();

        this.fillForm();
    },
    data() {
        return {
            form: new Form({
                note: null,
                dietary_requirements: null,
                special_occasion: null,
                shoe_sizes: null,
                package: null,
                form_status: null,
            }),
            loading: false,
            statusOptions: Booking.statusOptions,
            bookingId: null,
            packageOptions: Booking.packageOptions,
            formStatusOptions: Booking.formStatusOptions
        };
    },
    methods: {
        ...mapActions({
            bookingDestroy: 'booking/destroy',
            bookingStore: 'booking/store',
            bookingUpdate: 'booking/update',
            bookingShow: 'booking/show',
            bookingCancel: 'booking/cancel',
            bookingSync: 'booking/sync'
        }),
        timeFormat(value) {
            return this.dateFormatTimezone(value).format('hh:mm A');
        },
        dateFormat(value) {
            return this.dateFormatTimezone(value).format("D MMM YY");
        },
        dateTimeFormat(value) {
            return this.dateFormatTimezone(value).format("D MMM YY hh:mm A");
        },
        handleCancel() {
            window.app.confirm({
                title: 'Cancel Booking',
                message: 'Are you sure you want to cancel this booking?',
                onConfirm: () => {
                    this.loading = true;

                    return this.bookingCancel({
                        id: this.booking.id,
                    }).then((response) => {
                        const route = window.app.findRouteByName('admin/booking/update', [], {
                            params: {
                                bookingId: this.booking.id
                            },
                        });

                        this.$router.push(route);

                    }).catch((error) => {
                        console.log("error");
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            this.bookingUpdate({
                ...this.form.fields,
                params: {
                    include: ['customer', 'group.bookings']
                }
            }).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        loadBooking() {
            this.loading = true;

            return this.bookingShow({
                id: this.$route.params.bookingId,
                params: {
                    include: ['customer', 'group.bookings']
                }
            }).then((response) => {
                this.bookingId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleSync() {
            this.loading = true;

            return this.bookingSync(this.booking.id).then(() => {
                this.loadBooking();
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Booking',
                message: 'Are you sure you want to delete this booking?',
                onConfirm: () => {
                    this.loading = true;

                    this.bookingDestroy({id: this.booking.id}).then(() => {
                        window.app.snackbar('Booking deleted');

                        this.$router.push({
                            name: 'admin/booking',
                        });

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        fillForm() {
            if(this.booking) {
                this.form.fields = {
                    id: this.booking.id,
                    note: this.booking.note,
                    dietary_requirements: this.booking.dietaryRequirements,
                    special_occasion: this.booking.specialOccasion,
                    shoe_sizes: this.booking.shoeSizes,
                    package: this.booking.package,
                    form_status: this.booking.formStatus,
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">

</style>
