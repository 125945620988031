<template>
    <div class="column px-0">
        <div class="experience-card border-radius-12 p-4">
            <div class="is-flex align-items-center">
                <span class="has-text-weight-normal fs-12 app-grey">
                    {{ bookingTimeFromTo }}
                </span>
            </div>
            <div class="mr-auto has-text-weight-semibold mb-3">
                {{ booking.service.nameFinal }}
            </div>
            <div class="is-flex align-items-center is-justify-content-space-between">
                <span class="border border-radius-8 px-2 py-1">
                    <img class="mr-3" src="/images/users2-1.svg" /> {{ booking.totalPersons }}
                </span>
                <span @click="handleEdit" class="is-clickable px-4 py-2 bg-grey--light-1 border-radius-8 is-flex align-items-center">
                    <img class="mr-3" src="/images/edit2-black.svg" width="20">
                    Edit
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import mixin from "@/mixin";

export default {
    name: "ActivityCard",
    mixins: [mixin],
    props: {
        booking: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        bookingTimeFromTo() {
            return `${this.dateFormatTimezone(this.booking.startAt).format('HH:mm')} - ${this.dateFormatTimezone(this.booking.endAt).format('HH:mm')}`
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
    },
    methods: {
        handleEdit() {
            const route = window.app.findRouteByName('booking/edit', [], {
                params: {
                    bookingUuid: this.booking.id
                },
            });

            this.$router.push(route);
        }
    }
}
</script>

<style lang="scss" scoped>
    .experience-card {
        border-left: 4px solid #414D5E;
        box-shadow: 0 3px 14px -1px rgba(28, 52, 84, 0.16), 0px 1px 4px -1px rgba(28, 48, 74, 0.16);

        &--active {
            border-radius: 0;
        }
    }
</style>
