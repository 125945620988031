import {
    AccountIntegration as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();


const store = new ResourceStore({
    key: 'accountIntegration',
    model: Model,
    actions: {
        syncBookings({commit, dispatch}, payload = {}) {
            return service.syncBookings(payload.id, payload).then((response) => {
                return response.data.data;
            });
        },
        syncResources({commit, dispatch}, payload = {}) {
            return service.syncResources(payload.id).then((response) => {
                return response.data.data;
            });
        },
        syncAll({commit, dispatch}, payload = {}) {
            return service.syncAll().then((response) => {
                return response.data.data;
            });
        },
    }
});

export default store.toObject();
