<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/closed-hour">Holidays & exceptions</router-link>
                            </li>
                            <li>
                                {{ closedHour ? closedHour.id : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <label class="label">Closed days</label>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-datepicker
                                    v-model="form.fields.startDate"
                                    placeholder="Start date"
                                    icon="calendar"
                                    horizontal-time-picker
                                    editable
                                    :locale="datePickerLocale">
                                </b-datepicker>
                                <FormError field="start_date" :form="form"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <b-datepicker
                                    v-model="form.fields.endDate"
                                    placeholder="End date"
                                    icon="calendar"
                                    horizontal-time-picker
                                    editable
                                    :locale="datePickerLocale">
                                </b-datepicker>
                                <FormError field="end_date" :form="form"/>
                            </div>
                        </div>
                    </div>
                    <label class="label">Closed hours</label>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-timepicker
                                    :disabled="allDay"
                                    placeholder="From"
                                    icon="clock"
                                    :incrementMinutes="15"
                                    append-to-body
                                    v-model="form.fields.startTime"
                                >
                                </b-timepicker>
                                <FormError field="start_time" :form="form"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <b-timepicker
                                    :disabled="allDay"
                                    placeholder="To"
                                    icon="clock"
                                    :incrementMinutes="15"
                                    append-to-body
                                    v-model="form.fields.endTime"
                                >
                                </b-timepicker>
                                <FormError field="end_time" :form="form"/>
                            </div>
                        </div>
                        <div class="column">
                            <b-checkbox v-model="allDay" @input="handleAllDayToggle">
                                All day
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="field">
                        <b-field label="Description" >
                            <b-input type="textarea" v-model="form.fields.description">
                            </b-input>
                        </b-field>
                        <FormError field="description" :form="form"/>
                    </div>
                    <div class="controls has-margin-top">
                        <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                        <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules'

import moment from "moment";

export default {
    name: 'Form',
    components: {},
    computed: {
        closedHour() {
            return this.closedHourId ? this.$store.getters['closedHour/show'](this.closedHourId) : null;
        },
    },
    async mounted() {
        if(this.$route.params.closedHourId) {
            await this.loadClosedHour();
        }

        this.fillForm();
    },
    data() {
        return {
            closedHourId: null,
            form: new Form({
                startDate: new Date(),
                endDate: new Date(),
                startTime: moment("00:00","HH:mm").toDate(),
                endTime: moment("23:45","HH:mm").toDate(),
                description: null
            }),
            allDay: true,
            loading: false,
            datePickerLocale: undefined
        };
    },
    methods: {
        ...mapActions({
            closedHourDestroy: 'closedHour/destroy',
            closedHourStore: 'closedHour/store',
            closedHourUpdate: 'closedHour/update',
            closedHourShow: 'closedHour/show',
            closedHourCancel: 'closedHour/cancel'
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            let action = null;

            if (this.closedHour) {
                action = this.closedHourUpdate;
            } else {
                action = this.closedHourStore;
            }

            const fields = {
                ...this.form.fields,
                startDate: this.form.fields.startDate ? moment(this.form.fields.startDate).format("YYYY-MM-DD") : null,
                endDate: this.form.fields.endDate ? moment(this.form.fields.endDate).format("YYYY-MM-DD") : null,
                startTime: this.form.fields.startDate ? moment(this.form.fields.startTime).format("HH:mm") : null,
                endTime: this.form.fields.endTime ? moment(this.form.fields.endTime).format("HH:mm") : null,
            }

            action(fields).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/closed-hour');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        loadClosedHour() {
            this.loading = true;

            return this.closedHourShow({
                id: this.$route.params.closedHourId,
                params: {}
            }).then((response) => {
                this.closedHourId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleAllDayToggle() {
          if(this.allDay) {
              this.form.fields = {
                  ...this.form.fields,
                  startTime: moment("00:00","HH:mm").toDate(),
                  endTime: moment("23:45","HH:mm").toDate(),
              }
          }
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Hours',
                message: 'Are you sure you want to delete this hours?',
                onConfirm: () => {
                    this.loading = true;

                    this.closedHourDestroy({id: this.closedHour.id}).then(() => {
                        window.app.snackbar('Hours deleted');

                        this.$router.push({
                            name: 'admin/closed-hour',
                        });

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });
        },
        fillForm() {
            if(this.closedHour) {
                this.form.fields = {
                    ...this.closedHour,
                    startDate: moment(this.closedHour.startDate).toDate(),
                    endDate: moment(this.closedHour.endDate).toDate(),
                    startTime: moment(this.closedHour.startTime, "HH:mm:ss").toDate(),
                    endTime: moment(this.closedHour.endTime, "HH:mm:ss").toDate(),
                }

                this.allDay = this.closedHour.startTime === "00:00:00" && this.closedHour.endTime === "23:45:00";
            }
        }
    }
};
</script>

<style scoped lang="scss">

</style>
