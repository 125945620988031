<template>
    <div>
        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/product">Products</router-link>
                            </li>
                            <li>
                                {{ product ? product.name : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <b-field label="Name *" >
                                    <b-input type="text" v-model="form.fields.name">
                                    </b-input>
                                </b-field>
                                <FormError field="name" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Description" >
                                    <b-input type="textarea" v-model="form.fields.description">
                                    </b-input>
                                </b-field>
                                <FormError field="description" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Important info" >
                                    <b-input type="textarea" v-model="form.fields.importantInfo">
                                    </b-input>
                                </b-field>
                                <FormError field="important_info" :form="form"/>
                            </div>
                            <div class="field">
                                <b-field label="Category">
                                    <b-select placeholder="Select category" v-model="form.fields.categoryId">
                                        <option
                                            v-for="option in categoryOptions"
                                            :value="option.value"
                                            :key="option.value">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="columns">
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Price">
                                            <b-input type="number" step="any" v-model="form.fields.price">
                                            </b-input>
                                        </b-field>
                                        <FormError field="price" :form="form"/>
                                    </div>
                                </div>
                                <div class="column is-one-quarter">
                                    <div class="field">
                                        <b-field label="Tax" >
                                            <b-input type="number" step="any" v-model="form.fields.taxRate">
                                            </b-input>
                                        </b-field>
                                        <FormError field="tax_rate" :form="form"/>
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                             <b-field label="Configuration">
                               <ul>
                                 <li>
                                   <b-checkbox v-model="form.fields.createFromPackage">
                                    Display in booking summary
                                   </b-checkbox>
                                 </li>
                               </ul>
                             </b-field>
                            </div>
                            <div class="field">
                                <b-field label="Status">
                                    <b-select placeholder="Select status" v-model="form.fields.status">
                                        <option
                                            v-for="status in statusOptions"
                                            :value="status.value"
                                            :key="status.value">
                                            {{ status.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <FormError field="status" :form="form"/>
                            </div>
                            <div class="field">
                                <label class="label">Images</label>
                                <FileUpload v-model="form.fields.files" :entity="product" :multiple="true" :extensions="['jpg','jpeg','png']"></FileUpload>
                            </div>
                            <hr/>
                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

import {
    Category,
    Product,
} from '@/internal';

import FileUpload from '@/views/Admin/components/FileUpload';

export default {
    name: 'Form',
    components: {
        FileUpload,
    },
    computed: {
        categories() {
            return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds) : [];
        },
        categoryOptions() {
            return this.categories ? [
                {
                    label: '-- Select Category --',
                    value: null,
                },
                ...this.categories.map((category) => {
                    return {
                        label: category.name,
                        value: category.id,
                    };
                })
            ] : [];
        },
        product() {
            return this.$route.params.productId ? this.$store.getters['product/show'](this.$route.params.productId) : null;
        },
    },
    created() {
        this.loadCategories();

        if(this.product) {
            this.form.fields = {
                ...this.product,
                files: this.product.files ? this.product.files : []
            }
        }
    },
    data() {
        return {
            categoryIds: [],
            form: new Form({
                name: '',
                description: '',
                status: Product.statusOptions[0].value,
                createFromPackage: false,
                files: [],
            }),
            loading: false,
            statusOptions: Product.statusOptions,
        };
    },
    methods: {
        ...mapActions({
            productDestroy: 'product/destroy',
            productStore: 'product/store',
            productUpdate: 'product/update',
            categoryIndex: 'category/index'
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            const fields = {
              ...this.form.fields,
              categories: this.form.fields.categoryId ? [this.form.fields.categoryId]  : []
            };

            let action = null;

            if (this.product) {
                action = this.productUpdate;
            } else {
                action = this.productStore;
            }

            action(Product.preparePayload(fields)).then(() => {
                window.app.snackbar({
                    message: 'Update successfully!',
                });
                this.$router.push('/admin/product');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete() {
            window.app.confirm({
                title: 'Delete Product',
                message: 'Are you sure you want to delete this product?',
                onConfirm: () => {
                    this.loading = true;
                    this.productDestroy({
                        id: this.product.id,
                    }).then(() => {
                        window.app.snackbar('Product Deleted');

                        this.$router.push('/admin/product');

                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadCategories() {
            this.categoryIndex({
                params: {
                    entityType: Category.entityTypeProduct
                }
            }).then((response) => {
                this.categoryIds = response.ids;
            }).catch((error) => {

            }).finally(() => {

            });
        }
    },
    watch: {
        product() {
            if(this.product) {
                this.form.fields = {
                    ...this.product,
                    files: this.product.files ? this.product.files : [],
                }
            }
        },
    }
};
</script>

<style lang="scss">


</style>
