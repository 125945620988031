import {
    ServiceRule as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';


const store = new ResourceStore({
    key: 'serviceRule',
    model: Model,
});

export default store.toObject();
