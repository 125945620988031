<template>
    <div>

        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                Integration Logs
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                    </div>
                </div>
            </div>
        </section>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">
              <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                <div class="column">
                            <b-table
                                :data="data"
                                :columns="columns"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true"
                                @click="handleEdit"
                                @sort="handleChangeSort">
                            </b-table>
                        </div>
                    </div>
                    <div v-if="paginator"  class="columns">
                        <div class="column">
                            <Paginator :paginator="paginator"></Paginator>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';

export default {
    name: 'Index',
    components: {
        Paginator
    },
    computed: {
      data() {
       return this.ids ? this.$store.getters['integrationLog/collection'](this.ids) : [];
      },
      fullPath () {
          return this.$route.fullPath;
      },
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            ids: [],
            columns: [
                {
                    field: 'fullName',
                    label: 'Name',
                },
                {
                    field: 'requestTime',
                    label: 'Request time',
                },
                {
                    field: 'message',
                    label: 'Message',
                },
                {
                    field: 'createdAt',
                    label: 'Date',
                },
                {
                    field: 'status',
                    label: 'Status',
                }
            ],
            paginator: null,
            loading: false
        }
    },
    methods: {
        ...mapActions({
            integrationLogIndex: 'integrationLog/index',
        }),
        handleEdit(row) {
            console.log(row);
            const route = window.app.findRouteByName('admin/integration-log/show', [], {
                params: {
                    integrationLogId: row.id
                },
            });

            this.$router.push(route);
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        loadData() {
            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 10,
                sortBy: this.$route.query.sortBy || 'id',
                sortOrder: this.$route.query.sortOrder || 'desc',
            };

            if (this.search) {
                params.search = this.search;
            }

            if(this.$route.query.status) {
                params.status = this.$route.query.status;
            }

            this.integrationLogIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
