import {
    Model,
} from '@/internal/modules';

import {
    AccountService as AccountService
} from '@/internal';

export default class Account extends Model {
    static get name() {
        return 'Account';
    }
    static get vuexModuleKey() {
        return 'account';
    }
    static get service() {
        return AccountService;
    }

    static get fields () {
        return {};
    }
}
