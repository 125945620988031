<template>
  <Dialog title="Add associated customer" v-model="open" @close="close" @submit="submit" submit-text="Add">
    <div>
      <b-autocomplete
        v-model="search"
        placeholder="Search by name, email or phone"
        :data="filteredCustomers"
        class=""
        field="label"
        @select="handleCustomerSelect"
        @input="handleCustomerSearch"
      >
      </b-autocomplete>
      <div v-if="selectedCustomer" class="mt-4 flex">
        <div class="flex">
          {{ selectedCustomer.name }} <br>
          {{ selectedCustomer.email }} <br>
          {{ selectedCustomer.phoneE164 }} <br>
        </div>
        <div class="flex:1 flex:end">
          <a @click="handleClearCustomer"><i class="fas fa-times"></i>  Clear</a>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/admin/Dialog'
import {debounce} from "lodash";
import {mapActions} from "vuex";
import moment from "moment";

export default {
  name: 'AddAssociatedCustomerDialog',
  components: {
    Dialog,
  },
  props: {
    customerId: {
      type: String,
      required: true
    },
    groupId: {
      type: String|null,
      required: false
    },
    booking: {
      type: Object|null,
      required: false
    }
  },
  computed: {
    filteredCustomers() {
      return this.searchedCustomers.map((customer) => {
        let label = customer.attributes.name;

        if(customer.attributes.origin_name) {
          label += " ("+customer.attributes.origin_name+")";
        }
        if(customer.attributes.user_id) {
          label += " - U"
        }

        return {
          id: customer.id,
          name: customer.attributes.name,
          email: customer.attributes.email,
          phoneE164: customer.attributes.phone_e164,
          user_id: customer.attributes.user_id,
          activeBookingId: customer.attributes.active_booking_id,
          label: label
        }
      })
    },
  },
  data() {
    return {
      open: false,
      error: null,
      search: null,
      performCustomerSearch: debounce(() => {
        if(!this.search) {
          this.searchedCustomers = [];
          return;
        }

        this.loading = true;

        const params = {
          limit: 10,
          search: this.search,
          include: 'activeBooking'
        };

        this.customerSearch({
          params,
        }).then((response) => {
          this.searchedCustomers = response;
        }).finally(() => {
          this.loading = false;
        })
      }, 500),
      searchedCustomers: [],
      selectedCustomer: null,
    }
  },
  methods: {
    ...mapActions({
      customerSearch: 'customer/search',
      groupStore: 'group/store',
      groupCustomerAdd: 'group/customerAdd'
    }),
    handleCustomerSearch() {
      this.performCustomerSearch();
    },
    handleCustomerSelect(customer) {
      if(!customer) {
        return;
      }

      this.selectedCustomer = customer;

      setTimeout(() => {
        this.search = null;
      },300);
    },
    handleClearCustomer() {
      this.selectedCustomer = null;
    },
    handleCreateCustomer() {
      this.newCustomerModalActive = true;
    },
    close() {
      this.handleClearCustomer();
      this.open = false;
    },
    success() {
      this.handleClearCustomer();
      this.$emit('success');
      this.open = false;
    },
    submit() {
      if (this.groupId) {
        this.groupCustomerAdd({
          'id': this.groupId,
          'customer_id': this.selectedCustomer.id
        }).then((response) => {
          this.success();
        }).catch((response) => {
          if(response.response.data && response.response.data.message) {
            this.error = response.response.data.message;
          }
        }).finally(() => {
          this.loading = false;
        });
      } else {
        let params = {
          'customer_ids': [this.customerId, this.selectedCustomer.id]
        }
        if (this.booking) { // Resident group
          params = {
            ...params,
            start_at: moment.utc(this.booking.startAt).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment.utc(this.booking.endAt).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            booking_id: this.booking.id
          }
        } else { // Non-resident group
          params = {
            ...params,
            start_at: moment.utc().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment.utc().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          }
        }

        this.groupStore(params).then((response) => {
          this.success();
        }).catch((response) => {
          if(response.response.data && response.response.data.message) {
            this.error = response.response.data.message;
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
}
</script>
