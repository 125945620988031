import {
    Customer as Model
} from '@/internal';

import {
  ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
  key: 'customer',
  model: Model,
  actions: {
      search({commit}, payload = {}) {
          return service.search(payload).then((response) => {
              return response.data && response.data.data ? response.data.data : [];
          });
      },
      exportData({commit}, id) {
          return service.exportData(id).then((response) => {
              return response.data ? response.data : null;
          });
      },
  }
});

export default store.toObject();
