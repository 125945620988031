import {
  Model,
} from '@/internal/modules';

import {
  GroupService as Service
} from '@/internal';

export default class Group extends Model {
  static get name() {
        return 'Group';
  }
  static get vuexModuleKey() {
      return 'group';
  }
  static get service() {
      return Service;
  }

  static get fields () {
    return {};
  }
}
