<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0">
            <div class="column">
                <div class="has-text-black has-text-weight-normal fs-16 mb-4">Download my data</div>
                <p class="has-text-black fs-14 mb-4">
                    Request the download of all your stored data.
                    Once the download is ready, which can take up to 30 days,
                    we will send you a link to your email so you can download all your information.
                </p>
                <div class="controls has-margin-top">
                    <BButton v-if="isDataDownloadRequested" class="border-radius-12 button--light-success mb-4 button-min-width-200">
                        <img src="/images/check2-success.svg" width="24" class="mr-2"/>
                        REQUESTED
                    </BButton>
                    <BButton v-else type="is-black" class="border-radius-12 mb-4 button-min-width-200" @click="handleDataDownloadRequest">
                        <img src="/images/download2.svg" class="mr-2"/>
                        DOWNLOAD
                    </BButton>
                </div>
            </div>
            <div class="column">
                <div class="has-text-black has-text-weight-normal fs-16 mb-4">Delete my account</div>
                <p class="has-text-black fs-14 mb-4">
                    If you delete your account all your data will be destroyed. This action can not be undone.
                </p>
                <div class="controls has-margin-top">
                    <BButton v-if="isAccountDeleteRequested" class="border-radius-12 button--light-success mb-4">
                        <img src="/images/check2-success.svg" width="24" class="mr-2 button-min-width-200"/>
                        REQUESTED
                    </BButton>
                    <BButton v-else class="border-radius-12 button--light-red mb-4 button-min-width-200" @click="toggleAccountDeleteModal">
                        <img src="/images/warning2-red.svg" class="mr-2"/>
                        Delete
                    </BButton>
                </div>
            </div>
        </div>

        <div v-if="modal" class="new-modal">
            <div class="new-modal--inner has-text-black">
                <div class="is-flex justify-content-between align-items-center mb-4">
                    <h4 class="has-text-weight-semibold fs-22">Are you sure?</h4>
                    <img @click="toggleAccountDeleteModal" src="/images/close2.svg" width="20"/>
                </div>
                <p class="mb-4">If you delete your account all your data will be destroyed. This action can not be undone.</p>
                <BButton class="logout-button border-radius-12 button--light-red mb-4" @click="handleAccountDeleteRequest" expanded >
                    <img src="/images/warning2-red.svg" class="mr-2" />
                    Delete
                </BButton>
                <BButton type="is-black" class="border-radius-12" expanded @click="toggleAccountDeleteModal">CANCEL</BButton>
            </div>
        </div>

    </section>
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: "Data",
    data() {
        return {
            isDataDownloadRequested: false,
            isAccountDeleteRequested: false,
            loading: false,
            modal: false
        }
    },
    computed: {

    },
    methods: {
        ...mapActions({
            requestDataDownload: 'user/requestDataDownload',
            requestAccountDelete: 'user/requestAccountDelete'
        }),
        handleDataDownloadRequest() {
            this.loading = true;

            this.requestDataDownload().then(() => {

                this.isDataDownloadRequested = true;

            }).catch((error) => {
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        toggleAccountDeleteModal() {
            this.modal = !this.modal;
        },
        handleAccountDeleteRequest() {
            this.loading = true;

            this.requestAccountDelete().then(() => {

                this.isAccountDeleteRequested = true;
                this.toggleAccountDeleteModal();

            }).catch((error) => {
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
