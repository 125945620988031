<template>
  <div
    class="flex-inline:center|center gap:4 c:primary f:14 medium p:4|12 r:40 capitalize"
    :class="statusClass"
  >
    {{ status }}
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusClass() {
      return `${this.status.toLowerCase().replace(/ /g, '-')}`;
    },
  },
};
</script>

<style>
.confirmed {
  background-color: var(--color-success-10);
}
.started {
  background-color: var(--color-success-10);
}
.pending {
  background-color: var(--color-primary-10);
}
.canceled {
  background-color: var(--color-error-10);
}
.requested {
  background-color: var(--color-primary-10);
}
.optional {
  background-color: var(--color-primary-10);
}
.finished {
  background-color: var(--color-success-10);
}
.no-show {
  background-color: var(--color-primary-10);
}
.enquired {
  background-color: var(--color-primary-10);
}
</style>
