<template>
  <SideDrawer _class="w:640@lg" :loading="loading" @close="$emit('close')">
    <template v-if="booking">
      <template slot="header">
        <Status :status="booking.status" />
      </template>
      <div class="p:24 flex:between|center">
        <div>
          <div v-if="booking.originId">
            <div class="f:14 bg:accent-10 c:accent-70 r:4 p:4|12 flex:|center semi">
              {{ booking.originName }} ({{ booking.number }})
            </div>
          </div>
          <div v-else>
            <div class="f:14 bg:accent-10 c:accent-70 r:4 p:4|12 flex:|center medium">
              {{ booking.number }}
            </div>
          </div>
        </div>
        <div class="f:14 c:base-40 medium">Created: {{ dateTimeFormat(booking.createdAt) }}</div>
      </div>
      <div class="ofy:auto flex:1 p:20 c:accent-70">
        <div class="columns">
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="book" />
              Booking number
            </div>
            <div class="semi">{{ booking.number }}</div>
          </div>
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="spark" />
              Service
            </div>
            <div class="semi">
              {{ booking.service ? booking.service.name : 'N/A' }}
            </div>
            <p v-if="booking.category">Category: {{ booking.category.name }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="user-square" />
              Customers
            </div>
            <div class="semi mb:8">{{ booking.customer.name }}</div>
            <div class="mb:8">{{ booking.customer.email }}</div>
            <div class="mb:8">{{ booking.customer.phoneE164 }}</div>
            <router-link class="flex-inline:|center gap:4 c:primary semi b:base-20 p:8|12 r:8"
              :to="`/admin/customer?show=${booking.customer.id}`">
              View
              <Icon admin name="arrow-up-right" />
            </router-link>
          </div>
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="user-group" />
              Persons
            </div>
            <div class="semi f:22">{{ booking.totalPersons }}</div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="clock" />
              Time
            </div>
            <div class="semi mb:8">{{ timeFormat(booking.startAt) }} - {{ timeFormat(booking.endAt) }}</div>
            <div>{{ booking.totalDuration }} minutes</div>
          </div>
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="calendar" />
              Date
            </div>
            <div class="semi">
              {{ booking.startAt.format('dddd, DD-MM-YYYY') }}
            </div>
          </div>
        </div>
        <template v-if="booking.group">
          <div class="field">
            <label class="label">Group: {{ booking.group.name }}</label>
          </div>
          <div v-if="booking.isGroup" class="field">
            <div class="table-container">
              <table class="table is-striped">
                <tr v-for="booking in booking.group.bookings">
                  <td>#{{ booking.number }}</td>
                  <td>{{ booking.category ? booking.category.name : 'N/A' }}</td>
                  <td>
                    {{ booking.startAt.format('D MMM YY') }} - {{ booking.endAt.format('D MMM YY') }} ({{
                      booking.nightsCount
                    }}
                    nights)
                  </td>
                  <td>{{ booking.status }}</td>
                  <td>
                    <template v-if="booking.id">
                      <router-link :to="`/admin/booking/${booking.id}/update`">Manage</router-link>
                    </template>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template v-if="service.subtype === 'restaurant'">
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                Room
              </div>
              <SelectInput v-model="form.fields.service_space_id"
                           placeholder="N/A"
                           :options="spaceOptions"
                           @input="handleRoomSelect"></SelectInput>
              <FormError field="service_space_id" :form="form" />
            </div>
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                Table
              </div>
              <SelectInput v-model="form.fields.service_item_id"
                           placeholder="N/A"
                           :options="itemOptions"
                           :disabled="!form.fields.service_space_id"></SelectInput>
              <FormError field="service_item_id" :form="form" />
            </div>
          </div>
        </template>

        <template v-if="booking.activityType === 'restaurant'">
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                <Icon admin name="note" />
                Package
              </div>
              <SelectInput v-model="form.fields.package" :options="packageOptions"></SelectInput>
              <FormError field="package" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                <Icon admin name="note" />
                Dietary requirements
              </div>
              <textarea class="styled r:8 p:12" v-model="form.fields.dietary_requirements" />
              <FormError field="dietary_requirements" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                <Icon admin name="note" />
                Special occasion
              </div>
              <textarea class="styled r:8 p:12" v-model="form.fields.special_occasion" />
              <FormError field="special_occasion" :form="form" />
            </div>
          </div>
        </template>

        <template v-if="booking.activityType === 'spa'">
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                <Icon admin name="note" />
                Form status
              </div>
              <SelectInput v-model="form.fields.form_status" :options="formStatusOptions"></SelectInput>
              <FormError field="form_status" :form="form" />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="c:base-50 flex:|center gap:8 semi mb:8">
                <Icon admin name="note" />
                Shoe sizes
              </div>
              <textarea class="styled r:8 p:12" v-model="form.fields.shoe_sizes" />
              <FormError field="shoe_sizes" :form="form" />
            </div>
          </div>
        </template>

        <div class="columns">
          <div class="column">
            <div class="c:base-50 flex:|center gap:8 semi mb:8">
              <Icon admin name="note" />
              Notes
            </div>
            <textarea class="styled r:8 p:12" rows="8" v-model="form.fields.note" />
            <FormError field="note" :form="form" />
          </div>
        </div>
      </div>

      <div slot="footer" class="t:right">
        <button
          class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
          @click="handleSave"
        >
          <Icon admin name="check" />
          Save
        </button>
      </div>
    </template>
  </SideDrawer>
</template>

<script>
import mixin from '@/mixin'
import moment from 'moment'
import { Form } from '@/internal/modules'
import { mapActions } from 'vuex'
import { Booking } from '@/internal'
import Status from '@/components/admin/Status'
import SideDrawer from '@/components/admin/SideDrawer.vue'

export default {
  name: 'BookingUpdateModal',
  mixins: [mixin],
  components: {
    Status,
    SideDrawer,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      form: new Form({
        note: null,
        dietary_requirements: null,
        special_occasion: null,
        shoe_sizes: null,
        package: null,
        form_status: null,
        service_space_id: null,
        service_item_id: null
      }),
      bookingId: null,
      loading: false,
      itemIds: [],
      spaceIds: [],
      packageOptions: Booking.packageOptions,
      formStatusOptions: Booking.formStatusOptions,
    }
  },
  computed: {
    isSyncable() {
      return this.booking && this.booking.originName === 'Mews' && this.booking.type === 'accommodation'
    },
    booking() {
      return this.bookingId ? this.$store.getters['booking/show'](this.bookingId) : null
    },
    service() {
      return this.booking ? this.booking.service : null
    },
    serviceItems() {
      return this.itemIds ? this.$store.getters['serviceItem/collection'](this.itemIds) : []
    },
    serviceSpaces() {
      let serviceSpaces = [];
      this.serviceItems.forEach(serviceItem => {
        if (serviceItem.serviceSpaceId && !serviceSpaces.find(s => parseInt(s.id) === parseInt(serviceItem.serviceSpaceId))) {
          serviceSpaces = [
            ...serviceSpaces,
            {
              'id': serviceItem.serviceSpaceId,
              'title': serviceItem.serviceSpaceTitle
            }
          ]
        }
      })
      return serviceSpaces;
    },
    itemOptions() {
      return [
        {
          label: "N/A",
          value: null,
        },
        ...this.serviceItems.filter(item => {
          return this.form.fields.service_space_id && parseInt(this.form.fields.service_space_id) === parseInt(item.serviceSpaceId);
        }).map(item => {
          return {
            label: item.title,
            value: parseInt(item.id),
            disabled: !item.available
          }
        })
      ]
    },
    spaceOptions() {
      return [
        {
          label: "N/A",
          value: null,
        },
        ...this.serviceSpaces.map(space => {
          return {
            label: space.title,
            value: parseInt(space.id),
          }
        })
      ]
    },
  },
  async mounted() {
    await this.loadBooking(this.id)
    if (this.service.subtype) {
      await this.loadServiceItems();
    }
    this.fillForm()
  },
  methods: {
    ...mapActions({
      bookingUpdate: 'booking/update',
      bookingCancel: 'booking/cancel',
      bookingSync: 'booking/sync',
      bookingShow: 'booking/show',
      serviceItemAvailable: 'serviceItem/available',
      serviceSpaceIndex: 'serviceSpace/index'
    }),
    loadServiceItems() {
      this.loading = true

      return this.serviceItemAvailable({
        params: {
          service_id: this.booking.serviceId,
          start_at: moment(this.booking.startAt).format('YYYY-MM-DD HH:mm:ss'),
          end_at: moment(this.booking.endAt).format('YYYY-MM-DD HH:mm:ss'),
          persons: this.booking.totalPersons,
          update_booking_id: this.booking.id
        }
      }).then((response) => {
          this.itemIds = response;
      }).catch((error) => {
      }).finally(() => {
          this.loading = false
      })
    },
    loadBooking(id) {
      this.loading = true

      return this.bookingShow({
        id: id,
        params: {
          include: ['customer', 'group.bookings'],
        },
      })
        .then((response) => {
          this.bookingId = response[0]
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    timeFormat(value) {
      return this.dateFormatTimezone(value).format('hh:mm a')
    },
    dateFormat(value) {
      return this.dateFormatTimezone(value).format('D MMM YY')
    },
    dateTimeFormat(value) {
      return this.dateFormatTimezone(value).format('D MMM YY hh:mm A')
    },
    handleSync() {
      this.loading = true

      return this.bookingSync(this.booking.id)
        .then(() => {
          this.loadBooking()
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleCancel() {
      window.app.confirm({
        title: 'Cancel Booking',
        message: 'Are you sure you want to cancel this booking?',
        onConfirm: () => {
          this.loading = true

          return this.bookingCancel({
            id: this.booking.id,
          })
            .then((response) => {
              const route = window.app.findRouteByName('admin/booking/update', [], {
                params: {
                  bookingId: this.booking.id,
                },
              })

              this.$router.push(route)
            })
            .catch((error) => {
              console.log('error')
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    },
    handleSave() {
      this.loading = true
      this.form.errors.clear()

      this.bookingUpdate({
        ...this.form.fields,
        params: {
          include: ['customer', 'group.bookings'],
        },
      })
        .then(() => {
          this.$emit('success')
        })
        .catch((error) => {
          this.form.recordErrors(error)
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fillForm() {
      if (this.booking) {
        this.form.fields = {
          id: this.booking.id,
          note: this.booking.note,
          dietary_requirements: this.booking.dietaryRequirements,
          special_occasion: this.booking.specialOccasion,
          shoe_sizes: this.booking.shoeSizes,
          package: this.booking.package,
          form_status: this.booking.formStatus,
          service_space_id: this.booking.serviceSpaceId,
          service_item_id: this.booking.serviceItemId,
        }
      }
    },
    handleRoomSelect(value) {
      if(!value) {
        this.form.fields.serviceItemId = null
      }
    }
  },
}
</script>

<style scoped lang="scss">
textarea.styled {
  background-color: rgb(244, 245, 247);
  border: unset;
  width: 100%;
  resize: vertical;
  font-weight: 500;
  color: #5b6676;
  border: 1px solid #c4cad3;
}
</style>
