import {
    Model,
} from '@/internal/modules';

import {
    IntegrationLogService as Service
} from '@/internal';

export default class IntegrationLog extends Model {
    static get name() {
        return 'IntegrationLog';
    }
    static get vuexModuleKey() {
        return 'integrationLog';
    }
    static get service() {
        return Service;
    }

    static get fields() {
        return {}
    }

}
