import {
    ClosedHour as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';


const store = new ResourceStore({
    key: 'closedHour',
    model: Model,
});

export default store.toObject();
