<template>
    <div>
        <b-loading v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/user">Users</router-link>

                            </li>
                            <li>
                                {{ user ? user.email : "New" }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="">
                        <div class="">
                            <div class="field">
                                <BField label="Name *" >
                                    <BInput type="text" v-model="form.fields.username">
                                    </BInput>
                                </BField>
                                <FormError field="username" :form="form"/>
                            </div>

                            <div class="field">
                                <BField label="Email *" >
                                    <BInput type="email" v-model="form.fields.email" :disabled="isExisting">
                                    </BInput>
                                </BField>
                                <FormError field="email" :form="form"/>
                            </div>

                            <div v-if="!isExisting" class="field">
                                <b-checkbox v-model="form.fields.sendInvitation">
                                    Send invitation email
                                </b-checkbox>
                            </div>

                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                                <b-button v-if="isExisting" class="is-pulled-right" type="is-danger" @click="handleDelete" outlined>Delete</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import moment from 'moment';
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Form',
    computed: {
        user() {
            return this.$route.params.userId ? this.$store.getters['user/show'](this.$route.params.userId) : null;
        },
        isExisting() {
            return this.user && this.user.id;
        }
    },
    mounted() {
        if(this.user) {
            this.fillForm();
        }
    },
    data() {
        return {
            form: new Form({
                username: '',
                email: '',
                sendInvitation: false
            }),
            loading: false,
            calendar: null,
            moment,
            currentDate: moment().format('YYYY-MM-DD')
        };
    },
    methods: {
        ...mapActions({
            serviceIndex: 'service/index',
            userDestroy: 'user/destroy',
            userStore: 'user/store',
            userUpdate: 'user/update',
            userCalendar: 'user/calendar',
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            let action = null;

            if (this.user) {
                action = this.userUpdate;
            } else {
                action = this.userStore;
            }

            action({
                ...this.form.fields,
            }).then(() => {
                window.app.snackbar({
                    message: 'Saved successfully!',
                });
                this.$router.push('/admin/user');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleDelete () {
            window.app.confirm({
                title: 'Delete User',
                message: 'Are you sure you want to delete this user?',
                onConfirm: () => {
                    this.loading = true;
                    this.userDestroy({
                        id: this.user.id,
                    }).then(() => {
                        window.app.snackbar('User Deleted');
                        this.$router.push('/admin/user');
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadCalendar(date) {
            this.currentDate = date
            this.loading = true;

            const payload =  {
                id: this.$route.params.userId,
                params: {
                    date
                }
            };

            this.userCalendar(payload).then((response) => {
                this.calendar = response;
            }).finally(() => {
                this.loading = false;
            });
        },
        fillForm() {
            if(!this.user){
                return;
            }

            this.form.fields = {
                id: this.user.id,
                email: this.user.email,
                username: this.user.username
            }
        }
    },
    watch: {
        user() {
            this.fillForm();
        },
    }
};
</script>

<style scoped>

</style>
