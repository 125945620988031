<template>
    <div>
        <div class="tabs">
            <ul>
                <li
                    :class="{'is-active': ['admin/customer/create', 'admin/customer/update'].includes(routeName)}"
                    @click="handleClick('admin/customer/update')">
                    <a>Overview</a>
                </li>
                <li :class="{'is-active': ['admin/customer/activity-log'].includes(routeName), 'disabled': newEntity}"
                    @click="handleClick('admin/customer/activity-log')">
                    <a>Activity</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormMenu",
    computed: {
        newEntity() {
            return !this.$route.params.customerId;
        },
        routeName() {
            return this.$route.name;
        }
    },
    mounted() {
    },
    methods: {
        handleClick(routeName) {
            const route = window.app.findRouteByName(routeName, [], {
                params: {
                    customerId: this.$route.params.customerId
                },
            });

            this.$router.push(route);
        }
    },
}
</script>

<style scoped lang="scss">

.disabled {
    pointer-events:none;
    opacity:0.6;
}

</style>


