<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="p:12 flex:between|center">
          <div class="font--primary f:36 bold">
            Customers
          </div>
          <button
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="handleNew"
          >
            <Icon admin name="user-plus" />
            New Customer
          </button>
        </div>
        <div class="p:12 flex:between|center">
          <SelectInput
            v-model="customerFilter"
            :options="customerFilterOptions"
            placeholder="All customers"
            @input="handleCustomerFilterSelect" />

          <div class="new-input">
            <b-input placeholder="Search..."
                     ref="search"
                     type="search"
                     icon="magnifying-glass"
                     v-model="search"
                     @input="handleSearch"
            >
            </b-input>
            <div v-show="!search || search === ''" class="new-input-icon new-input-icon--right new-input--search">
              ⌘F
            </div>
          </div>

         </div>
        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">

              <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                <div class="column">
                            <b-table
                              sticky-header
                              height="calc(100vh - 324px)"
                                :data="data"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true"
                                @click="handleEdit"
                                @sort="handleChangeSort">

                                <b-table-column field="name" label="Name" v-slot="props">
                                    {{ props.row.name }}
                                </b-table-column>

                                <b-table-column field="email" label="Email" v-slot="props">
                                    {{ props.row.email }}
                                </b-table-column>

                                <b-table-column field="phoneE164" label="Phone" v-slot="props">
                                    {{ props.row.phoneE164 }}
                                </b-table-column>

                                <b-table-column field="originName" label="Origin" v-slot="props">
                                    {{ props.row.originName }}
                                </b-table-column>

                                <b-table-column label="Info" v-slot="props">
                                    <i v-if="props.row.userId" class="fa-solid fa-user" title="Has user account"></i>
                                    <i v-if="props.row.activeBookingId" class="fa-solid fa-house-chimney-user" title="Resident"></i>
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                    <div v-if="paginator"  class="columns">
                        <div class="column">
                            <Paginator :paginator="paginator"></Paginator>
                        </div>
                    </div>


                </div>
            </div>

        </section>

        <CustomerModal
          v-if="$route.query.show && dataLoaded"
          @close="$router.back()"
        />
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import Paginator from '@/views/Admin/components/Paginator';
import CustomerModal from '@/components/admin/Customer/CustomerModal/CustomerModal.vue';
import {debounce} from "lodash";

export default {
    name: 'Index',
    components: {
        Paginator,
        CustomerModal
    },
    computed: {
      data() {
       return this.ids ? this.$store.getters['customer/collection'](this.ids) : [];
      },
      relevantQueryParams() {
        const { show, ...rest } = this.$route.query;
        return JSON.stringify(rest);
      }
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            ids: [],
            columns: [
                {
                    field: 'name',
                    label: 'Name',
                },
                {
                    field: 'email',
                    label: 'Email',
                },
                {
                    field: 'phoneE164',
                    label: 'Phone',
                },
                {
                    field: 'originName',
                    label: 'Origin',
                },
            ],
            customerFilterOptions: [
                {
                    value: 'all',
                    label: 'All customers',
                },
                {
                    value: 'with-user-account',
                    label: 'Customers with user account',
                },
            ],
            paginator: null,
            search: this.$route.query.search || null,
            customerFilter: this.$route.query.customerFilter || 'all',
            performSearch: debounce(() => {
                const route = Object.assign({}, this.$route);

                let query = {...route.query};

                if(this.search && this.search !== "") {
                    query.search = this.search;
                } else {
                    delete query.search;
                }

                this.$router.replace({
                    name: route.name,
                    query: query
                });
            }, 1000),
            loading: false,
            dataLoaded: false
        }
    },
    watch: {
        relevantQueryParams() {
            this.loadData();
        }
    },
    methods: {
        ...mapActions({
            customerIndex: 'customer/index',
        }),
        handleNew() {
          this.$router.push({ query: { ...this.$route.query, show: 'create' } });
        },
        handleEdit(row) {
          this.$router.push({ query: { ...this.$route.query, show: row.id } });
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.replace({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        handleSearch() {
            this.performSearch();
        },
        handleCustomerFilterSelect() {
            const route = Object.assign({}, this.$route);

            this.$router.replace({
                name: route.name,
                query: {
                    ...route.query,
                    customerFilter: this.customerFilter,
                }
            });
        },
        loadData() {
            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 100,
                sortBy: this.$route.query.sortBy || 'created_at',
                sortOrder: this.$route.query.sortOrder || 'desc',
            };

            if (this.search) {
                params.search = this.search;
            }

            if(this.customerFilter) {
                params.customerFilter = this.customerFilter;
            }

            this.customerIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
                this.dataLoaded = true;
            })
        }
    },
}
</script>
