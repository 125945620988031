import {
    Booking as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'booking',
    model: Model,
    actions: {
        groupUpdate({commit}, payload = {}) {
            return service.groupUpdate(payload.id, payload).then((response) => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        },
        statusUpdate({commit}, payload = {}) {
            return service.statusUpdate(payload.id, payload).then((response) => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        },
        reschedule({commit}, payload = {}) {
            return service.reschedule(payload.id, payload).then((response) => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        },
        cancel({commit}, payload = {}) {
            return service.cancel(payload.id, payload).then((response) => {
                return ResourceStore.processData(this.key, commit, response.data);
            });
        },
        sync({commit}, id) {
            return service.sync(id).then((response) => {
                return response.data;
            });
        },
        customerAdd({commit}, payload = {}) {
          return service.customerAdd(payload.id, payload).then((response) => {
            return response.data;
        });
      },
    }
});

export default store.toObject();
