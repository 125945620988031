import {
    Model
} from '@/internal/modules';

import {
    File,
    AnnouncementService as AnnouncementService
} from '@/internal';

export default class Announcement extends Model {

    static get name() {
        return 'Announcement';
    }

    static get vuexModuleKey() {
        return 'announcement';
    }

    static get service() {
        return AnnouncementService;
    }

    static get fields () {
        return {
            files: {
                preparePayload: (value) => {
                    return value ? value.map(file => file.id) : [];
                },
                type: File,
            }
        };
    }

    get files() {
        return this.hasMany(File);
    }

    static get statusOptions() {
        return [
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Published',
                value: 'published',
            }
        ]
    }

    static get guestVisibilityOptions() {
        return [
            {
                label: 'Any guest',
                value: 'any',
            },
            {
                label: 'Resident only',
                value: 'resident',
            },
            {
                label: 'Non-resident only',
                value: 'non-resident',
            }
        ]
    }

}
