<template>
    <section class="section authentication height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <div class="columns">
                    <div class="column">
                        <div class="mb-8">
                            <img src="/images/compass.svg" alt="compass" class="compass-bg">
                        </div>

                        <div class="has-text-black has-text-weight-semibold mb-4 fs-22">
                            Reset your password
                        </div>

                        <div class="has-text-black mb-4">
                            Enter your email to receive instructions to reset your password.
                        </div>

                        <div :class="['field is-relative', {'input--error': form.errors.has('email')}]">
                            <BField label="Email">
                                <BInput type="email" v-model="form.fields.email"></BInput>
                            </BField>
                            <img v-if="form.errors.has('email')" src="/images/warning2-red.svg" class="input__icon" />
                            <FormError field="email" :form="form"/>
                        </div>

                    </div>
                </div>
                <div class="mt-auto pb-4">
                    <div class="controls has-margin-top">
                        <BButton type="is-black" class="border-radius-12" expanded @click="handleForgotPassword">SEND PASSWORD RESET EMAIL</BButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal/modules';

export default {
  name: 'ForgotPassword',
  components: {},
  computed: {

  },
  data() {
    return {
      form: new Form({
        email: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword',
    }),
    handleForgotPassword() {

      this.loading = true;
      this.forgotPassword({
        ...this.form.fields,
      }).then(() => {
        this.$router.push('/auth/forgot-password/email-confirmation');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
