import {
    Product as Model
} from '@/internal';

import {
    ResourceStore,
    Paginator
} from '@/internal/modules';

const service = new Model.service();

const store = new ResourceStore({
    key: 'product',
    model: Model,
    actions: {
        available({commit}, payload = {}) {
            return service.available(payload).then((response) => {
                const ids = ResourceStore.processData('product', commit, response.data, payload && payload.replaceStore === true);

                let paginator = null;

                if (response.data.meta && response.data.meta.pagination) {
                    paginator = new Paginator(response.data.meta.pagination);
                }

                return {
                    ids,
                    paginator,
                };
            });
        },
    }
});

export default store.toObject();
