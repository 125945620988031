<template>
    <div class="min-height-100">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>

        <section class="section section-grey height-full p-0 pb-100">
            <div v-if="service && category" class="container container-extra-small p-0">
                <div class="column">
                    <div class="position-relative">
                        <template v-if="service.imageUrl">
                            <div class="service-card-img" :style="{ 'background-image': 'url('+service.imageUrl+')' }"></div>
                        </template >
                        <template v-else>
                            <div class="service-card-img" :style="{ 'background-image': 'url(https://bulma.io/images/placeholders/1280x960.png)' }"></div>
                        </template>
                    </div>
                    <div class="is-flex align-items-center border-bottom-black pb-3">
                        <div class="is-size-5 mr-auto">
                            {{ category.name }}
                        </div>
                    </div>
                    <div class="is-flex align-items-center pt-3">
                        <div class="mr-auto">
                            {{ category.description }}
                        </div>
                    </div>
                </div>
                <div class="column">
                    <BButton type="is-black" rounded expanded @click="handleBook">book</BButton>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "ShowAccommodation",
    created() {
        this.loadService();
        this.loadCategory();
    },
    data() {
        return {
            categoryId: null,
            serviceId: null,
            loading: false,
        };
    },
    computed: {
        category() {
            return this.categoryId ? this.$store.getters['category/show'](this.categoryId) : null;
        },
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.$route.params.serviceUuid) : null;
        },
    },
    methods: {
        ...mapActions({
            serviceShow: 'service/show',
            categoryShow: 'category/show'
        }),
        loadService() {
            this.loading = true;

            return this.serviceShow({
                id: this.$route.params.serviceUuid,
                params: {
                    include: ['serviceWorkingHours']
                }
            }).then((response) => {
                this.serviceId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        loadCategory() {
            this.loading = true;

            return this.categoryShow({
                id: this.$route.query.categoryId
            }).then((response) => {
                this.categoryId = response[0];

                this.updatePageTitle();
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        handleBook() {
            const route = window.app.findRouteByName('service/accommodation/book', [], {
                params: {
                    serviceUuid: this.service.id,
                },
                query: {
                    categoryId: this.category.id
                }
            });

            this.$router.push(route);

        },
        updatePageTitle() {
            window.app.vue.$emit(`pageTitleUpdate`, this.category.name);
        }
    },
}
</script>

<style scoped>
.section-grey {
    background-color: #D8D5D4;
}
.min-height-100 {
    height: 1px;
    min-height: 100%;
}
</style>
