<template>
    <div>
        <div class="is-flex calendar m-0">
            <div class="calendar-current-time"></div>
            <div>
                <div class="calendar-cell calendar-cell-head"></div>
                <p class="is-flex is-justify-content-center is-align-items-center calendar-cell-time"
                   v-for="hour in hours">
                    {{ hour }}
                </p>
            </div>
            <div class="calendar-scroll is-flex">
                <div v-for="(timeslots, day) in dates">
                    <div class="calendar-cell calendar-cell-head is-flex is-justify-content-center is-align-items-center px-4">{{ formatDay(day) }}</div>
                    <template v-for="slot in timeslots">
                        <p @click="handleSlotSelect(slot)" :title="formatTitle(slot)"
                           :class="[
                            'calendar-cell is-flex is-justify-content-center is-align-items-center is-clickable',
                            { 'border-dashed-grey-top' : ['00', '30'].includes(slot.end.split(':')[1])},
                            { 'border-dashed-grey-bottom' : ['15', '45'].includes(slot.end.split(':')[1])},
                        ]"
                           :style="{'background-color': `${slot.color}60`}"
                        >
                            {{ formatTime(slot) }}
                        </p>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import mixin from "@/mixin";

export default {
    name: 'WeeklyCalendar',
    mixins: [mixin],
    computed: {
      hours() {
          const hours = [];

          for (let i=9; i < 21; i++) {
              let date = moment().hour(i).minutes(0).format("HH:mm");
              hours.push(date);
          }

          return hours;
      },
     timezone() {
         return this.$store.state.system.timezone;
     },
    },
    props: {
        dates: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {};
    },
    mounted() {
        const cells = Array.from(document.querySelectorAll('.calendar-cell-time'))
        const currentTime = moment().format('HH:mm');
        const currentTimeLine = document.querySelector('.calendar-current-time');
        const calendar = document.querySelector('.calendar');
        if (cells) {
            const cell = cells.find(cell => cell.innerText.split(':')[0] === currentTime.split(':')[0])

            if (cell) {
                currentTimeLine.style.top = `${cell.offsetTop + parseInt(currentTime.split(':')[1])}px`
                currentTimeLine.style.width = `${calendar.scrollWidth}px`
            }
        }
    },
    methods: {
        handleSlotSelect(slot) {
            if(!slot.available) {

                // Navigate to booking page if exists
                if(slot.booking_ids && slot.booking_ids.length) {
                    const route = window.app.findRouteByName('admin/booking/update', [], {
                        params: {
                            bookingId: slot.booking_ids[0]
                        },
                    });

                    this.$router.push(route);
                }

                return;
            }

            this.$emit('select', slot);
        },
        formatTime(slot) {
            return this.dateFormatTimezone(slot.start).format("HH:mm");
        },
        formatDay(date) {
            return moment(date).format('dddd, MMMM Do YYYY');
        },
        formatTitle(slot) {
            const bookingIds = slot.booking_ids ? slot.booking_ids.join(',') : 0;

            return "Bookings:" + bookingIds + "; Persons:" + slot.persons_count;
        }
    }
}
</script>

<style scoped lang="scss">
.calendar {
    position: relative;

    &-scroll {
        overflow-x: auto;
        white-space: nowrap;
    }

    &-current-time {
        position: absolute;
        background: red;
        height: 2px;
    }

    &-cell {
        min-width: 120px;
        height: 20px;
        border: 1px solid black;
        font-size: 12px;
        color: rgb(0 0 0 / 40%);

        &-head {
            height: 30px;
            color: #000000;
        }

        &-time {
            width: 120px;
            height: 80px;
            border: 1px solid black;
        }
    }
}

.border-dashed-grey {
    &-top {
        border-top: 1px dashed black;
    }
    &-bottom {
        border-bottom: 1px dashed black;
    }
}
</style>
