<template>
    <div class="background" :style="{'background-image' : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.77%), url(${backgroundImage})`}" >
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div v-if="service" class="is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="text-center mt-4">
                <img :src="account && account.branding.logoUrl" alt="Finnlough" style="max-width: 240px;">
            </div>
            <div class="welcome_title text-center has-text-white font-primary fs-55 line-height-1">
                Continue to Book {{ service.title }}
            </div>
            <div class="columns">
                <div class="column text-center">
                    <button
                        class="login-btn button is-white pointer is-fullwidth is-flex is-justify-content-start border-radius-12"
                        @click="$router.push({name: 'auth/sign-up'})"
                    >
                        <div class="mx-2">
                            <img class="login-btn__img icon__social-media mr-2" src="/images/envelope2.svg" alt="Envelope">
                        </div>
                        <div class="has-text-weight-medium">Continue with email</div>
                    </button>
                </div>
                <!--  Google login-->
                <div class="column text-centered">
                    <button
                        id="button-google"
                        class="login-btn button is-white pointer is-fullwidth is-flex is-justify-content-start border-radius-12"
                        @click="handleGoogleLogin"
                    >
                        <div class="mx-2">
                            <img class="login-btn__img icon__social-media mr-2" src="/images/google2.svg" alt="Google logo">
                        </div>
                        <div class="has-text-weight-medium">Continue with google</div>
                    </button>
                </div>
                <!--  Facebook login-->
                <div class="column text-centered">
                    <button
                        class="login-btn button is-white pointer is-fullwidth is-flex is-justify-content-start border-radius-12"
                        @click="handleFacebookLogin"
                    >
                        <div class="mx-2">
                            <img class="login-btn__img icon__social-media mr-2" src="/images/facebook2.svg" alt="Facebook logo">
                        </div>
                        <div class="has-text-weight-medium">Continue with facebook</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: "Welcome",
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
        backgroundImage() {
            return this.service && this.service.image_url ? this.service.image_url : this.account && this.account.branding.homeImageUrl;
        },
        account() {
            return this.$store.getters['auth/account'];
        }
    },
    data() {
        return {
            loading: false,
            service: null
        };
    },
    async mounted() {
        await this.loadService();

        if(this.service) {
            window.app.storage.setItem('redirectRoute', this.generateRedirectRoute());
        } else {
            this.redirectToWelcome();
        }

        // If logged in, redirect to booking page automatically
        if(this.user) {
            this.$router.push(window.app.auth.redirectRouteAfterLogin());
            window.app.auth.clearRedirectRoute();
        }
    },
    methods: {
        ...mapActions({
            googleLogin: 'auth/googleLogin',
            facebookLogin: 'auth/facebookLogin',
            serviceShowBySlug: 'service/showBySlug',
        }),
        handleGoogleLogin() {
            window.location.href = "/auth/google";
        },
        handleFacebookLogin() {
            window.location.href = "/auth/facebook";
        },
        loadService() {
            this.loading = true;

            return this.serviceShowBySlug(this.$route.params.slug).then((response) => {
                this.service = {
                    id: response.data.id,
                    ...response.data.attributes
                };
            }).catch((error) => {
                // Error
            }).finally(() => {
                this.loading = false;
            });
        },
        redirectToWelcome() {
            this.$router.push('/welcome');
        },
        generateRedirectRoute() {
            return {
                name: 'service/activity/book',
                params: {
                    serviceUuid: this.service.id
                },
                query: {
                    ...this.$route.query
                }
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    & > div {
        height: 100%;
        padding: 30px 20px;
    }
}
.login-btn {
    &__img {
        width: 30px;
    }

    &__text {
        //width: 100%;
    }
}

.icon__envelope {
    margin-left: -9px
}

.icon__social-media {
    margin-left: -10px
}

.welcome_title {
    text-shadow: 0px 0px 13px rgba(0, 0, 0, 0.78);
}

</style>
