import {
    Model,
} from '@/internal/modules';

import {
    CartServiceService as CartServiceService
} from '@/internal';

export default class CartService extends Model {
    static get name() {
        return 'CartService';
    }
    static get vuexModuleKey() {
        return 'cartService';
    }
    static get service() {
        return CartServiceService;
    }

    static get fields () {
        return {

        };
    }

}
