export default {
  setItem(key, value) {
    return typeof value === 'string' ? window.localStorage.setItem(key, value) : window.localStorage.setItem(key, JSON.stringify(value));
  },
  getItem(key) {
    const value = window.localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  removeItem(key) {
    return window.localStorage.removeItem(key);
  },
};
