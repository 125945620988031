import { ResourceService } from '@/internal/modules';

export default class BookingService extends ResourceService {
    constructor() {
        super();
        this.baseUrl = 'booking';
    }

    groupUpdate(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/group-update`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    statusUpdate(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/status-update`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    reschedule(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/reschedule`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    cancel(id, payload = {}) {

        const { params, formData, ...rest } = payload;

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/cancel`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    sync(id) {

        return this.request({
            method: 'put',
            url: `${this.baseUrl}/${id}/sync`
        });
    }

    customerAdd(id, payload = {}) {

      const { params, formData, ...rest } = payload;

      return this.request({
        method: 'post',
        url: `${this.baseUrl}/${id}/customer/add`,
        params: this.prepareParams(params),
        data: this.preparePayload(payload)
    });
  }

}
