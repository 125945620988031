<template>
  <div>
    <BLoading active v-if="loading" />
    <div class="columns">
      <div class="column">
        <b-field>
          <b-upload class="file-label" v-model="newFiles" :multiple="multiple" @input="handleFilesUpload">
            <div class="reset flex:|center gap:8 f:16 semi p:8|12 r:8 o:0.9:hover bg:primary c:white">
              <Icon admin name="upload" />
              Upload image
            </div>
          </b-upload>
        </b-field>
      </div>
    </div>
    <div class="flex gap:16 is-desktop files-container">
      <div v-for="(file, index) in files" class="r:8 w:140 of:hidden">
        <figure class="image">
          <div class="file-content" @click="openFile(file)">
            <b-image :src="file.url" ratio="6by4"></b-image>
          </div>
          <button
            class="reset abs:4|4 w:24 h:24 flex:center|center r:50 bg:primary-10 c:base-70 o:0.9:hover"
            @click="handleRemoveImage(file, index)"
          >
            <Icon admin name="trash" class="w:16 h:16" />
          </button>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FileUpload',
  components: {},
  computed: {
    entityType() {
      return this.entity ? `App\\Models\\${this.entity.constructor.name}` : false
    },
  },
  mounted() {
    this.files = this.value
  },
  data() {
    return {
      files: [],
      fileExtMapper: {
        'image/jpg': 'jpg',
        'image/jpeg': 'jpeg',
        'image/png': 'png',
        'image/svg+xml': 'svg',
        'application/pdf': 'pdf',
      },
      newFiles: [],
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      store: 'file/store',
      destroy: 'file/destroy',
    }),
    async handleFilesUpload() {
      let newFiles

      if (this.multiple) {
        newFiles = this.newFiles
      } else {
        newFiles = [this.newFiles]
      }

      if (!newFiles.length) return

      let error = false

      if (this.extensions.length) {
        for (let file of newFiles) {
          let ext = this.getFileExt(file)

          if (!ext || !this.extensions.includes(ext)) {
            error = `File extension ${file.type} not supported`
            break
          }
        }
      }

      if (error) {
        window.app.snackbar({
          message: error,
          type: 'is-danger',
        })
        this.newFiles = []
        return
      }

      for (let file of newFiles) {
        await this.uploadFile(file)
      }
    },
    async uploadFile(file) {
      const formData = new FormData()
      formData.append('file', file)

      if (this.entity) {
        formData.append('entity_type', this.entityType)
        formData.append('entity_id', this.entity.id)
      }

      if (this.isImage(file)) {
        let dimensions = await this.getImageDimensions(file)
        formData.append('width', dimensions.width)
        formData.append('height', dimensions.height)
      }

      this.loading = true

      return this.store({ formData })
        .then((response) => {
          let newFile = {
            id: parseInt(response.id),
            url: response.attributes.url,
          }

          this.files = [...this.files, newFile]
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
          this.newFiles = []
        })
    },
    handleRemoveImage(file, index) {
      this.loading = true

      this.destroy({ id: file.id })
        .then(() => {
          // It will be removed from store
        })
        .catch((error) => {
          window.app.snackbarError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    isImage(file) {
      return ['jpg', 'jpeg', 'png'].includes(this.getFileExt(file))
    },
    getImageDimensions(file) {
      return new Promise((resolve, reject) => {
        let dimensions = { width: 0, height: 0 }

        let reader = new FileReader()

        reader.onload = () => {
          let img = new Image()
          img.onload = () => {
            dimensions.width = img.width
            dimensions.height = img.height
            resolve(dimensions)
          }
          img.src = reader.result
        }

        reader.readAsDataURL(file)
      })
    },
    getFileExt(file) {
      let type = file.type.toLowerCase()
      return type in this.fileExtMapper ? this.fileExtMapper[type] : false
    },
    openFile(file) {
      window.open(file.url, '_blank')
    },
  },
  props: {
    extensions: {
      default: () => [],
      required: false,
    },
    entity: {
      default: false,
      required: false,
    },
    multiple: {
      default: () => false,
      required: false,
    },
    value: {
      default: () => [],
      required: false,
    },
  },
  watch: {
    files() {
      this.$emit('input', this.files)
    },
    value() {
      this.files = this.value
    },
  },
}
</script>

<style lang="scss">
.files-container {
  flex-wrap: wrap;

  .file-content {
    cursor: pointer;
  }

  .remove-icon .icon {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 2em;
    width: 2em;
    z-index: 10;
    background: #000000b5;
    color: #fff;
    cursor: pointer !important;
  }
}
</style>
