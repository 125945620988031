<template>
    <div>
        <div class="tabs">
            <ul>
                <li
                    :class="{'is-active': ['admin/service/create', 'admin/service/update'].includes(routeName)}"
                    @click="goto('admin/service/update')">
                    <a>General</a>
                </li>
<!--                Not used ATM            -->
<!--                <li :class="{'is-active': ['admin/service/calendar'].includes(routeName), 'disabled': newEntity}"-->
<!--                    @click="handleClick('admin/service/calendar')">-->
<!--                    <a>Calendar</a>-->
<!--                </li>-->
                <li :class="{'is-active': ['admin/service/working-hours'].includes(routeName), 'disabled': newEntity}"
                    @click="goto('admin/service/working-hours')">
                    <a>Working hours</a>
                </li>
                <li :class="{'is-active': ['admin/service/rules'].includes(routeName), 'disabled': newEntity}"
                    @click="goto('admin/service/rules')">
                    <a>Rules</a>
                </li>
               <li :class="{'is-active': ['admin/service/items'].includes(routeName), 'disabled': newEntity}"
                   @click="goto('admin/service/items')">
                 <a>Items</a>
               </li>
                <li :class="{'is-active': ['admin/service/email'].includes(routeName), 'disabled': newEntity}"
                    @click="goto('admin/service/email')">
                    <a>Email</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormMenu",
    computed: {
        newEntity() {
            return !this.$route.params.serviceId;
        },
        routeName() {
            return this.$route.name;
        }
    },
    mounted() {
    },
    methods: {
        goto(routeName) {
            const route = window.app.findRouteByName(routeName, [], {
                params: {
                    serviceId: this.$route.params.serviceId
                },
            });

            this.$router.push(route);
        }
    },
}
</script>

<style scoped lang="scss">

.disabled {
    pointer-events:none;
    opacity:0.6;
}

</style>
