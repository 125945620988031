<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="p:12 flex:between|center">
          <div class="font--primary f:36 bold">
            Users
          </div>
          <button
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="handleNew"
          >
            <Icon admin name="user-plus" />
            New User
          </button>
        </div>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">

              <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                <div class="column">
                            <b-table
                              sticky-header
                              height="calc(100vh - 324px)"
                                :data="data"
                                :columns="columns"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true"
                                @click="handleEdit"
                                @sort="handleChangeSort">
                            </b-table>
                        </div>
                    </div>
                    <div v-if="paginator"  class="columns">
                        <div class="column">
                            <Paginator :paginator="paginator"></Paginator>
                        </div>
                    </div>


                </div>
            </div>

            <UserModal
              v-if="$route.query.show"
              @close="$router.back()"
            />
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import Paginator from '@/views/Admin/components/Paginator';
import UserModal from '@/components/admin/User/UserModal';

import {
    User,
} from '@/internal'

export default {
    name: 'Index',
    components: {
        Paginator,
        UserModal
    },
    computed: {
      data() {
       return this.ids ? this.$store.getters['user/collection'](this.ids) : [];
      },
      fullPath () {
          return this.$route.fullPath;
      },
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            ids: [],
            columns: [
                {
                    field: 'email',
                    label: 'Email',
                },
                {
                    field: 'username',
                    label: 'Name',
                },
            ],
            paginator: null,
            loading: false
        }
    },
    methods: {
        ...mapActions({
            userIndex: 'user/index',
        }),
        handleNew() {
          this.$router.push({ query: { ...this.$route.query, show: 'create' } });
        },
        handleEdit(row) {
          this.$router.push({ query: { ...this.$route.query, show: row.id } });
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        loadData() {
            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 10,
                sortBy: this.$route.query.sortBy || 'created_at',
                sortOrder: this.$route.query.sortOrder || 'desc',
                type: User.typeStaff
            };

            if (this.search) {
                params.search = this.search;
            }

            this.userIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
