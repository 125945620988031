<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <h2 class="modal-card-title fs-2">New Customer</h2>
                <button
                    type="button"
                    class="delete"
                    @click="handleClose"/>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <BField label="First Name *" >
                                <BInput type="text" v-model="form.fields.firstName">
                                </BInput>
                            </BField>
                            <FormError field="first_name" :form="form"/>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <BField label="Last Name *" >
                                <BInput type="text" v-model="form.fields.lastName">
                                </BInput>
                            </BField>
                            <FormError field="last_name" :form="form"/>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <BField label="Email *" >
                        <BInput type="email" v-model="form.fields.email">
                        </BInput>
                    </BField>
                    <FormError field="email" :form="form"/>
                </div>

                <div class="field" style="margin-bottom: 6rem !important">
                    <BField class="mb-2" label="Phone *">
                        <div class="phone-input">
                            <div class="w-100">
                                <div class="column px-0">
                                    <MazPhoneNumberInput
                                        v-model="phoneNumber"
                                        @update="handlePhoneNumberUpdate"
                                        :default-phone-number="''"
                                        default-country-code="GB"
                                    />
                                </div>
                            </div>
                        </div>
                    </BField>
                    <FormError field="phone" :form="form"/>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Close"
                    @click="handleClose" />
                <b-button
                    class="is-pulled-right"
                    label="Save" @click="handleSave"
                    type="is-primary" />
            </footer>
        </div>
    </div>
</template>

<script>
import {
    Form,
} from '@/internal/modules';

import {mapActions} from "vuex";
import { MazPhoneNumberInput } from 'maz-ui'
import mixin from "@/mixin";

export default {
    name: 'NewCustomerModal',
    mixins: [mixin],
    components: {
        MazPhoneNumberInput,
    },
    computed: {
        countries() {
            return this.$store.getters['country/all'];
        }
    },
    data() {
        return {
            loading: false,
            form: new Form({
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                phoneCountryCode: null
            }),
            phoneNumber: null,
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions({
            customerStore: 'customer/store',
        }),
        handleSave() {
            this.loading = true;

            this.form.errors.clear();

            this.customerStore({
                ...this.form.fields
            }).then((response) => {
                this.clearForm();
                this.$emit('success', response);
            }).catch((response) => {
                this.form.recordErrors(response);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleClose() {
            this.clearForm();
            this.$emit('close');
        },
        clearForm() {
            this.form.fields = {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                phoneCountryCode: null
            };
        },
        handlePhoneNumberUpdate(event) {
            if(!event.isValid) {
                return;
            }
            this.form.fields.phone = event.phoneNumber;
            this.form.fields.phoneCountryCode = event.countryCallingCode;
        },

    }
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
