import { ResourceService } from '@/internal/modules';

export default class UserService extends ResourceService {

    constructor() {
        super();
        this.baseUrl = 'user';
    }

    setActiveBooking(payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'post',
            url: `${this.baseUrl}/active-booking`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    activeBookingSummary(payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/active-booking/summary`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    booking(payload = {}) {
        const { params, ...rest } = payload;

        return this.request({
            method: 'get',
            url: `${this.baseUrl}/booking`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    calendar(id, payload = {}) {

        const { params, ...rest } = payload;

        return this.request({
            method: 'get',
            url:`user/${id}/calendar`,
            params: this.prepareParams(params),
            data: this.preparePayload(rest),
        });
    }

    loginUrl(id) {
        return this.request({
            method: 'post',
            url:`user/${id}/login-url`
        });
    }

    notificationSettings(payload = {}) {
        const { params, ...rest } = payload;
        return this.request({
            method: 'put',
            url: `${this.baseUrl}/notification-settings`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    requestDataDownload(payload = {}) {
        const { params, ...rest } = payload;
        return this.request({
            method: 'put',
            url: `${this.baseUrl}/request-data-download`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }

    requestAccountDelete(payload = {}) {
        const { params, ...rest } = payload;
        return this.request({
            method: 'put',
            url: `${this.baseUrl}/request-account-delete`,
            params: this.prepareParams(params),
            data: this.preparePayload(payload)
        });
    }
}
