<template>
  <transition name="fade">
    <div class="backdrop" @click.self="closeOnBackdrop && close()">
      <transition name="slide-fade" appear>
        <div v-if="drawerOpen" class="h:full bg:white r:8 ofh flex-col rel w:100%" :class="_class">
          <b-loading :is-full-page="false" v-model="loading" />
          <div class="flex:between|center p:24">
            <button class="reset lh:0" @click="close">
              <Icon admin name="x" class="c:primary" />
            </button>
            <slot name="header" />
          </div>

          <div class="flex:1 ofy:auto">
            <slot />
          </div>

          <div class="flex:end|center p:12|24 bt:base-20 gap:32">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>

export const sideDrawerMixin = {
  methods: {
    routeShowEdit(value) {
      const id = value.id || value;
      this.$router.push({ query: { ...this.$route.query, show: id } });
    },
    routeShowCreate() {
      this.$router.push({ query: { ...this.$route.query, show: 'create' } });
    }
  }
}

export default {
  name: 'SideDrawer',
  props: {
    _class: {
      type: String,
      default: 'w:960@lg',
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerOpen: true,
    };
  },
  methods: {
    close() {
      this.drawerOpen = false;
      setTimeout(() => this.$emit('close'), 200);
    },
  },
};
</script>

<style scoped>
.backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 39;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
