import {
    Model,
} from '@/internal/modules';

import {
    FileService as FileService
} from '@/internal';

export default class File extends Model {
    static get name() {
        return 'File';
    }
    static get vuexModuleKey() {
        return 'file';
    }
    static get service() {
        return FileService;
    }

    static get fields () {
        return {
            name: {
                required: true,
                type: String,
            },
        };
    }


}
