import {
    Model,
} from '@/internal/modules';

import {
    CartServiceResource as CartServiceResource, // service
    CartService,
} from '@/internal';

export default class Cart extends Model {
    static get name() {
        return 'Cart';
    }
    static get vuexModuleKey() {
        return 'cart';
    }
    static get service() {
        return CartServiceResource;
    }

    static get fields () {
        return {

        };
    }

    get cartServices() {
        return this.hasMany(CartService);
    }

}
