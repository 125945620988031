<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <h2 class="modal-card-title fs-2">Item</h2>
                <button
                    type="button"
                    class="delete"
                    @click="handleClose"/>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <b-field label="Name" >
                                <b-input type="text" placeholder="Name" v-model="form.fields.title">
                                </b-input>
                            </b-field>
                            <FormError field="title" :form="form"/>
                        </div>
                        <div class="field">
                          <b-field label="Description" >
                            <b-input type="textarea" v-model="form.fields.description"/>
                          </b-field>
                          <FormError field="description" :form="form"/>
                        </div>
                        <div class="field">
                          <b-field label="Capacity" >
                            <b-input type="number" v-model="form.fields.capacity"/>
                          </b-field>
                          <FormError field="capacity" :form="form"/>
                        </div>
                        <div class="field">
                          <b-field label="Configuration">
                            <ul>
                              <li>
                                <b-checkbox v-model="form.fields.modular">
                                  Combinable
                                </b-checkbox>
                              </li>
                            </ul>
                          </b-field>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    label="Close"
                    @click="handleClose" />
                <b-button
                    class="is-pulled-right"
                    label="Save" @click="handleSave"
                    type="is-primary" />
            </footer>
        </div>
    </div>
</template>

<script>
import {
    Form,
} from '@/internal/modules';

import {mapActions} from "vuex";
import mixin from "@/mixin";

export default {
    name: 'ServiceItemModal',
    mixins: [mixin],
    components: {},
    props: {
        item: {
            type: Object || null,
            required: false
        },
        service: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            form: new Form({
                serviceId: this.service.id,
                order: 0,
                type: 'table',
                title: null,
                description: null,
                modular: false,
                capacity: 0
            }),
        };
    },
    mounted() {
      console.log(this.item)
      if(this.item) {
            this.fillForm();
        }
    },
    methods: {
        ...mapActions({
            serviceItemStore: 'serviceItem/store',
            serviceItemUpdate: 'serviceItem/update',
        }),
        fillForm() {
            this.form.fields = {
                ...this.item
            }
        },
        handleSave() {
            this.loading = true;

            this.form.errors.clear();

            const fields = {
                ...this.form.fields,
            }

            let action = null;
            if (this.item) {
                action = this.serviceItemUpdate;
            } else {
                action = this.serviceItemStore;
            }

            action(fields).then((response) => {
                this.clearForm();
                this.$emit('success', response);
            }).catch((response) => {
                this.form.recordErrors(response);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleClose() {
            this.clearForm();
            this.$emit('close');
        },
        clearForm() {
            this.form.fields = {
              serviceId: this.service.id,
              order: 0,
              type: 'table',
              title: null,
              description: null,
              modular: false,
              capacity: 0
            };
        },
    }
}
</script>

<style scoped lang="scss">
.modal-card-head {
    p {
        margin-bottom: 0;
    }
}
.modal-card-body {
    min-height: 175px;
}
.modal-card-foot {
    background-color: #fff;
    border-top: none;
}
</style>
