<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>

        <div class="p:12 flex:between|center">
          <div class="font--primary f:36 bold">
            Holidays & exceptions
          </div>
          <button
            class="reset ff:callout flex:|center gap:8 f:20 fw:xbold c:white bg:accent p:10|12 r:8 o:0.9:hover"
            @click="handleNew"
          >
            <Icon admin name="calendar-plus" />
            New Exception
          </button>
        </div>

        <section class="section p-3" style="height: calc(100vh - 202px);">

          <div class="card p-4 border--light-grey" style="height: 100%">
            <div class="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between" style="height: 100%;">

              <div class="columns" style="height: calc(100% - 52px); overflow-y: auto;">
                <div class="column">
                            <b-table
                              sticky-header
                              height="calc(100vh - 324px)"
                                :data="data"
                                :striped="true"
                                :hoverable="true"
                                :sortable="true"
                                @click="handleEdit"
                                @sort="handleChangeSort">

                                <b-table-column label="Closed days" v-slot="props">
                                    {{ dateFormat(props.row.startDate) }} - {{ dateFormat(props.row.endDate) }}
                                </b-table-column>

                                <b-table-column label="Closed hours" v-slot="props">
                                    {{ props.row.startTime }} - {{ props.row.endTime }}
                                </b-table-column>

                                <b-table-column label="Description" v-slot="props">
                                    {{ props.row.description }}
                                </b-table-column>

                                <b-table-column label="" v-slot="props">
                                    <b-dropdown :ref="`contextRow${props.row.id}`" aria-role="list" position="is-bottom-left"
                                               class="has-text-left is-clickable">

                                        <template #trigger="{ active }">
                                            <div @click.prevent.stop="handleRowActionClick(props.row)" >
                                                <BIcon icon="ellipsis"/>
                                            </div>
                                        </template>

                                        <b-dropdownItem aria-role="listitem" >
                                            <div @click.prevent.stop="handleRowActionUpdate(props.row)">
                                                <BIcon icon="pencil-alt"/>
                                                Update
                                            </div>
                                        </b-dropdownItem>

                                        <b-dropdownItem aria-role="listitem">
                                            <div @click.prevent.stop="handleRowActionDelete(props.row)">
                                                <BIcon icon="trash-alt"/>
                                                Delete
                                            </div>
                                        </b-dropdownItem>

                                    </b-dropdown>
                                </b-table-column>

                            </b-table>
                        </div>
                    </div>
                    <div v-if="paginator"  class="columns">
                        <div class="column">
                            <Paginator :paginator="paginator"></Paginator>
                        </div>
                    </div>

                </div>
            </div>

    </section>
    <ClosedHourModal
      v-if="$route.query.show"
      @close="$router.back()"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import Paginator from '@/views/Admin/components/Paginator';
import {debounce} from "lodash";
import mixin from "@/mixin";

import ClosedHourModal from '@/components/admin/ClosedHour/ClosedHourModal'

export default {
    name: 'Index',
    mixins: [mixin],
    components: {
        Paginator,
        ClosedHourModal
    },
    computed: {
      data() {
       return this.ids ? this.$store.getters['closedHour/collection'](this.ids) : [];
      },
      fullPath () {
          return this.$route.fullPath;
      },
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            ids: [],
            paginator: null,
            search: this.$route.query.search || null,
            performSearch: debounce(() => {
                const route = Object.assign({}, this.$route);

                let query = {...route.query};

                if(this.search && this.search !== "") {
                    query.search = this.search;
                } else {
                    delete query.search;
                }

                this.$router.push({
                    name: route.name,
                    query: query
                });
            }, 1000),
            loading: false
        }
    },
    methods: {
        ...mapActions({
            closedHourIndex: 'closedHour/index',
            closedHourDestroy: 'closedHour/destroy'
        }),
        handleNew() {
          this.$router.push({ query: { ...this.$route.query, show: 'create' } });
        },
        handleEdit(row) {
          this.$router.push({ query: { ...this.$route.query, show: row.id } });
        },
        handleChangeSort (sortBy, sortOrder, event) {
            if(!event) {
                return
            }

            event.preventDefault();

            const route = Object.assign({}, this.$route);

            this.$router.push({
                name: route.name,
                query: {
                    ...route.query,
                    sortBy: sortBy,
                    sortOrder: sortOrder,
                },
            });
        },
        handleSearch() {
            this.performSearch();
        },
        handleRowActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        handleRowActionUpdate(row) {
            this.$router.push('/admin/closed-hour?show=' + row.id);
        },
        handleRowActionDelete(row) {
            window.app.confirm({
                title: 'Delete Hours',
                message: 'Are you sure you want to delete this hours?',
                onConfirm: () => {
                    this.loading = true;

                    this.closedHourDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Hours deleted');
                        this.loadData();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });

                },
            });

        },
        loadData() {
            this.loading = true;

            const params = {
                page: this.$route.query.page || 1,
                perPage: this.$route.query.perPage || 10,
                sortBy: this.$route.query.sortBy || 'start_date',
                sortOrder: this.$route.query.sortOrder || 'desc',
            };

            if (this.search) {
                params.search = this.search;
            }

            this.closedHourIndex({
                params,
            }).then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.ids = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        dateFormat(value) {
          return this.dateFormatTimezone(value).format('DD/MM/YYYY');
        },
    },

    watch: {
        fullPath () {
            this.loadData();
        },
    },
}
</script>
