<template>
    <div>
        <b-loading v-model="loading" ></b-loading>

        <section class="section is-title-bar hero is-hero-bar">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <ul>
                            <li>
                                <router-link to="/admin">Home</router-link>
                            </li>
                            <li>
                                <router-link to="/admin/user/account">My Account</router-link>

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container container-extra-small">
                <div class="box">
                    <div class="">
                        <div class="">
                            <div class="field">
                                <BField label="Name *" >
                                    <BInput type="text" v-model="form.fields.username">
                                    </BInput>
                                </BField>
                                <FormError field="username" :form="form"/>
                            </div>

                            <div class="field">
                                <BField label="Email *" >
                                    <BInput type="email" v-model="form.fields.email" :disabled="true">
                                    </BInput>
                                </BField>
                                <FormError field="email" :form="form"/>
                            </div>

                            <div class="field">
                                <b-button type="is-ghost" class="pl-0" @click="handleChangePasswordClick">Change password</b-button>
                            </div>

                            <template v-if="form.fields.changePassword">
                                <div class="field">
                                    <BField label="Old password *" >
                                        <BInput type="password" v-model="form.fields.password">
                                        </BInput>
                                    </BField>
                                    <FormError field="password" :form="form"/>
                                </div>

                                <div class="field">
                                    <BField label="New password *" >
                                        <BInput type="password" v-model="form.fields.newPassword">
                                        </BInput>
                                    </BField>
                                    <FormError field="new_password" :form="form"/>
                                </div>

                                <div class="field">
                                    <BField label="New password confirmation *" >
                                        <BInput type="password" v-model="form.fields.newPasswordConfirm">
                                        </BInput>
                                    </BField>
                                    <FormError field="new_password_confirm" :form="form"/>
                                </div>
                            </template>

                            <div class="controls has-margin-top">
                                <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Account',
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    mounted() {
        if(this.user) {
            this.fillForm();
        }
    },
    data() {
        return {
            form: new Form({
                username: '',
                email: '',
                changePassword: false,
                password: null,
                newPassword: null,
                newPasswordConfirm: null
            }),
            loading: false,
        };
    },
    methods: {
        ...mapActions({
            serviceIndex: 'service/index',
            userDestroy: 'user/destroy',
            userStore: 'user/store',
            userUpdate: 'user/update',
            userCalendar: 'user/calendar',
        }),
        handleSave() {
            this.loading = true;
            this.form.errors.clear();

            let action = null;

            if (this.user) {
                action = this.userUpdate;
            } else {
                action = this.userStore;
            }

            action({
                ...this.form.fields,
            }).then(() => {
                window.app.snackbar({
                    message: 'Saved successfully!',
                });
                this.$router.push('/admin/user');
            }).catch((error) => {
                this.form.recordErrors(error);
                window.app.snackbarError(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        handleChangePasswordClick() {
            this.form.fields.changePassword = true;
        },
        fillForm() {
            if(!this.user){
                return;
            }

            this.form.fields = {
                ...this.form.fields,
                id: this.user.id,
                email: this.user.email,
                username: this.user.username
            }
        }
    },
    watch: {
        user() {
            this.fillForm();
        },
    }
};
</script>

<style scoped>

</style>
