import {
    ApiService
} from '@/internal/modules';


export default class DashboardService extends ApiService {

  index(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: '/dashboard',
      params: this.prepareParams(params),
    });
  }

}
