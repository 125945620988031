<template>
    <section class="section height-full min-height-full pb-0">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="flex-column is-flex is-flex-direction-column height-full">
                <h1 class="fs-22 has-text-weight-semibold mb-3">Your experience for</h1>

                <HorizontalCalendar v-model="date"/>

                <template v-if="bookings.length">
                    <div class="services-container services-container-height is-block">
                        <template v-for="(booking, index) in bookings">
                             <ActivityCard :class="[index + 1 === bookings.length ? 'floating-btn-padding' : 'pb-0']" :booking="booking" />
                        </template>
                    </div>
                </template>

                <template v-else>
                     <div class="text-center mt-5">
                         <img src="/images/girl2-1.svg" width="194"/>
                         <p class="has-text-black has-text-weight-semibold fs-18 my-5">No activities booked for this date</p>
                     </div>
                </template>

                <div :class="['mt-auto', {'pb-4': !bookings.length}]">
                    <div :class="{'button--float': bookings.length}">
                        <BButton type="is-black" class="border-radius-12" :expanded="!bookings.length" @click="addActivity">+ ADD ACTIVITY</BButton>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
import {mapActions} from "vuex";

import moment from 'moment';

import HorizontalCalendar from "../components/HorizontalCalendar";
import ActivityCard from "../components/ActivityCard";

export default {
    name: "Calendar",
    components: { HorizontalCalendar, ActivityCard },
    computed: {
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        activeBooking() {
            return this.user.activeBookingId ? this.$store.getters['activeBooking/show'](this.user.activeBookingId) : null;
        },
        bookings() {
            return this.bookingIds ? this.$store.getters['booking/collection'](this.bookingIds) : [];
        },
        fullPath () {
            return this.$route.fullPath;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        formattedDate() {
            return this.date ? moment(this.date).format('ddd, MMM Do') : null
        }
    },
    mounted() {
        if(this.$route.query.bookingSuccess) {
            this.showSuccessBar();
        }

        if(this.$route.query.date) {
            this.date =  moment(this.$route.query.date).toDate();
        }
    },
    data() {
        return {
            bookingIds: [],
            loading: false,
            date: null
        }
    },
    methods: {
        ...mapActions({
            bookingStore: 'booking/store',
            userBookings: 'user/booking'
        }),
        loadBookings() {
            this.loading = true;

            const params = {
                date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
                type: 'activity'
            };

            this.userBookings({
                params,
            }).then((response) => {
                this.bookingIds = response;
            }).finally(() => {
                this.loading = false;
            });
        },
        addActivity() {
            this.$router.push({
                path: '/calendar/add',
                query: {
                    date: this.date ? moment(this.date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
                }
            });
        },
        showSuccessBar() {
            window.app.snackbar({
                message: `<div class="has-text-weight-semibold">Experience Booked</div><div class="has-text-weight-normal">We’ve added it to your calendar</div`,
                position: 'is-bottom',
                actionText: null,
            });
        }
    },
    watch: {
        date() {
            this.loadBookings();
        }
    }
}
</script>

<style lang="scss">
.services-container {
    flex-wrap: wrap;

    &-height {
        height: calc(100vh - 296px);
    }

    .card {
        cursor: pointer;
    }
}
.button--float {
    position: fixed;
    bottom: 100px;
    right: 18px;

    button {
        box-shadow: 0 8px 24px -4px rgba(28, 50, 79, 0.38), 0px 2px 6px -1px rgba(28, 55, 90, 0.16);
    }
}

.floating-btn-padding {
    padding-bottom: 72px !important;
}
</style>


