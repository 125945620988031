<template>
  <div>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
    <div v-if="service">
      <section class="section is-title-bar hero is-hero-bar">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <ul>
                <li>
                  <router-link to="/admin">Home</router-link>
                </li>
                <li>
                  <router-link to="/admin/service">Services</router-link>
                </li>
                <li v-if="service">
                  <router-link :to="{ name: 'admin/service/update', params: { serviceId: service.id } }">
                    {{ service.name }}
                  </router-link>
                </li>
                <li>
                  Email
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="section">

        <FormMenu />

        <div class="container container-extra-small">
          <div class="box">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <b-field label="Email content">
                    <MarkdownEditor v-model="form.fields.emailContent" />
                  </b-field>
                  <FormError field="emailContent" :form="form" />
                </div>
                <div class="controls has-margin-top">
                  <b-button type="is-primary" @click="handleSave" :loading="loading">Save</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormMenu from '@/views/Admin/Service/FormMenu';
import Paginator from '@/views/Admin/components/Paginator';
import MarkdownEditor from '@/components/admin/MarkdownEditor.vue';

import { Form } from '@/internal/modules';
import { Service } from '@/internal'

export default {
  name: 'Email',
  components: {
    FormMenu,
    Paginator,
    MarkdownEditor,
  },
  computed: {
    service() {
      return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
    },
    timezone() {
      return this.$store.state.system.timezone;
    }
  },
  mounted() {
    this.loadService();
  },
  data() {
    return {
      paginator: null,
      loading: false,
      serviceId: null,
      form: new Form({
        email_content: '',
      }),
    };
  },
  methods: {
    ...mapActions({
      serviceShow: 'service/show',
      serviceUpdate: 'service/update',
    }),
    loadService() {
      this.loading = true;

      return this.serviceShow({
        id: this.$route.params.serviceId
      }).then((response) => {
        this.serviceId = response[0];
        this.form.fields = {
          ...this.service
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSave() {
      this.loading = true;
      this.form.errors.clear();

      const fields = {
        ...this.form.fields,
      };

      let action = null;

      if (this.service) {
        action = this.serviceUpdate;
      } else {
        return
      }

      action(Service.preparePayload(fields)).then((response) => {
        window.app.snackbar({
          message: 'Saved successfully!',
        });
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  }
};
</script>

<style lang="scss"></style>
