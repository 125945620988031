<template>
<!--    <section class="pb-100">-->
    <section>
        <div class="container container-extra-small p-0 height-full">
            <div v-if="logoutModal" class="new-modal">
                <div class="new-modal--inner has-text-black">
                    <div class="is-flex justify-content-between align-items-center mb-4">
                        <h4 class="has-text-weight-semibold fs-22">Are you sure?</h4>
                        <img @click="toggleLogoutModal" src="/images/close2.svg" width="20"/>
                    </div>
                    <p class="mb-4">If you log out, you’ll log in again to see all your stuff</p>
                    <BButton class="border-radius-12 button--light-red mb-4" @click="handleLogout" expanded :loading="loading">
                        <img src="/images/power2-red.svg" class="mr-2" />
                        Log out
                    </BButton>
                    <BButton type="is-black" class="border-radius-12" expanded @click="toggleLogoutModal">CANCEL</BButton>
                </div>
            </div>
            <div
                class="setting"
                v-for="setting in settings"
                @click="$router.push({ path: `/user/settings/${setting.route}` })"
            >
                <div
                    class="card-header is-shadowless is-radiusless border-0"
                    role="button"
                >
                    <img :src="`/images/${setting.icon}.svg`" width="30px" class="mr-4" />
                    <p class="card-header-title has-text-weight-medium mb-0 px-0">{{ setting.title }}</p>
                    <a class="card-header-icon p-0">
                        <b-icon icon="angle-right"></b-icon>
                    </a>
                </div>
            </div>

            <div
                class="setting"
                @click="toggleLogoutModal"
            >
                <div
                    class="card-header is-shadowless is-radiusless border-0"
                    role="button"
                >
                    <img src="/images/power2.svg" width="30px" class="mr-4" />
                    <p class="card-header-title has-text-weight-medium mb-0 px-0">Logout</p>
                    <a class="card-header-icon p-0">
                        <b-icon icon="angle-right"></b-icon>
                    </a>
                </div>
            </div>

            <div class="py-2 px-5 fs-12 has-text-black">
                <p>© 2023 Finn Lough. All rights reserved</p>
            </div>

            <!--  &lt;!&ndash; TODO make global (vuex) loading&ndash;&gt;-->
            <!--  <BButton type="is-text" class="has-text-danger" @click="handleLogout" :loading="loading">Logout</BButton>-->
        </div>
    </section>
</template>

<script>
import {mapActions} from 'vuex';

import {
    Form,
} from '@/internal/modules';

export default {
    name: 'Index',
    components: {},
    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    created() {
        if (this.user) {
            this.form.fields = {
                ...this.user
            }
        }
    },
    data() {
        return {
            form: new Form({
                firstName: '',
                lastName: '',
                email: '',
            }),
            loading: false,
            logoutModal: false,
            settings: [
                {
                    icon: 'booking-summary2',
                    title: 'Booking summary',
                    route: 'booking',
                },
                {
                    icon: 'account-settings2',
                    title: 'Account settings',
                    route: 'profile',
                },
                // {
                //     title: 'Timezone setup',
                //     route: 'timezone',
                // },
                // {
                //     icon: 'interests',
                //     title: 'Update My Interests',
                //     route: 'interests',
                // },
                {
                    icon: 'notifications-settings2',
                    title: 'Notifications settings',
                    route: 'notifications',
                },
                // {
                //     icon: 'experiences',
                //     title: 'My experiences',
                //     route: 'experiences',
                // },
                {
                    icon: 'data2',
                    title: 'My data',
                    route: 'data',
                },
            ]
        };
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        handleLogout () {
            this.logout().then(() => {

                this.$router.push('/');

                window.location.reload();
            });
        },
        toggleLogoutModal() {
            this.logoutModal = !this.logoutModal;
        }
    },
};
</script>

<style scoped lang="scss">
.setting {
    &:hover {
        background-color: #FFFFFF;
    }

    .card-image {
        width: 30px;
    }
}
</style>
