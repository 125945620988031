<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading"></b-loading>

        <BCollapse
            v-for="category in categories"
            class="show is-shadowless has-background-white"
            animation="slide"
            :key="category.id"
            :open="parseInt(catalogStatus.categoryOpen) === parseInt(category.id)"
            @open="handleCategoryOpen(category)"
            @close="handleCategoryClose(category)"
            :aria-id="'category' + category.id">
            <template #trigger="props">
                <div
                    class="card-header is-shadowless is-radiusless border-0 px-5"
                    role="button"
                    :aria-controls="'category-' + category.id"
                    :aria-expanded="parseInt(catalogStatus.categoryOpen) === parseInt(category.id)"
                >
                    <img :src="category.icon" class="mr-2"/>
                    <p class="card-header-title mb-0 px-0 py-2 has-text-black has-text-weight-semibold">{{ category.name }}</p>
                    <a class="card-header-icon p-0">
                        <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
                    </a>
                </div>
            </template>
            <div class="w-100">
                <div class="content">
                    <!--Category has subcategories-->
                    <template v-if="category.subcategories.length">
                        <BCollapse
                            v-for="subcategory in category.subcategories"
                            class="show has-background-light border-bottom app-grey--light-border"
                            animation="slide"
                            :key="subcategory.id"
                            :open="parseInt(catalogStatus.subcategoryOpen) === parseInt(subcategory.id)"
                            @open="handleSubCategoryOpen(subcategory)"
                            @close="handleSubCategoryClose(subcategory)"
                            :aria-id="'service' + subcategory.id">
                            <template #trigger="props">
                                <div
                                    class="card-header is-shadowless is-radiusless border-0 app-grey--lighter-bg px-5"
                                    role="button"
                                    :aria-controls="'service-' + subcategory.id"
                                    :aria-expanded="parseInt(catalogStatus.subcategoryOpen) === parseInt(subcategory.id)"
                                >
                                    <p class="card-header-title mb-0 px-5 py-2 has-text-black has-text-weight-normal">{{ subcategory.name }}</p>
                                    <a class="card-header-icon p-0">
                                        <b-icon :icon="props.open ? 'angle-up' : 'angle-down'"></b-icon>
                                    </a>
                                </div>
                            </template>
                            <div class="py-2 px-3 services-container app-grey--lighter-bg">
                                <template v-if="subcategory.services.length">
                                    <div class="service-card p-4" v-for="service in subcategory.services">
                                        <div class="is-clickable is-relative" @click="handleActivityClick(service)">
                                            <template v-if="service.imageUrl">
                                                <div class="service-card-img" :style="{'background-image' : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${service.imageUrl})`}">
                                                </div>
                                            </template >
                                            <template v-else>
                                                <div class="service-card-img" :style="{'background-image' : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(https://bulma.io/images/placeholders/1280x960.png)` }">
                                                </div>
                                            </template>

                                            <div class="service-card-info has-text-weight-semibold text-white text-wrap">
                                                <div>{{ service.nameFinal }}</div>
                                                <div v-if="service.showPricing">
                                                    <span class="has-text-weight-normal fs-12">Cost by person</span> <br>
                                                    <span v-if="service.priceDescription">
                                                        {{ service.priceDescription }}
                                                    </span>
                                                    <span v-else>
                                                        £{{ service.price }}
                                                     </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <p class="has-text-centered">Not available at the moment</p>
                                </template>
                            </div>
                        </BCollapse>
                    </template>
                    <!--Category has services-->
                    <template v-else>
                        <div class="py-2 px-3 services-container app-grey--lighter-bg">
                            <div class="service-card p-4" v-for="service in category.services">
                                <div class="is-clickable is-relative" @click="handleActivityClick(service)">
                                    <template v-if="service.imageUrl">
                                        <div class="service-card-img" :style="{'background-image' : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${service.imageUrl})`}">
                                        </div>
                                    </template >
                                    <template v-else>
                                        <div class="service-card-img" :style="{'background-image' : `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(https://bulma.io/images/placeholders/1280x960.png)` }">
                                        </div>
                                    </template>
                                    <div class="service-card-info has-text-weight-semibold text-white text-wrap">
                                        <div>{{ service.nameFinal }}</div>
                                        <div v-if="service.showPricing">
                                            <span class="has-text-weight-normal fs-12">Cost by person</span> <br>
                                            <span v-if="service.priceDescription">
                                                {{ service.priceDescription }}
                                            </span>
                                            <span v-else>
                                                 £{{ service.price }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="is-flex is-align-items-center is-justify-content-space-between">
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </BCollapse>
    </div>
</template>

<script>
import {mapActions} from "vuex";

import {
    Service,
} from '@/internal'

export default {
    name: 'ServiceCatalog',
    computed: {
        categories() {
            return this.categoryIds ? this.$store.getters['category/collection'](this.categoryIds).filter((category) => { return !category.categoryId }) : [];
        },
        user() {
            return this.$store.getters['auth/user'];
        },
        fullPath () {
            return this.$route.fullPath;
        }
    },
    mounted() {
        this.loadServiceCatalog();
        this.loadCatalogStatus();
    },
    data() {
        return {
            categoryIds: [],
            isOpen: 1,
            loading: false,
            catalogStatus: {
                categoryOpen: 0,
                subcategoryOpen: 0
            }
        };
    },
    methods: {
        ...mapActions({
            serviceCatalog: 'service/catalog'
        }),
        handleAccommodationBook(category) {
            if(!category.services.length) {
                alert("No service to book");
                return;
            }

            const service = category.services[0];

            const route = window.app.findRouteByName('service/accommodation/book', [], {
                params: {
                    serviceUuid: service.id,
                },
                query: {
                    categoryId: category.id
                }
            });

            this.$router.push(route);
        },
        handleAccommodationShow(category) {
            if(!category.services.length) {
                alert("No service to show");
                return;
            }

            const service = category.services[0];

            const route = window.app.findRouteByName('service/accommodation/show', [], {
                params: {
                    serviceUuid: service.id,
                },
                query: {
                    categoryId: category.id
                }
            });

            this.$router.push(route);
        },
        handleActivityClick(service) {
            const route = {
                name: 'service/activity/book',
                params: {
                    serviceUuid: service.id
                },
                query: {
                    ...this.$route.query
                }
            };

            this.$router.push(route);
        },
        handleCategoryOpen(category) {
            this.catalogStatus.categoryOpen = category.id;
        },
        handleCategoryClose(category) {
            this.catalogStatus.categoryOpen = 0;
        },
        handleSubCategoryOpen(subcategory) {
            this.catalogStatus.subcategoryOpen = subcategory.id;
        },
        handleSubCategoryClose(subcategory) {
            this.catalogStatus.subcategoryOpen = 0;
        },
        loadServiceCatalog() {
            this.loading = true;

            let params = {
                date: this.$route.query.date ?? null
            };

            this.serviceCatalog({
                params
            }).then((response) => {
                this.categoryIds = response.ids;
            }).finally(() => {
                this.loading = false;
            });
        },
        saveCatalogStatus() {
            window.app.storage.setItem('catalogStatus', this.catalogStatus)
        },
        loadCatalogStatus() {
            if(window.app.storage.getItem('catalogStatus')) {
                this.catalogStatus = window.app.storage.getItem('catalogStatus');
            }
        }
    },
    watch: {
        'catalogStatus': {
            handler() {
                this.saveCatalogStatus();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.services-container {
    overflow: auto;
    white-space: nowrap;
}
.service-card {
    display: inline-block;

    &-img {
        height: 196px;
        width: 196px;
        box-shadow: 0 12px 40px -6px rgba(28, 50, 79, 0.38), 0px 3px 18px -2px rgba(28, 55, 90, 0.16);
        border-radius: 24px;
        margin-bottom: 16px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center
    }

    &-info {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
    }

    .button.service-card-btn {
        height: 30pt;
    }
}
</style>
