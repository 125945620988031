<template>
    <section>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <template v-for="(notification, index) in notifications">
                <div :class="['notification-card' , {'notification-card--new' : notification.readAt}]" @click="handleShow(notification)" role="button">
                    <div class="border-bottom p-4">
    <!--                    <div class="is-shadowless is-radiusless border-0 mb-1">-->
                        <div class="is-flex">
    <!--                        <div :class="['notification-badge is-inline-block', { 'notification-badge&#45;&#45;transparent': notification.readAt}]"></div>-->
                            <img class="notification-image mb-1" :src="`/images/${notification.readAt ? 'envelope2open' : 'envelope2-black'}.svg`" />

                            <p :class="['mb-1 is-inline-block', notification.readAt ? 'has-text-weight-normal' : 'has-text-black has-text-weight-semibold']">{{ notification.data.subject }}</p>

    <!--                        <span class="notification-time is-inline-block is-pulled-right">-->
    <!--                            {{ moment(notification.createdAt).format('ddd, MMM Do') }}-->
    <!--                            <b-icon icon="angle-right"></b-icon>-->
    <!--                        </span>-->
                        </div>
                        <div class="is-flex">
                            <p :class="['mb-3 text-truncate notification-message', notification.readAt ? 'has-text-weight-normal app-grey' : 'has-text-black has-text-weight-medium']">{{ notification.data.message }}</p>
                            <span :class="['notification-badge is-inline-block is-flex-shrink-0 ml-2', {'notification-badge--transparent' : notification.readAt}]"></span>
                        </div>
                        <div>
                            <p :class="['notification-time mb-0', {'has-text-weight-normal app-grey' : notification.readAt}]">{{ notificationCreated(notification) }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import {mapActions} from "vuex";

import moment from 'moment';

export default {
    name: "Index",
    components: {},
    computed: {
        notifications() {
            return this.notificationIds ? this.$store.getters['notification/collection'](this.notificationIds) : [];
        },
        authenticated() {
            return this.$store.state.auth.authenticated;
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    mounted() {
        this.loadNotifications();
    },
    data() {
        return {
            notificationIds: [],
            loading: false,
            paginator: null,
            moment
        }
    },
    methods: {
        ...mapActions({
            notificationIndex: 'notification/index'
        }),
        dateFormat(value) {
            return moment(value).format("ddd, D MMM YYYY");
        },
        handleShow(notification) {
            const route = window.app.findRouteByName('notification/show', [], {
                params: {
                    notificationUuid: notification.id
                },
            });

            this.$router.push(route);
        },
        loadNotifications() {
            this.loading = true;

            this.notificationIndex().then((response) => {
                if (response.paginator) {
                    this.paginator = response.paginator;
                }

                this.notificationIds = response.ids;
            }).finally(() => {
                this.loading = false;
            })
        },
        notificationCreated(date) {
            const start_date = moment(date.createdAt,'YYYY-MM-DD HH:mm:ss');
            const end_date = moment(moment(),'YYYY-MM-DD HH:mm:ss');
            const duration = moment.duration(end_date.diff(start_date));
            return duration.days() > 0 ? moment(date.createdAt).format('ddd, MMM Do') : moment(date.createdAt).fromNow()
        }
    }
}
</script>

<style lang="scss">
    .notification-card {
    //    padding: 0.5rem 1rem;
        background-color: #f4f5f7;

        &--new {
            background-color: #ffffff;
        }
    }

    .notification-image {
        margin-right: 14px;
    }

    .notification-badge {
        height: 13px;
        width: 13px;
        background-color: #f52419;
        border-radius: 50%;
        margin-top: 5px;
        margin-right: 10px;

        &--transparent {
            @extend .notification-badge;
            background-color: transparent;
        }
    }
    .notification-message {
        //color: #706C64;
        //color: #5B6676;
        margin-left: 35px;
        font-size: 14px;
    }
    .notification-time {
        //color: #919190;
        //color: #5B6676;
        margin-left: 35px;
        font-size: 14px;
    }
</style>


