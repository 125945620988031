<template>
    <div>
        <b-loading :is-full-page="true" v-model="loading" ></b-loading>
        <div v-if="service">
            <section class="section is-title-bar hero is-hero-bar">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <ul>
                                <li>
                                    <router-link to="/admin">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/admin/service">Services</router-link>
                                </li>
                                <li v-if="service">
                                    <router-link :to="{ name:'admin/service/update', params: { serviceId: service.id }}">{{ service.name }}</router-link>
                                </li>
                                <li>
                                    Rules
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-button  @click="serviceRuleModalActive = true" class="mr-1" type="is-primary">Add Rule</b-button>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">

                <FormMenu />

                <div class="card">
                    <div class="card-content">
                        <div class="columns">
                            <div class="column">
                                <b-table
                                    :data="rules"
                                    :striped="true"
                                    :hoverable="true"
                                    :sortable="true">

                                    <b-table-column field="dayOfTheWeek" label="Rule" v-slot="props">
                                        {{ props.row.label }}
                                        <template v-if="hasInterval(props.row)">
                                            {{ props.row.startTime }} - {{ props.row.endTime }}
                                        </template>
                                    </b-table-column>

                                    <b-table-column field="from" label="Value" v-slot="props">
                                        {{ props.row.value }}
                                    </b-table-column>

                                    <b-table-column field="from" label="Apply" v-slot="props">
                                        {{ props.row.applyTypeDescription }}
                                    </b-table-column>

                                    <b-table-column label="" v-slot="props">
                                        <b-dropdown :ref="`contextRow${props.row.id}`"
                                                    aria-role="list"
                                                    position="is-bottom-left"
                                                    class="has-text-left is-clickable">

                                            <template #trigger="{ active }">
                                                <div @click.prevent.stop="handleRowActionClick(props.row)" >
                                                    <BIcon icon="ellipsis"/>
                                                </div>
                                            </template>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleUpdate(props.row)">
                                                    <BIcon icon="pencil-alt"/>
                                                    Update
                                                </div>
                                            </b-dropdownItem>

                                            <b-dropdownItem aria-role="listitem" class="pr-0">
                                                <div @click.prevent.stop="handleDelete(props.row)">
                                                    <BIcon icon="trash-alt"/>
                                                    Delete
                                                </div>
                                            </b-dropdownItem>

                                        </b-dropdown>
                                    </b-table-column>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <b-modal
                v-model="serviceRuleModalActive"
                trap-focus
                :destroy-on-hide="true"
                :can-cancel="false"
                aria-role="dialog"
                aria-label="Service Rule Modal"
                aria-modal>
                <template #default="props">
                    <ServiceRuleModal :rule="activeRule" :service="service" @success="handleRuleAddSuccess"  @close="props.close"  />
                </template>
            </b-modal>

        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FormMenu from '@/views/Admin/Service/FormMenu';
import Paginator from '@/views/Admin/components/Paginator';
import ServiceRuleModal from '@/views/Admin/components/ServiceRuleModal';

import moment from 'moment-timezone';

export default {
    name: 'Rules',
    components: {
        FormMenu,
        Paginator,
        ServiceRuleModal
    },
    computed: {
        service() {
            return this.serviceId ? this.$store.getters['service/show'](this.serviceId) : null;
        },
        timezone() {
            return this.$store.state.system.timezone;
        },
        rules() {
            return this.service ? this.service.rules : [];
        }
    },
    mounted() {
        this.loadService();
    },
    data() {
        return {
            paginator: null,
            loading: false,
            serviceId: null,
            serviceRuleModalActive: false,
            activeRule: null,
        };
    },
    methods: {
        ...mapActions({
            serviceShow: 'service/show',
            serviceRuleDestroy: 'serviceRule/destroy',
        }),
        handleRowActionClick(row) {
            this.$refs['contextRow' + row.id].toggle();
        },
        handleUpdate(row) {
            this.activeRule = row;
            this.serviceRuleModalActive = true
        },
        handleRuleAddSuccess() {
            this.serviceRuleModalActive = false;
            this.activeRule = null;

            window.app.snackbar({
                message: 'Added successfully!',
            });
            this.loadService();
        },
        handleDelete(row) {
            window.app.confirm({
                title: 'Delete Rule',
                message: 'Are you sure you want to delete this rule?',
                onConfirm: () => {
                    this.loading = true;

                    this.serviceRuleDestroy({id: row.id}).then(() => {
                        window.app.snackbar('Service rule deleted');
                        this.loadService();
                    }).catch((e) => {
                        if (e.response && e.response.data) {
                            window.app.snackbar({
                                message: e.response.data.message,
                                type: 'is-danger',
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                },
            });
        },
        loadService() {
            this.loading = true;

            return this.serviceShow({
                id: this.$route.params.serviceId,
                params: {
                    include: ['serviceRules']
                }
            }).then((response) => {
                this.serviceId = response[0];
            }).catch((error) => {

            }).finally(() => {
                this.loading = false;
            });
        },
        hasInterval(rule) {
            return ['max-persons-per-interval', 'max-bookings-per-interval'].includes(rule.type);
        }
    }
};
</script>

<style lang="scss">

</style>
