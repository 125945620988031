<template>
    <div class="pb-100">
        <b-loading :is-full-page="true" v-model="loading"></b-loading>
        <div class="container container-extra-small p-0 height-full">
            <div class="position-relative">
                <div class="home-image" :style="{'background-image' : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.77%), url(${backgroundImage})`}" alt="">
                    <img :src="account && account.branding.logoUrl" class="position-absolute mt-3 home-logo" style="max-width: 240px">
                </div>
                <div class="home-name position-absolute has-text-white font-primary">
                    <div class="mb-4">
                        <div class="has-text-weight-semibold fs-18">Welcome</div>
                        <div class="font-primary fs-50 has-text-weight-normal">{{ user.name }}</div>
                    </div>
                    <template v-if="activeBooking">
                        <template v-if="activeBooking.isGroup">
                            <div class="mb-4">
                                <div class="has-text-weight-semibold">You’re staying</div>
                                <div class="has-text-weight-normal">{{ activeBooking.group.startAt.format("D MMM YY") }} - {{ activeBooking.group.endAt.format("D MMM YY") }}</div>
                            </div>
                            <div class="fs-18 has-text-weight-semibold">{{ activeBooking.group.categoryName }}</div>
                        </template>
                        <template v-else>
                            <div class="mb-4">
                                <div class="has-text-weight-semibold">You’re staying</div>
                                <div class="has-text-weight-normal">{{ activeBooking.startAt.format("D MMM YY") }} - {{ activeBooking.endAt.format("D MMM YY") }}</div>
                            </div>
                            <div v-if="activeBooking.category" class="fs-18 has-text-weight-semibold">{{ activeBooking.category.name }}</div>
                        </template>
                    </template>
                    <template v-else-if="account && account.branding.showBookRoomButton">
                        <b-button class="button is-white border-radius-12 is-clickable" @click="handleBook">BOOK ROOM</b-button>
                    </template>
                </div>
            </div>
            <!-- Pending bookings -->
            <NonScheduledBookings v-if="nonScheduledBookings.length > 0" :nonScheduledBookings="nonScheduledBookings" />
            <!-- Announcements -->
            <News />
            <!-- Services -->
            <div class="text-black has-text-weight-semibold fs-22 px-5 py-3 position-relative has-background-white">
                Browse our activities
            </div>
            <ServiceCatalog />
            <!-- Information -->
            <Info v-if="activeBooking" />
        </div>
    </div>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";

    import ServiceCatalog from "@/views/components/ServiceCatalog";
    import NonScheduledBookings from "@/views/components/NonScheduledBookings";
    import News from "@/views/components/News";
    import Info from "@/views/components/Info";

    export default {
        name: "Home",
        components: {
            Info,
            News,
            NonScheduledBookings,
            ServiceCatalog
        },
        computed: {
            activeBooking() {
                return this.activeBookingId ? this.$store.getters['booking/show'](this.activeBookingId) : null;
            },
            nonScheduledBookings() {
                return this.activeBooking ? this.activeBooking.nonScheduledBookings : [];
            },
            authenticated() {
                return this.$store.state.auth.authenticated;
            },
            user() {
                return this.$store.getters['auth/user'];
            },
            account() {
              return this.$store.getters['account/current'];
            },
            backgroundImage() {
              if(this.activeBooking) {
                if(this.activeBooking.category && this.activeBooking.category.imageUrl) {
                  return this.activeBooking.category.imageUrl;
                }
              } else {
                return this.account && this.account.branding.homeImageUrl;
              }
            }
        },
        mounted() {
            if(this.user.activeBookingId) {
                this.loadActiveBooking();
            }
        },
        data() {
            return {
                activeBookingId: null,
                paginator: null,
                loading: false
            }
        },
        methods: {
            ...mapActions({
                bookingShow: 'booking/show'
            }),
            handleBook() {
                window.open(this.account && this.account.branding.bookRoomButtonUrl, '_blank');
            },
            loadActiveBooking() {
                this.loading = true;

                return this.bookingShow({
                    id: this.user.activeBookingId,
                    params: {
                        include: ['pendingNonScheduledBookings', 'group.bookings']
                    }
                }).then((response) => {
                    this.activeBookingId = response[0];
                }).catch((error) => {

                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style lang="scss">
.home {
    &-image {
        height: 390px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-logo {
        transform: translateX(-50%);
        left: 50%;
    }

    &-name {
        bottom: 20px;
        left: 20px;
        line-height: 1;
    }
}
</style>
