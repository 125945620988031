import {
  Model,
} from '@/internal/modules';

import {
    User,
    Booking,
    Order,
    Group,
    CustomerService as Service
} from '@/internal';

export default class Customer extends Model {
  static get name() {
        return 'Customer';
  }
  static get vuexModuleKey() {
      return 'customer';
  }
  static get service() {
      return Service;
  }

  static get fields () {
    return {
      email: {
        required: true,
        type: String,
      },
    };
  }

    get user() {
        return this.hasOne(User);
    }

    get activityBookings() {
        return this.hasMany(Booking);
    }

    get activeBooking() {
        return this.hasOne(Booking);
    }

    get orders() {
        return this.hasMany(Order);
    }

    get groups() {
        return this.hasMany(Group);
    }
}
