import {
    ServiceSpace as Model
} from '@/internal';

import {
    ResourceStore,
} from '@/internal/modules';


const store = new ResourceStore({
    key: 'serviceSpace',
    model: Model,
});

export default store.toObject();
