<template>
    <div @click="handleProductShow" class="product py-0">
        <div class="product-card border-radius-24 mb-5 overflow-hidden">
            <template v-if="product.imageUrl">
                <div class="product-image border-grey--light--thin"
                     :style="{'background-image' : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.77%), url(${product.imageUrl})`}"></div>
            </template>
            <template v-else>
                <div class="product-image"></div>
            </template>
            <div class="p-4">
                <div class="is-flex is-justify-content-center is-flex-direction-column">
                    <div class="product-title">{{ product.name }}</div>
                    <div class="my-3">£ {{ product.price }}</div>
<!--                    <GroupInput v-if="true" :show-icon="false" :full-width="false" v-model="productsTotal" />-->
                    <BButton type="is-black" class="border-radius-12 mb-0" @click="handleProductShow">
                        <img src="/images/cart2-white.svg" class="mr-2" alt="" />
                        ADD
                    </BButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GroupInput from '../components/GroupInput';
export default {
    name: 'ProductCard',
    components: {GroupInput},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            productsTotal: 0
        }
    },
    methods: {
        handleProductShow() {
            const route = window.app.findRouteByName('products/show', [], {
                params: {
                    productUuid: this.product.id
                },
            });

            this.$router.push(route);
        }
    }
}
</script>

<style lang="scss" scoped>
.product {
    cursor: pointer;
    line-height: 1.2;

    &-card {
        box-shadow: 0 12px 40px -6px rgba(28, 50, 79, .38), 0 3px 18px -2px rgba(28, 55, 90, .16);
    }

    &-title {
        min-height: 40px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }

    &-image {
        flex-shrink: 0;
        width: 100%;
        height: 112px;
        background-position: center;
        background-size: cover;
    }
}
</style>
